import React, { useState } from "react";
import useTranslations from "translations";
import { ArrowForwardIos } from "@material-ui/icons";
import CustomInput from "component/CustomInput/CustomInput";

const DiscountCoupon = ({ coupon, error, clearDiscount, applyDiscount }) => {
  const translation = useTranslations();
  const [showDiscountCoupon, setShowDiscountCoupon] = useState(null);
  const [discountCoupon, setDiscountCoupon] = useState(coupon);

  const handleClearDiscount = () => {
    setDiscountCoupon(null);
    setShowDiscountCoupon(false);
    clearDiscount();
  };

  const handleApplyDiscount = () => applyDiscount(discountCoupon.replace(' ', ''));
  const toggleDiscountForm = () => setShowDiscountCoupon(!showDiscountCoupon);

  return <>
    <div onClick={toggleDiscountForm} className="new__button">
      <p>{coupon ? `${translation.Payment.couponApplied} - ${coupon}` : translation.Payment.haveAnyDiscount}</p>
      <ArrowForwardIos style={{ color: '#2c2c2c', fontSize: "large", fontWeight: "bold", transform: showDiscountCoupon ? 'rotate(90deg)' : '' }} component="svg" />
    </div>
    {showDiscountCoupon && <div className="coupon">
      <div className="grid--small-form">
        <CustomInput
          id="couponMessage"
          errorMessage={error}
          fieldLabel={translation.Payment.coupon}
          placeholder={translation.Payment.discountCode}
          value={discountCoupon}
          onChange={evt => setDiscountCoupon(evt.target.value)}
        />
        {coupon ?
          <button className="button--primary button--max button--plus" onClick={handleClearDiscount}>
            <h3><strong>{translation.Payment.clearCoupon}</strong></h3>
          </button> :
          <button className="button--primary button--max button--plus" onClick={handleApplyDiscount} disabled={!discountCoupon}>
            <h3><strong>{translation.Payment.apply}</strong></h3>
          </button>}
      </div>
    </div>}
  </>;
}

export default DiscountCoupon;