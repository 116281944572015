import React from "react";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if (["production", "prod"].includes(process.env.NODE_ENV)) {
      localStorage.removeItem("backTo");
      localStorage.removeItem("persist:delivery");
      this.props.history.push('/');
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', height: '100vh', width: '100vw' }}>
        <h1>Something went wrong.</h1>
      </div>;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
