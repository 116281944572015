import React, { useState } from "react";
import './css/CustomInputPasswordStrengthMeterStyled.scss';
import CustomInput from "../CustomInput/CustomInput";
import zxcvbn from "zxcvbn";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const CustomInputPasswordStrengthMeter = (props) => {
  const [password, setPassword] = useState("");
  const [secured, setSecured] = useState({
    score: 0
  });
  const securityLabels = ["", "Weak", "Fair", "Good", "Strong"];
  const [inputPasswordType, setInputPasswordType] = useState(true);
  const handleOnChange = event => {
    const { value } = event.target;
    setPassword(value);
    setSecured(zxcvbn(value));
    props.onChange(event);
  };
  const handleShowPassword = () => setInputPasswordType(!inputPasswordType);
  return (
    <div className="password-strength-meter">
      <CustomInput
        id="pid"
        type={inputPasswordType ? "password" : "text"}
        fieldLabel={props.fieldLabel}
        value={password}
        onChange={handleOnChange}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              href={""}
              size="small"
              aria-label="toggle password visibility"
              onClick={() => handleShowPassword()}
            >
              {!inputPasswordType ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <div className="password-strength-meter__progress-container">
        <progress 
          className={`password-strength-meter__progress strength-${secured.score}`}
          value={secured.score} max="4" />
        <p>{securityLabels[secured.score]}</p>
      </div>
    </div>
  );
};

export default CustomInputPasswordStrengthMeter;
