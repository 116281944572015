import { sha256 } from "js-sha256";
import _ from "lodash";
import { REGULAR_EXPRESSIONS } from "./Utils";
import pako from "pako";
import { store } from "../App";
import * as moment from "moment";

moment.locale("es", {
  months:
    "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
  monthsShort:
    "Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split("_"),
  weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
  weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
  weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
});

export const ENCRYPT = (data) => {
  let array = new Buffer(data);
  let base64 = array.toString("base64");
  return base64 + "|" + sha256.hex(array);
};

export function validateFieldByArray(
  value,
  validators,
  length = 0,
  caption = null
) {
  if (!validators) return;

  let message = "";
  _.forEach(validators, (rule) => {
    let validated = rules(rule, value, length, caption);
    if (validated !== null && validated !== undefined) {
      message = validated;
      return message;
    }
  });
  return message ? message : null;
}

function rules(validation_type, value, length, caption = null) {
  switch (validation_type) {
    case "required":
      if (!value || !value.toString().trim().length) {
        return caption || "Campo requerido";
      }
      break;
    case "integer":
      if (!Number.isInteger(Number(value))) {
        return caption || "No es un entero válido";
      }
      break;
    case "number":
      if (isNaN(Number(value))) {
        return caption || "Número inválido";
      }
      break;
    case "email":
      if (value && value !== "") {
        let emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!emailValid) {
          return caption || "Dirección de correo inválida";
        }
      }
      break;
    case "password":
      let passwordValid = value.length >= 6;
      if (!passwordValid) {
        return caption || "La contraseña debe tener más de 6 caracteres";
      }
      break;
    case "us_phone_number":
      if (value && value.toString().length) {
        if (!value.match(REGULAR_EXPRESSIONS.US_PHONE_NUMBER)) {
          return caption || "Número de teléfono inválido";
        }
      }
      break;
    case "date":
      if (value && value.toString().length) {
        if (!value.match(REGULAR_EXPRESSIONS.DATE)) {
          return caption || "Fecha seleccionada inválida";
        }
      }
      break;
    case "length":
      if (value && value.toString().trim().length < length) {
        return (
          `${caption} ${length}` ||
          `Mínimo número de caracteres mayor que ${length}`
        );
      }
      break;
    case "phone_length":
      if (value && value.toString().trim().length < length) {
        return caption
          ? `${caption} ${length}`
          : `El telefono debe tener mínimo ${length} caracteres`;
      }
      break;
    case "special_characters":
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?À-ÿ\u00f1\u00d1]/;
      if (value && format.test(value)) {
        return caption || "No se permiten caracteres especiales";
      }
      break;
    default:
      return null;
  }
}

export const tConvert = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const tConvertWithoutSeconds = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    time = time.slice(1);
    time[3] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

export const customEncode = (data) => {
  let encoded = null;
  try {
    let compress = pako.deflate(JSON.stringify(data), { gzip: true });
    let buff = new Buffer(compress);
    let base64data = buff.toString("base64");
    let hash = sha256.hex(buff);
    encoded = base64data + "|" + hash;
  } catch (err) {
    console.log(err);
  }
  return encoded;
};

export const customDecode = (data) => {
  let decoded = null;
  try {
    let body = data.split("|");
    let bufferFromBody = _base64ToArrayBuffer(body[0]);
    let decompress = pako.inflate(bufferFromBody);
    let bufferFromDecompress = new Buffer(decompress);
    let info = bufferFromDecompress.toString("utf8");
    decoded = JSON.parse(info);
  } catch (err) {
    return "";
  }
  return decoded;
};

export const customDecodeNoCompressed = (data) => {
  let decoded = null;
  try {
    let body = data.split("|");
    let bufferFromBody = _base64ToArrayBuffer(body[0]);
    let hashFromBody = body[1];
    let hash = sha256.hex(bufferFromBody);
    if (hash === hashFromBody) {
      let bufferFromDecompress = new Buffer(bufferFromBody);
      let info = bufferFromDecompress.toString("utf8");
      decoded = JSON.parse(info);
    }
  } catch (err) {
    console.error(err);
  }
  return decoded;
};

const _base64ToArrayBuffer = (base64) => {
  let binary_string = window.atob(base64);
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const getUserAuthenticated = () => {
  const authData = store.getState().auth;
  const encodedRole = authData && authData.user ? authData.user : null;
  return customDecode(encodedRole);
};

export const addToStorage = (name, value) => {
  localStorage.setItem(`cllWebApp-${name}`, JSON.stringify(value));
};

export const removeFromStorage = (name) => {
  localStorage.removeItem(`cllWebApp-${name}`);
};

export const formatDateWithMonthShort = (date, locale = "es") => {
  return moment(date, "YYYY-MM-DD HH:mm a")
    .locale("es")
    .format("dddd, MMMM Do YYYY, h:mm a");
  /** Example: 23 mar 2022, 2:00 p. m. **/
};
