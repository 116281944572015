import { createSelector } from "reselect";
import * as actions_card from "./card-management.actions";

const INITIAL_STATE = {
  userCardsList: [],
  newCard: null,
  cardToEdit: null,
  addCardSuccess: false,
  deleteCardSuccess: false,
};

export default function user_cards(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions_card.LOAD_CARDS_SUCCESS: {
      return {
        ...state,
        userCardsList: action?.response?.data || [],
      }
    }
    case actions_card.ADD_USER_CARD_SUCCESS: {
      return {
        ...state,
        addCardSuccess: true,
        newCard: action?.response?.data,
      }
    }
    case actions_card.EDIT_USER_CARD_SUCCESS: {
      return {
        ...state,
        cardToEdit: action?.response?.data,
      }
    }
    case actions_card.DELETE_USER_CARD_SUCCESS: {
      return {
        ...state,
        deleteCardSuccess: action?.response?.data,
      }
    }
    case actions_card.CLEAR_MANAGE_CREDIT_CARD: {
      return {
        ...state,
        newCard: null,
        addCardSuccess: false,
        deleteCardSuccess: false,
      }
    }
    default: {
      return state;
    }
  }
}

const selectCardsState = (state) => state.user_cards;

export const cardsListSelector = createSelector(
  selectCardsState,
  (state) => state.userCardsList
);

export const newCardSelector = createSelector(
  selectCardsState,
  (state) => state.newCard
);

export const addCardSelector = createSelector(
  selectCardsState,
  (state) => state.addCardSuccess
);

export const deletedCardSelector = createSelector(
  selectCardsState,
  (state) => state.deleteCardSuccess
);