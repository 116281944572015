import React from "react";
import "./css/FormAddAddressStyles.scss";
import { clearAddressStates, loadMunicipalities, loadProvinces } from "../../modules/home/store/home.actions";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import { validateFieldByArray } from "../../utils/AuxiliarFunctions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomInput from "../CustomInput/CustomInput";
import CustomSelect from "../CustomSelect/CustomSelect";
import {
  addDeliveryAddress,
  updateDeliveryAddress,
  clearDeliveryAddressState,
  setDeliveryAddressToEdit
} from "./store/form-add-address.actions";
import CustomInputTextMask from "../CustomInputTextMask/CustomInputTextMask";
import { store } from "../../App";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    padding: "1em 2em",
    fontSize: ".8em",
  },
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: "0!important",
    height: 36
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: "0!important",
  },
  noOptionsMessageText: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  dropdownIndicator: {
    padding: "0!important",
  },
  singleValue: {
    padding: "0!important",
    marginLeft: ".5em"
  },
  placeholder: {
    position: 'absolute',
    marginLeft: ".5em"
  },
  paper: {
    position: 'absolute',
    zIndex: 10000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  textField: {
    margin: "0",
    width: "100%",
    background: "white",
  },
  checkbox: {
    color: "#424b5a",
    '&$checked': {
      color: "#428d44",
    },
    fontSize: ".8em",
  },
  caption: {
    color: "#424b5a",
    marginLeft: "1.15em",
    fontSize: "1em",
    textAlign: "left"
  },
  actions: {
    position: 'relative',
    marginTop: theme.spacing(1),
  },
  okButton: {
    background: "#428d44",
  },
  cancelButton: {
    background: "#424b5a",
  }
});

class FormAddAddress extends React.Component {
  state = {
    checkAsUsualAddress: true,
    formProvinces: [],
    formMunicipalities: [],
    formLocalities: [],
    deliveryAddress: {
      contactName: '',
      details: '',
      municipality: '',
      locality: '',
      additionalInfo: '',
      telephoneById: {
        code: "",
        number: " ",
      },
      province: '',
      name: "",
    },
    restaurant: {},
    errors: {},
    loadedFilters: {},
    validNewAddress: false
  };
  trans = {};
  translation = {};

  componentWillMount() {
    const { dispatch } = this.props;
    this.translation = this.props.translation;
    this.trans = this.props.translation.UserAddress;
    let deliveryAddress = this.state.deliveryAddress;
    let loadedFilters = store.getState().filters.filters;
    if (this.props.selectedAddress) {
      deliveryAddress = this.props.selectedAddress;
    } else if (this.props.parent === "STEP_DELIVERY") {
      if (loadedFilters && loadedFilters.provinceid) {
        deliveryAddress['municipalityid'] = loadedFilters.municipalityid ? loadedFilters.municipalityid : '';
        deliveryAddress['provinceid'] = loadedFilters.provinceid ? loadedFilters.provinceid : '';
        deliveryAddress['localityid'] = loadedFilters.localityid ? loadedFilters.localityid : '';
      }
    }
    this.setState({
      deliveryAddress, loadedFilters,
      restaurant: this.props.selectedRestaurant ? this.props.selectedRestaurant : null
    }, () => {
      dispatch(loadProvinces());
    }
    );
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    if (nextProps.address && nextProps.address.provinces_loaded) {
      let deliveryAddress = this.state.deliveryAddress;
      let formProvinces = nextProps.address.provinces;
      let selectedProvince = null;
      if (this.state.deliveryAddress && this.state.deliveryAddress.provinceid) {
        let provinceId = this.state.deliveryAddress.provinceid;
        if (provinceId) {
          selectedProvince = _.find(formProvinces, function (prov) {
            return prov.id === provinceId;
          });
        }
      }
      deliveryAddress["province"] = selectedProvince;
      this.setState({ formProvinces, deliveryAddress }, () => {
        dispatch(loadMunicipalities(selectedProvince && selectedProvince.id ? selectedProvince.id : ''));
        this.validateAddressForm();
        dispatch(clearAddressStates());
      });
    }
    if (nextProps.address && nextProps.address.municipalities_loaded) {
      let deliveryAddress = this.state.deliveryAddress;
      let formLocalities = [];
      let selectedMunicipality = null;
      let selectedLocality = null;
      let formMunicipalities = _.forEach(nextProps.address.municipalities, function (munic) {
        munic["value"] = munic.id;
        munic["label"] = munic.name;
      });
      if (this.state.deliveryAddress && this.state.deliveryAddress.municipalityid) {
        let municipalityId = this.state.deliveryAddress.municipalityid;
        let localityId = this.state.deliveryAddress.localityid;
        selectedMunicipality = _.find(formMunicipalities, function (mun) {
          return mun.id === municipalityId;
        });
        if (selectedMunicipality) {
          _.forEach(selectedMunicipality.localities, function (loc) {
            let obj = loc;
            obj["value"] = loc.id;
            obj["label"] = loc.name;
            formLocalities.push(obj);
          });
        }
        if (formLocalities && localityId) {
          selectedLocality = _.find(formLocalities, function (loc) {
            return loc.id === localityId;
          });
        }
      }
      // else if (this.state.restaurant && this.state.restaurant.addressById) {
      //   let municipalityId = this.state.restaurant.addressById.municipalityid;
      //   let localityId = this.state.restaurant.addressById.localityid;
      //   selectedMunicipality = _.find(formMunicipalities, function (mun) {
      //     return mun.id === municipalityId;
      //   });
      //   if (selectedMunicipality) {
      //     _.forEach(selectedMunicipality.localities, function (loc) {
      //       let obj = loc;
      //       obj["value"] = loc.id;
      //       obj["label"] = loc.name;
      //       formLocalities.push(obj);
      //     });
      //   }
      //   if (formLocalities && localityId) {
      //     selectedLocality = _.find(formLocalities, function (loc) {
      //       return loc.id === localityId;
      //     });
      //   }
      // }
      deliveryAddress["municipality"] = selectedMunicipality;
      deliveryAddress["locality"] = selectedLocality;
      this.setState(
        { formMunicipalities, formLocalities, deliveryAddress },
        () => {
          this.validateAddressForm();
          dispatch(clearAddressStates());
        }
      );
    }
    if (nextProps.delivery_address && nextProps.delivery_address.done_add_delivery_address) {
      let addressAdded = nextProps.delivery_address.new_delivery_address;
      this.props.selectResult(addressAdded);
      this.clearDeliveryAddressData();
      dispatch(clearDeliveryAddressState());
    }
    if (nextProps.delivery_address && nextProps.delivery_address.done_update_delivery_address) {
      let addressUpdated = nextProps.delivery_address.updated_delivery_address;
      this.props.selectResult(addressUpdated);
      this.clearDeliveryAddressData();
      dispatch(clearDeliveryAddressState());
    }
  }

  handleChangeInputForm = e => {
    const deliveryAddress = this.state.deliveryAddress;
    deliveryAddress[e.target.id] = e.target.value;
    this.setState({ deliveryAddress }, () => {
      this.validateAddressForm();
    });
  };

  handleDeliveryPhone = deliveryPhone => {
    let address = this.state.deliveryAddress;
    let deliveryTelephone = address.telephoneById ? address.telephoneById : {};
    deliveryTelephone['number'] = deliveryPhone;
    address['telephoneById'] = deliveryTelephone;
    this.setState({ deliveryAddress: address }, () => {
      this.validateAddressForm();
    });
  };

  handleSelectFormProvince = selected => {
    const { dispatch } = this.props;
    const deliveryAddress = {
      ...this.state.deliveryAddress,
      province: selected,
      provinceid: selected.id,
      municipality: '',
      locality: ''
    };
    this.setState({ ...this.state, deliveryAddress }, () => {
      dispatch(loadMunicipalities(selected.id));
      this.validateAddressForm();
    });
  };

  handleSelectFormMunicipality = selected => {
    let deliveryAddress = this.state.deliveryAddress;
    let formLocalities = [];
    if (selected) {
      _.forEach(selected.localities, function (loc) {
        let obj = loc;
        obj["value"] = loc.id;
        obj["label"] = loc.name;
        formLocalities.push(obj);
      });
    }
    deliveryAddress["municipality"] = selected;
    deliveryAddress["locality"] = '';
    deliveryAddress["provinceid"] = selected.provinceid;
    deliveryAddress["municipalityid"] = selected.id;
    deliveryAddress["localityid"] = '';
    this.setState({ deliveryAddress, formLocalities }, () => {
      this.validateAddressForm();
    });
  };

  handleSelectFormLocality = selected => {
    let deliveryAddress = this.state.deliveryAddress;
    deliveryAddress["locality"] = selected;
    deliveryAddress["localityid"] = selected.id;
    this.setState({ deliveryAddress }, () => {
      this.validateAddressForm();
    });
  };

  clearDeliveryAddressData = () => {
    const { dispatch } = this.props;
    let deliveryAddress = {
      contactName: null,
      details: null,
      municipality: null,
      locality: null,
      additionalInfo: null,
      telephoneById: {
        code: "",
        number: " ",
      },
      province: 13,
      name: "",
    };
    this.setState({ deliveryAddress });
    dispatch(setDeliveryAddressToEdit(null));
  };

  saveAddress = () => {
    const { dispatch } = this.props;
    let phoneById = this.state.deliveryAddress.telephoneById || null;
    let number = phoneById.number.replace(/\D/g, "") || null;
    phoneById.number = number.length === 8 ? `53${number}` : number;

    let address = {
      id: this.state.deliveryAddress.id ? this.state.deliveryAddress.id : null,
      name: this.state.deliveryAddress.contactName ? this.state.deliveryAddress.contactName : null,
      details: this.state.deliveryAddress.details ? this.state.deliveryAddress.details : null,
      contactName: this.state.deliveryAddress.contactName ? this.state.deliveryAddress.contactName : null,
      telephoneById: phoneById,
      localityid: this.state.deliveryAddress.locality ? this.state.deliveryAddress.locality.id : null,
      municipalityid: this.state.deliveryAddress.municipality ? this.state.deliveryAddress.municipality.id : null,
      provinceid: this.state.deliveryAddress.municipality ? this.state.deliveryAddress.municipality.provinceid : null,
      additionalInfo: this.state.deliveryAddress.additionalInfo ? this.state.deliveryAddress.additionalInfo : null,
      favorite: this.props.edit ? true : this.state.checkAsUsualAddress,
    };

    if (this.props.edit) {
      dispatch(updateDeliveryAddress(address));
    } else {
      dispatch(addDeliveryAddress(address));
    }

    if (this.props.onSavedAddress) {
      this.props.onSavedAddress(address);
    }
  };

  validateAddressForm = () => {
    let errors = {};
    let contactName = validateFieldByArray(this.state.deliveryAddress.contactName, ["required"], this.translation.Validations["required"]);
    if (contactName) {
      errors.contactName = contactName;
    }
    let details = validateFieldByArray(this.state.deliveryAddress.details, ["required"], this.translation.Validations["required"]);
    if (details) {
      errors.details = details;
    }
    let phone = this.state.deliveryAddress.telephoneById ? this.state.deliveryAddress.telephoneById : {};
    let telephoneById = validateFieldByArray(phone.number, ["required"], this.translation.Validations["required"]);
    if (telephoneById) {
      errors.telephoneById = telephoneById;
    } else {
      let cleanPhone = phone.number.replace(/\D/g, "");
      const length = cleanPhone.length;

      if ((cleanPhone.startsWith('53') && length !== 10) || (!cleanPhone.startsWith('53') && length === 10)) {
          errors.telephoneById = this.translation.Validations["incomplete_phone_number"];
      } else if (length !== 8 && length !== 10) {
          errors.telephoneById = this.translation.Validations["incomplete_phone_number"];
      }
    }
    let province = validateFieldByArray(this.state.deliveryAddress.province, ["required"], this.translation.Validations["required"]);
    if (province) {
      errors.province = province;
    }
    let municipality = validateFieldByArray(this.state.deliveryAddress.municipality, ["required"], this.translation.Validations["required"]);
    if (municipality) {
      errors.municipality = municipality;
    }
    let locality = validateFieldByArray(this.state.deliveryAddress.locality, ["required"], this.translation.Validations["required"]);
    if (locality) {
      errors.locality = locality;
    }
    this.setState({ errors, validNewAddress: _.isEmpty(errors) });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className="form_container" container component='div'>
        <Grid container item className="" md={12} sm={12} xs={12} component="div">
          <Grid container item className="form_container__body__fields" md={6} sm={12} xs={12} component="div">
            <CustomInput
              required
              autoFocus
              id="contactName"
              errorMessage={this.state.errors["contactName"]}
              fieldLabel={`${this.trans.contactName}`}
              value={this.state.deliveryAddress.contactName}
              onChange={e => this.handleChangeInputForm(e)}
            />
          </Grid>
          <Grid container item className="form_container__body__fields" md={6} sm={12} xs={12} component="div">
            <CustomInputTextMask
              required
              id="telephoneById"
              errorMessage={this.state.errors["telephoneById"]}
              fieldLabel={`${this.trans.telephoneFieldLabel}`}
              value={this.state.deliveryAddress.telephoneById?.number || null}
              onChange={evt => this.handleDeliveryPhone(evt.target.value)}
              guide={false}
              hideMask={true}
            />
          </Grid>
          <Grid container item className="form_container__body__fields" md={12} sm={12} xs={12}
            component="div">
            <CustomInput
              required
              id="details"
              standardLength={250}
              errorMessage={this.state.errors["details"]}
              fieldLabel={`${this.trans.details}`}
              value={this.state.deliveryAddress.details}
              onChange={e => this.handleChangeInputForm(e)}
            />
          </Grid>
          <Grid container item className="form_container__body__fields" md={6} sm={12} xs={12} component="div">
            <CustomSelect
              required
              id="province"
              isClearable={false}
              errorMessage={this.state.errors["province"]}
              fieldLabel={this.trans.province}
              value={this.state.deliveryAddress.province}
              onChange={this.handleSelectFormProvince}
              options={this.state.formProvinces}
            />
          </Grid>
          <Grid container item className="form_container__body__fields" md={6} sm={12} xs={12} component="div">
            <CustomSelect
              required
              id="municipality"
              isClearable={false}
              errorMessage={this.state.errors["municipality"]}
              fieldLabel={this.trans.municipality}
              value={this.state.deliveryAddress.municipality}
              onChange={this.handleSelectFormMunicipality}
              options={this.state.formMunicipalities}
            />
          </Grid>
          <Grid container item className="form_container__body__fields" md={6} sm={12} xs={12} component="div">
            <CustomSelect
              required
              id="locality"
              isClearable={false}
              errorMessage={this.state.errors["locality"]}
              fieldLabel={this.trans.location}
              value={this.state.deliveryAddress.locality}
              onChange={this.handleSelectFormLocality}
              options={this.state.formLocalities}
              noMatchesFoundFor={this.trans.noMatchesFoundFor}
              tryAnotherSearchCriteria={this.trans.tryAnotherSearchCriteria}
            />
          </Grid>
          <Grid container item className="form_container__body__fields" md={12} sm={12} xs={12}
            component="div">
            <CustomInput
              multiline
              rows="1"
              rowsMax="6"
              id="additionalInfo"
              fieldLabel={`${this.trans.additionalIndications}`}
              value={this.state.deliveryAddress.additionalInfo}
              onChange={e => this.handleChangeInputForm(e)}
            />
          </Grid>
        </Grid>
        <Grid container item className="" md={12} sm={12} xs={12} component="div">
          <Grid container item className="form_container__body__fields" md={12} sm={12} xs={12}
            component="div">
            <span className={classes.caption}>
              {`* ${this.trans.requiredFields}.`}
            </span>
          </Grid>
        </Grid>
        <Grid container className="form_actions" component='div'>
          <Grid container item xs={12} component='div'>
            {this.props.onCancel ?
              <Grid item md={6} xs={6} className="button_align_right" component='div'>
                <button onClick={this.props.onCancel} className="button--outline button--big">
                  {this.trans.userAddressFormBtnCancel}
                </button>
              </Grid> : null}
            <Grid item md={this.props.onCancel ? 6 : 12} xs={this.props.onCancel ? 6 : 12} component='div'
              className={this.props.onCancel ? "button_align_left" : "button_align_center"}>
              <button onClick={this.saveAddress} disabled={!this.state.validNewAddress}
                className="button--primary button--big">
                {`${this.props.onCancel ? this.trans.userAddressFormBtnSave : this.trans.userAddressFormBtnConfirm}`}
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

FormAddAddress.propTypes = {
  parent: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
  checkAsUsual: PropTypes.bool.isRequired,
  selectResult: PropTypes.func.isRequired,
  translation: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  selectedRestaurant: PropTypes.object,
  selectedAddress: PropTypes.object
};

function mapStateToProps(state) {
  const { address, delivery_address } = state;
  return { address, delivery_address };
}

export default withStyles(styles)(connect(mapStateToProps)(FormAddAddress));