import React, { useEffect, useState } from 'react';
import Cart from "../../modules/cart/Cart";
import useTranslation from "../../translations";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLoadingMaskStatus } from 'component/Mask/store/mask.actions';
import { setBDOrder, storeBrowserOrder } from 'modules/Checkout/store/checkout.actions';
import { selectBDCart, selectCartRestaurant } from "../../modules/cart/store/cart.reducers";
import Voucher from 'modules/Checkout/components/Voucher';
import { selectBrowserOrder } from 'modules/Checkout/store/checkout.reducers';

export default function MyPurchaseButton(props) {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const cartBD = useSelector(selectBDCart);
  const restaurant = useSelector(selectCartRestaurant);
  const browserOrder = useSelector(selectBrowserOrder);
  const [cartModal, setCartModal] = useState(false);
  const [checkoutPath, setCheckoutPath] = useState(false);
  const [showFloatingCart, setShowFloatingCart] = useState(window.innerWidth < 1200);

  useEffect(() => {
    recalculate();
    window.addEventListener('resize', recalculate);
  }, []);

  props.history.listen(async (location) => {
    if (["/prv/pay", "/prv/checkout"].includes(location.pathname)) {
      setCheckoutPath(true);
    }
  });

  const countCartItems = () => {
    return (cartBD?.items?.length ? cartBD?.items?.map(item => item.offerCount).reduce((a, b) => a + b) : 0);
  };

  const showCartModal = () => setCartModal(!cartModal);
  const recalculate = () => setShowFloatingCart(window.innerWidth < 1200);

  const editPurchase = () => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(setBDOrder(null));
    dispatch(storeBrowserOrder(null));
    props.history.push({ pathname: `/${restaurant.slug}` });
  };

  return (
    <div>
      {showFloatingCart && countCartItems() > 0 && <div className="cart-section">
        <button className="button--primary button--max" onClick={() => showCartModal()}>
          <span>{countCartItems()}</span>
          <span>{translation.Restaurant.viewMyRequest}</span>
          {checkoutPath
            ? browserOrder?.total > 0 && <span className="cart-total">
              ${parseFloat(browserOrder?.total).toFixed(2)}
            </span>
            : cartBD?.items?.length > 0 && <span className="cart-total">
              ${parseFloat(cartBD?.total).toFixed(2)}
            </span>
          }
        </button>

        {cartBD?.items?.length > 0 &&
          (checkoutPath
            ? <Voucher edit={editPurchase} />
            : cartModal && <Cart translation={translation} onClose={showCartModal}
              title={<h1 className="cart__header__title">{translation.Cart.myRequest}</h1>} />)
        }
      </div>
      }
    </div>
  )
}