import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./css/UserAddressStyles.scss";
import { IconButton } from "@material-ui/core";
import FormAddAddress from "../../component/FormAddAddress/FormAddAddress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
  deleteDeliveryAddress, loadDeliveryAddress, setDeliveryAddressToEdit
} from "../../component/FormAddAddress/store/form-add-address.actions";
import { deliveryAddresses } from "../../component/FormAddAddress/store/form-add-address.reducers";
import useTranslations from "../../translations";
import { Add, Clear } from "@material-ui/icons";
import { sendToCheckAvailability } from "../availabilityValidation/store/availability-validation.actions";
import Skeleton from "@material-ui/lab/Skeleton";

export default function UserAddress() {
  const translation = useTranslations();
  const dispatch = useDispatch();
  const addresses = useSelector(deliveryAddresses);
  const topBarSearchOptionSelected = JSON.parse(localStorage.getItem('address'));
  const [showDialogForm, setShowDialogForm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(loadDeliveryAddress());
  }, [dispatch]);

  const hideDeliveryDialogForm = () => setShowDialogForm(false);

  const prepareToAddAddress = () => {
    setSelectedAddress(null);
    setEdit(false);
    setShowDialogForm(true);
  };

  const handleSavedAddress = (address) => {
    setSelectedAddress(address);
    setEdit(false);
    setShowDialogForm(false);
    dispatch(loadDeliveryAddress());
  }

  const prepareToEditAddress = address => {
    dispatch(setDeliveryAddressToEdit(address));
    setSelectedAddress(address);
    setEdit(true);
    setShowDialogForm(true);
  };

  const cancelEditAddress = address => {
    setSelectedAddress(address);
    setEdit(false);
    setShowDialogForm(false);
  };

  const deleteAddress = address => dispatch(deleteDeliveryAddress(address.id));

  const empty =
    addresses && addresses.length > 0
      ? addresses.filter(address => address.id).length === 0
      : false;

  let DELIVERY_ADDRESS_FORM = null;
  if (showDialogForm) {
    DELIVERY_ADDRESS_FORM = (
      <div className="address-section">
        <Paper className="address-form">
          <div className="address-form-header">
            <Typography variant="h5" component="h3">
              {edit
                ? `${translation.UserAddress.editingAddress} – ${selectedAddress.name}`
                : translation.UserAddress.deliveryAddress}
            </Typography>
            <button
              className="address-form-header__close button--transparent button--round"
              onClick={cancelEditAddress}
            >
              <Clear component={"svg"} />
            </button>
          </div>
          <div className="address-form-body">
            <FormAddAddress
              translation={translation}
              parent={"USER_ADDRESS"}
              edit={edit}
              onSavedAddress={handleSavedAddress}
              checkAsUsual={false}
              selectResult={hideDeliveryDialogForm}
              onCancel={cancelEditAddress}
              selectedAddress={edit ? selectedAddress : { provinceid: topBarSearchOptionSelected?.provinceid }}
            />
          </div>
        </Paper>
      </div>
    );
  }

  return (
    <div>
      {showDialogForm ? (
        DELIVERY_ADDRESS_FORM
      ) : (
        <div className="container small-container">
          <div className="address">
            <div className="address__header">
              <h2 className="main-title">
                {translation.Profile.favoriteAddresses}
                <span className="underline"></span>
              </h2>
              <button className="button--primary button--primary-inverted-rounded" onClick={prepareToAddAddress}>
                <Add style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                <span>{translation.UserAddress.addNew}</span>
              </button>
            </div>
            <ul className="address-data">
              {!empty ? (
                addresses ? addresses.map(address =>
                  address && address.id ? (
                    <li key={address.id}>
                      <span>{address.name}</span>
                      <span>{address.details}</span>
                      <IconButton href={""} component="span" onClick={() => prepareToEditAddress(address)} style={{ width: '24px' }}>
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fill: "#1e4acc", width: '24px' }}>
                          <path d="M23.2,5L21,2.8c-1-1-2.7-1-3.8,0l-12,12c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0l-2.6,7.1
                                  c-0.1,0.4,0,0.8,0.2,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3-0.1l7.1-2.6c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.2
                                  c0,0,0,0,0,0l12-12C24.3,7.7,24.3,6,23.2,5z M16.2,6.7l3.1,3.1l-8.8,8.8L9,17l-1.6-1.6L16.2,6.7z M5.1,20.9l1.3-3.6l0.4,0.4
                                  l1.9,1.9L5.1,20.9z M21.8,7.3l-1.1,1.1l-3.1-3.1l1.1-1.1c0.2-0.2,0.7-0.2,0.9,0l2.2,2.2C22.1,6.6,22.1,7.1,21.8,7.3z"/>
                        </svg>
                      </IconButton>
                      <IconButton href={""} component="span" onClick={() => deleteAddress(address)} style={{ width: '24px' }}>
                        <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ fill: "#1e4acc", width: '24px' }}>
                          <path d="M15,4h-4c-0.6,0-1,0.4-1,1v1h6V5c0-0.5-0.4-0.9-0.9-1L15,4z M15,11c0.6,0,1,0.4,1,1v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6
                                  C14,11.4,14.4,11,15,11z M11,11c0.6,0,1,0.4,1,1v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6C10,11.4,10.4,11,11,11z M19,8H7v13
                                  c0,0.5,0.4,0.9,0.9,1L8,22h10c0.6,0,1-0.4,1-1V8z M15,2c1.7,0,3,1.3,3,3v1h4c0.5,0,0.9,0.4,1,0.9L23,7c0,0.6-0.4,1-1,1h-1v13
                                  c0,1.6-1.2,2.9-2.8,3L18,24H8c-1.7,0-3-1.3-3-3V8H4C3.5,8,3.1,7.6,3,7.1L3,7c0-0.6,0.4-1,1-1h4V5c0-1.6,1.2-2.9,2.8-3L11,2H15z"/>
                        </svg>
                      </IconButton>
                    </li>
                  ) : null
                ) : [...Array(2)].map((k) => <Skeleton key={k} variant="rect" width="100%" height={87.78} animation="wave" style={{ marginBottom: "1em" }} />)
              ) : (
                <h3>-- {translation.UserAddress.noAddressesFound} --</h3>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
