import { TASA_PESO } from "../../constants";
import { Translation } from "./types";

export default {
  Code: "ES",
  ConnectedTo: "Conectado a id",
  Validations: {
    fieldRequired: "Campo Requerido",
    required: "Campo requerido",
    integer: "No es un entero válido",
    number: "Número inválido",
    email: "Dirección de correo inválida",
    password: "La contraseña debe tener más de 6 caracteres",
    us_phone_number: "Número de teléfono inválido",
    date: "Fecha seleccionada inválida",
    length: "Mínimo número de caracteres mayor que",
    maxLength: "Ha alcanzado el máximo número de caracteres permitidos",
    special_characters: "No se permiten caracteres especiales",
    form: "Existen errores en el formulario",
    incomplete_phone_number: "Número de teléfono incompleto",
    login: "Credenciales incorrectas",
  },
  Actions: {
    new: "Nuevo",
    close: "Cerrar",
    cancel: "Cancelar",
    accept: "Aceptar",
    apply: "Aplicar",
    save: "Guardar",
    continue: "Continuar",
  },
  General: {
    seeMore: "Ver más",
    viewAll: "Ver todos",
    viewRestaurants: "Ver Restaurantes",
    readFullArticle: "Read the full article",
    notAvailable: "No disponible",
    noMatchesFoundFor: "No se han encontrado resultados para",
    tryAnotherSearchCriteria: "Prueba con otro criterio de búsqueda",
    noResultList: "Sin resultados que mostrar",
    defaultAlertTitle: "Alerta",
  },
  Pagination: {
    labelRowsPerPage: "Filas por página",
  },
  TopBar: {
    linkDelivery: "A domicilio",
    linkHelp: "Ayuda",
    linkHome: "Home",
    linkCll: "Tamtos",
    linkRestaurant: "Restaurantes",
    linkReserve: "Reservar",
    btnLogin: "Regístrate o inicia sesión",
    btnLogout: "Cerrar Sesión",
    btnProfile: "Mi cuenta",
    linkPersonalData: "Datos personales",
    linkPreferredAddresses: "Direcciones preferidas",
    linkCardManagement: "Gestión de tarjetas",
    linkPurchaseHistory: "Histórico de pedidos",
    btnRegister: "Crear cuenta",
    btnMyProfile: "Mi Perfil",
    optionDelivery: "Domicilio",
    optionPickup: "Recogida",
    deliveryModeDetails: "Detalles de la entrega",
    searchPlaceholder: "Buscar...",
    search: "Buscar",
    cart: "Carrito",
  },
  Address: {
    selectAddress: "Coloque la dirección",
    deliverIn: "Entregar en",
    deliverAt: "Entregar a",
  },
  Home: {
    labelSelectMunicipality: "Dónde quieres que entreguemos",
    sectionBusinessCategoryTitle: "Encuentra todo lo que necesitas",
    sectionBusinessCategorySubtitle:
      "Pulsa sobre la tienda que desees y comienza a comprar",
    sectionHeaderMobileTitle: "La mejor comida a domicilio en Cuba",
    tabTitleDefault: "Restaurantes | Todo lo que necesitan en Cuba a un clic",
    tabTitleRestaurant:
      "Comida a domicilio en Cuba y mucho más! | Restaurantes",
    tabTitleSupermarket: "Restaurantes | Supermercado | Combos y más!",
    metaDefault:
      "Restaurantes. Restaurantes, Supermercado y Tiendas con todo lo que necesiten tus familiares en Cuba con las mejores ofertas. ",
    metaRestaurant:
      "Con Restaurantes los mejores platos cubanos, ahora hasta la puerta de tu casa. La mejor comida a domicilio en Cuba.",
    metaSupermarket:
      "Supermercado de Restaurantes. Combos y muchó más!. Entrega en 1 o 2 días!",
    sectionHeaderTitleDefault: "Restaurantes.",
    sectionHeaderTitleRestaurant:
      "Restaurantes: La mejor comida a domicilio y mucho más",
    sectionHeaderTitleSupermarket: "Sumpermercado.",
    sectionHeaderTitle1: "Elige qué van a comer hoy, ",
    sectionHeaderTitle2: "Tú invitas, nosotros entregamos",
    sectionHeaderSubtitle1: "Selecciona restaurante",
    sectionHeaderSubtitle2: "Crea la orden",
    sectionHeaderSubtitle3: "Reciben en casa",
    sectionHeaderHomeDeliveryOption: "Entrega a domicilio",
    sectionHeaderPickOrderOption: "Recoger pedido",
    sectionHeaderSearchButton: "Buscar",
    sectionFoodCategoryTitle: "¿Qué tipo de comida buscas?",
    sectionPopularsTitle: "Los más populares",
    sectionTrendingTitle: "Tendencias en La Habana",
    sectionSubscriptionTitle: "¡No te pierdas ni una!",
    sectionSubscriptionSubtitle:
      "Suscríbete a nuestro boletín para estar al día de las mejores promociones.",
    sectionSubscriptionButton: "Quiero suscribirme",
    sectionSubscriptionInputPlaceholder: "Correo",
    sectionPressTitle: "Cuballama Delivery en la prensa",
    defaultPageMessageTitle:
      "Envío de comida a domicilio en Cuba y de todo para tu familia",
    defaultPageMessageContent:
      "Cuballama Mercado es una solución todoterreno para cuidar de tu familia con entrega de comida a domicilio en Cuba hasta en el mismo día. Comida… y mucho más: además de los combos de alimentos, las cenas y los productos del agro, tenemos electrodomésticos, artículos de aseo, higiene y para el hogar. Para combatir desde los antojos hasta el apagón cuando se les antoje. Cuando estás en un apuro, pide la entrega de comida a domicilio en Cuba, y si no, también. Cuballama, una sola familia cubana.",
  },
  OfferCarousel: {
    title: "Ofertas especiales",
    seeAll: "Ver todas",
  },
  RestaurantCarousel: {
    since: "Desde",
    minute: "Min",
    title: "Comida a domicilio en La Habana",
    seeAll: "Ver todos",
  },
  PromotionsCarousel: {
    carouselTitle: "Ofertas de Hoy",
  },
  RestaurantList: {
    sectionFilterButton: "Filtrar",
    filterDialogHeader: "Filtros",
    filterTitle: "Estas filtrando por",
    eraseAll: "Borrar todo",
    noResult: "Sin resultados que mostrar",
    title: "Entrega a domicilio",
    restaurant: "restaurantes",
  },
  Filter: {
    sort: "Ordenar",
    atHome: "A Domicilio",
    pickup: "Recogida",
    promotion: "Promoción",
    dietIntolerance: "Dieta/Intolerancia",
    cuisineType: "Tipo de cocina",
    eraseAll: "Borrar todos",
    apply: "Aplicar",
    deliverAt: "Entregar en",
    restaurantName: "Nombre del restaurante",
    deliverTime: "Tiempo de entrega",
    bestScore: "Mejor puntuación",
    averagePrice: "Precio promedio",
  },
  Restaurant: {
    hours: "Horario",
    menuOfDay: "Menú del día",
    back: "Volver",
    atHome: "A domicilio",
    pickup: "Recogida",
    thisIsYourRequest: "Este es su pedido",
    viewMyRequest: "Ver mi pedido",
    backToMenu: "Ir al Menú",
    completeRequest: "Completar Pedido",
    evaluations: "valoraciones",
    more: "Más",
  },
  Login: {
    info: "Accede a tu cuenta o crea una nueva para poder finalizar el proceso de pago.",
    welcome: "Crea o accede a tu cuenta",
    indentifyWithCuballama:
      "Para acceder a tu cuenta o crear una nueva indícanos tu celular.",
    header:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    emailField: "Correo electrónico",
    emailHeader: "Correo electrónico del cliente",
    emailSubHeader:
      "Por favor introduzca una dirección de correo electrónico válida",
    backToLogin: "Volver a empezar",
    needToLogin: "Necesita autenticarse para acceder a esta acción",
    phoneNumberField: "Teléfono",
    passwordField: "Contraseña",
    pinHeader: "Validar teléfono",
    pinSubHeader: "Introduce el código de validación que te hemos enviado al",
    pin: "Pin",
    pinAction: "Validar",
    pinMsg: "Se ha procedido a enviar un codigo PIN a su telefono",
    pinNotReceived: "No recibiste el código?",
    pinHelper: "Solo 4 caracteres",
    verificationHeader: "Completa la verificación",
    verificationSubHeaderPart1: "Te hemos enviado un correo a ",
    verificationSubHeaderPart2:
      "Sigue las instrucciones del email para verificar tu cuenta.",
    btnSuccessfulVerification: "Lo he verificado",
    btnEmailNotReachable: "No tengo acceso al email",
    alertVerificationTitle: "Por favor, valida tu email",
    alertVerificationMessage:
      "Por tu seguridad te hemos enviado un correo electrónico para verificar que eres tú. Por favor, comprueba tu bandeja de entrada y pulsa el enlace que encontrarás adjunto. Una vez lo hagas vuelve a esta página y pulsa continuar para completar tu acceso.",
    rememberMe: "Recuérdame",
    haveYouForgotPassword: "¿Has olvidado tu contraseña?",
    loginAction: "Continuar",
    registerInvitation: "Te enviaremos un SMS para verificar tu teléfono",
    createItHere: "Créala aquí",
    phonePlaceHolder: "Prefijo con teléfono",
    wait: "Espera",
    seconds: "segundos",
    resendCode: "Reenviar código",
  },
  Register: {
    createCuballamaAccount: "Crea una cuenta Tamtos",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    info2:
      "En unos segundos recibirás tu PIN. Si has utilizado un teléfono móvil lo recibirás en un SMS, en caso contrario, por una llamada.",
    phoneField: "Teléfono",
    validateAction: "Validar Teléfono",
    alreadyRegistered: "¿Ya tienes una cuenta en Tamtos?",
    introPinOrGetACall:
      "Introduce el PIN que te hemos enviado por SMS o llamada al",
    modifyAction: "Modificar",
    pinReceived: "Pin recibido",
    password: "Contraseña",
    email: "Correo Electrónico",
    thisDataIsOptional: "Este dato es opcional",
    conditionPart1: "Al crear tu cuenta aceptas las",
    conditionPart2: "condiciones legales",
    conditionPart3: "y la",
    conditionPart4: "política de privacidad",
    conditionPart5: "de Tamtos",
    registerAction: "Crear Cuenta",
    resendCodeTitle: "¿No has recibido el PIN?",
    receivePinAgain: "Elige como deseas recibir el código",
    receivePinPerCall: "Por Teléfono",
    receivePinBySms: "Por SMS",
    receivePinByWhatsApp: "Por WhatsApp",
    talkToAnAgent: " Habla con un agente",
    cancel: "Cancelar",
  },
  RecoverPassword: {
    title1: "Identifícate con tu cuenta Tamtos",
    title2: "Identifícate con tu cuenta Tamtos",
    helperText:
      "En unos segundos recibirás tu PIN. Si has utilizado un teléfono móvil lo recibirás en un SMS, en caso contrario, por una llamada.",
    goBackToLogin: "¿Ya recuerdas la contraseña?",
    phoneField: "Teléfono",
    pinReceived: "Pin recibido",
    password: "Contraseña",
    sendPin: "Enviar PIN",
    usernameField: "Teléfono",
    modifyAction: "Modificar",
    setPasswordAction: "Cambiar contraseña",
    resendCodeTitle: "¿No has recibido el PIN?",
    getPinBySms: "Recibir PIN por SMS",
    getPinByCall: "Recibir PIN por llamada",
    talkToAnAgent: "Hablar con un agente",
  },
  Search: {
    title: "",
    subtitle: "",
    inputPlaceholder: "¿Qué estás buscando?",
    generalPlaceholder: "Coloque un término a buscar",
    notFoundPlaceholder:
      "Lo sentimos, no pudimos encontrar lo que estaba buscando",
  },
  Payment: {
    codes: {
      TRANSACTION_NOT_ALLOWED:
        "Transacción fallida, por favor inténtelo nuevamente",
      DECLINED: "Transacción declinada, por favor contacte con su Banco",
      PROCESSOR_DECLINED:
        "Transacción declinada, por favor contacte con su Banco",
      EXPIRED_CARD: "Tarjeta Expirada, por favor revise su método de pago",
      INVALID_TRANSACTION:
        "Transacción inválida, por favor inténtelo nuevamente",
      GATEWAY_REJECTED: "Transacción rechazada por autorización bancaria",
    },
    failureTitle: "No se ha podido procesar el pago",
    failureSubtitle: "Inténtalo de nuevo o selecciona otra forma de pago.",
    failureDate: "Fecha",
    failureAmount: "Importe",
    failureGenericMessage: 'Mensaje: Su banco no aprobó el pago. Por favor contacte su banco para que autoricen sus pagos a tamtos',
    successTitle: "El pago se ha completado con éxito",
    successSubtitle: "A continuación mostramos los detalles de la compra.",
    viewMyRequest: "Ver mi pedido",
    thisIsMyRequest: "Este es su pedido",
    haveAnyDiscount: "¿Tienes un cupón de descuento?",
    couponApplied: "Cupón de descuento aplicado",
    invalidCoupon: "Cupón no válido",
    discountCode: "Código de descuento",
    apply: "Aplicar",
    clearCoupon: "Eliminar",
    subtotal: "Subtotal",
    shipping: "Coste de envío",
    freeShipping: "Gratis",
    taxes: "Tarifa de servicio",
    discount: "Descuento (Cupón)",
    bonus: "Descuento (Bonificación)",
    coupon: "Cupón",
    total: "Total",
    pending: "A calcular",
    daily: "Diario",
    modify: "Modificar",
    modifyRequest: "Modificar Pedido",
    continueCheckout: "Continuar con Checkout",
    deliveryData: "Datos de Entrega",
    deliveryDate: "Entrega",
    pay: "Pagar",
    addCard: "Guardar tarjeta de crédito",
    addAndPay: "Guardar tarjeta de crédito y pagar",
    evaluations: "valoraciones",
    addressNotAvailable: "Dirección no disponible.",
    validatingAddress: "Validando dirección . . .",
    invalidDeliveryAddress:
      "El restaurante seleccionado no realiza envíos a esta dirección.",
    validDeliveryAddress:
      "La dirección seleccionada para esta entrega es válida.",
    setYourAddress: "Indicanos tu dirección",
    addressNewAddress: "Añadir nueva dirección",
    addDeliveryAddress: "Adicionar dirección de entrega",
    setDeliveryAddress: "Seleccione dirección de entrega",
    deliveryAddress: "Dirección de entrega",
    address: "Dirección",
    deliveryAddressHint:
      "Incluye el nombre y el número de tu calle, así como la zona o barrio",
    municipality: "Municipio",
    city: "Ciudad",
    name: "Nombre",
    phone: "Teléfono",
    date: "Fecha",
    time: "Hora",
    labelPickup: "recogida",
    labelDelivery: "entrega",
    labelDateAndTimeOf: "Fecha y hora de ",
    clickToSelectDates: "Seleccione la fecha de entrega",
    deliveryWarning:
      "Asegúrese de que haya alguien para recepcionar el pedido en la fecha y hora señaladas.",
    confirmDateDialogTitle: "Este pedido será entregado el ",
    confirmDateDialogMessage: "¿Desea continuar?",
    confirmDateDialogButtonOk: "",
    confirmDateDialogButtonCancel: "",
    confirm: "Confirmar",
    confirmAndPay: "Confirmar y pagar",
    paymentInProgressMessage:
      "Estamos procesando tu pago. El proceso puede tomar hasta 1 minuto. No cierres esta pantalla hasta obtener una respuesta.",
    availableCredit: "Saldo Tamtos",
    cashPaymentOption: "Efectivo",
    cashPaymentOptionLabel: "Pagar en efectivo en la entrega",
    makeYourPaymentsWithCash: `Este pago se realizará en la moneda local CUP al cambio ${TASA_PESO} CUP x 1`,
    addCreditCard: "Adicionar Tarjeta de Crédito",
    goBack: "Volver",
    asap: "Lo antes posible",
    contactData: "Datos de contacto",
    discountedBalance: "Saldo descontado",
    remainingBalance: "Saldo restante",
    transactionId: "Id Transacción",
    creditCard: "Tarjeta de Crédito",
    paypalAccount: "Cuenta de Paypal",
    expirationDate: "Expiración",
    cart: "Carrito",
    paymentId: "Id de Pago",
    paymentDoneMessage: "Su pago ha sido realizado",
    paymentErrorMessage:
      "Su pago no se realizó debido a un error durante la transacción",
    paymentCancelMessage:
      "Ha cancelado la transacción de pago, puede volver al pago inicial, dando clic en el botón abajo",
    message: "Mensaje",
    restaurant: "Restaurante",
    estimatedDeliveryTime: "Entrega ~ ",
    acceptTerms:
      "Al continuar, usted COMPRENDE y ACEPTA que no es posible CANCELAR ni MODIFICAR su pedido.",
    estimatedTime: " Min Aproximados",
    delivery: "Entrega",
    pickUp: "Recogida",
    prices: "Precios",
    estimatedTimeShort: "minutos",
    minDeliveryCost: "Envío ~ ",
    approx: "(Aprox)",
    creditCardEndWith: "Tarjeta terminada en",
    payWithCard: "Pago con Tarjeta",
    payWithTuBolsillo: "Pago con Tu Bolsillo",
    edit: "Editar",
    cancelEdit: "Cancelar",
    addPaymentMethod: "Añadir Nueva",
    warningMessageBeforePay:
      "Al pulsar el botón 'Pagar' acepto el pago de la compra y sus condiciones de entrega",
    titleDeliveryConditions: "Condiciones de entrega",
    titleSelectPaymentMethod: "Forma de pago",
    payRestWith1: "Pagar",
    payRestWith2: "restantes con",
    titleResumeBeforePayment: "Completar la compra",
    newPaymentMethod: "Añadir nueva forma de pago",
    alertDeletePaymentMethod:
      "Esta a punto de borrar este método de pago. Está seguro que es esto lo que desea?",
    alertSelectPaymentMethod:
      "Por favor seleccione un método de pago para continuar con su compra",
    paymentMethodAdded: "Método de pago añadido",
    closeAddMethodDialog: "Cerrar",
    paymentMethodCancelled: "Método de pago cancelado",
    paypalAccountAdded: "Cuenta de Paypal añadida",
    attention: "Atención",
    contacts: "Contactos",
    errors: {
      "no valid date.": "Fecha no valida",
      "no valid value.": "Valor incorrecto",
      "No card number provided.": "Número de tarjeta requerido",
      "Credit card number is in invalid format.":
        "El número de tarjeta de crédito tiene formato incorrecto",
      "Warning! This credit card number is associated with a scam attempt.":
        "Atención! Este número de tarjeta de crédito esta asociado con intentos de estafa",
      "Credit card number has an inappropriate number of digits.":
        "El número de tarjeta de crédito tiene caracteres inapropiados",
      "Do Not Honor.":
        "Su banco no quiere aceptar la transacción actual, por favor contacte a su banco",
      "Insufficient Funds.": "La cuenta no tiene fondos suficientes",
      "Limit Exceeded.":
        "Los intentos de transacción han excedido el limite para esta cuenta",
      "Cardholder's Activity Limit Exceeded.":
        "Los intentos de transacción han excedido el limite para esta cuenta",
      "Expired Card.": "La tarjeta ya expiró",
      "Invalid Credit Card Number.": "Número de tarjeta de crédito no válido",
      "Invalid Expiration Date.": "Fecha de expiración no valido",
      "No Account.":
        "El número de tarjeta de crédito no es reconocido por su banco, por favor contacte a su banco para más información",
      "Card Account Length Error.": "Número de tarjeta con longitud incorrecta",
      "Card Issuer Declined CVV.": "Código de seguridad incorrecto",
      "Processor Declined – Possible Lost Card.":
        "Tarjeta declinada, posiblemente haya sido reportada como perdida, por favor contactar con el banco",
      "Processor Declined – Possible Stolen Card.":
        "Tarjeta declinada, posiblemente haya sido reportada como robada, por favor contactar con el banco",
      "Processor Declined – Fraud Suspected.":
        "Tarjeta declinada por sospecha de fraude, por favor contacte con su banco",
      "Transaction Not Allowed.":
        "Transacción no permitida por el banco, probablemente se deba a un problema con su tarjeta",
      "Duplicate Transaction.":
        "Transacción duplicada. Se cancela para evitar cobros duplicados",
      "Invalid Transaction.": "Su banco declinó la transacción",
      "Declined – Updated Cardholder Available.":
        "Tarjeta Declinada, esta expiró o fue reportada como perdida y se emitió una nueva como reemplazo",
      "Processor Does Not Support This Feature.":
        "Tu cuenta no permite procesar este tipo de transacción",
      "Card Type Not Enabled.": "Tipo de tarjeta no permitida",
      "Set Up Error – Card.": "Ocurrió un problema con la tarjeta emitida",
      "Processor Declined.":
        "Transacción declinada por el banco, por favor contacte con su banco",
      "Invalid Authorization Code.": "Código de autorizo no encontrado",
      "Declined – Call For Approval.":
        "Transacción declinada, la tarjeta usada requiere confirmación del cliente, por favor contacte con su banco",
      "Declined – Call Issuer.":
        "Transacción declinada por el banco que emitió la tarjeta. Intente nuevamente o de lo contrario contacte con su banco",
      "Declined.":
        "Transacción declinada por el banco, por favor contacte con su banco",
      "Invalid Credit Plan.": "Plan de crédito no valido",
      "Credit Card Number does not match method of payment.":
        "El número de tarjeta no coincide con el método de pago",
      "Card reported as lost or stolen.":
        "Esta tarjeta fue reportada como perdida o robada, por favor contacte con su banc",
      "Address Verification Failed.":
        "PayPal no pudo verificar porque la dirección no tiene formato correcto",
      "Address Verification and Card Security Code Failed.":
        "Los chequeos de AVS y CVV fallaron para esta transacción",
      "PayPal Business Account preference resulted in the transaction failing.":
        "PayPal business account resultó en una transacción fallida",
      "PayPal Business Account Restricted.":
        "PayPal business account restringida, por favor contacte al equipo de soporte de Paypal",
      "Authorization Expired.": "El código de autorización de PayPal expiró",
      "PayPal Business Account Locked or Closed.":
        "PayPal business account ha sido bloqueada o cerrada, por favor contacte al equipo de soporte de Paypal",
      "PayPal Buyer Revoked Pre-Approved Payment Authorization.":
        "PayPal revoco el pre-aprobado de esta cuenta",
      "PayPal Payee Account Invalid Or Does Not Have a Confirmed Email.":
        "Su cuenta de PayPal no es válida o no tiene un email confirmado",
      "PayPal Payee Email Incorrectly Formatted.":
        "Su cuenta de PayPal tiene el email con formato no válido",
      "PayPal Validation Error.":
        "PayPal no puede aprobar esta transacción, por favor contacte al equipo de soporte de Paypal",
      "Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment.":
        "Su cuenta de PayPal ha sido cancelada, por favor contacte al equipo de soporte de Paypal",
      "Payer Account Is Locked Or Closed.":
        "Su cuenta de PayPal no puede ser usada para transacciones en estos momentos, por favor intentelo nuevamente o contacte con el equipo de soporte de Paypal",
      "PayPal Transaction Limit Exceeded.":
        "Límite de transacción de PayPal excedido",
      "PayPal payment method is invalid.":
        "Esta cuenta de PayPal ha sido cancelada o bloqueada",
      "PayPal authorization amount limit exceeded.":
        "El límite de autorizo de PayPal ha sido excedido",

      "Credit card type is not accepted by this merchant account.":
        "Esta tarjeta de crédito no es aceptada por este comercio",
      "CVV is required.": "CVV requerido",
      "CVV must be 4 digits for American Express and 3 digits for other card types.":
        "CVV debe ser de 4 dígitos para American Express y 3 dígitos para el resto",
      "Expiration date is required.": "Fecha de expiración requerida",
      "Expiration date is invalid.": "Fecha de expiración no válida",
      "Expiration year is invalid. It must be between 1975 and 2201.":
        "El año en fecha de expiración no es válido",
      "Expiration month is invalid.":
        "El mes en fecha de expiración no es válida",
      "Expiration year is invalid.":
        "El año en fecha de expiración no es válida",
      "Credit card number is required.": "Número de tarjeta requerido",
      "Credit card number is invalid.": "Número de tarjeta no válido",
      "Credit card number must be 12-19 digits.":
        "Número de tarjeta debe tener de 12-19 dígitos",
      "Credit card number is not an accepted test number.":
        "Número de tarjeta no aceptado como número de prueba",
      "Duplicate card exists in the vault.": "Número de tarjeta duplicado",
      "CVV verification failed.": "CVV verificación fallida",
      "Credit card number is prohibited.": "Número de tarjeta prohibido",
      "Required attribute is missing": "Atributo requerido",
      "Incomplete PayPal account information.":
        "Información de PayPal incompleta",
      "Invalid PayPal account information.": "Información de PayPal no válida",
      "Pre-Approved Payment enabled PayPal account required for vaulting.":
        "Pre-aprobación de cuenta Paypal requerida para poder ser usada",
      "Error communicating with PayPal.": "Error comunicando con PayPal",
      "PayPal authentication expired.": "Autenticación de PayPal expirada",
      "Amount is required.": "Monto requerido",
      "Attribute is not in the required format":
        "Atributo con formato no válido",
    },
  },
  PaymentSuccess: {
    contactName: "Receptor en Cuba",
    deliveryAddress: "Dirección de entrega",
    pickUpAddress: "Dirección de recogida",
    telephone: "Teléfono",
  },
  Profile: {
    personalData: "Datos personales",
    favoriteAddresses: "Mis direcciones",
    logout: "Cerrar sesión",
    cancel: "Cancelar",
    save: "Guardar",
    fullname: "Nombre y Apellidos",
    change: "Cambiar",
    email: "Correo electrónico",
    passwordMustMatch: "Contraseña debe coincidir",
    mainPhone: "Teléfono principal",
  },
  UserAddress: {
    contactName: "Nombre del Receptor en Cuba",
    contactNamePlaceholder: "Quién recibirá el pedido",
    telephoneFieldLabel: "Teléfono",
    editingAddress: "Editar dirección de entrega",
    deliveryAddress: "Nueva dirección de entrega",
    myUsualAddresses: "Mis direcciones habituales",
    addNew: "Añadir Nueva",
    noAddressesFound: "No tienes direcciones añadidas",
    tag: "Etiqueta",
    tagHint: "Identifica tu dirección (casa, trabajo,…)",
    details: "Dirección",
    mainStreet: "Calle Principal",
    typeToSearch: "Escriba para buscar...",
    typeToSearchHint:
      "Incluye el nombre y el número de tu calle; así como la zona o barrio ",
    betweenStreet: "Entre Calle",
    andStreet: "Y Calle",
    building: "Número de Casa o Edificio",
    stairs: "Escalera",
    number: "Apartamento",
    province: "Provincia",
    municipality: "Municipio",
    location: "Barrio",
    additionalIndications: "Indicaciones adicionales",
    additionalIndicationsHint:
      "Por favor, añade cualquier indicación adicional que ayude al repartidor a encontrar tu dirección.",
    storeAddressInMyUsualAddresses:
      "Guardar esta dirección en mis direcciones habituales.",
    requiredFields: "Campos obligatorios",
    save: "Guardar",
    confirm: "Confirmar",
    loading: "Cargando",
    noMatchFound: "No se encontraron coincidencias",
    noMatchesFoundFor: "No se han encontrado resultados para",
    tryAnotherSearchCriteria: "Prueba con otro criterio de búsqueda",
    userAddressFormBtnCancel: "Cancelar",
    userAddressFormBtnSave: "Guardar",
    userAddressFormBtnConfirm: "Confirmar",
  },
  CardManagement: {
    cardListTitle: "Mis tarjetas de crédito",
    cardsNoFound: "No se encontraron tarjetas de crédito",
    addCardFormTitle: "Adicionar tarjeta de crédito",
    editCardFormTitle: "Actualizar datos de la tarjeta",
    buttonAddLabel: "Guardar tarjeta de crédito",
  },
  PurchaseHistory: {
    title: "Historial de pedidos",
    description:
      "Pulsa sobre el pedido que desees repetir y automáticamente se cargarán los platos, cantidades y precios en el carrito.\n Antes de pagar, verifica que todos los datos son correctos y la orden se tramitará de inmediato.",
    contactName: "Nombre",
    mostRecentlyFirst: "Más actuales primero",
    oldestFirst: "Más antiguos primero",
    resumeRequest: "Resumen del pedido",
    total: "Total",
    purchaseAgain: "Volver a pedir",
    noPurchasesToShow: "No tiene compras realizadas",
    orderId: "Orden:",
    deliveryAddress: "Dirección de entrega",
    itemBy: " artículo por ",
    itemsBy: " artículos por ",
    more: "Mostrar más",
    deliveredTo: "Entregado a:",
    date: "Fecha:",
    created: "Creado",
    delivered: "Entregado",
    status: "Estado:",
    resendOrder: "Repetir orden",
    fullOrderDialogTitle: "Este es el resumen completo de tu pedido",
    orderPayType: "Pedido pagado con:",
    deliveryDate: "Fecha de Entrega",
  },
  Offer: {
    additionsTitle: "Agregos",
    additionsActionAdd: "Agregar",
    variantTitle: "Variantes",
    variantTitleNote: "Seleccione una opción",
  },
  OfferItem: {
    soldOut: "Agotado",
    maximumOfferOnStock: "Ha añadido el máximo número de ofertas disponibles.",
  },
  Footer: {
    support: "Soporte",
    frequentQuestions: "Preguntas frecuentes",
    testimonials: "Testimonios",
    help: "Ayuda",
    legal: "Legal",
    privacy: "Privacy",
    termsOfUse: "Condiciones de uso",
    privacyPolicy: "Política de Privacidad",
    cookies: "Cookies",
    contact: "Contacto",
    copyright: "Tamtos",
    termsAndConditions: "Términos y Condiciones",
    joinTheTeam: "Une tu negocio",
    GATEWAY_REJECTED: "Transacción declinada por autorización del Banco",
  },
  Banner: {
    text: " Debido a la Tormenta Tropical Laura, a partir del 24 de Agosto a las 6PM se vera afectado el servicio de comida a domicilio. Rogamos nos disculpen por las molestias ocasionadas.",
  },
  AvailabilityValidation: {
    alertTitle: "¡Atención!",
    alertSubtitle:
      "Existen ofertas del carrito de compras que requieren de su atención:",
    offerMessage:
      "Las siguientes ofertas no están disponibles y serán removidas del carrito de compras:",
    offerNotAvailable:
      "Los siguientes productos no están disponibles y serán removidos del carrito de compras:",
    offerInsufficientStock:
      "No está dispone la cantidad solicitada de los siguientes productos, serán removidos del carrito de compras:",
    variantNotAvailable:
      "Las siguientes especificaciones de productos no están disponibles:",
    additionsNotAvailable:
      "Los siguientes agregos para productos no están disponibles:",
    offerWithVariantsNotAvailable:
      "Los siguientes productos serán removidos del carrito de compras por contener especificaciones que no están disponibles:",
    offerWithAdditionsNotAvailable:
      "Los siguientes productos serán removidos del carrito de compras por contener agregos que no están disponibles:",
    offerAddsMessage:
      "Los siguientes extras no están disponibles. Las comidas que los contengan serán eliminadas del carrito de compra:",
    restaurantMessage:
      "El restaurante elegido está agotado de momento, por favor elija otra opción. Disculpe las inconveniencias.",
  },
  CustomerSatisfaction: {
    subtitle:
      "Tu experiencia es muy valiosa para otros usuarios. ¡Muchas Gracias!",
    labelInputScore: "Tu puntuación general de este restaurante",
    labelInputScoreTag: "Haz clic para puntuar",
    labelInputOpinion: "Tu opinión nos ayuda a dar un mejor servicio",
    send: "Enviar",
    goBack: "Atrás",
    completeRatingMessage: "Su valoración ha sido registrada",
    thanksMessage: " ¡Muchas Gracias!",
    errorMessageTitle: "A ocurrido un error",
    errorMessage: "No es posible realizar la valoración de la orden.",
  },
  Cart: {
    restaurantValidationMessage:
      "Ha seleccionado una oferta de un negocio diferente al de las ofertas existentes en el carrito de compras. Esta acción eliminará las selecciones previas. ¿Desea continuar?",
    subtotal: "Subtotal",
    shipping: "Coste de envío",
    serviceTax: "Tarifa del servicio",
    total: "Total",
    completeRequest: "Completar pedido",
    myRequest: "Mi pedido",
    serviceTaxInfo:
      "'Tarifa del servicio' es la comisión que cobra Tamtos para que la plataforma de Restaurantes exista. Incluye procesamiento de órdenes y pagos, atención al cliente y mantenimiento.",
  },
} as Translation;
