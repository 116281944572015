import {createSelector} from "reselect";
import {
    ALL_RESTAURANT_VIEW, MOST_POPULAR_LOADED_SUCCESS, MOST_POPULAR_LOADED_FAILURE
} from "./restaurant-carousel.actions";

const INITIAL_STATE = {
    restaurantView: false,
    most_popular: [],
};

export default function restaurant_carousel(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case ALL_RESTAURANT_VIEW: {
            return {
                ...state,
                restaurantView: action.restaurantView
            };
        }
        case MOST_POPULAR_LOADED_SUCCESS:
            return {
                ...state,
                most_popular: action.most_popular || [],
            };
        case MOST_POPULAR_LOADED_FAILURE:
            return {
                ...state,
                most_popular: [],
            };
        default: {
            return state;
        }
    }
}

export const selectRestaurantCarousel = state => state.restaurant_carousel;

export const getRestaurantView = createSelector(
    selectRestaurantCarousel,
    state => state.restaurantView
);

export const selectMostPopulars = createSelector(
    selectRestaurantCarousel,
    state => state.most_popular
);