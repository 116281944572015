import { post } from "../../../core/services";
import { setLoadingMaskStatus } from "../../../component/Mask/store/mask.actions";
import { notifyError } from "../../notification/store/notification.actions";

export const RESTAURANT_DETAIL_LOADED_SUCCESS = "RESTAURANT_DETAIL_LOADED_SUCCESS";
export const RESTAURANT_DETAIL_LOADED_FAILURE = "RESTAURANT_DETAIL_LOADED_FAILURE";
export const BUSINESS_OFFERS_LIST_LODADED_SUCCESS = "BUSINESS_OFFERS_LIST_LODADED_SUCCESS";
export const BUSINESS_OFFERS_LIST_LODADED_FAILURE = "BUSINESS_OFFERS_LIST_LODADED_FAILURE";
export const BUSINESS_OFFERS_LIST_FETCH_MORE_SUCCESS = "BUSINESS_OFFERS_LIST_FETCH_MORE_SUCCESS";
export const BUSINESS_OFFERS_LIST_FETCH_MORE_FAILURE = "BUSINESS_OFFERS_LIST_FETCH_MORE_FAILURE";
export const CLEAR_RESTAURANTS_DETAIL_STATES = "CLEAR_RESTAURANTS_DETAIL_STATES";

const baseUrl = "business";

function restaurantDetailLoadedSuccess(response) {
  return {
    type: RESTAURANT_DETAIL_LOADED_SUCCESS,
    response,
  };
}

function restaurantDetailLoadedFailure(response) {
  return {
    type: RESTAURANT_DETAIL_LOADED_FAILURE,
    response,
  };
}

function businessOffersListLoadedSuccess(response) {
  return {
    type: BUSINESS_OFFERS_LIST_LODADED_SUCCESS,
    response,
  };
}

function businessOffersListLoadedFailure(response) {
  return {
    type: BUSINESS_OFFERS_LIST_LODADED_FAILURE,
    response,
  };
}

function businessOffersListFetchMoreSuccess(response) {
  return {
    type: BUSINESS_OFFERS_LIST_FETCH_MORE_SUCCESS,
    response,
  };
}

export function clearRestaurantDetail() {
  return {
    type: CLEAR_RESTAURANTS_DETAIL_STATES,
  };
}

export function loadBusinessDetail(DATA) {
  return (dispatch) => {
    post(`${baseUrl}/businessDetails`, DATA)
      .then((args) => {
        dispatch(restaurantDetailLoadedSuccess(args));
      })
      .catch((response) => {
        const errMsg = response && response.data ? response.data.message : "ERROR!";
        dispatch(restaurantDetailLoadedFailure(errMsg));
        dispatch(notifyError(errMsg));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function loadBusinessOfferListByClassification(DATA, passiveLoading = false) {
  return (dispatch) => {
    post(`${baseUrl}/businessMenu`, DATA)
      .then((response) => {
        if (passiveLoading) {
          dispatch(businessOffersListFetchMoreSuccess(response));
        } else {
          dispatch(businessOffersListLoadedSuccess(response));
        }
      })
      .catch((error) => {
        const errMsg = error && error.data ? error.data.message : "ERROR Aqui!";
        dispatch(businessOffersListLoadedFailure(errMsg));
        dispatch(notifyError(errMsg));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
