import { secureGet, simpleGet, simplePost } from "../../../core/services";
import { notifyError } from "../../notification/store/notification.actions";
import { setLoadingMaskStatus } from "../../../component/Mask/store/mask.actions";

const baseUrl = "deliveryOrder";

export const STORE_DELIVERY_INFO = "STORE_DELIVERY_INFO";
export const DELIVERY_DATE_LOADED_SUCCESS = "DELIVERY_DATE_LOADED_SUCCESS";
export const DELIVERY_DATE_LOADED_FAILURE = "DELIVERY_DATE_LOADED_FAILURE";
export const DELIVERY_DATE_LIST_LOADED_SUCCESS =
  "DELIVERY_DATE_LIST_LOADED_SUCCESS";
export const DELIVERY_DATE_LIST_LOADED_FAILURE =
  "DELIVERY_DATE_LIST_LOADED_FAILURE";
export const DELIVERY_TIME_LOADED_SUCCESS = "DELIVERY_TIME_LOADED_SUCCESS";
export const DELIVERY_TIME_LOADED_FAILURE = "DELIVERY_TIME_LOADED_FAILURE";
export const VALIDATE_DELIVERY_ADDRESS_SUCCESS =
  "VALIDATE_DELIVERY_ADDRESS_SUCCESS";
export const VALIDATE_DELIVERY_ADDRESS_FAILURE =
  "VALIDATE_DELIVERY_ADDRESS_FAILURE";
export const CLEAR_VALID_DELIVERY_ADDRESS = "CLEAR_VALID_DELIVERY_ADDRESS";
export const ESTIMATE_DELIVERY_COST = "ESTIMATE_DELIVERY_COST";
export const CLEAR_DELIVERY_COST = "CLEAR_DELIVERY_COST";
export const PURCHASE_CREATED_SUCCESS = "PURCHASE_CREATED_SUCCESS";
export const PURCHASE_CREATED_FAILURE = "PURCHASE_CREATED_FAILURE";
export const NOTIFY_ORDER_CREATED_SUCCESS = "NOTIFY_ORDER_CREATED_SUCCESS";
export const NOTIFY_ORDER_CREATED_FAILURE = "NOTIFY_ORDER_CREATED_FAILURE";
export const CLEAR_DELIVERY_STATE = "CLEAR_DELIVERY_STATE";

export function storeDeliveryInfo(data) {
  return {
    type: STORE_DELIVERY_INFO,
    data,
  };
}

function deliveryAddressValidationResult(valid) {
  return {
    type: VALIDATE_DELIVERY_ADDRESS_SUCCESS,
    valid,
  };
}

function deliveryAddressValidationFailure(valid) {
  return {
    type: VALIDATE_DELIVERY_ADDRESS_FAILURE,
    valid,
  };
}

function deliveryCostEstimated(cost) {
  return {
    type: ESTIMATE_DELIVERY_COST,
    cost,
  };
}

export function clearDeliveryCost() {
  return {
    type: CLEAR_DELIVERY_COST,
  };
}

export function clearValidDeliveryAddress() {
  return {
    type: CLEAR_VALID_DELIVERY_ADDRESS,
  };
}

function purchaseCreatedSuccess(purchase) {
  return {
    type: PURCHASE_CREATED_SUCCESS,
    purchase,
  };
}

function purchaseCreatedFailure(purchase) {
  return {
    type: PURCHASE_CREATED_FAILURE,
    purchase,
  };
}

function notifyOrderCreatedSuccess(response) {
  return {
    type: NOTIFY_ORDER_CREATED_SUCCESS,
    response,
  };
}

function notifyOrderCreatedFailure(response) {
  return {
    type: NOTIFY_ORDER_CREATED_FAILURE,
    response,
  };
}

function deliveryDateLoadedSuccess(response) {
  return {
    type: DELIVERY_DATE_LOADED_SUCCESS,
    response,
  };
}

function deliveryDateLoadedFailure(response) {
  return {
    type: DELIVERY_DATE_LOADED_FAILURE,
    response,
  };
}

function deliveryDateListLoadedSuccess(response) {
  return {
    type: DELIVERY_DATE_LIST_LOADED_SUCCESS,
    response,
  };
}

function deliveryDateListLoadedFailure(response) {
  return {
    type: DELIVERY_DATE_LIST_LOADED_FAILURE,
    response,
  };
}

function deliveryTimeLoadedSuccess(response) {
  return {
    type: DELIVERY_TIME_LOADED_SUCCESS,
    response,
  };
}

function deliveryTimeLoadedFailure(response) {
  return {
    type: DELIVERY_TIME_LOADED_FAILURE,
    response,
  };
}

export function clearDeliveryState() {
  return {
    type: CLEAR_DELIVERY_STATE,
  };
}

export function loadServerCurrentDate() {
  return (dispatch) => {
    secureGet(`${baseUrl}/getActualDate`)
      .then((response) => {
        dispatch(deliveryDateLoadedSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(deliveryDateLoadedFailure(errMsg));
        dispatch(notifyError(errMsg));
      });
  };
}

export function loadDeliveryDateList(RESTAURANT_ID) {
  return (dispatch) => {
    secureGet(`${baseUrl}/getDeliveryDateTimeTest?id=${RESTAURANT_ID}`)
      .then((response) => {
        dispatch(deliveryDateListLoadedSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(deliveryDateListLoadedFailure(errMsg));
        dispatch(notifyError(errMsg));
      });
  };
}

export function loadDeliveryTimeList(DATE, RESTAURANT_ID) {
  return (dispatch) => {
    secureGet(
      `${baseUrl}/getDeliveryHoursRangeTest?date=${DATE}&id=${RESTAURANT_ID}`
    )
      .then((response) => {
        dispatch(deliveryTimeLoadedSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(deliveryTimeLoadedFailure(errMsg));
        dispatch(notifyError(errMsg));
      });
  };
}

export function validateDeliveryAddress(restaurantId, deliveryAddress) {
  return (dispatch) => {
    simplePost(
      `address/validateAddressForDelivery?restaurantId=${restaurantId}`,
      deliveryAddress
    )
      .then((response) => dispatch(deliveryAddressValidationResult(response)))
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(deliveryAddressValidationFailure(errObj.result));
          dispatch(notifyError(errMsg));
        }
      });
  };
}

export function estimateDeliveryCost(RESTAURANT_ID, DISTANCE) {
  return (dispatch) => {
    secureGet(`cart/getDeliveryCost?restaurantId=${RESTAURANT_ID}&distance=${DISTANCE}`)
      .then((cost) => dispatch(deliveryCostEstimated(cost)));
  };
}

export function createPurchase(purchase, history) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/createUpdateDeliveryOrder`, purchase)
      .then((response) => {
        dispatch(purchaseCreatedSuccess(response));
        history.push({ pathname: "/prv/pay_success" });
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(purchaseCreatedFailure(errObj.result));
          history.push({ pathname: "/prv/pay_error" });
          dispatch(notifyError(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function notifyOrderCreated(ORDER_ID) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simpleGet(`${baseUrl}/sendNotificationOrder?orderId=${ORDER_ID}`)
      .then((response) => {
        dispatch(notifyOrderCreatedSuccess(response));
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(notifyOrderCreatedFailure(errObj.result));
          dispatch(notifyError(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
