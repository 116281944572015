import React from "react";

import "./css/ToastStyle.scss";

import { Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";


const Toast = ({ text }) => {
  return (
    <div className="toast-distribution">
      <Warning />
      <Typography variant="caption" display="block">
        {text}
      </Typography>
    </div>
  ) 
};

export default Toast;
