import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { simpleGet, simplePost } from "core/services";

const baseUrl = "payment/cll2";

export const SET_USER_CARD_DATA = "SET_USER_CARD_DATA";
export const LOAD_CARDS_SUCCESS = "LOAD_CARDS_SUCCESS";
export const LOAD_CARDS_FAILURE = "LOAD_CARDS_FAILURE";
export const ADD_USER_CARD_SUCCESS = "ADD_USER_CARD_SUCCESS";
export const ADD_USER_CARD_FAILURE = "ADD_USER_CARD_FAILURE";
export const SET_USER_CARD_TO_EDIT = "SET_USER_CARD_TO_EDIT";
export const EDIT_USER_CARD_SUCCESS = "EDIT_USER_CARD_SUCCESS";
export const EDIT_USER_CARD_FAILURE = "EDIT_USER_CARD_FAILURE";
export const DELETE_USER_CARD_SUCCESS = "DELETE_USER_CARD_SUCCESS";
export const DELETE_USER_CARD_FAILURE = "DELETE_USER_CARD_FAILURE";
export const CLEAR_MANAGE_CREDIT_CARD = "CLEAR_MANAGE_CREDIT_CARD";

function cardsLoadSuccess(response) {
  return {
    type: LOAD_CARDS_SUCCESS,
    response,
  };
}

function cardsLoadFailure(response) {
  return {
    type: LOAD_CARDS_FAILURE,
    response,
  };
}

function addUserCardSuccess(response) {
  return {
    type: ADD_USER_CARD_SUCCESS,
    response,
  };
}

function addUserCardFailure(response) {
  return {
    type: ADD_USER_CARD_FAILURE,
    response,
  };
}

function editUserCardSuccess(response) {
  return {
    type: EDIT_USER_CARD_SUCCESS,
    response,
  };
}

function editUserCardFailure(response) {
  return {
    type: EDIT_USER_CARD_FAILURE,
    response,
  };
}

function deleteUserCardSuccess(response) {
  return {
    type: DELETE_USER_CARD_SUCCESS,
    response,
  };
}

function deleteUserCardFailure(response) {
  return {
    type: DELETE_USER_CARD_FAILURE,
    response,
  };
}

export function setUserCardData(response) {
  return {
    type: SET_USER_CARD_DATA,
    response,
  };
}

export function clearManageCreditCard() {
  return {
    type: CLEAR_MANAGE_CREDIT_CARD,
  };
}

export function loadCards() {
  return (dispatch) => {
    simpleGet(`${baseUrl}/customer_payment_methods`)
      .then((response) => {
        dispatch(cardsLoadSuccess(response));
      })
      .catch((response) => {
        dispatch(cardsLoadFailure(response));
      });
  };
}

export function addCard(DATA) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/add_customer_payment_methods`, DATA)
      .then((response) => {
        dispatch(addUserCardSuccess(response));
        dispatch(loadCards());
        dispatch(setLoadingMaskStatus(false));
      })
      .catch((response) => {
        dispatch(addUserCardFailure(response));
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function editCard() {
  return (dispatch) => {
    simplePost(`${baseUrl}/customer_payment_methods`)
      .then((response) => {
        dispatch(editUserCardSuccess(response));
      })
      .catch((response) => {
        dispatch(editUserCardFailure(response));
      });
  };
}

export function deleteCard(ID) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/delete_customer_payment_methods`, { id: ID })
      .then((response) => {
        dispatch(deleteUserCardSuccess(response));
      })
      .catch((response) => {
        dispatch(deleteUserCardFailure(response));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
