import React, { useEffect } from "react";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";
import { useDispatch } from "react-redux";
import { sendToCheckAvailability } from "../availabilityValidation/store/availability-validation.actions";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  // const translations = useTranslations();

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(setLoadingMaskStatus(false));
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wrap container-fluid" style={{ textAlign: 'left' }}>
      <h1 style={{ textAlign: 'center' }}> Política de privacidad – Tamtos </h1>
      <p>
        Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o realiza una compra en www.tamtos.com
      </p>
      <p>
        <strong>Información personal que recopilamos</strong><br />
        Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas en su dispositivo. Además, a medida que navega por el Sitio, recopilamos información sobre las páginas web o productos individuales que ve, qué sitios web o términos de búsqueda lo remitieron al Sitio e información sobre cómo interactúa con el Sitio. Nos referimos a esta información recopilada automáticamente como «Información del dispositivo».
      </p>
      <p>
        Recopilamos información del dispositivo utilizando las siguientes tecnologías:
        – Las «cookies» son archivos de datos que se colocan en su dispositivo o computadora y que a menudo incluyen un identificador único anónimo. Para obtener más información sobre las cookies y cómo deshabilitarlas, visite http://www.allaboutcookies.org.
        – Los «archivos de registro» realizan un seguimiento de las acciones que ocurren en el Sitio y recopilan datos que incluyen su dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia / salida y marcas de fecha / hora.
        – Las «balizas web», las «etiquetas» y los «píxeles» son archivos electrónicos que se utilizan para registrar información sobre cómo navega por el Sitio.

      </p>
      <p>
        Además, cuando realiza una compra o intenta realizar una compra a través del Sitio, recopilamos cierta información de usted, incluido su nombre, dirección de facturación, dirección de envío, información de pago (incluidos los números de tarjeta de crédito, dirección de correo electrónico y número de teléfono). a esta información como «Información de pedido».
      </p>
      <p>
        Cuando hablamos de “Información personal” en esta Política de privacidad, hablamos tanto de Información del dispositivo como de Información del pedido.
      </p>
      <p>
        <strong>¿Como usamos tu información personal?</strong><br />
        Usamos la información de pedidos que recopilamos generalmente para cumplir con los pedidos realizados a través del Sitio (incluido el procesamiento de su información de pago, la organización del envío y el suministro de facturas y / o confirmaciones de pedidos). Además, utilizamos esta información de pedido para:
        – Comunicarse contigo;
        – Revise nuestros pedidos de riesgo potencial o fraude; y
        – Cuando esté en línea con las preferencias que ha compartido con nosotros, le proporcionaremos información o publicidad relacionada con nuestros productos o servicios.
      </p>
      <p>
        Utilizamos la información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en general, para mejorar y optimizar nuestro sitio (por ejemplo, generando análisis sobre cómo navegan e interactúan nuestros clientes) el Sitio, y para evaluar el éxito de nuestras campañas de marketing y publicidad).
      </p>
      <p>
        <strong>Compartir su información personal</strong><br />
        Compartimos su información personal con terceros para ayudarnos a usar su información personal, como se describe anteriormente. Utilizamos Google Analytics para ayudarnos a comprender cómo nuestros clientes usan el Sitio. Puede leer más sobre cómo Google usa su Información personal aquí: https://www.google.com/intl/en/policies/privacy/. También puede optar por no participar en Google Analytics aquí: https://tools.google.com/dlpage/gaoptout.
      </p>
      <p>
        Finalmente, también podemos compartir su información personal para cumplir con las leyes y regulaciones aplicables, para responder a una citación, orden de allanamiento u otra solicitud legal de información que recibimos, o para proteger nuestros derechos.
      </p>
      <p>
        <strong>Publicidad conductual</strong><br />
        Como se describió anteriormente, utilizamos su información personal para proporcionarle anuncios dirigidos o comunicaciones de marketing que creemos que pueden ser de su interés. Para obtener más información sobre cómo funciona la publicidad dirigida, puede visitar la página educativa de Network Advertising Initiative («NAI») en http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
      </p>
      <p>
        Puede optar por no recibir publicidad dirigida enviando un corro a info@tamtos.com
      </p>
      <p>
        Además, puede optar por no participar en algunos de estos servicios visitando el portal de exclusión de Digital Advertising Alliance en: http://optout.aboutads.info/.
      </p>
      <p>
        <strong>No rastrear</strong><br />
        Tenga en cuenta que no modificamos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal de No rastrear desde su navegador.
      </p>
      <p>
        <strong>Tus derechos</strong><br />
        Si es residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que su información personal sea corregida, actualizada o eliminada. Si desea ejercer este derecho, contáctenos a través de la información de contacto a continuación.
      </p>
      <p>
        Además, si usted es residente europeo, tenga en cuenta que estamos procesando su información para cumplir con los contratos que podamos tener con usted (por ejemplo, si realiza un pedido a través del Sitio), o de lo contrario para llevar a cabo nuestro negocio legítimo.
      </p>
    </div >
  );
}

export default PrivacyPolicy;
