import {secureGet} from "../../../core/services";
import {setLoadingMaskStatus} from "../../../component/Mask/store/mask.actions";

export const DONE_FETCH_PURCHASES = "DONE_FETCH_PURCHASES";
export const DONE_FETCH_MORE_PURCHASES = "DONE_FETCH_MORE_PURCHASES";
export const FAILURE_FETCH_PURCHASES = "FAILURE_FETCH_PURCHASES";

const baseUrl = "deliveryOrder";

function doneFetchPurchases(response) {
  return {
    type: DONE_FETCH_PURCHASES,
    response
  };
}

function doneFetchMorePurchases(response) {
  return {
    type: DONE_FETCH_MORE_PURCHASES,
    response
  };
}

export function failureFetchPurchases(response) {
  return {
    type: FAILURE_FETCH_PURCHASES,
    response
  };
}

export function doFetchPurchases(POINTER, COUNTER, PASSIVE_LOADING = false) {
  return dispatch => {
    if (!PASSIVE_LOADING) {
      dispatch(setLoadingMaskStatus(true));
    }
    secureGet(`${baseUrl}/getDeliveryOrder?pointer=${POINTER}&counter=${COUNTER}`)
      .then(response => {
        if (!PASSIVE_LOADING) {
          dispatch(doneFetchPurchases(response));
        } else {
          dispatch(doneFetchMorePurchases(response));
        }
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(failureFetchPurchases(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}