import { createSelector } from "reselect";
import * as actions_delivery from "./delivery.actions";
import {CLEAR_PAYMENTS} from "./payment.actions";

const INITIAL_STATE = {
  addresses: [],
  new_delivery_address: null,
  done_add_delivery_address: false,
  purchase: {},
  coupon_payment: null,
  purchase_coupon_payment_success: false,
  purchase_coupon_payment_failure: false,
  valid: null,
  deliveryInfo: null,
  deliveryCost: null,
  deliveryDate: null,
  deliveryDateOptions: null,
  deliveryTimeOptions: null,
};

export default function delivery(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions_delivery.STORE_DELIVERY_INFO: {
      localStorage.setItem('checkout', JSON.stringify(action.data));
      return {
        ...state,
        deliveryInfo: action.data,
      };
    }
    case actions_delivery.DELIVERY_DATE_LOADED_SUCCESS: {
      return {
        ...state,
        deliveryDate: action.response.data,
        deliveryTimeOptions: null,
      };
    }
    case actions_delivery.DELIVERY_DATE_LOADED_FAILURE: {
      return {
        ...state,
        deliveryDate: null,
        deliveryTimeOptions: null,
      };
    }
    case actions_delivery.DELIVERY_DATE_LIST_LOADED_SUCCESS: {
      return {
        ...state,
        deliveryDateOptions: action.response.data,
      };
    }
    case actions_delivery.DELIVERY_DATE_LIST_LOADED_FAILURE: {
      return {
        ...state,
        deliveryDateOptions: null,
      };
    }
    case actions_delivery.DELIVERY_TIME_LOADED_SUCCESS: {
      return {
        ...state,
        deliveryTimeOptions: action.response.data,
      };
    }
    case actions_delivery.DELIVERY_TIME_LOADED_FAILURE: {
      return {
        ...state,
        deliveryTimeOptions: null,
      };
    }
    case actions_delivery.VALIDATE_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        valid: action.valid.data,
        deliveryCost: null,
      };
    }
    case actions_delivery.VALIDATE_DELIVERY_ADDRESS_FAILURE: {
      return {
        ...state,
        valid: action.valid,
        deliveryCost: null,
      };
    }
    case actions_delivery.CLEAR_VALID_DELIVERY_ADDRESS: {
      return {
        ...state,
        valid: null,
      };
    }
    case actions_delivery.ESTIMATE_DELIVERY_COST: {
      return {
        ...state,
        deliveryCost: action.cost.data,
      };
    }
    case actions_delivery.CLEAR_DELIVERY_COST: {
      return {
        ...state,
        deliveryCost: null,
      };
    }
    case actions_delivery.PURCHASE_CREATED_SUCCESS: {
      return {
        ...state,
        coupon_payment: action.purchase.data,
        purchase_coupon_payment_success: true,
        purchase_coupon_payment_failure: false,
      };
    }
    case actions_delivery.PURCHASE_CREATED_FAILURE: {
      return {
        ...state,
        coupon_payment: null,
        purchase_coupon_payment_success: false,
        purchase_coupon_payment_failure: true,
      };
    }
    case actions_delivery.NOTIFY_ORDER_CREATED_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions_delivery.NOTIFY_ORDER_CREATED_FAILURE: {
      return {
        ...state,
      };
    }
    case actions_delivery.CLEAR_DELIVERY_STATE: {
      return {
        ...state,
        done_add_delivery_address: false,
        purchase_coupon_payment_success: false,
        purchase_coupon_payment_failure: false,
        new_delivery_address: null,
        purchase: {},
        valid: null,
      };
    }
    case CLEAR_PAYMENTS: {
      return {
        ...state,
        coupon_payment: INITIAL_STATE.coupon_payment,
        purchase_coupon_payment_success: INITIAL_STATE.purchase_coupon_payment_success,
        purchase_coupon_payment_failure: INITIAL_STATE.purchase_coupon_payment_failure,
      };
    }
    default: {
      return {
        ...state,
        new_delivery_address: null,
      };
    }
  }
}

export const selectDeliveryState = (state) => state.delivery;

export const selectDeliveryInfo = createSelector(
  selectDeliveryState,
  (state) => state.deliveryInfo
);

export const selectDeliveryAddresses = createSelector(
  selectDeliveryState,
  (state) => state.addresses
);

export const selectDeliveryDate = createSelector(
  selectDeliveryState,
  (state) => state.deliveryDate
);

export const selectDeliveryDateList = createSelector(
  selectDeliveryState,
  (state) => state.deliveryDateOptions
);

export const selectDeliveryTimeOptions = createSelector(
  selectDeliveryState,
  (state) => state.deliveryTimeOptions
);

export const selectValidationDeliveryAddress = createSelector(
  selectDeliveryState,
  (state) => state.valid
);

export const selectDeliveryCost = createSelector(
  selectDeliveryState,
  (state) => state.deliveryCost
);

export const selectCouponPayment = createSelector(
  selectDeliveryState,
  (state) => state.coupon_payment
);

export const selectCouponPaymentSuccess = createSelector(
  selectDeliveryState,
  (state) => state.purchase_coupon_payment_success
);

export const selectCouponPaymentFailure = createSelector(
  selectDeliveryState,
  (state) => state.purchase_coupon_payment_failure
);
