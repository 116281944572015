import React, { useEffect, useState } from 'react';
import useTranslations from 'translations';
import './css/CardManagementStyles.scss';
import { useDispatch, useSelector } from "react-redux";
import { Add, Clear } from '@material-ui/icons';
import { Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import UpsertCreditCard from 'modules/Pay/components/UpsertCreditCard';
import { addCard, clearManageCreditCard, deleteCard, loadCards } from './store/card-management.actions';
import { addCardSelector, cardsListSelector, deletedCardSelector } from './store/card-management.reducers';
import PayMethodRow from "modules/payment/components/StepSelectPaymentMethod/PayMethodRow";
import { selectProfile } from 'modules/profile/store/profile.reducers';
import { doFetchProfile } from 'modules/profile/store/profile.actions';
import { showAlert } from 'modules/alert/store/alert.actions';
import { setLoadingMaskStatus } from 'component/Mask/store/mask.actions';

export default function CardManagement() {
  const translation = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(selectProfile);
  const cardAdded = useSelector(addCardSelector);
  const cardDeleted = useSelector(deletedCardSelector);
  const userCardsList = useSelector(cardsListSelector);
  const [edit, setEdit] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const [method, setMethod] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [creditCardsList, setCreditCardsList] = useState([]);

  useEffect(() => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(doFetchProfile());
    dispatch(loadCards());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userCardsList) {
      setCreditCardsList(userCardsList);
      dispatch(clearManageCreditCard());
      dispatch(setLoadingMaskStatus(false));
    }
    // eslint-disable-next-line
  }, [userCardsList]);

  useEffect(() => {
    if (cardAdded) {
      closeCardForm();
    }
    // eslint-disable-next-line
  }, [cardAdded]);

  useEffect(() => {
    if (cardDeleted) {
      dispatch(loadCards());
    }
    // eslint-disable-next-line
  }, [cardDeleted]);

  const prepareToAddCard = () => {
    setSelectedCard(null);
    setEdit(false);
    setShowCardForm(true);
  };

  const closeCardForm = (card) => {
    setSelectedCard(card);
    setEdit(false);
    setShowCardForm(false);
  };

  const addCreditCard = (card) => {
    let newCard = {
      accountId: user.accountId,
      card: {
        cvv: card.cvv,
        postalCode: card.zip,
        number: card.number.replaceAll(" ", ""),
        expirationMonth: card.expirationDate.split("/")[0],
        expirationYear: `20${card.expirationDate.split("/")[1]}`,
      },
    };
    dispatch(setLoadingMaskStatus(true));
    dispatch(addCard(newCard));
  };

  const deleteCreditCard = (cardId) => {
    dispatch(showAlert(translation.Payment.alertDeletePaymentMethod, '', '', '', () => {
      dispatch(setLoadingMaskStatus(true));
      dispatch(deleteCard(cardId));
    }));
  };

  let DELIVERY_CARD_FORM = null;
  if (showCardForm) {
    DELIVERY_CARD_FORM = (
      <div className="address-section">
        <Paper className="address-form">
          <div className="address-form-header">
            <Typography variant="h5" component="h3">
              {edit
                ? `${translation.CardManagement.editCardFormTitle} – ${selectedCard.name}`
                : translation.CardManagement.addCardFormTitle}
            </Typography>
            <button
              className="address-form-header__close button--transparent button--round"
              onClick={closeCardForm}
            >
              <Clear component={"svg"} />
            </button>
          </div>
          <div className="address-form-body" style={{ padding: '1.5em 1.5em 1em' }}>
            <UpsertCreditCard card={selectedCard} handleSubmit={addCreditCard} />
          </div>
        </Paper>
      </div>
    );
  }

  const empty = creditCardsList?.length > 0 ? creditCardsList.filter(address => address.id).length === 0 : false;

  return (
    <div>
      {showCardForm ? (
        DELIVERY_CARD_FORM
      ) : (
        <div className="container small-container">
          <div className="card_management">
            <div className="card_management_header">
              <h2 className="main-title">
                {translation.CardManagement.cardListTitle}
                <span className="underline"></span>
              </h2>
              <button className="button--primary button--primary-inverted-rounded" onClick={prepareToAddCard}>
                <Add style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                <span>{translation.UserAddress.addNew}</span>
              </button>
            </div>
            <ul className="card_management_data">
              {!empty
                ? creditCardsList
                  ? <div className="payment-methods">
                    {creditCardsList?.map(({ id, firstSix, lastFour, cardType, nameOnCard, paymentMethodType, maskedNumber }) => {
                      const icon = paymentMethodType === 'CARD' ? (cardType?.replace(/\s/g, "") || "visa") : `paypal`;
                      // const text = paymentMethodType === 'CARD' ? `${translation.Payment.creditCardEndWith} ${lastFour}` : nameOnCard;
                      const text = paymentMethodType === 'CARD'
                        ? firstSix && lastFour
                          ? `${firstSix}******${lastFour}`
                          // ? `${card?.firstSix.substring(0, 4)} - ${card?.firstSix.substring(4, 6)}XX - XXXX - ${card?.lastFour}`
                          : maskedNumber
                        : nameOnCard;
                      return <PayMethodRow
                        id={id}
                        key={id}
                        icon={icon}
                        ifCheckFunction={(id) => method === id}
                        text={text}
                        handleClick={() => setMethod(id)}
                        handleDelete={() => deleteCreditCard(id)}
                        styleClass={{ cursor: 'default' }}
                      />
                    })}
                  </div>
                  : <div className="payment-methods">
                    <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
                    <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
                    <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
                  </div>
                : <h3>-- {translation.CardManagement.cardsNoFound} --</h3>
              }
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}