import { setLoadingMaskStatus } from "../../../component/Mask/store/mask.actions";
import { notifyError } from "../../notification/store/notification.actions";
import { post, simpleGet } from "../../../core/services";
import { doFetchProfile } from "modules/profile/store/profile.actions";

const baseUrl = "account";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CLL_LOCALSTORAGE_LOGIN = "CLL_LOCALSTORAGE_LOGIN";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_DEVICEID = "LOGIN_DEVICEID";
export const SHOW_EMAIL_FORM = "SHOW_EMAIL_FORM";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const CHECK_VERIFY_EMAIL = "CHECK_VERIFY_EMAIL";
export const TOKEN_REFRESHED = "TOKEN_REFRESHED";
export const TOKEN_FAILURE = "TOKEN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";

export function cllLoginSuccess(response) {
  return {
    type: CLL_LOCALSTORAGE_LOGIN,
    response,
  };
}

function loginSuccess(response) {
  return {
    type: LOGIN_SUCCESS,
    response,
  };
}

function loginFailure(response) {
  return {
    type: LOGIN_FAILURE,
    response,
  };
}

function loginDeviceId() {
  return {
    type: LOGIN_DEVICEID,
  };
}

function showEmailForm() {
  return { type: SHOW_EMAIL_FORM };
}

function verifyEmail(response) {
  return {
    type: VERIFY_EMAIL,
    response,
  };
}

function checkVerifyEmail(response) {
  return {
    type: CHECK_VERIFY_EMAIL,
    response,
  };
}

export function tokenRefreshed(response) {
  return {
    type: TOKEN_REFRESHED,
    response,
  };
}

export function tokenFailure(response) {
  return {
    type: TOKEN_FAILURE,
    response,
  };
}

function logoutSuccess(response) {
  return {
    type: LOGOUT_SUCCESS,
    response,
  };
}

function logoutFailure(response) {
  return {
    type: LOGOUT_FAILURE,
    response,
  };
}

export function clearAuthState() {
  return {
    type: CLEAR_AUTH_STATE,
  };
}

export function verifyPin(DATA) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    post(`${baseUrl}/login`, DATA)
      .then((response) => {
        if (response) {
          switch (response?.data?.code) {
            case 1159: //email required
              localStorage.setItem("Dt3mp0", JSON.stringify(DATA));
              dispatch(showEmailForm());
              break;
            case 1126: //verify account - show component
              localStorage.setItem("Dt3mp0", JSON.stringify(DATA));
              dispatch(verifyEmail(response?.data?.result));
              break;
            case 8013: //verify account - show alert
              localStorage.setItem("Dt3mp0", JSON.stringify(DATA));
              dispatch(checkVerifyEmail(response?.data?.result));
              break;
            case 1110: //wrong code
              dispatch(loginFailure(response?.data?.message));
              break;
            case 1146: //expired code
              dispatch(loginFailure(response?.data?.message));
              break;
            default:   //login success - return token
              dispatch(loginSuccess(response?.data?.result));
              dispatch(doFetchProfile());
              break;
          }
        }
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : {};
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(loginFailure(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function logoutUser() {
  return (dispatch) => {
    simpleGet(`${baseUrl}/logout`)
      .catch((response) => {
        const errMsg = response?.data?.message || "ERROR!";
        dispatch(logoutFailure(errMsg));
        dispatch(notifyError(errMsg));
      })
      .finally(() => {
        dispatch(logoutSuccess());
        dispatch(setLoadingMaskStatus(false));
        localStorage.setItem("address", null);
        localStorage.setItem("cllWebApp-agent", null);
        setTimeout(() => {
          window.location.reload();
        }, 400);
      });
  };
}

export function requestPin(DATA) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    post(`${baseUrl}/passwordless`, DATA)
      .then((response) => {
        localStorage.setItem("Dt3mp0", JSON.stringify(DATA));
        // dispatch(notifyInfo(response.data.description));
        dispatch(loginDeviceId());
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : {};
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(loginFailure(errMsg));
          // dispatch(notifyError(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
