import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import useTranslations from "translations";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

const AcceptTerms = ({ acceptTerms, setAcceptTerms }) => {
  const translation = useTranslations();

  return <div className="step-delivery__checkbox">
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlank component={"svg"} style={{ color: "#2c2c2c" }} />}
          checkedIcon={<CheckBox style={{ color: "#2c2c2c" }} component={"svg"} />}
          checked={acceptTerms}
          onChange={() => setAcceptTerms(!acceptTerms)}
          value="acceptTerms"
        />
      }
      label={<p style={{ textAlign: "left", margin: 0 }}>{translation.Payment.acceptTerms}</p>}
    />
  </div>;
}

export default AcceptTerms;