import * as bc_actions from './business-categories.actions';
import {createSelector} from "reselect";

const INITIAL_STATE = {
  business_categories_list: [],
};

export default function business_categories(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case bc_actions.BUSINESS_CATEGORIES_LOADED_SUCCESS:
      return {
        ...state,
        business_categories_list: action.response.data || [],
      };
    case bc_actions.BUSINESS_CATEGORIES_LOADED_FAILURE:
      return {
        ...state,
        business_categories_list: [],
      };
    default:
      return state;
  }
}
export const selectBusinessCategoriesState = (state) => state.business_categories;

export const selectBusinessCategories = createSelector(
  selectBusinessCategoriesState,
  (state) =>
    state.business_categories_list
);

