import React, { useRef, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import Swiper from "react-id-swiper";
import { ChevronLeftSharp, ChevronRightSharp } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const params = {
  lazy: true,
  spaceBetween: 0,
  breakpoints: {
    320: {
      slidesPerView: 4.5,
      slidesPerGroup: 1,
    },
    576: {
      slidesPerView: 6.5,
      slidesPerGroup: 1,
    },
    768: {
      slidesPerView: 7.5,
      slidesPerGroup: 1,
    },
    1024: {
      slidesPerView: 8.5,
      slidesPerGroup: 1,
    },
    1200: {
      slidesPerView: 9.5,
      slidesPerGroup: 1,
    }
  },
};

export default function BusinessSwipper({ loading, list, goToBusiness, businessPlaceholders }) {
  const swiperRef = useRef(null);

  const goNext = () => swiperRef.current?.swiper?.slideNext();
  const goPrev = () => swiperRef.current?.swiper?.slidePrev();

  return !loading
    ?
    <div style={{ width: '100%' }}>
      <div className="swipper-actions">
        <IconButton onClick={goPrev}><ChevronLeftSharp /></IconButton>
        <IconButton onClick={goNext}><ChevronRightSharp /></IconButton>
      </div>
      <Swiper {...params} ref={swiperRef} >
        {list?.map((business, k) => (
          <div className="business-item" key={k} onClick={(event) => goToBusiness(event, business)}>
            <div className="business-item__avatar">
              <img src={business.imgSm} alt={business.name} />
            </div>
            <div className="business-item__name">{business.name}</div>
          </div>))}
      </Swiper>
    </div>
    : <div className="skeleton-row">
      {[...Array(businessPlaceholders)].map((_, k) => <div key={k} className="bc-skeleton" style={{ marginBottom: '1em' }}>
        <div className="bc-skeleton__avatar">
          <Skeleton variant="circle" width={65} height={65} animation="wave" />
        </div>
        <div className="bc-skeleton__title">
          <Skeleton variant="rect" width={65} height={20} animation="wave" />
        </div>
      </div>)}
    </div>;
}