import request from "request-promise";
import {
  browserName, browserVersion, deviceType, getUA, mobileModel, mobileVendor, osName, osVersion,
} from "react-device-detect";
import * as _ from "lodash";

export const getDeviceDetectInfo = () => {
  return {
    browserName,
    browserVersion,
    osName,
    osVersion,
    mobileVendor,
    mobileModel,
    getUA,
    deviceType,
  };
};

export const loadCountryCode = async () => {
  try {
    let result = await request("https://www.cloudflare.com/cdn-cgi/trace").then((response) => {
      let resp = {};
      let test = response.split('\n');
      _.forEach(test, function (item) {
        const split = item.split("=");
        const key = split[0];
        resp[key] = split[1];
      });
      return resp;
    });
    return {country: result.loc, address: result.ip};
  } catch (e) {
    return {country: "US"};
  }
};

export const getIp = async () => {
  try {
    const {ip} = await request("https://jsonip.com").then((response) =>
      JSON.parse(response)
    );
    const result = await request(
      `https://apility-io-ip-geolocation-v1.p.rapidapi.com/${ip}`,
      {
        headers: {
          "x-rapidapi-host": "apility-io-ip-geolocation-v1.p.rapidapi.com",
          "x-rapidapi-key":
            "cd2f9bfa23msh337456b4eefed85p1821d7jsne732a71f6ac0",
          accept: "application/json",
          useQueryString: true,
        },
      }
    ).then((response) => JSON.parse(response));

    return result.ip;
  } catch (e) {
    return {country: "US"};
  }
};

export const fetchIp = () => {
  return fetch("https://api.ipify.org?format=json")
    .then((response) => response.json())
    .then(({ip}) => {
      return ip;
    });
};
