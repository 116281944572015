import React from "react";

export default function PdfIcon({ icon, width = 16, alt, classes = "", ext = "png", fill }) {
  return (
    <img
      className={classes}
      width={width}
      src={`./images/icons/${icon}.${ext}`}
      alt={alt}
      style={{ 'width': width }}
      fill={fill}
    />
  );
}
