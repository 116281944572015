import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import * as braintree from "braintree-web";

import "./css/PaymentProcessStyle.scss";

import useTranslations from "translations/useTranslations";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { showAlert } from "modules/alert/store/alert.actions";

import { BRAINTREE } from "./paymentType";
import { chargeCll2Payment } from "./store/payment.actions";
import { notifyError } from "modules/notification/store/notification.actions";
import Step3dSecureVerification from "./components/Step3dSecureVerification/Step3dSecureVerification";
import { selectCll2ClientToken } from "./store/payment.reducers";
import { sendToCheckAvailability } from "modules/availabilityValidation/store/availability-validation.actions";


export default function PaymentProcess3DSecure({ history, location }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const translation = useTranslations();

  const clientToken = useSelector(selectCll2ClientToken);
  // const [threeDClient, setThreeDClient] = useState(null);

  useEffect(() => {
    if (clientToken) {
      (async () => {
        try {
          const { threeDClient } = await initBT();
          // setThreeDClient(threeDClient);
          const { paymentMethodId, nonce, amount, paymentRequest } = location.state;
          handle3dSec(threeDClient, paymentMethodId, nonce, parseFloat(amount).toFixed(2), paymentRequest);
        } catch (e) {
          console.log(e);
          dispatch(setLoadingMaskStatus(false));
          dispatch(showAlert(`Su pago no ha sido procesado debido a un error con la seguridad 3Ds, será redirigido al checkout para repetir el proceso`,
            '', '', '', () => history.push({ pathname: "/prv/checkout" })))
        }
      })();
    }
    // eslint-disable-next-line
  }, [clientToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(sendToCheckAvailability());

    if (!localStorage.getItem('checkout')) {
      history.push({ pathname: "/prv/checkout" });
    }

    // eslint-disable-next-line
  }, []);

  const initBT = async () => {
    try {
      const btClient = await braintree.client.create({ authorization: clientToken, locale: 'de_DE' });
      console.log('btClient', btClient);
      const threeDClient = await braintree.threeDSecure.create({ client: btClient });
      console.log('bt3DClient', threeDClient);
      // setThreeDClient(threeDClient);
      return { threeDClient };
    } catch (e) {
      console.log(`Error trying to initialize BT Client: \n ${JSON.stringify(e, null, 4)}`);
      dispatch(setLoadingMaskStatus(false));
      throw e;
    }
  }

  const handle3dSec = (threeDClient, paymentMethodId, nonce, amount, paymentRequest) => {
    let my3DSContainer;
    threeDClient.verifyCard({
      nonce,
      amount,
      addFrame: function (err, iframe) {
        my3DSContainer = document.createElement('div');
        my3DSContainer.appendChild(iframe);
        document.getElementById("verification-container").appendChild(my3DSContainer);
        dispatch(setLoadingMaskStatus(false));
      },
      removeFrame: function () {
        document.getElementById("verification-container").removeChild(my3DSContainer);
        dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
      }
    }, function (err, payload) {
      if (err) {
        console.error(err);
        dispatch(notifyError(err.error.message));
        return;
      }

      if (payload.liabilityShifted) {
        // Liablity has shifted
        dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
        dispatch(chargeCll2Payment(BRAINTREE, amount, paymentMethodId, payload.nonce, paymentRequest, null));
      } else if (payload.liabilityShiftPossible) {
        // Liablity may still be shifted
        // Decide if you want to submit the nonce
        dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
        dispatch(chargeCll2Payment(BRAINTREE, amount, paymentMethodId, payload.nonce, paymentRequest, null));
      } else {
        // Liablity has not shifted and will not shift
        // Decide if you want to submit the nonce
        dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
        dispatch(chargeCll2Payment(BRAINTREE, amount, paymentMethodId, payload.nonce, paymentRequest, null));
      }
    });

  }


  return (
    <div className="container">
      <div className="grid--sidebar">
        <div className="steps">
          <div className={classes.root}>
            <div className="step" style={{ height: '80vh' }}>
              <Step3dSecureVerification
                translation={translation}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const useStepIconStyles = makeStyles({
  root: {
    color: "#707a85",
    fontWeight: 600,
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#2c2c2c"
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "2.5em",
    height: "2.5em",
    borderRadius: "50%",
    border: "1.5px solid #2c2c2c",
    backgroundColor: "#ffffff"
  },
  completed: {
    zIndex: 1,
    fontSize: 18,
    backgroundColor: "#2c2c2c"
  },
  icon: {}
});

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active /*, completed*/ } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      <div className={classes.circle}>
        <span className={classes.icon}>{props.icon}</span>
      </div>
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};
