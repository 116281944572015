import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./css/SearchStyle.scss";

import useTranslation from "translations";
import PdfIcon from "component/Icon/PdfIcon";

import { selectDefaultPageCounter, selectFilters, selectDefaultFilters } from "store/filter/filter.reducers";
import { clearRestaurantCarouselsListStates, loadRestaurantsCarouselsList } from "store/filter/filter.actions";
import { store } from "../../App";
import { showAllBusinessForProductType } from "../../store/filter/filter.actions";
import { browserHistory } from "../../utils/History";
import SearchModal from "component/SearchModal/SearchModal";


export default function Search({ location, grown = false }) {
  const translation = useTranslation();
  const history = browserHistory;
  const filters = useSelector(selectFilters);
  const defaultFilters = useSelector(selectDefaultFilters);
  const defaultPageCounter = useSelector(selectDefaultPageCounter);
  const dispatch = useDispatch();
  const [showDialogForm, setShowDialogForm] = useState(false);
  const [value, setValue] = useState("");
  const [preview] = useState(location && location.state && location.state.preview ? location.state.preview : 4);
  const businessCategorySelected = store.getState().filters.filters.businessCategory;

  const search = (event, { suggestion }) => {
    if (suggestion) {
      const { label, id, classification } = suggestion;

      if (classification === 'restaurant') {
        history.replace(`/${id}`);
        history.go(0)
      } else {
        if (window.location.pathname !== '/') {
          history.replace(`/`);
          history.go(0)
        }
        // Filter but respect the province, muinicipality and locality
        const { municipalityid, provinceid, localityid } = filters;
        dispatch(clearRestaurantCarouselsListStates());
        dispatch(showAllBusinessForProductType(suggestion?.label));
        dispatch(loadRestaurantsCarouselsList(preview,
          {
            ...defaultFilters,
            businessCategory: businessCategorySelected,
            provinceid, municipalityid, localityid,
            [classification]: [label],
            classificationLabel: label,
          },
          1, defaultPageCounter, false, true));
      }
    }
  };

  useEffect(() => {
    setValue(filters.classificationLabel || '');
  }, [filters]);

  return (
    <li className="bsbt bu af" style={{ padding: 0 }}>
      <div className="bs bt bu">
        <button className="button-search" onClick={() => setShowDialogForm(true)}>
          <PdfIcon width="24" height="24" icon="system/search_black" alt="loc" ext="svg" />
          <span>{translation.TopBar.search}</span>
        </button>
        <SearchModal showDialogForm={showDialogForm} setShowDialogForm={() => setShowDialogForm(false)}
          handleSelect={null} />
      </div>
    </li>
  );
};

