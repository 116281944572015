import React, {useState} from 'react';
import '../../css/RecoverPasswordStyle.scss';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import useTranslation from "../../../../translations";
import {selectRecoverFormData} from "../../store/recover-password.reducers";
import {sendRecoverPasswordPin, setRecoverPasswordForm} from "../../store/recover-password.actions";
import CustomInputTextMask from "../../../../component/CustomInputTextMask/CustomInputTextMask";
import {getOrCreateDeviceID} from "../../../../utils/Utils";

export const SendValidationPin = () => {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const {phone} = useSelector(selectRecoverFormData);
  const [validated, setValidated] = useState(false);

  const handlePhoneChange = (phone) => {
    const cleanPhone = phone.replace(/\D/g, "");
    setValidated(cleanPhone && cleanPhone.length >= 10);
    dispatch(setRecoverPasswordForm({phone: cleanPhone}));
  };

  const sendPin = () => {
    let data = {
      channel: "ANDROID",
      deviceId: getOrCreateDeviceID(),
      typeOfPinShipping: "",
      username: phone,
    };
    dispatch(sendRecoverPasswordPin(data));
  };

  return (
    <Paper className="form">
      <Typography className="title" component="p">
        {translation.RecoverPassword.title1}
      </Typography>
      <h2>
        <div className="divider"/>
      </h2>
      <div className="fields">
        <Grid container item md={9} sm={8} xs={12} spacing={3} component="div">
          <Grid item xs={12} component="div">
            <CustomInputTextMask
              required
              id="phone"
              fieldLabel={translation.RecoverPassword.usernameField}
              value={phone}
              onChange={event => handlePhoneChange(event.target.value)}
            />
          </Grid>
          <Grid container item xs={12} spacing={1} className="action" component="div">
            <button
              onClick={sendPin}
              disabled={!validated}
              className="button--primary button--max"
            >
              {translation.RecoverPassword.sendPin}
            </button>
          </Grid>
          <Grid container item xs={12} spacing={1} className="register-link" component="div">
            <Typography>{translation.RecoverPassword.goBackToLogin}</Typography>
            <a href={"./login"} style={{color: "#428d44"}}>
              {translation.Login.loginAction}
            </a>
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
};