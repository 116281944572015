import { store } from "App";
import { browserHistory } from "utils/History";
import { showAlert } from "modules/alert/store/alert.actions";
import { AVAILABILITY_ERROR, cleanCart } from "modules/cart/store/cart.actions";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import {
  clearBrowserOrderAddress,
  clearBrowserOrderCoupon,
  clearBrowserOrderDate,
} from "modules/Checkout/store/checkout.actions";
import { setDeliveryAddressOnTopBarSelected } from "component/FormAddAddress/store/form-add-address.actions";

const redirectTo = (path = "/") => {
  console.log({ pathname: `${path}` });
  browserHistory.push({ pathname: `${path}` });
};
const locationInPayment = () =>
  browserHistory?.location?.pathname.includes("/prv/pay");
const changeBusiness = () => redirectTo("/");
const redirectToCheckout = () => redirectTo("/prv/checkout");

const seeOfferChanges = (DATA) => {
  if (browserHistory?.location?.pathname !== DATA?.result?.restaurantSlug) {
    redirectTo(DATA?.result?.restaurantSlug);
  }
};
const clearCart = () => {
  store.dispatch(cleanCart());
  // redirectTo();
};

const changeAddress = () => {
  localStorage.removeItem("address");
  store.dispatch(clearBrowserOrderAddress());
  store.dispatch(setDeliveryAddressOnTopBarSelected(null));
  if (locationInPayment()) {
    redirectToCheckout();
  }
};

const changeDateSelected = () => {
  store.dispatch(clearBrowserOrderDate());
  if (browserHistory?.location?.pathname !== "/prv/checkout") {
    redirectToCheckout();
  }
};

const seeTalonChanges = () => {
  if (browserHistory?.location?.pathname !== "/prv/checkout") {
    redirectTo("/prv/checkout");
  }
};

const clearCoupon = () => {
  store.dispatch(clearBrowserOrderCoupon());
  if (browserHistory?.location?.pathname !== "/prv/checkout") {
    redirectTo("/prv/checkout");
  }
};

const extractMessage = (data) => {
  if (data?.message) {
    return data.message;
  }

  if (data?.description) {
    return data.description;
  }

  if (data?.errorInfo?.message || data?.errorInfo?.description) {
    return data.errorInfo?.message || data.errorInfo?.description;
  }

  return `Error code ${data?.code}`;
};

const verificationMiddleware = (store) => (next) => (action) => {
  if (action.type === AVAILABILITY_ERROR) {
    const DATA = action?.response?.data;
    switch (DATA.code) {
      case 8001: {
        /** EL negocio no esta disponible */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () => redirectTo())
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8002: {
        /** La oferta no esta disponible */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () =>
            seeOfferChanges(DATA)
          )
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8003: {
        /** El precio del producto cambió */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () =>
            seeOfferChanges(DATA)
          )
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8004: {
        /** EL negocio no llega a esta dirección */
        store.dispatch(
          showAlert(
            extractMessage(DATA),
            "Cambiar negocio",
            "Seleccione dirección",
            () => changeBusiness(),
            () => changeAddress(),
            { required: true }
          )
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8006: {
        /** El carrito no existe */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () => clearCart())
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8007: {
        /** Cambios en los descuentos de talón */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () => seeTalonChanges())
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8008: {
        /** La fecha seleccionada no está disponible */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () =>
            changeDateSelected()
          )
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      case 8010: {
        /** Cupon de descuento inválido */
        store.dispatch(
          showAlert(extractMessage(DATA), "", "", "", () => clearCoupon())
        );
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
      default: {
        store.dispatch(showAlert(extractMessage(DATA)));
        store.dispatch(setLoadingMaskStatus(false));
        return;
      }
    }
  }
  return next(action);
};

export default verificationMiddleware;
