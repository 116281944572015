import { createSelector } from "reselect";
import {
  CLEAR_CUISINE_TYPE_STATES,
  LOADED_ALL_CUISINE_TYPE,
  LOADED_CUISINE_TYPE, PRODUCT_TYPE_LOADED_FAILURE, PRODUCT_TYPE_LOADED_SUCCESS,
} from "./cuisine-type.actions";

const INITIAL_STATE = {
  product_types: [],
  cuisine_type: [],
  all_cuisine_type: [],
  cuisine_type_loaded: false,
  all_cuisine_type_loaded: false,
  message: "",
};

export default function cuisine_type(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADED_CUISINE_TYPE:
      return {
        cuisine_type: action.cuisine_type || [],
        cuisine_type_loaded: true,
      };
    case LOADED_ALL_CUISINE_TYPE:
      return {
        all_cuisine_type: action.all_cuisine_type || [],
        all_cuisine_type_loaded: true,
      };
    case PRODUCT_TYPE_LOADED_SUCCESS:
      return {
        ...state,
        product_types: action.response || [],
      };
    case PRODUCT_TYPE_LOADED_FAILURE:
      return {
        ...state,
        product_types: [],
      };
    case CLEAR_CUISINE_TYPE_STATES:
      return {
        ...state,
        cuisine_type_loaded: false,
        all_cuisine_type_loaded: false,
        message: "",
      };
    default:
      return state;
  }
}

export const selectCuisineState = (state) => state.cuisine_type;

export const selectCuisineTypes = createSelector(
  selectCuisineState,
  (state) => state.cuisine_type || []
);

export const selectAllCuisineTypes = createSelector(
  selectCuisineState,
  (state) => state.all_cuisine_type || []
);

export const selectProductTypes = createSelector(
  selectCuisineState,
  (state) => state.product_types || []
);
