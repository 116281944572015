import { get } from "../../../../../core/services";

const baseUrl = "filter";

export const LOADED_CUISINE_TYPE = "LOADED_CUISINE_TYPE";
export const LOADED_ALL_CUISINE_TYPE = "LOADED_ALL_CUISINE_TYPE";
export const PRODUCT_TYPE_LOADED_SUCCESS = "PRODUCT_TYPE_LOADED_SUCCESS";
export const PRODUCT_TYPE_LOADED_FAILURE = "PRODUCT_TYPE_LOADED_FAILURE";
export const CLEAR_CUISINE_TYPE_STATES = "CLEAR_CUISINE_TYPE_STATES";

function cuisineTypeLoaded(cuisine_type) {
  return {
    type: LOADED_CUISINE_TYPE,
    cuisine_type,
  };
}

function allCuisineTypeLoaded(all_cuisine_type) {
  return {
    type: LOADED_ALL_CUISINE_TYPE,
    all_cuisine_type,
  };
}

function productTypeLoadedSuccess(response) {
  return {
    type: PRODUCT_TYPE_LOADED_SUCCESS,
    response,
  };
}

function productTypeLoadedFailure(response) {
  return {
    type: PRODUCT_TYPE_LOADED_FAILURE,
    response,
  };
}

export function clearCuisineTypeStates() {
  return {
    type: CLEAR_CUISINE_TYPE_STATES,
  };
}

export function loadCuisineType(QUANTITY) {
  let PREVIEW = QUANTITY ? "?preview=" + QUANTITY : "";
  return (dispatch) => {
    get(`${baseUrl}/getCuisineType${PREVIEW}`).then((cuisine_type) => {
      dispatch(cuisineTypeLoaded(cuisine_type));
    });
  };
}

export function loadAllCuisineType(QUANTITY) {
  return (dispatch) => {
    get(`${baseUrl}/getCuisineType${QUANTITY ? "?quantity=true" : ""}`).then(
      (all_cuisine_type) => {
        dispatch(allCuisineTypeLoaded(all_cuisine_type));
      }
    );
  };
}

export function loadProductTypeByCategory(categories) {
  let PARAMS =
    categories && categories.length > 0 ? `?categories=${categories}` : "";
  return (dispatch) => {
    get(`${baseUrl}/getProductType${PARAMS}`)
      .then((response) => {
        dispatch(productTypeLoadedSuccess(response));
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(productTypeLoadedFailure(errMsg));
        }
      });
  };
}

export function loadAllProductTypes() {
  return (dispatch) => {
    get(`${baseUrl}/getProductType`)
      .then((response) => {
        dispatch(productTypeLoadedSuccess(response));
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(productTypeLoadedFailure(errMsg));
        }
      });
  };
}
