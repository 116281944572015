import React, { useState } from "react";
import Provider from "react-redux/es/components/Provider";
import { PersistGate } from "redux-persist/integration/react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { TranslationsContext } from "./translations";
import { persistStore } from "redux-persist";
import ToastManager from "./modules/notification/ToastManager";
import configureStore from "./core/ConfigureStore";
import { IndexRoutes } from "./routes/IndexRoutes";
import "App.scss";
import "./styles/main.scss";
import { AvailabilityValidation } from "./modules/availabilityValidation/AvailabilityValidation";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import firebase from "firebase/app";
import { FIREBASE_CONFIG } from "./config/Analytics";
import TwilioChat from "./component/TwilioChat/TwilioChat";

export const store = configureStore();
let persist = persistStore(store);

export const isAuth = () => store.getState().auth?.access_token;

const theme = createMuiTheme({
  typography: { fontFamily: "San Francisco" },
  palette: {
    primary: {
      main: "#2c2c2c",
    },
  },
});

function App() {
  let lang = store.getState().lang;
  const [language, setLanguage] = useState(lang.code);

  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONFIG);
  }
  firebase.analytics();

  // ReactPixel.init(FACEBOOK_PIXEL_CONFIG.key, null, FACEBOOK_PIXEL_OPTIONS);
  // ReactPixel.pageView();

  return (
    <Provider store={store}>
      {/* <TwilioChat /> */}
      <PersistGate loading={null} persistor={persist}>
        <ThemeProvider theme={theme}>
          <TranslationsContext.Provider value={{ language, setLanguage }}>
            <AvailabilityValidation />
            <IndexRoutes />
            <ToastManager />
            <ToastsContainer
              store={ToastsStore}
              lightBackground
              position={ToastsContainerPosition.TOP_CENTER}
            />
          </TranslationsContext.Provider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
