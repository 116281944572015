import React, { useEffect, useState } from 'react';
import '../../css/RecoverPasswordStyle.scss';
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../../translations";
import { selectChangePasswordSuccess, selectRecoverFormData } from "../../store/recover-password.reducers";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CustomInputTextMask from "../../../../component/CustomInputTextMask/CustomInputTextMask";
import { Button, InputAdornment } from "@material-ui/core";
import CustomInput from "../../../../component/CustomInput/CustomInput";
import CustomInputPasswordStrengthMeter
  from "../../../../component/CustomInputPasswordStrengthMeter/CustomInputPasswordStrengthMeter";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  changePassword, editPhone, sendRecoverPasswordPin, setRecoverPasswordForm
} from "../../store/recover-password.actions";
import { getOrCreateDeviceID } from "../../../../utils/Utils";
import { validateFieldByArray } from "../../../../utils/AuxiliarFunctions";
import * as _ from "lodash";
import { browserHistory } from "../../../../utils/History";

const colorBoringGreen = "#2c2c2c";

const useStyles = makeStyles(() => ({
  textField: {
    flexBasis: 50,
    width: "100%",
    border: "none",
    margin: "none"
  },
  input: {
    fontSize: ".8em",
    backgroundColor: "#fff !important"
  },
  helper: {
    fontSize: ".8em",
    lineHeight: ".8",
    fontStyle: "italic"
  },
  button: {
    text: {
      fontSize: ".8em"
    },
    color: colorBoringGreen
  }
}));

const InputAdornmentButton = withStyles({
  root: {
    fontSize: ".7em",
    textTransform: "none",
    color: colorBoringGreen,
    fontWeight: 400
  }
})(Button);
export const SetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const history = browserHistory;
  const { phone, pin, password } = useSelector(selectRecoverFormData);
  const passwordChanged = useSelector(selectChangePasswordSuccess);
  const [errors, setErrors] = useState({});
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [phone, pin, password]);

  useEffect(() => {
    if (passwordChanged) {
      history.push('./login');
    }
    // eslint-disable-next-line
  }, [passwordChanged]);

  const handleOnChange = (field, value) => {
    dispatch(setRecoverPasswordForm({ [field]: value }));
  };

  const backToEditPhone = () => {
    dispatch(editPhone());
  };

  const handleChangePassword = () => {
    let data = {
      channel: "ANDROID",
      deviceId: getOrCreateDeviceID(),
      password: password,
      username: phone,
      pin: pin,
    };
    dispatch(changePassword(data));
  };

  const sendPinBySms = () => {
    let data = {
      channel: "ANDROID",
      deviceId: getOrCreateDeviceID(),
      typeOfPinShipping: "SMS",
      username: phone,
    };
    dispatch(sendRecoverPasswordPin(data));
  };

  const sendPinByCall = () => {
    let data = {
      channel: "ANDROID",
      deviceId: getOrCreateDeviceID(),
      typeOfPinShipping: "CALL",
      username: phone,
    };
    dispatch(sendRecoverPasswordPin(data));
  };

  const validateForm = () => {
    let errors = {};
    let pinValidations = validateFieldByArray(pin, ["required"], translation.Validations["required"]);
    if (pinValidations) {
      errors.pin = pinValidations;
    }
    let passwordValidations = validateFieldByArray(password, ["required"], translation.Validations["required"]);
    if (passwordValidations) {
      errors.password = passwordValidations;
    }
    let valid = _.isEmpty(errors);
    setErrors(errors);
    setValidForm(valid);
  };

  return (
    <div>
      <Paper className="form">
        <Typography className="title" component={"p"}>
          {translation.RecoverPassword.title1}
        </Typography>
        <h2>
          <div className="divider" />
        </h2>
        <Typography className="subtitle" component={"p"}>
          {translation.RecoverPassword.helperText}
        </Typography>
        <div className="fields">
          <Grid container item md={9} sm={8} xs={12} spacing={3} component={"div"}>
            <Grid item xs={12} component={"div"}>
              <CustomInputTextMask
                required
                id="phone"
                fieldLabel={translation.RecoverPassword.phoneField}
                value={phone}
                innerProps={{ readOnly: true, classes: { input: classes.input } }}
                endAdornment={
                  <InputAdornment position="end">
                    <InputAdornmentButton className={classes.button} onClick={() => backToEditPhone()}>
                      {translation.RecoverPassword.modifyAction}
                    </InputAdornmentButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} component={"div"}>
              <CustomInput
                id="pin"
                required
                fieldLabel={`${translation.RecoverPassword.pinReceived}`}
                value={pin}
                onChange={event => handleOnChange("pin", event.target.value)}
              />
              <span className="label-error">{errors["pin"]}</span>
            </Grid>
            <Grid item xs={12} component={"div"}>
              <CustomInputPasswordStrengthMeter
                id="password"
                fieldLabel={translation.RecoverPassword.password}
                value={password}
                onChange={event => handleOnChange("password", event.target.value)}
              />
              <span className="label-error">{errors["password"]}</span>
            </Grid>
            <Grid container item xs={12} spacing={1} className="action" component={"div"}>
              <button
                onClick={handleChangePassword}
                disabled={!validForm}
                className="button--primary button--max"
              >
                {translation.RecoverPassword.setPasswordAction}
              </button>
            </Grid>
            <Grid container item xs={12} spacing={1} className="register-link" component={"div"}>
              <Typography component={"p"}>{translation.RecoverPassword.goBackToLogin}</Typography>
              <a href={"./login"} style={{ color: "#428d44" }}>
                {translation.Login.loginAction}
              </a>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Paper className="resend_code_form">
        <Typography className="resend_code_title" component={"p"}>
          {translation.RecoverPassword.resendCodeTitle}
        </Typography>
        <Grid container xs={12} component={"div"}>
          <Grid item md={4} sm={4} xs={12} component={"div"}>
            <div className="resend_code" onClick={sendPinBySms}>
              {translation.RecoverPassword.getPinBySms}
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12} component={"div"}>
            <div className="resend_code" onClick={sendPinByCall}>
              {translation.RecoverPassword.getPinByCall}
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12} component={"div"}>
            <div className="resend_code">
              <a href="https://www.cuballama.com/informacion/contacto">
                {translation.RecoverPassword.talkToAnAgent}
              </a>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
};