import React, { useRef } from "react";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import Swiper from "react-id-swiper";
import { ChevronLeftSharp, ChevronRightSharp } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const params = {
  lazy: true,
  spaceBetween: 0,
  breakpoints: {
    320: {
      slidesPerView: 2.5,
      slidesPerGroup: 2,
    },
    576: {
      slidesPerView: 3.5,
      slidesPerGroup: 3,
    },
    768: {
      slidesPerView: 4.5,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 5.5,
      slidesPerGroup: 3,
    },
    1200: {
      slidesPerView: 5,
      slidesPerGroup: 3,
    }
  },
};

export default function OfferSwipper({ loading, business, goToBusiness, businessPlaceholders }) {
  const swiperRef = useRef(null);

  const goNext = (event) => {
    event.stopPropagation();
    return swiperRef.current?.swiper?.slideNext();
  }

  const goPrev = (event) => {
    event.stopPropagation();
    return swiperRef.current?.swiper?.slidePrev();
  }

  return !loading
    ?
    <div style={{ width: '100%' }}>
      <div className="swipper-actions">
        <IconButton onClick={goPrev}><ChevronLeftSharp /></IconButton>
        <IconButton onClick={goNext}><ChevronRightSharp /></IconButton>
      </div>
      <Swiper {...params} ref={swiperRef} >
        {business.offers?.map((offer, k) => (
          <div className="item-offer" key={k} onClick={(event) => goToBusiness(event, business, offer)}>
            <div className="item-offer__image">
              <img src={offer.offerImage} alt={offer.offerName} width="100%" height="auto" />
            </div>
            <div className="item-offer__name">{offer.offerName}</div>
            <div className="item-offer__price">{offer.price}</div>
          </div>))}
        <div style={{ width: '300px', height: 'auto' }}></div>
        <div style={{ width: '300px', height: 'auto' }}></div>
        <div style={{ width: '300px', height: 'auto' }}></div>
        <div style={{ width: '300px', height: 'auto' }}></div>
      </Swiper>
    </div>
    : <div className="skeleton-row__business">
      {[...Array(businessPlaceholders)].map((_, k) => <div key={k} className="bc-skeleton__business" style={{ marginBottom: '1em' }}>
        <div className="bc-skeleton__business__header">
          <div className="bc-skeleton__avatar">
            <Skeleton variant="circle" width={65} height={65} animation="wave" />
          </div>
          <div className="bc-skeleton__title">
            <Skeleton variant="rect" width={65} height={20} animation="wave" style={{ marginBottom: '.8em' }} />
            <Skeleton variant="rect" width={65} height={20} animation="wave" />
          </div>
        </div>
        <div className="bc-skeleton__business__offers">
          {[...Array(businessPlaceholders)].map((_, k) =>
            <div className="bc-skeleton__business__offer" key={k}>
              <Skeleton variant="rect" width={120} height={120} animation="wave" style={{ marginBottom: '.5em' }} />
              <Skeleton variant="rect" width={120} height={20} animation="wave" />
            </div>)}
        </div>
      </div>)}
    </div>;
}