import React from "react";
import useTranslations from "translations";
import { useSelector, useDispatch } from "react-redux";
import "./css/AlertStyle.scss";
import { hideAlert } from "./store/alert.actions";
import { selectExtras, selectShowAlert, selectMessage, selectTextAction1, selectTextAction2, selectAction1, selectAction2 } from "./store/alert.reducers";
import UberModal from "component/UberModal/UberModal";
import Grid from "@material-ui/core/Grid";

export default function Alert() {
  const translation = useTranslations();
  const dispatch = useDispatch();
  const open = useSelector(selectShowAlert);
  const action1 = useSelector(selectAction1);
  const action2 = useSelector(selectAction2);
  const textAction1 = useSelector(selectTextAction1);
  const textAction2 = useSelector(selectTextAction2);
  const message = useSelector(selectMessage);
  const extras = useSelector(selectExtras);

  const handleAction1 = () => {
    action1 && action1();
    handleClose();
  };

  const handleAction2 = () => {
    action2 && action2();
    handleClose();
  };

  const handleClose = () => dispatch(new hideAlert());

  return (
    open && <UberModal onClose={() => handleClose()} height={UberModal.sizes.SMALLER}
      required={extras?.required}
      smallModal={true}
      title={<h4>{translation.General.defaultAlertTitle}</h4>}
      subtitle={<h1>{translation.General.defaultAlertTitle}</h1>}
      // image={{ src: "./images/picture/addresses.jpg" }}
      content={
        <div style={{ padding: '24px 24px 32px' }}>
          <h2>{message}</h2>
        </div>
      }
      actions={
        <Grid container className="form_actions" component='div'>
          <Grid container item alignItems="center"
            justifyContent="center" xs={12} component='div' style={{ paddingBottom: '15px' }}>
            {action1 ? <Grid item md={action2 ? 6 : 12} xs={action2 ? 6 : 12} component='div' style={{ padding: '0 7px' }}>
              <button className="button--primary button--max button--plus" onClick={handleAction1}>
                {textAction1 ? textAction1 : translation.Actions[action2 ? 'cancel' : 'close']}
              </button>
            </Grid>
              : null}

            {action2 ? <Grid item md={action1 ? 6 : 12} xs={action2 ? 6 : 12} component='div' style={{ padding: '0 7px' }}>
              <button className="button--primary button--max button--plus" onClick={handleAction2}>
                {textAction2 ? textAction2 : translation.Actions[action1 ? 'cancel' : 'close']}
              </button>
            </Grid>
              : null}
          </Grid >
        </Grid >
      }
    />
  );
}


