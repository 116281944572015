import {createSelector} from "reselect";
import * as action_OC from './offer-carousel.actions';

const INITIAL_STATE = {
    all_offer_view: false,
    restaurant_offer_promotion: [],
};

export default function offer_carousel(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case action_OC.ALL_OFFERS_VIEW: {
            return {
                ...state,
                all_offer_view: action.view_all
            };
        }
        case action_OC.RESTAURANT_OFFER_LOADED_SUCCESS:
            return {
                ...state,
                restaurant_offer_promotion: action.offers || [],
            };
        case action_OC.RESTAURANT_OFFER_LOADED_FAILURE:
            return {
                ...state,
                restaurant_offer_promotion: [],
            };
        default: {
            return state;
        }
    }
}

export const selectOfferCarousel = state => state.offer_carousel;

export const getOfferView = createSelector(
    selectOfferCarousel,
    state => state.all_offer_view
);

export const selectRestaurantOfferPromotion = createSelector(
    selectOfferCarousel,
    state => state.restaurant_offer_promotion
);