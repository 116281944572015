import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import useTranslations from "translations";

const PaymentErrorAlert = ({ show, onClose, message }) => {
  const translation = useTranslations();

  return <Dialog open={show} onClose={onClose}>
    <DialogTitle className="verification_dialog_header">
      {translation.Payment.attention}
    </DialogTitle>
    <DialogContent className="verification_dialog_body">
      <h3>{message}</h3>
    </DialogContent>
    <DialogActions className="verification_dialog_actions">
      <button className="button--primary" onClick={onClose}>
        {translation.Actions.accept}
      </button>
    </DialogActions>
  </Dialog>
}

export default PaymentErrorAlert;