import { createSelector } from "reselect";
import * as availability_actions from "./availability-validation.actions";

const INITIAL_STATE = {
  availability_response: null,
  availability_checked: false,
  failed_availability_check: false,
  successful_availability_check: false,
};

export default function availability_validation(
  state = INITIAL_STATE,
  action = {}
) {
  switch (action.type) {
    case availability_actions.SUCCESSFUL_AVAILABILITY_CHECK:
      return {
        availability_response: action.response || null,
        availability_checked: true,
        failed_availability_check: false,
        successful_availability_check: true,
      };
    case availability_actions.FAILED_AVAILABILITY_CHECK:
      return {
        availability_response: null,
        availability_checked: true,
        failed_availability_check: true,
        successful_availability_check: false,
      };
    case availability_actions.CLEAR_AVAILABILITY_STATES:
      return {
        ...state,
        availability_checked: false,
        failed_availability_check: false,
        successful_availability_check: false,
      };
    case availability_actions.SEND_TO_CHECK_AVAILABILITY:
      return {
        ...state,
        availability_checked: false,
        failed_availability_check: false,
        successful_availability_check: false,
      };
    default:
      return state;
  }
}

export const selectAvailabiltyState = (state) => state.availability_validation;

export const selectAvailabilityResponse = createSelector(
  selectAvailabiltyState,
  (state) => state.availability_response
);
