import * as BD_ACTIONS from "./restaurant-detail.actions";
import { createSelector } from "reselect";

const INITIAL_STATE = {
    restaurant_detail: {},
    business_offers: [],
    loading: true,
    restaurant_detail_loaded: false,
    message: '',
};

export default function restaurant_detail(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case BD_ACTIONS.RESTAURANT_DETAIL_LOADED_SUCCESS: {
            return {
                restaurant_detail: action.response.data || null,
                restaurant_detail_loaded: true,
            }
        }
        case BD_ACTIONS.RESTAURANT_DETAIL_LOADED_FAILURE: {
            return {
                restaurant_detail: null,
                restaurant_detail_loaded: false,
            }
        }
        case BD_ACTIONS.BUSINESS_OFFERS_LIST_LODADED_SUCCESS: {
            return {
                business_offers: action.response.data || null,
            }
        }
        case BD_ACTIONS.BUSINESS_OFFERS_LIST_LODADED_FAILURE: {
            return {
                business_offers: null,
            }
        }
        case BD_ACTIONS.BUSINESS_OFFERS_LIST_FETCH_MORE_SUCCESS: {
            const newFetchedOffers = action.response.data.list || [];
            const stateOffers = state.business_offers.list || [];
            const offerIds = stateOffers.map(({ id }) => id);
            const sanitazedList = newFetchedOffers.filter((fetched) => !offerIds.includes(fetched.id));
            return {
                ...state,
                loading: false,
                business_offers: {
                    ...state.business_offers,
                    list: stateOffers.concat([...sanitazedList])
                },
            }
        }
        case BD_ACTIONS.CLEAR_RESTAURANTS_DETAIL_STATES:
            return {
                ...state,
                restaurant_detail_loaded: false,
                message: ''
            };
        default:
            return state;
    }
}

export const selectRestaurantDetailState = state => state.restaurant_detail;

export const selectRestaurantDetailObject = createSelector(
    selectRestaurantDetailState,
    state => state.restaurant_detail
);

export const selectBusinessOffersList = createSelector(
    selectRestaurantDetailState,
    state => state.business_offers
);

export const selectRestaurantDetailLoaded = createSelector(
    selectRestaurantDetailState,
    state => state.restaurant_detail_loaded
);