import {createSelector} from "reselect";
import * as actions from './promotions-carousel.actions';

const INITIAL_STATE = {
  generalPromotions: [],
};

export default function promotions_carousel(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions.GENERAL_PROMOTIONS_LOADED_SUCCESS:
      return {
        generalPromotions: action.response || [],
      };
    case actions.GENERAL_PROMOTIONS_LOADED_FAILURE:
      return {
        generalPromotions: [],
      };
    default:
      return state;
  }
}

export const selectGeneralPromotions = (state) => state.promotions_carousel;

export const GeneralPromotionsList = createSelector(
  selectGeneralPromotions,
  (state) => state.generalPromotions || []
);
