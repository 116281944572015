import { simplePost, post } from "core/services";

export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const CLEAR_STATES = "CLEAR_STATES";
export const SEARCH_TERM = "LOADED_FILTERS";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const loading = () => ({ type: LOADING });
export const loaded = () => ({ type: LOADED });
export const setTerm = (term) => ({ type: SEARCH_TERM, term });
export const clearSearch = () => ({ type: CLEAR_STATES });
export const searchSuccess = (response) => ({ type: SEARCH_SUCCESS, response });
export const searchFail = (response) => ({ type: SEARCH_FAIL, response });

export function loadBusinessList(filters, passiveLoading = false) {
  return (dispatch) => {
    if (!passiveLoading) {
      dispatch(loading());
    }
    post("business/searchList", filters)
      .then((response) => {
        // if (!passiveLoading) {
        dispatch(searchSuccess(response));
        // if (
        //   !["/", "/mercado", "/mercado/"].includes(window.location.pathname)
        // ) {
        // goToCategorySlug({ slug: "mercado" }, true);
        // }
        // } else {
        //   dispatch(searchFail(response));
        // }
      })
      .catch((error) => {
        let errObj = error?.response?.data || null;
        if (errObj) {
          const errMsg = errObj?.message || "ERROR!";
          dispatch(searchFail(errMsg));
          // dispatch(notifyError(errMsg));
        }
        // if (!passiveLoading) {
        //   browserHistory.push({ pathname: "/" });
        // }
      })
      .finally(() => {
        dispatch(loaded());
      });
  };
}
