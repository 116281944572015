import {createSelector} from "reselect";
import {
  DONE_FETCH_PROFILE,
  DONE_UPDATE_PROFILE,
  SET_PROFILE
} from "./profile.actions";

const INITIAL_STATE = {
  profile: {}
};

export default function profile(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_PROFILE:
    case DONE_FETCH_PROFILE:
    case DONE_UPDATE_PROFILE: {
      return {
        ...state,
        profile: action.profile && action.profile.data ? action.profile.data : {}
      };
    }
    default: {
      return state;
    }
  }
}

export const selectProfileState = state => state.profile;

export const selectProfile = createSelector(
  selectProfileState,
  state => state.profile
);
