/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Slide from "@material-ui/core/Slide/Slide";
import ReactCodeInput from "react-code-input";
import "./css/PinStyled.scss";
import useTranslations from "translations";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { DEFAULT_CONFIG } from "config/Config";
import { validateFieldByArray } from "utils/AuxiliarFunctions";
import {
  clearAuthState,
  requestPin,
  verifyPin,
} from "../../store/login.actions";
import { loginUserSelector } from "../../store/login.reducers";
import { Clear } from "@material-ui/icons";
import {
  addToStorage,
  customDecode,
} from "../../../../utils/AuxiliarFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Pin = ({ history }) => {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const loginResponse = useSelector(loginUserSelector);
  const [timer, setTimer] = useState(30);
  const [data, setData] = useState({ "2fa_code": null });
  const [errors, setErrors] = useState({});
  const [validateForm, setValidateForm] = useState(false);
  const [showResendPinModal, setShowResendPinModal] = useState(false);

  const [loginData, setLoginData] = useState({
    deviceId: undefined,
    formatedPhoneNumber: undefined,
    email: undefined,
    phoneNumber: undefined,
    pinDeliveryMethod: "",
  });

  useEffect(() => {
    if (!loginData.phoneNumber) {
      const tempData = JSON.parse(localStorage.getItem("Dt3mp0"));
      if (tempData) {
        setLoginData(tempData);
        dispatch(setLoadingMaskStatus(false));
      } else {
        history.push("../login");
      }
    }
    let seconds = timer;
    setInterval(() => {
      if (seconds && seconds > 0) {
        seconds -= 1;
        setTimer(seconds);
      } else {
        setTimer(null);
      }
    }, 1000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginResponse.login_success) {
      addToStorage("token", loginResponse.cllWebAppToken);
      addToStorage(
        "stamp",
        new Date().getTime() +
        Number(loginResponse.cllWebAppToken.expires_in) * 1000
      );
      localStorage.removeItem("backTo");
      localStorage.removeItem("Dt3mp0");
      localStorage.removeItem("Dv4lP1");
      localStorage.removeItem("D3m41l");

      const url = localStorage.getItem("backTo");
      if (!["/prv/checkout", "/prv/pay", "/prv/pay_success", "/prv/pay_error", "/prv/pay_cancel"].includes(url)) {
        const pathname = url ? url.replace("..", "") : "/";
        history.push({ pathname });
      }

      dispatch(clearAuthState());
    }
    if (loginResponse.login_failure) {
      setErrors({ ...errors, code: loginResponse.errors });
    }
    if (loginResponse.show_email_form) {
      history.push("/email");
    }
    if (loginResponse.verify_email) {
      history.push("/verification/email");
    }
    // eslint-disable-next-line
  }, [loginResponse]);

  const handleOnBlur = (field, value) => setData({ [field]: value });

  useEffect(() => {
    validateForm && validateFields();
  }, [data, loginData]);

  const validateFields = () => {
    let errors = {};
    let code = validateFieldByArray(
      data["2fa_code"],
      ["required"],
      translation.Validations["required"]
    );
    if (code) {
      errors.code = code;
    } else {
      code = validateFieldByArray(
        data["2fa_code"],
        ["length"],
        4,
        translation.Validations["length"]
      );
      if (code) {
        errors.code = code;
      }
      const saved = localStorage.getItem("Dv4lP1");
      const valueSaved = saved ? customDecode(saved) : null;
      if (data["2fa_code"] === valueSaved) {
        errors.code = "Invalid code";
      }
    }
    return code === null || !!loginData.phoneNumber;
  };

  const resendSmsCode = () => {
    const loginData = JSON.parse(localStorage.getItem("Dt3mp0"));
    console.log(loginData);
    const data = {
      ...loginData,
      captchaToken: null,
      pinDeliveryMethod: "SMS",
    };
    delete data["2fa_code"];
    dispatch(requestPin(data));
    hideResendPinModal();
  };

  const getCodeByCall = () => {
    const loginData = JSON.parse(localStorage.getItem("Dt3mp0"));
    const data = {
      ...loginData,
      captchaToken: null,
      pinDeliveryMethod: "CALL",
    };
    delete data["2fa_code"];
    dispatch(requestPin(data));
    hideResendPinModal();
  };

  const getCodeByWhatsApp = () => {
    const loginData = JSON.parse(localStorage.getItem("Dt3mp0"));
    const data = {
      ...loginData,
      captchaToken: null,
      pinDeliveryMethod: "WHATSAPP",
    };
    delete data["2fa_code"];
    dispatch(requestPin(data));
    hideResendPinModal();
  };

  const handleSubmit = () => {
    setValidateForm(true);
    if (validateFields()) {
      let login = {
        username: loginData.phoneNumber,
        grant_type: "password",
        client_id: DEFAULT_CONFIG.client_id,
        device_id: loginData.deviceId,
        passwordless_pin: data["2fa_code"],
        channel: "WEB",
      };
      dispatch(verifyPin(login));
    }
  };

  const editPhone = () => {
    history.push("../login");
    localStorage.removeItem("Dt3mp0");
    dispatch(clearAuthState());
  };

  const viewResendPinModal = () => {
    setShowResendPinModal(true);
  };
  const hideResendPinModal = () => {
    setShowResendPinModal(false);
  };

  let RESEND_PIN = null;
  if (showResendPinModal) {
    RESEND_PIN = (
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        className="resend-pin-modal"
        TransitionComponent={Transition}
        open={showResendPinModal}
        onClose={hideResendPinModal}
      >
        <DialogTitle className="resend-pin-modal__header">
          {translation.Register.receivePinAgain}
          <button
            className="resend-pin-modal__header__close button--transparent"
            onClick={hideResendPinModal}
          >
            <Clear component="svg" />
          </button>
        </DialogTitle>
        <DialogContent className="content">
          <div className="content-child">
            <div className="resend_code" onClick={resendSmsCode}>
              {translation.Register.receivePinBySms}
            </div>
            <div className="resend_code" onClick={getCodeByCall}>
              {translation.Register.receivePinPerCall}
            </div>
            <div className="resend_code" onClick={getCodeByWhatsApp}>
              {translation.Register.receivePinByWhatsApp}
            </div>
            <div className="resend_code">
              <a href="https://www.cuballama.com/informacion/contacto">
                {translation.Register.talkToAnAgent}
              </a>
            </div>
            <div
              className="resend_code resend_code--cancel"
              onClick={hideResendPinModal}
            >
              {translation.Register.cancel}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div className="pin-to-login">
      {RESEND_PIN}
      <Grid container className="container" component="div">
        <div className="form">
          <Typography className="title" component="h1">
            {translation.Login.pinHeader}
            <span />
          </Typography>
          <Typography className="subtitle" component="p">
            {translation.Login.pinSubHeader}
          </Typography>
          <div className="fields">
            <Grid
              container
              item
              md={8}
              sm={8}
              xs={12}
              spacing={4}
              component={"div"}
            >
              <Grid item xs={12} component={"div"}>
                <div className="modify-button__container">
                  <span>{loginData.formatedPhoneNumber}</span>
                  <a className="modify-button__icon" onClick={() => editPhone()}>
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                    </svg>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} component={"div"}>
                <ReactCodeInput
                  name="PIN"
                  type="number"
                  inputMode="numeric"
                  fields={4}
                  autoFocus={true}
                  isValid={errors.code == null}
                  inputStyle={{
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    boxShadow: "none",
                    margin: "4px",
                    width: "42px",
                    height: "50px",
                    fontSize: "32px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    outline: "none",
                    padding: "0",
                    fontFamily: "Nunito Sans,sans-serif",
                    backgroundColor: "#fff",
                    borderColor: "#d3d3d3",
                    color: "#000",
                  }}
                  inputStyleInvalid={{
                    borderRadius: "4px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "red",
                    color: "red",
                    boxShadow: "none",
                    margin: "4px",
                    width: "42px",
                    height: "50px",
                    fontSize: "32px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    outline: "none",
                    padding: "0",
                  }}
                  className=""
                  onChange={(value) => handleOnBlur("2fa_code", value)}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                className="error-message-container"
                component={"div"}
              >
                {validateForm && errors.code && (
                  <p className="fields-error">{errors.code}</p>
                )}
              </Grid>

              <Grid container item xs={12} className="action" component={"div"}>
                <button
                  onClick={handleSubmit}
                  className="button--primary button--max"
                >
                  {translation.Login.pinAction}
                </button>
              </Grid>
              <Grid
                container
                item
                xs={12}
                className="register-link"
                component="div"
              >
                <button
                  onClick={viewResendPinModal}
                  className="button--primary button--max"
                >
                  {translation.Login.resendCode}
                </button>
                {/* <Typography component="p">{translation.Login.pinNotReceived}</Typography> */}
                {/* {timer
                  ? <Typography
                    component="p">{`${translation.Login.wait} ${timer} ${translation.Login.seconds}`}</Typography>
                  : <button className="button-link" onClick={viewResendPinModal}>
                    {translation.Login.resendCode}
                  </button>} */}
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
};
