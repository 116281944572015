import React from "react";

export default function AtomIcon({ icon, width = 16, alt, classes = "" }) {
  return (
    <img
      className={classes}
      width={width}
      src={
        `./images/icons/atom/atoms-icons-system-${icon}.svg`
      }
      alt={alt}
    />
  );
}
