import { createSelector } from "reselect";
import { SHOW_ALERT, HIDE_ALERT } from "./alert.actions";

const INITIAL_STATE = {
  show: false,
  textAction1: null,
  textAction2: null,
  action1: null,
  action2: null,
  message: "",
  extras: {},
};

export default function alert(state = INITIAL_STATE, action = {}) {
  const { type, message, textAction1, textAction2, action1, action2, extras } =
    action;

  switch (type) {
    case SHOW_ALERT: {
      return {
        show: true,
        textAction1,
        textAction2,
        action1,
        action2,
        message,
        extras,
      };
    }
    case HIDE_ALERT: {
      return {
        ...INITIAL_STATE,
      };
    }
    default: {
      return state;
    }
  }
}

export const selectAlertState = (state) => state.alert;

export const selectShowAlert = createSelector(
  selectAlertState,
  (state) => state.show
);

export const selectTextAction1 = createSelector(
  selectAlertState,
  (state) => state.textAction1
);

export const selectTextAction2 = createSelector(
  selectAlertState,
  (state) => state.textAction2
);

export const selectAction1 = createSelector(
  selectAlertState,
  (state) => state.action1
);

export const selectAction2 = createSelector(
  selectAlertState,
  (state) => state.action2
);

export const selectMessage = createSelector(
  selectAlertState,
  (state) => state.message
);

export const selectExtras = createSelector(
  selectAlertState,
  (state) => state.extras
);
