import Axios from "axios";
import { DEFAULT_CONFIG } from "../../config/Config";
import { store } from "../../App";

Axios.defaults.baseURL = DEFAULT_CONFIG.server;
Axios.defaults.headers["Content-Type"] = "application/json";
Axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
Axios.defaults.headers.post["Content-Type"] = "application/json";
Axios.defaults.headers.post["responseType"] = "json";

const getToken = () => {
  const authData = store.getState().auth;
  if (authData?.access_token) {
    return authData.access_token;
  }

  const itemTokenObject = localStorage.getItem("cllWebApp-token");
  if (itemTokenObject) {
    const item = JSON.parse(itemTokenObject);
    return item.access_token || "";
  }

  return "";
};

const getApiKey = () => {
  const envProd =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "prod";
  return !envProd
    ? "YXBwX2RlbGl2ZXJ5X2Rldl9zZWNyZXQ=|b780910e086faf831ff6364a7fe7cca1726cee53118540b3678e3707dfc9ec75?YXBwX2NsbF9kZXZfY2xpZW50|b2a0d3036f9d04fc69252e6220be77f815606b45e43aa02d54a1baba811ddf48"
    : "YXBwX2RlbGl2ZXJ5X3Byb2Rfc2VjcmV0|b97bbb6e21113fcd2876ed6c3442bdf51bdc77b446c585f2bb1c7a61ba77c9af?YXBwX2NsbF9wcm9kX2NsaWVudA==|32b4457772f8cb61624012e1f84d039641afe350d50fd72913b540ff96faae84";
};

function secureHeader(useApiKey = false) {
  const authToken = useApiKey ? getApiKey() : getToken();
  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
}

/** ************************************************************************************************************** **/

export function publicGet(path) {
  return Axios.get(`${DEFAULT_CONFIG.server}/${path}`);
}

/** ************************************************************************************************************** **/

export function get(path) {
  return Axios.get(`${DEFAULT_CONFIG.server}/${path}`).then((response) => {
    return response.data;
  });
}

export function del(path) {
  return Axios.delete(`${DEFAULT_CONFIG.server}/${path}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
}

export function put(path, body = {}) {
  return Axios.put(`${DEFAULT_CONFIG.server}/${path}`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function post(path, body = {}) {
  return Axios.post(`${DEFAULT_CONFIG.server}/${path}`, body);
}

/** ************************************************************************************************************** **/

export function simpleGet(path, useApiKey = false) {
  return Axios.get(`${DEFAULT_CONFIG.server}/${path}`, secureHeader(useApiKey));
}

export function simplePost(path, body = {}, useApiKey = false) {
  return Axios.post(
    `${DEFAULT_CONFIG.server}/${path}`,
    body,
    secureHeader(useApiKey)
  );
}

export function simpleDel(path, useApiKey = false) {
  return Axios.delete(
    `${DEFAULT_CONFIG.server}/${path}`,
    secureHeader(useApiKey)
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function secureGet(path, useApiKey = false) {
  return Axios.get(`${DEFAULT_CONFIG.server}/${path}`, secureHeader(useApiKey))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function securePost(path, body = {}, useApiKey = false) {
  return Axios.post(
    `${DEFAULT_CONFIG.server}/${path}`,
    body,
    secureHeader(useApiKey)
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function secureDel(path, useApiKey = false) {
  return Axios.delete(
    `${DEFAULT_CONFIG.server}/${path}`,
    secureHeader(useApiKey)
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
