import { LOADED_BANNER_MESSAGES } from "./banner.actions";
import { createSelector } from "reselect";

const INITIAL_STATE = {
  messages: [],
};

export default function banner(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADED_BANNER_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    default:
      return state;
  }
}

export const selectBannerMessagesState = (state) => state.banner;

export const selectBannerMessage = createSelector(
  selectBannerMessagesState,
  (state) =>
    state.messages && state.messages.length > 0 ? state.messages[0] : ""
);
