import React, { useEffect, useState } from 'react';
import './css/availabilityValidationStyles.scss';
import { useDispatch, useSelector } from "react-redux";
import { selectCartItems, selectCartRestaurant, selectCategoryInCart } from "../cart/store/cart.reducers";
import useTranslations from "../../translations/useTranslations";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { selectAvailabiltyState } from "./store/availability-validation.reducers";
import { cleanCart, removeItemToCart, updateOfferStock, updateRestaurantInCart } from "../cart/store/cart.actions";
import * as _ from "lodash";
import { Lens } from "@material-ui/icons";
import { browserHistory } from "../../utils/History";

export const AvailabilityValidation = () => {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const cartItems = useSelector(selectCartItems);
  const restaurant = useSelector(selectCartRestaurant);
  const categoryInCart = useSelector(selectCategoryInCart);
  const availabilityState = useSelector(selectAvailabiltyState);
  const [open, setOpen] = useState(false);
  const [fullScreenSize, setFullScreenSize] = useState(false);
  const [listOfferNotAvailable, setListOfferNotAvailable] = useState(null);
  const [listOfInsufficientStock, setListOfInsufficientStock] = useState(null);
  const [listVariantNotAvailable, setListVariantNotAvailable] = useState(null);
  const [listAdditionsNotAvailable, setListAdditionsNotAvailable] = useState(null);
  const [listOfferWithVariantNotAvailable, setListOfferWithVariantNotAvailable] = useState(null);
  const [listOfferWithAdditionsNotAvailable, setListOfferWithAdditionsNotAvailable] = useState(null);
  const [showRestaurantNotAvailableMessage, setShowRestaurantNotAvailableMessage] = useState(false);

  useEffect(() => {
    handleFullScreen();
    window.addEventListener('resize', handleFullScreen);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      if (availabilityState && availabilityState.availability_checked) {
        validateCartItemExistence(cartItems);
      }
    }
    // eslint-disable-next-line
  }, [availabilityState]);

  const handleFullScreen = () => {
    const width = window.innerWidth;
    const fullScreen = width < 768;
    setFullScreenSize(fullScreen);
  };

  const validateCartItemExistence = (offerCartItems) => {
    if (availabilityState.successful_availability_check) {
      const availabilityResponse = availabilityState.availability_response;
      if (availabilityResponse && availabilityResponse.available) {
        /* Update restaurant data in cart */
        dispatch(updateRestaurantInCart(availabilityResponse.restaurantData));

        let offerListChecked = availabilityResponse.checkOfferList;
        let listOfferNotAvailable = [];
        let listOfInsufficientStock = [];
        let listVariantNotAvailable = [];
        let listAdditionsNotAvailable = [];
        let listOfferWithVariantNotAvailable = [];
        let listOfferWithAdditionsNotAvailable = [];

        if (offerListChecked && offerListChecked.length > 0) {
          _.forEach(offerListChecked, function (item) {
            if (!item.available) {
              /* Offer Not available */
              const offerCheckedExistentInCart = getNotAvailableOfferFromCart(offerCartItems, item);
              if (offerCheckedExistentInCart) {
                listOfferNotAvailable.push(offerCheckedExistentInCart);
              }
            } else if (!item.stock) {
              /* Insufficient offers in stock */
              const offerCheckedExistentInCart = getNotAvailableOfferFromCart(offerCartItems, item);
              if (offerCheckedExistentInCart) {
                listOfInsufficientStock.push(offerCheckedExistentInCart);
              }
            } else {
              /* Update stock for offer in cart */
              dispatch(updateOfferStock(item));
              /* Verify availability for variant */
              const variantNotAvailable = _.filter(item.checkOfferVariantsDtos, ['available', false]);
              if (variantNotAvailable.length > 0) {
                const offerCheckedExistentInCart = getNotAvailableOfferFromCart(offerCartItems, item);
                if (offerCheckedExistentInCart) {
                  listOfferWithVariantNotAvailable.push(offerCheckedExistentInCart);
                  listVariantNotAvailable = variantNotAvailable;
                }
              }
              /* Verify availability additions */
              const additionNotAvailable = _.filter(item.checkOfferAddsDtos, ['available', false]);
              if (additionNotAvailable.length > 0) {
                const offerCheckedExistentInCart = getNotAvailableOfferFromCart(offerCartItems, item);
                if (offerCheckedExistentInCart) {
                  listOfferWithAdditionsNotAvailable.push(offerCheckedExistentInCart);
                  listAdditionsNotAvailable = additionNotAvailable;
                }
              }
            }
          });
        }

        if (listOfferNotAvailable.length > 0 || listOfInsufficientStock.length > 0 ||
          listOfferWithVariantNotAvailable.length > 0 || listOfferWithAdditionsNotAvailable.length > 0) {
          setListOfferNotAvailable(listOfferNotAvailable);
          setListOfInsufficientStock(listOfInsufficientStock);
          setListVariantNotAvailable(listVariantNotAvailable);
          setListAdditionsNotAvailable(listAdditionsNotAvailable);
          setListOfferWithVariantNotAvailable(listOfferWithVariantNotAvailable);
          setListOfferWithAdditionsNotAvailable(listOfferWithAdditionsNotAvailable);
          setShowRestaurantNotAvailableMessage(false);
          handleOpen();
        }
      } else {
        setShowRestaurantNotAvailableMessage(true);
        handleOpen();
      }
    }
  };

  const getNotAvailableOfferFromCart = (offerCartItems, item) => {
    const result = _.filter(offerCartItems, function (cartI) {
      if (cartI.id === item.id) {
        let cartItemListVariantAux = [];
        let cartItemListAdditionsAux = [];
        let variantLength = true;
        let additionsLength = true;

        if (cartI.variantSelected) {
          if (item.checkOfferVariantsDtos && item.checkOfferVariantsDtos.length > 0) {
            _.forEach(item.checkOfferVariantsDtos, function (v) {
              let auxVar = _.find(cartI.variantSelected, { 'id': v.id });
              if (auxVar) {
                cartItemListVariantAux.push(auxVar);
              }
            });
          }
          variantLength = cartItemListVariantAux.length === cartI.variantSelected.length;
        }

        if (cartI.additionsSelected) {
          if (item.checkOfferAddsDtos && item.checkOfferAddsDtos.length > 0) {
            _.forEach(item.checkOfferAddsDtos, function (a) {
              let auxAdds = _.find(cartI.additionsSelected, { 'id': a.id });
              if (auxAdds) {
                cartItemListAdditionsAux.push(auxAdds);
              }
            });
          }
          additionsLength = cartItemListAdditionsAux.length === cartI.additionsSelected.length;
        }
        if (variantLength && additionsLength) {
          return cartI;
        }
      }
    });
    return result[0] || null;
  };

  const handleOk = () => {
    if (showRestaurantNotAvailableMessage) {
      dispatch(cleanCart());
    } else {
      const allOfferToRemove = _.concat(listOfferNotAvailable, listOfInsufficientStock, listOfferWithVariantNotAvailable, listOfferWithAdditionsNotAvailable);
      for (let i = 0; i < cartItems.length; i++) {
        let count = cartItems[i].count;
        let aux = _.find(allOfferToRemove, cartItems[i]);
        if (aux) {
          while (count > 0) {
            dispatch(removeItemToCart(cartItems[i]));
            count--;
          }
          i--;
        }
      }
      if (categoryInCart && categoryInCart.slug) {
        browserHistory.push({ pathname: `${categoryInCart.slug}/${restaurant.slug}` });
      } else {
        browserHistory.push({ pathname: `/` });
      }
      if (!cartItems || cartItems.length <= 0) {
        dispatch(cleanCart());
      }
    }
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const offerListToPrint = (listOfferNotAvailable) => {
    return (
      <div>
        {listOfferNotAvailable && listOfferNotAvailable.length > 0 ? listOfferNotAvailable.map((offer, k) => {
          return (
            <div className="offerRow" key={k}>
              <Lens className="icon-list" component={"svg"} />
              <span className="title">
                {offer.name}
              </span>
              {offer.variantSelected && offer.variantSelected.length > 0 ?
                <span className="variant">
                  <span className="dot">{'·'}</span>
                  ({offer.variantSelected.map((variant) => `${translation.Code === "ES" ? variant.nameEs : variant.nameEn}`).join(", ")})
                </span> : null}
              {offer.additionsSelected && offer.additionsSelected.length > 0 ?
                <span className="details">
                  <span className="dot">{'·'}</span>
                  ({offer.additionsSelected.map(({ name }) => `${name}`).join(", ")})
                </span> : null}
            </div>
          )
        }) : null}
      </div>
    )
  };

  const variantListToPrint = (listVariantNotAvailable) => {
    return (
      <div>
        {listVariantNotAvailable && listVariantNotAvailable.length > 0 ? listVariantNotAvailable.map((variant, k) => {
          return (
            <div className="offerRow" key={k}>
              <Lens className="icon-list" component={"svg"} />
              <span className="title">
                {translation.Code === "ES" ? variant.nameEs : variant.nameEn}
              </span>
            </div>
          )
        }) : null}
      </div>
    )
  };


  const additionsListToPrint = (listAdditionsNotAvailable) => {
    return (
      <div>
        {listAdditionsNotAvailable && listAdditionsNotAvailable.length > 0 ? listAdditionsNotAvailable.map((additions, k) => {
          return (
            <div className="offerRow" key={k}>
              <Lens className="icon-list" component={"svg"} />
              <span className="title">
                {additions.name}
              </span>
            </div>
          )
        }) : null}
      </div>
    )
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown fullScreen={fullScreenSize} open={open} maxWidth="md" fullWidth
      onClose={handleClose}>
      <div className="availability-validation">
        <div className="account-container">
          <div className="basic-card full relative mb-20">
            <div className="d-flex justfy-content-center flex-column">
              <div className="expanded-block">
                <div className="FlexColumn">
                  <h4>{translation.AvailabilityValidation.alertTitle}</h4>
                  {showRestaurantNotAvailableMessage ?
                    <div>
                      <p>{translation.AvailabilityValidation.restaurantMessage}</p>
                      <div className="spacer" />
                    </div> :
                    <div>
                      <p>{translation.AvailabilityValidation.alertSubtitle}</p>
                      <div className="resume">
                        <table>
                          <tbody>
                            {listOfferNotAvailable && listOfferNotAvailable.length > 0 ?
                              <div>
                                <div className="spacer" />
                                <div className="info-text">{translation.AvailabilityValidation.offerNotAvailable}</div>
                                {offerListToPrint(listOfferNotAvailable)}
                              </div> : null}
                            {listOfInsufficientStock && listOfInsufficientStock.length > 0 ?
                              <div>
                                <div className="spacer" />
                                <div
                                  className="info-text">{translation.AvailabilityValidation.offerInsufficientStock}</div>
                                {offerListToPrint(listOfInsufficientStock)}
                              </div> : null}
                            {listVariantNotAvailable && listVariantNotAvailable.length > 0 ?
                              <div>
                                <div className="spacer" />
                                <div className="info-text">{translation.AvailabilityValidation.variantNotAvailable}</div>
                                {variantListToPrint(listVariantNotAvailable)}
                              </div> : null}
                            {listOfferWithVariantNotAvailable && listOfferWithVariantNotAvailable.length > 0 ?
                              <div>
                                {/*<div className="spacer"/>*/}
                                <div
                                  className="info-text_2">{translation.AvailabilityValidation.offerWithVariantsNotAvailable}</div>
                                {offerListToPrint(listOfferWithVariantNotAvailable)}
                              </div> : null}
                            {listAdditionsNotAvailable && listAdditionsNotAvailable.length > 0 ?
                              <div>
                                <div className="spacer" />
                                <div
                                  className="info-text">{translation.AvailabilityValidation.additionsNotAvailable}</div>
                                {additionsListToPrint(listAdditionsNotAvailable)}
                              </div> : null}
                            {listOfferWithAdditionsNotAvailable && listOfferWithAdditionsNotAvailable.length > 0 ?
                              <div>
                                {/*<div className="spacer"/>*/}
                                <div
                                  className="info-text_2">{translation.AvailabilityValidation.offerWithAdditionsNotAvailable}</div>
                                {offerListToPrint(listOfferWithAdditionsNotAvailable)}
                              </div> : null}
                          </tbody>
                        </table>
                      </div>
                      <div className="spacer" />
                    </div>
                  }
                </div>
              </div>
              <div className="d-flex justfy-content-center fixed-block">
                <button className="button--primary" onClick={() => handleOk()}>
                  {translation.Actions.accept}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
};