import React from 'react';
import { useDispatch } from "react-redux";
import { setLoadingMaskStatus } from "../../../../component/Mask/store/mask.actions";
import { store } from "../../../../App";

const OfferItem = (props) => {
  const dispatch = useDispatch();
  const data = props.data ? props.data : {};
  const restaurantSlug = props.restaurantSlug ? props.restaurantSlug : null;
  const businessCategorySelected = store.getState().filters.filters.businessCategory;

  const viewOfferDetail = (data) => {
    if (restaurantSlug && data && data.slug && businessCategorySelected && businessCategorySelected.slug) {
      dispatch(setLoadingMaskStatus(true));
      props.history.push({
        // pathname: businessCategorySelected.slug === '/mercado' ? `${restaurantSlug}` : `${businessCategorySelected.slug}/${restaurantSlug}`,
        pathname: restaurantSlug,
        state: {
          offer: data.slug
        }
      });
    }
  };

  return (
    <div className={props.customClass} onClick={() => viewOfferDetail(data)}>
      <div className="card">
        <div className="card-image-container">
          {data.imgLg ? <img src={data.imgLg} alt={data.name} className="" />
            : <img src={"./images/picture/no-image.png"} alt={data.name} className="" />}
        </div>
        <div className="card-details-container">
          <h4 className="label-title">{data.name}</h4>
          <div className="label-content">
            <div style={{ display: "grid" }}>
              <span>
                {`$${data.price ? data.price.toFixed(2) : (0).toFixed(2)}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default OfferItem;