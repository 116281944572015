import React from "react";
import "./css/SpinnerEnhancedStyle.scss";
import {Add, Remove, DeleteForeverOutlined} from "@material-ui/icons";

export default function SpinnerEnhanced({value, handleAdd, handleRemove, menu}) {
  return (
    <div className={`spinner-grid ${menu ? 'spinner-grid-menu' : ''}`}>
      {value ?
        <button className="button--transparent button--small" onClick={handleRemove}>
          {value === 1 ? <DeleteForeverOutlined alt="delete" component="svg"/>
            : <Remove alt="delete" component="svg"/>}
        </button> : null}
      <span>{value}</span>
      <button className="button--transparent button--small" onClick={handleAdd}>
        <Add alt="add" component="svg"/>
      </button>
    </div>
  );
}
