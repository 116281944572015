import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Grid, Slide, Typography } from "@material-ui/core";
import useTranslations from "translations";
import { loginUserSelector } from "modules/login/store/login.reducers";
import "./css/EmailVerificationStyles.scss";
import { clearAuthState, verifyPin } from "modules/login/store/login.actions";
import { addToStorage } from "utils/AuxiliarFunctions";
import AtomIcon from "component/Icon/AtomIcon";

export const EmailVerification = ({ history }) => {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const loginResponse = useSelector(loginUserSelector);
  const [email, setEmail] = useState(" *****@***.** ");
  const [login, setLogin] = useState(null);
  const [showValidateEmailAlert, setShowValidateEmailAlert] = useState(false);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("Dt3mp0"));
    if (loginData) {
      let loginObj = {
        channel: loginData.channel,
        username: loginData.username,
        client_id: loginData.client_id,
        device_id: loginData.device_id,
        grant_type: loginData.grant_type,
        passwordless_pin: loginData.passwordless_pin,
      };
      setLogin(loginObj);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginResponse?.verify_email) {
      if (loginResponse?.verificationData?.data) {
        setEmail(loginResponse?.verificationData?.data?.email);
      }
    }
    if (loginResponse?.check_verify_email) {
      if (loginResponse?.verificationData?.data) {
        setEmail(loginResponse?.verificationData?.data?.email);
        setShowValidateEmailAlert(true);
      }
    }
    if (loginResponse.login_success) {
      const url = localStorage.getItem("backTo");
      addToStorage("token", loginResponse?.cllWebAppToken);
      addToStorage(
        "stamp",
        new Date().getTime() +
        Number(loginResponse?.cllWebAppToken?.expires_in) * 1000
      );
      localStorage.removeItem("backTo");
      localStorage.removeItem("Dt3mp0");
      localStorage.removeItem("Dv4lP1");
      localStorage.removeItem("D3m41l");
      const pathname = url ? url.replace("..", "") : "/";
      history.push({ pathname });
      dispatch(clearAuthState());
    }
    if (loginResponse.login_failure) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, [loginResponse]);

  const verifyAndLogin = (value) => {
    let aux = login;
    aux["unverify_device"] = value;
    dispatch(verifyPin(aux));
  };

  const handleCloseValidateEmailAlert = () => {
    setShowValidateEmailAlert(false);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  let VALIDATE_EMAIL_ALERT = null;
  if(showValidateEmailAlert){
    VALIDATE_EMAIL_ALERT = (<Dialog
      fullWidth={true}
      maxWidth="xs"
      open={showValidateEmailAlert}
      onClose={handleCloseValidateEmailAlert}
      TransitionComponent={Transition}
    >
      <div className="verification_alert">
        <div className="alert__header">
          <br />
          <br />
          <button
            className="alert__header__close button--transparent button--round"
            onClick={handleCloseValidateEmailAlert}
          >
            <AtomIcon width="24" icon="cancel" alt="close" />
          </button>
        </div>
        <div className="alert__body">
          <div className="alert_title">
            <img className="alert_icon" src={"images/icons/system/circle-check-green.svg"} alt="" />
            <h2 className="alert_header_title">{translation.Login.alertVerificationTitle}</h2>
          </div>
          <div className="alert_message">
            <span className="verification_alert_message">{translation.Login.alertVerificationMessage}</span>
          </div>
        </div>
        <div className="alert__footer">
          <div className="button-wrapper button-wrapper--double">
           <button className="button--primary button--max" onClick={handleCloseValidateEmailAlert}>
              {translation.Actions.accept}
            </button>
          </div>
        </div>
      </div>
    </Dialog>)
  }

  return (
    <div className="email_verification">
      {VALIDATE_EMAIL_ALERT}
      <Grid container className="container" component="div">
        <div className="form">
          <Typography className="title" component="h1">
            {translation.Login.verificationHeader}
            <span />
          </Typography>
          <Typography component="p" style={{ margin: '3em 0' }}>
            {translation.Login.verificationSubHeaderPart1}
            <span className="emailHint">{` ${email}. `}</span>
            {translation.Login.verificationSubHeaderPart2}
          </Typography>
          <div className="fields">
            <Grid
              container
              item
              md={8}
              sm={8}
              xs={12}
              spacing={4}
              component={"div"}
            >
              <br />
              <Grid container item xs={12} className="action" component={"div"}>
                <button
                  onClick={() => verifyAndLogin(false)}
                  className="button--primary button--max"
                >
                  {translation.Login.btnSuccessfulVerification}
                </button>
              </Grid>
              <Grid container item xs={12} className="action" component="div">
                <button
                  onClick={() => verifyAndLogin(true)}
                  className="button--outline button--max"
                >
                  {translation.Login.btnEmailNotReachable}
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
};
