import React from "react";

export default function Picture({
  src,
  width = "100",
  height = "auto",
  alt,
  classes
}) {
  const url = src.startsWith("data:image/")
    ? src
    : `./images/picture/${src}`;
  return (
    <img
      className={classes}
      width={width}
      height={height}
      src={url}
      alt={alt || url}
    />
  );
}
