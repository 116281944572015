import {post} from "../../../core/services/ApiRequest";
import {setLoadingMaskStatus} from "../../../component/Mask/store/mask.actions";
import {notifyError} from "../../notification/store/notification.actions";
import {browserHistory} from "../../../utils/History";

const history = browserHistory;

export const SET_FORM = "SET_FORM";
export const BACK_TO_VALIDATE_PHONE = "BACK_TO_VALIDATE_PHONE";
export const DONE_VALIDATE_PHONE = "DONE_VALIDATE_PHONE";
export const ERROR_VALIDATE_PHONE = "ERROR_VALIDATE_PHONE";
export const DONE_VERIFY_CODE = "DONE_VERIFY_CODE";
export const ERROR_VERIFY_CODE = "ERROR_VERIFY_CODE";

const baseUrl = "account";

export function setForm(form) {
  return {type: SET_FORM, form}
}

export function backToValidatePhone() {
  return {type: BACK_TO_VALIDATE_PHONE}
}

function doneValidatePhone(data) {
  return {type: DONE_VALIDATE_PHONE, data}
}

function errorValidatePhone(error) {
  return {type: ERROR_VALIDATE_PHONE, error}
}

function doneVerifyCode(data) {
  return {type: DONE_VERIFY_CODE, data}
}

function errorVerifyCode(error) {
  return {type: ERROR_VERIFY_CODE, error}
}

export function doValidatePhone(validation) {
  return async dispatch => {
    try {
      dispatch(setLoadingMaskStatus(true));
      const {data} = await post(`${baseUrl}/verify`, validation);
      dispatch(doneValidatePhone(data));
    } catch ({response}) {
      dispatch(errorValidatePhone(response));
      dispatch(notifyError(response.data.description));
    } finally {
      dispatch(setLoadingMaskStatus(false));
    }
  };
}

export function doVerifyCode(verification) {
  return async dispatch => {
    try {
      dispatch(setLoadingMaskStatus(true));
      const response = await post(`${baseUrl}/register`, verification);
      dispatch(doneVerifyCode(response));
      history.push('./login');
    } catch (error) {
      let errObj = error.response && error.response.data ? error.response.data : {};
      if (errObj) {
        const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
        dispatch(errorVerifyCode(errMsg));
        dispatch(notifyError(errMsg));
      }
    } finally {
      dispatch(setLoadingMaskStatus(false));
    }
  };
}
