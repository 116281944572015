import {createSelector} from "reselect";
import * as recover_actions from "../store/recover-password.actions";

const INITIAL_STATE = {
  phone: "",
  pin: "",
  password: "",
  send_pin_success: false,
  change_password_success: false,
};
export default function recover(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case recover_actions.SET_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      }
    }
    case recover_actions.SEND_PIN_SUCCESS: {
      return {
        ...state,
        send_pin_success: true,
      }
    }
    case recover_actions.BACK_TO_EDIT_PHONE: {
      return {
        ...INITIAL_STATE,
        send_pin_success: false
      }
    }
    case recover_actions.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        change_password_success: true,
      }
    }
    default: {
      return state;
    }
  }
}


export const selectRecoverState = state => state.recover;
export const selectRecoverFormData = createSelector(
  selectRecoverState,
  state => {
    const {phone, pin, password} = state;
    return {phone, pin, password};
  }
);

export const selectSendPinSuccess = createSelector(
  selectRecoverState,
  state => state.send_pin_success
);

export const selectChangePasswordSuccess = createSelector(
  selectRecoverState,
  state => state.change_password_success
);