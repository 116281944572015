/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useTranslations from "../../../../translations";
import { Add, CheckCircle, RadioButtonUnchecked, Remove } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { selectBDCart } from "../../../cart/store/cart.reducers";
import UberModal from "component/UberModal/UberModal";

const ModalMenu = (props) => {
  const offer = props.offer;
  const restaurant = props.restaurant;
  const onClose = props.onClose;
  const translation = useTranslations();
  const cartBD = useSelector(selectBDCart);
  const image = { src: offer.imgLg, name: offer.name };
  const title = offer.name;
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(false);
  const [availableStock, setAvailableStock] = useState(false);
  const [stock, setStock] = useState('');
  const [offerInfo, setOfferInfo] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [cartItem, setCartItem] = useState({});
  const [totalCostOfCartItem, setotalCostOfCartItem] = useState(0);

  useEffect(() => {
    if (cartBD?.items) {
      setCartItems(cartBD.items);
    }
    // eslint-disable-next-line
  }, [cartBD?.items]);

  useEffect(() => {
    let isAvailable = (!!(!offer || (offer.manageStock && offer.stock > 0 && offer.stock > countOfferInCart()) || (offer && !offer.manageStock)));
    setOfferInfo(offer);
    setStock(offer.stock || '');
    setAvailableStock(isAvailable);

    let newOffer = {
      ...offer,
      restaurant: restaurant,
      count: 1,
      additionsSelected: [],
      variantSelected: [],
    };
    setCartItem(newOffer);
    // eslint-disable-next-line
  }, [offer]);

  useEffect(() => {
    if (cartItem?.id) {
      getTotalOfCartItem();
    }
    // eslint-disable-next-line
  }, [cartItem]);

  useEffect(() => { validateFields(); }, [cartItem]);

  const getTotalOfCartItem = () => {
    let additions = cartItem?.additionsSelected?.length > 0
      ? cartItem?.additionsSelected?.map(item => item.price)
        .reduce((prev, current) => prev + current)
      : 0;
    let variants = cartItem?.variantSelected?.length > 0
      ? cartItem?.variantSelected?.map(item => item.price)
        .reduce((prev, current) => prev + current)
      : 0;
    setotalCostOfCartItem((cartItem?.price + additions + variants) * cartItem.count);
  };

  const countOfferInCart = () => {
    const items = cartItems.reverse().filter(item => item.offerId === offer.id);
    if (items && items.length > 0) {
      return items.map(item => item.offerCount).reduce((prev, next) => prev + next);
    }
    return 0;
  };

  const addCounterToCartItem = () => {
    const count = cartItem.count || 1;
    setCartItem({ ...cartItem, count: count + 1 });
  };

  const removeCounterToCartItem = () => {
    let count = cartItem.count || 1;
    count = count > 1 ? count - 1 : 1;
    setCartItem({ ...cartItem, count });
  };

  const onAdditionChanged = (adt) => {
    const isChecked = cartItem.additionsSelected && cartItem.additionsSelected.map(item => item.id).includes(adt.id);
    if (!isChecked) {
      cartItem.additionsSelected.push(adt);
    } else {
      cartItem.additionsSelected = cartItem.additionsSelected.filter((item) => item.id !== adt.id);
    }
    setCartItem({ ...cartItem });
  };

  const onVariantSelected = (variant) => {
    const isVariantTypeChecked = cartItem.variantSelected && cartItem.variantSelected.map(item => item.variantTypeId).includes(variant.variantTypeId);
    const isVariantChecked = cartItem.variantSelected && cartItem.variantSelected.map(item => item.id).includes(variant.id);
    if (isVariantTypeChecked) {
      let aux = cartItem.variantSelected.filter((item) => item.variantTypeId !== variant.variantTypeId);
      if (!isVariantChecked) {
        aux.push(variant);
        cartItem.variantSelected = aux;
      }
    } else {
      cartItem.variantSelected.push(variant);
    }
    setCartItem({ ...cartItem });
  };

  const addCartItemToCart = () => {
    props.handleOnAddItemToCart(cartItem);
    onClose();
  };

  const validateFields = () => {
    let errors = {};
    if (cartItem.variantTypes && cartItem.variantTypes.length > 0) {
      if (cartItem.variantSelected && cartItem.variantSelected.length > 0) {
        _.forEach(cartItem.variantTypes, function (type) {
          const isVariantTypeSelected = cartItem.variantSelected && cartItem.variantSelected.map(item => item.variantTypeId).includes(type.id);
          if (!isVariantTypeSelected) {
            errors[`variantRequired_${type.id}`] = `${translation.Validations["required"]}`;
          }
        })
      } else {
        errors.variantRequired = "Required";
      }
    }
    if (offerInfo.manageStock) {
      let isAvailable = (countOfferInCart() + cartItem.count) <= stock
      setAvailableStock(isAvailable);
    }
    setErrors(errors);
    setValid(_.isEmpty(errors));
  };

  const content = <div>
    {cartItem?.variantTypes?.length > 0 && cartItem?.variantTypes?.map((type, key) =>
    (<ul className="mydialog__dialog__content__list">
      {type?.variants?.length > 0 && <li key={key}>
        <Grid container item className="" md={12} sm={12} xs={12} component={"div"}>
          <Grid container item md={12} sm={12} xs={12} component={"div"}
            className="mydialog__dialog__content__list__head">
            <Grid container item md={12} sm={12} xs={12} component={"div"}
              className="mydialog__dialog__content__list__head__title">
              {translation.Code === "ES" ? type.nameEs : type.nameEn}
            </Grid>
            <Grid container item md={12} sm={12} xs={12} component={"div"}
              className="mydialog__dialog__content__list__head__subtitle required">
              {errors.variantRequired ? translation.Offer.variantTitleNote : ""}
              {errors[`variantRequired_${type.id}`] ? translation.Offer.variantTitleNote : ""}
            </Grid>
          </Grid>
          <Grid container item md={12} sm={12} xs={12} component={"div"}
            className="mydialog__dialog__content__list__body">
            {type?.variants?.map((variant, k) => {
              const isChecked = cartItem?.variantSelected?.length && cartItem?.variantSelected?.map(item => item.id).includes(variant?.id);
              return (
                <div key={k} className="mydialog__dialog__content__list__body__item">
                  <div className="mydialog__dialog__content__list__body__item__name">
                    <FormControlLabel
                      control={
                        <Radio
                          id="variant"
                          icon={<RadioButtonUnchecked component={"svg"} />}
                          checkedIcon={<CheckCircle component={"svg"} style={{ color: '#2c2c2c' }} />}
                          checked={isChecked || false}
                          onChange={() => onVariantSelected(variant)}
                        />}
                      label={translation.Code === "ES" ? variant.nameEs : variant.nameEn}
                      labelPlacement="end"
                    />
                  </div>
                  <span className="mydialog__dialog__content__list__body__item__price">
                    {variant.price ? '$' + variant.price.toFixed(2) : ''}
                  </span>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </li>}
    </ul>))}

    {cartItem.adds?.length > 0 &&
      <ul className="mydialog__dialog__content__list">
        <li>
          <div>
            <div className="mydialog__dialog__content__list__head">
              <div className="mydialog__dialog__content__list__head__title">
                Agregos
              </div>
              <div className="mydialog__dialog__content__list__head__subtitle">
                Adicione tantos como quiera
              </div>
            </div>
            <div className="mydialog__dialog__content__list__body">
              {cartItem?.adds?.map((adt, key) => {
                const isChecked = cartItem?.additionsSelected && cartItem?.additionsSelected?.map(item => item.id).includes(adt?.id);
                return <div className="mydialog__dialog__content__list__body__item" key={key}>
                  <GreenCheckbox checked={isChecked || false} onChange={() => onAdditionChanged(adt)}
                    name="checkedG" />
                  <span className="mydialog__dialog__content__list__body__item__name">{adt?.name}</span>
                  <span
                    className="mydialog__dialog__content__list__body__item__price">${adt?.price ? adt?.price?.toFixed(2) : (0).toFixed(2)}</span>
                </div>
              })}
            </div>
          </div>
        </li>
      </ul>}
  </div>;

  const actions = <>
    <div className="offer-actions">
      <div className="spinner-grid spinner-grid-menu">
        <button className="button--transparent button--small" onClick={removeCounterToCartItem} disabled={!valid}>
          <Remove alt="delete" component="svg" />
        </button>
        <span>{cartItem.count}</span>
        <button className="button--transparent" onClick={addCounterToCartItem}
          disabled={!availableStock || !valid || (stock && (countOfferInCart() + cartItem.count) >= stock)}>
          <Add alt="add" component="svg" />
        </button>
      </div>
    </div>
    <button className="button--primary button--max" style={{ marginLeft: '5px' }} onClick={() => addCartItemToCart()}
      disabled={!availableStock || !valid || (stock && (countOfferInCart() + cartItem.count) > stock)}>
      <div className="button__label">
        {!availableStock ? translation.General.notAvailable : translation.Offer.additionsActionAdd}
      </div>
      <div className="button__price">{`$${totalCostOfCartItem?.toFixed(2) || (0).toFixed(2)}`}</div>
    </button>
  </>;

  return (
    <UberModal title={title} onClose={onClose} image={image} actions={actions} imageSize={UberModal.imageSizes.MID}
      subtitle={cartItem.name} description={cartItem.description} content={content}
      extra={<div className="stock-hint">
        {availableStock ? null : translation.OfferItem.maximumOfferOnStock}
      </div>}
    />
  )
};

const GreenCheckbox = withStyles({
  root: {
    color: green,
    '&$checked': {
      color: '#2c2c2c',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default ModalMenu;