import { combineReducers } from "redux";

import cll2 from "./cll2/reducers";
import cash from "./cash/cash.reducers";
import braintree from "./braintree/braintree.reducers";
import tubolsillo from "./tubolsillo/tubolsillo.reducers";

export default combineReducers({
  cll2,
  cash,
  braintree,
  tubolsillo,
});

export * from "./cll2/reducers";
export * from "./cash/cash.reducers";
export * from "./braintree/braintree.reducers";
export * from "./tubolsillo/tubolsillo.reducers";
