import { Translation } from "./types";
import { TASA_PESO } from "../../constants";

export default {
  Code: "EN",
  ConnectedTo: "Connected to id",
  Validations: {
    fieldRequired: "Field Required",
    required: "Required field",
    integer: "Not a valid number",
    number: "Not valid number",
    email: "Wrong email",
    password: "Password must be 6 characters or more",
    us_phone_number: "Invalid phone number",
    date: "Invalid date",
    length: "Minimum characters must be above of",
    maxLength: "You have reached the maximum number of characters allowed",
    special_characters: "Special characters not allowed",
    form: "There are validation errors in the form",
    incomplete_phone_number: "Incomplete phone number",
    login: "Invalid credentials",
  },
  Actions: {
    new: "New",
    close: "Close",
    cancel: "Cancel",
    accept: "Accept",
    apply: "Apply",
    save: "Save",
    continue: "Continue",
  },
  General: {
    seeMore: "See more",
    viewAll: "View all",
    viewRestaurants: "View Restaurants",
    readFullArticle: "Read the full article",
    notAvailable: "Not available",
    noMatchesFoundFor: "No matches found for",
    tryAnotherSearchCriteria: "try another search criteria",
    noResultList: "No results to show",
    defaultAlertTitle: "Alert",
  },
  Pagination: {
    labelRowsPerPage: "Rows Per Page",
  },
  TopBar: {
    linkDelivery: "Delivery",
    linkHelp: "Help",
    linkCll: "Tamtos",
    linkHome: "Home",
    linkRestaurant: "Restaurant",
    linkReserve: "Reserve",
    btnLogin: "Sign up or sign in",
    btnLogout: "Logout",
    btnProfile: "My Account",
    linkPersonalData: "Personal data",
    linkPreferredAddresses: "Preferred addresses",
    linkCardManagement: "Cards Management",
    linkPurchaseHistory: "Purchase history",
    btnRegister: "Create Account",
    btnMyProfile: "My Profile",
    optionDelivery: "Delivery",
    optionPickup: "Pickup",
    deliveryModeDetails: "Delivery Details",
    searchPlaceholder: "Search...",
    search: "Search",
    cart: "Cart",
  },
  Address: {
    selectAddress: "Set you address",
    deliverIn: "Deliver In",
    deliverAt: "Deliver at",
  },
  Home: {
    labelSelectMunicipality: "Where do you want it delivered",
    sectionBusinessCategoryTitle: "Find everything you need",
    sectionBusinessCategorySubtitle:
      "Click on the store you want and start shopping",
    sectionHeaderMobileTitle: "Food delivery in Cuba and more",
    tabTitleDefault: "Restaurants | Everything you need in Cuba at a click",
    tabTitleRestaurant: "Food delivery in Cuba and much more! | Restaurants",
    tabTitleSupermarket: "Restaurants | Supermarket | Combos and more!",
    metaDefault:
      "Restaurantes. Restaurants, Supermarket and Stores with everything your relatives need in Cuba with the best offers.",
    metaRestaurant:
      "With Restaurantes the best Cuban dishes, now delivered to your doorstep. The best food delivery in Cuba.",
    metaSupermarket:
      "Supermercado de Restaurantes. Combos and much more !. Delivery in 1 or 2 days!",
    sectionHeaderTitleDefault: "Restaurantes",
    sectionHeaderTitleRestaurant:
      "Restaurants: The best food delivery and much more.",
    sectionHeaderTitleSupermarket: "Sumpermarket.",
    sectionHeaderTitle1: "Choose what you want to eat today, ",
    sectionHeaderTitle2: "we will take it",
    sectionHeaderSubtitle1: "Select restaurant",
    sectionHeaderSubtitle2: "Create the order",
    sectionHeaderSubtitle3: "Receive at home",
    sectionHeaderHomeDeliveryOption: "Home Delivery",
    sectionHeaderPickOrderOption: "Pick Order",
    sectionHeaderSearchButton: "Search",
    sectionFoodCategoryTitle: "What kind of food are you looking for?",
    sectionPopularsTitle: "The most popular",
    sectionTrendingTitle: "Trends in Havana",
    sectionSubscriptionTitle: "Don't miss anything!",
    sectionSubscriptionSubtitle:
      "Subscribe to our newsletter to keep up to date with the best promotions.",
    sectionSubscriptionButton: "I want to subscribe",
    sectionSubscriptionInputPlaceholder: "Email",
    sectionPressTitle: "Delivery in the press",
    defaultPageMessageTitle:
      "Home delivery of food in Cuba and everything for your family",
    defaultPageMessageContent:
      "Restaurants is an all-terrain solution to take care of your family with food delivery at home in Cuba even on the same day. Food... and much more: in addition to food combos, dinners, and farm products, we have electrical appliances, toiletries, hygiene, and household items. To fight from cravings to blackout when they want it. When you're in a hurry, order food delivery in Cuba, and if not, too. Restaurants, a single Cuban family.",
  },
  OfferCarousel: {
    title: "Special offers",
    seeAll: "See all",
  },
  RestaurantCarousel: {
    since: "Since",
    minute: "Min",
    title: "Food delivery in Havana",
    seeAll: "See all",
  },
  PromotionsCarousel: {
    carouselTitle: "Today's Deals",
  },
  RestaurantList: {
    sectionFilterButton: "Filter",
    filterDialogHeader: "Filter",
    filterTitle: "You are filtering by",
    eraseAll: "Erase all",
    noResult: "No results to show",
    title: "Home delivery",
    restaurant: "restaurants",
  },
  Filter: {
    sort: "Sort",
    atHome: "At home",
    pickup: "Pickup",
    promotion: "Promotion",
    dietIntolerance: "Diet/Intolerance",
    cuisineType: "Cuisine type",
    eraseAll: "Erase all",
    apply: "Apply",
    deliverAt: "Deliver at",
    restaurantName: "Restaurant name",
    deliverTime: "Deliver time",
    bestScore: "Best score",
    averagePrice: "Average price",
  },
  Restaurant: {
    hours: "Hours",
    menuOfDay: "Menu of the day",
    back: "Back",
    atHome: "At home",
    pickup: "Pickup",
    thisIsYourRequest: "This is your request",
    viewMyRequest: "View my request",
    backToMenu: "Back to Menu",
    completeRequest: "Complete Request",
    evaluations: "evaluations",
    more: "More",
  },
  Login: {
    info: "Enter to your account or create a new one so you can finish your payment.",
    welcome: "Access your Account",
    indentifyWithCuballama:
      "To access your account or create a new one provide your phone number.",
    header:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    emailField: "Email",
    emailHeader: "Customer email",
    emailSubHeader: "Please enter a valid email address",
    backToLogin: "Go back to start",
    needToLogin: "You need to sign into the system to execute this action",
    phoneNumberField: "Phone",
    passwordField: "Password",
    pinHeader: "Validate phone number",
    pinSubHeader: "Introduce the validation code that we sent to your phone",
    pin: "Pin",
    pinAction: "Validate",
    pinMsg: "a PIN number has been sent to your phone",
    pinNotReceived: "Didn't you get the code?",
    pinHelper: "Only 4 characters",
    verificationHeader: "Complete the verification",
    verificationSubHeaderPart1: "We have sent you an email to ",
    verificationSubHeaderPart2:
      "Follow the instructions in the email to verify your account.",
    btnSuccessfulVerification: "I have verified",
    btnEmailNotReachable: "I don't have access to email",
    alertVerificationTitle: "Please, validate your email",
    alertVerificationMessage:
      "For your security we have sent you an email to verify that it is you. Please check your inbox and click on the attached link. Once you do, return to this page and press continue to complete your access.",
    rememberMe: "Remember me",
    haveYouForgotPassword: "Have you forgotten your password?",
    loginAction: "Continue",
    registerInvitation: "We will send you an SMS to verify your phone",
    createItHere: "Create it here",
    phonePlaceHolder: "Phone with prefix",
    wait: "Wait",
    seconds: "seconds",
    resendCode: "Resend code",
  },
  Register: {
    createCuballamaAccount: "Create a Account",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    info2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque egestas. lorem quis quam suscipit ultricies sed aliquam et nunc vitae suscipit.",
    phoneField: "Phone",
    validateAction: "Validate Phone",
    alreadyRegistered: "Already registered in Tamtos?",
    introPinOrGetACall:
      "Enter the PIN that we have sent to you via SMS o call to",
    modifyAction: "Modify",
    pinReceived: "Pin received",
    password: "Password",
    email: "Email",
    thisDataIsOptional: "This data is optional",
    conditionPart1: "Creating this account means that you accept",
    conditionPart2: "legal conditions",
    conditionPart3: "and the",
    conditionPart4: "policy of privacy",
    conditionPart5: "of Tamtos",
    registerAction: "Create Account",
    resendCodeTitle: "You have not received the PIN?",
    receivePinAgain: "Choose how do you wish to receive the code",
    receivePinPerCall: "By call",
    receivePinBySms: "By SMS",
    receivePinByWhatsApp: "By WhatsApp",
    talkToAnAgent: "Talk to an agent",
    cancel: "Cancel",
  },
  RecoverPassword: {
    title1: "Identify with a account",
    title2: "Identify with a account",
    helperText:
      "In a few seconds you will receive your PIN. If you have used a mobile phone you will receive it in an SMS, otherwise by a call",
    goBackToLogin: "Do you already remember the password?",
    sendPin: "Send PIN",
    phoneField: "Phone",
    pinReceived: "Pin received",
    password: "Password",
    usernameField: "Telephone",
    modifyAction: "Modify",
    setPasswordAction: "Change Password",
    resendCodeTitle: "You have not received the PIN?",
    getPinBySms: "Receive PIN per SMS",
    getPinByCall: "Receive PIN per call",
    talkToAnAgent: "Talk to an agent",
  },
  Search: {
    title: "",
    subtitle: "",
    inputPlaceholder: "What are you looking for?",
    generalPlaceholder: "Place your search query",
    notFoundPlaceholder:
      "Sorry, but we could not find what you were looking for",
  },
  Payment: {
    codes: {
      TRANSACTION_NOT_ALLOWED: "Transaction failed, please try again",
      DECLINED: "Transaction Declined, please contact your Bank",
      PROCESSOR_DECLINED: "Declined transaction, please contact your Bank",
      EXPIRED_CARD: "Expired Card, please check your payment method.",
      INVALID_TRANSACTION: "Invalid transaction, please try again",
      GATEWAY_REJECTED: "Transaction declined by Bank authorization",
    },
    failureTitle: "Payment could not be processed",
    failureSubtitle: "Please try again or select another payment method.",
    failureDate: "Date",
    failureAmount: "Amount",
    failureGenericMessage: 'Message: Your payment was not aproved by your bank. Please contact your bank so they authorize a payment to tamtos',
    successTitle: "Payment has been completed successfully",
    successSubtitle: "Below we show the details of the purchase.",
    viewMyRequest: "View my request",
    thisIsMyRequest: "This is my request",
    haveAnyDiscount: "Do you have any discount?",
    couponApplied: "Discount coupon applied",
    invalidCoupon: "Invalid coupon",
    discountCode: "Discount code",
    apply: "Apply",
    clearCoupon: "Remove",
    subtotal: "Subtotal",
    shipping: "Shipping",
    freeShipping: "Free",
    taxes: "Taxes",
    discount: "Discount (Coupon)",
    bonus: "Discount (Bonus)",
    coupon: "Coupon",
    total: "Total",
    pending: "To calculate",
    daily: "Daily",
    modify: "Modify",
    modifyRequest: "Modify Request",
    continueCheckout: "Continue to Checkout",
    deliveryData: "Delivery Data",
    deliveryDate: "Delivery",
    pay: "Pay",
    addCard: "Save credit card",
    addAndPay: "Save credit card and pay",
    evaluations: "evaluations",
    addressNotAvailable: "Address not available.",
    validatingAddress: "Validating address . . .",
    invalidDeliveryAddress:
      "The selected restaurant does not ship to this address.",
    validDeliveryAddress: "This is a valid address for this delivery.",
    setYourAddress: "Tell us your address",
    addressNewAddress: "Add new address",
    addDeliveryAddress: "Add delivery address",
    setDeliveryAddress: "Set delivery address",
    deliveryAddress: "Delivery address",
    address: "Address",
    deliveryAddressHint:
      "Include your street name and number, as well as your neighborhood or area",
    municipality: "Municipality",
    city: "City",
    name: "Name",
    phone: "Phone",
    date: "Date",
    time: "Time",
    labelPickup: "pick up",
    labelDelivery: "delivery",
    labelDateAndTimeOf: "Date and time of ",
    clickToSelectDates: "Select the delivery date",
    deliveryWarning:
      "Make sure that there will be a person to receive your request at the selected date and time.",
    confirmDateDialogTitle: "This order will be delivered on ",
    confirmDateDialogMessage: "Do you wish to continue?",
    confirmDateDialogButtonOk: "",
    confirmDateDialogButtonCancel: "",
    confirm: "Confirm",
    confirmAndPay: "Confirm and pay",
    paymentInProgressMessage:
      "We are processing your payment. This process can take up to 1 minute. Do not close this screen until you get an answer.",
    availableCredit: "Credit",
    cashPaymentOption: "Cash",
    cashPaymentOptionLabel: "Pay cash on delivery",
    makeYourPaymentsWithCash: `This payment will be made in the local currency CUP at the exchange rate ${TASA_PESO} CUP x 1`,
    addCreditCard: "Add Credit Card",
    goBack: "Back",
    asap: "As soon as posible",
    contactData: "Contact data",
    discountedBalance: "Discounted balance",
    remainingBalance: "Remaining Balance",
    transactionId: "Transaction ID",
    creditCard: "Credit Card",
    paypalAccount: "Paypal Account",
    expirationDate: "Expiration",
    cart: "Cart",
    paymentId: "Payment ID",
    paymentDoneMessage: "Your payment is done",
    paymentErrorMessage:
      "Your payment did not finish because an error during the transaction",
    paymentCancelMessage:
      "You cancel this payment transaction, you can go back to the initial payment, clicking the button down below",
    message: "Message",
    restaurant: "Restaurant",
    estimatedDeliveryTime: "Delivery ~ ",
    acceptTerms:
      "By continuing, you UNDERSTAND and ACCEPT that it is not possible to CANCEL or MODIFY your order.",
    estimatedTime: " Min approximate",
    delivery: "Delivery",
    pickUp: "Pick up",
    prices: "Prices",
    estimatedTimeShort: "minutes",
    minDeliveryCost: "Shipping ~ ",
    approx: "(Approx)",
    creditCardEndWith: "Credit Card ended with",
    payWithCard: "Payment with Card",
    payWithTuBolsillo: "Payment with Tu Bolsillo",
    edit: "Edit",
    cancelEdit: "Cancel",
    addPaymentMethod: "Add Payment Method",
    warningMessageBeforePay:
      "By pressing the button 'Pay' I accept this purchase and its delivery conditions",
    titleDeliveryConditions: "Delivery conditions",
    titleSelectPaymentMethod: "Payment Method",
    payRestWith1: "Pay remaining",
    payRestWith2: "with",
    titleResumeBeforePayment: "Complete purchase",
    newPaymentMethod: "Add new payment method",
    alertDeletePaymentMethod:
      "You are about to delete this payment method. Are you sure this is what you want?",
    alertSelectPaymentMethod:
      "Please select a payment method to continue your purchase",
    paymentMethodAdded: "Payment method added successfully",
    closeAddMethodDialog: "Close",
    paymentMethodCancelled: "Payment method cancelled",
    paypalAccountAdded: "Paypal account added",
    attention: "Attention",
    contacts: "Contacts",
    errors: {
      "no valid date.": "No valid date",
      "no valid value.": "No valid value",
      "No card number provided.": "No card number provided",
      "Credit card number is in invalid format.":
        "Credit card number is in invalid format",
      "Warning! This credit card number is associated with a scam attempt.":
        "Warning! This credit card number is associated with a scam attempt",
      "Credit card number has an inappropriate number of digits.":
        "Credit card number has an inappropriate number of digits",
      "Do Not Honor.": "Bank is unwilling to accept the transaction",
      "Insufficient Funds.":
        "The account did not have sufficient funds to cover the transaction amount",
      "Limit Exceeded.":
        "The attempted transaction exceeds the withdrawal limit of the account",
      "Cardholder's Activity Limit Exceeded.":
        "The attempted transaction exceeds the activity limit of the account",
      "Expired Card.": "	Card is expired",
      "Invalid Credit Card Number.": "Invalid credit card number",
      "Invalid Expiration Date.": "Invalid card expiration date",
      "No Account.": "Card number is not on file with the card-issuing bank",
      "Card Account Length Error.":
        "Card number does not include the proper number of digits",
      "Card Issuer Declined CVV.": "Invalid security code ",
      "Processor Declined – Possible Lost Card.":
        "The card used has likely been reported as lost, please contact your bank",
      "Processor Declined – Possible Stolen Card.":
        "The card used has likely been reported as stolen, please contact your bank",
      "Processor Declined – Fraud Suspected.":
        "Fraud suspected, please contact your bank",
      "Transaction Not Allowed.":
        "Bank is declining the transaction for unspecified reasons, possibly due to an issue with the card itself",
      "Duplicate Transaction.":
        "The transaction appears to be a duplicate of a previously submitted transaction and was declined to prevent charging the same card twice",
      "Invalid Transaction.": "Bank declined the transaction",
      "Declined – Updated Cardholder Available.":
        "The submitted card has expired or been reported lost and a new card has been issued",
      "Processor Does Not Support This Feature.":
        "Your account can't process transactions with the intended feature",
      "Card Type Not Enabled.":
        "Your account can't process the attempted card type",
      "Set Up Error – Card.": "There is a problem with the submitted card",
      "Processor Declined.":
        "Declined, your bank is unwilling to accept the transaction, please contact your bank",
      "Invalid Authorization Code.":
        "The authorization code was not found or not provided",
      "Declined – Call For Approval.":
        "Declined, The card used for this transaction requires customer approval, please contact your bank",
      "Declined – Call Issuer.":
        "Declined, The card-issuing bank has declined this transaction. Have the customer attempt the transaction again. If persist, please contact your bank",
      "Declined.":
        "Declined, your bank is unwilling to accept the transaction, please contact your bank",
      "Invalid Credit Plan.": "Invalid Credit Plan",
      "Credit Card Number does not match method of payment.":
        "Credit Card Number does not match method of payment",
      "Card reported as lost or stolen.": "Card reported as lost or stolen",
      "Address Verification Failed.":
        "PayPal was unable to verify that the transaction qualifies for Seller Protection because the address was improperly formatted",
      "Address Verification and Card Security Code Failed.":
        "Both the AVS and CVV checks failed for this transaction",
      "PayPal Business Account preference resulted in the transaction failing.":
        "PayPal Business Account preference resulted in the transaction failing",
      "PayPal Business Account Restricted.":
        "PayPal business account restricted, please contact PayPal support team",
      "Authorization Expired.": "The PayPal authorization is no longer valid",
      "PayPal Business Account Locked or Closed.":
        "PayPal Business Account Locked or Closed, please contact PayPal support team",
      "PayPal Buyer Revoked Pre-Approved Payment Authorization.":
        "PayPal buyer revoked pre-approved payment authorization",
      "PayPal Payee Account Invalid Or Does Not Have a Confirmed Email.":
        "PayPal payee account is invalid or does not have a confirmed email",
      "PayPal Payee Email Incorrectly Formatted.":
        "PayPal payee email incorrectly formatted",
      "PayPal Validation Error.":
        "PayPal can't validate this transaction, please contact PayPal support team",
      "Funding Instrument In The PayPal Account Was Declined By The Processor Or Bank, Or It Can't Be Used For This Payment.":
        "PayPal account was declined, please contact PayPal support team",
      "Payer Account Is Locked Or Closed.":
        "PayPal account can't be used for transactions at this time, please retry later or contact PayPal support team",
      "PayPal Transaction Limit Exceeded.": "PayPal Transaction Limit Exceeded",
      "PayPal payment method is invalid.":
        "PayPal payment method has either expired or been canceled",
      "PayPal authorization amount limit exceeded.":
        "PayPal authorization amount limit exceeded",

      "Credit card type is not accepted by this merchant account.":
        "Credit card type is not accepted by this merchant account.",
      "CVV is required.": "CVV is required.",
      "CVV must be 4 digits for American Express and 3 digits for other card types.":
        "CVV must be 4 digits for American Express and 3 digits for other card types.",
      "Expiration date is required.": "Expiration date is required.",
      "Expiration date is invalid.": "Expiration date is invalid.",
      "Expiration year is invalid. It must be between 1975 and 2201.":
        "Expiration year is invalid.",
      "Expiration month is invalid.": "Expiration month is invalid.",
      "Expiration year is invalid.": "Expiration year is invalid.",
      "Credit card number is required.": "Credit card number is required.",
      "Credit card number is invalid.": "Credit card number is invalid.",
      "Credit card number must be 12-19 digits.":
        "Credit card number must be 12-19 digits.",
      "Credit card number is not an accepted test number.":
        "Credit card number is not an accepted test number.",
      "Duplicate card exists in the vault.":
        "Duplicate card exists in the vault.",
      "CVV verification failed.": "CVV verification failed.",
      "Credit card number is prohibited.": "Credit card number is prohibited.",
      "Required attribute is missing": "Required attribute is missing",
      "Incomplete PayPal account information.":
        "Incomplete PayPal account information.",
      "Invalid PayPal account information.":
        "Invalid PayPal account information.",
      "Pre-Approved Payment enabled PayPal account required for vaulting.":
        "Pre-Approved Payment enabled PayPal account required for vaulting.",
      "Error communicating with PayPal.": "Error communicating with PayPal.",
      "PayPal authentication expired.": "PayPal authentication expired.",
      "Amount is required.": "Amount is required.",
      "Attribute is not in the required format":
        "Attribute is not in the required format",
    },
  },
  PaymentSuccess: {
    contactName: "Receiver in Cuba",
    deliveryAddress: "Delivery address",
    pickUpAddress: "Pick up address",
    telephone: "Telephone",
  },
  Profile: {
    personalData: "Personal Data",
    favoriteAddresses: "My Addresses",
    logout: "Logout",
    cancel: "Cancel",
    save: "Save",
    fullname: "Full name",
    change: "Change",
    email: "Email",
    passwordMustMatch: "Password must match",
    mainPhone: "Main Phone",
  },
  UserAddress: {
    contactName: "Name of the Receiver in Cuba",
    contactNamePlaceholder: "Who will receive the order",
    telephoneFieldLabel: "Telephone",
    editingAddress: "Edit delivery address",
    deliveryAddress: "New delivery address",
    myUsualAddresses: "My Usual Addresses",
    addNew: "Add New",
    noAddressesFound: "No addresses to show",
    tag: "Tag",
    tagHint: "Specify your address (home, work,…)",
    details: "Address",
    mainStreet: "Main Street",
    typeToSearch: "Type to search...",
    typeToSearchHint:
      "Include your street name and number; also your location or neighborhood ",
    betweenStreet: "Between Street",
    andStreet: "And Street",
    building: "Home or Building Number",
    stairs: "Stair",
    number: "Apartment",
    province: "Province",
    municipality: "Municipality",
    location: "Neighborhood",
    additionalIndications: "Additional indications",
    additionalIndicationsHint:
      "Please, add any additional indications that may help the delivery drivers to find your address.",
    storeAddressInMyUsualAddresses: "Save this address in my usual directions.",
    requiredFields: "Required Fields",
    save: "Save",
    confirm: "Confirm",
    loading: "Loading",
    noMatchFound: "No matches found",
    noMatchesFoundFor: "No matches found for",
    tryAnotherSearchCriteria: "try another search criteria",
    userAddressFormBtnCancel: "Cancel",
    userAddressFormBtnSave: "Save",
    userAddressFormBtnConfirm: "Confirm",
  },
  PurchaseHistory: {
    title: "Purchase History",
    description:
      "Click on the order you want to repeat and the dishes, quantities and prices will automatically be loaded into the cart.\n Before paying, check that all the information is correct and the order will be processed immediately.",
    contactName: "Name",
    mostRecentlyFirst: "Most recent first",
    oldestFirst: "Oldest first",
    resumeRequest: "Resume request",
    total: "Total",
    purchaseAgain: "Purchase again",
    noPurchasesToShow: "No purchases to show",
    orderId: "Order:",
    deliveryAddress: "Delivery address",
    itemBy: " item By ",
    itemsBy: " items By ",
    more: "Show more",
    deliveredTo: "Delivered to:",
    date: "Date:",
    created: "Created",
    delivered: "Delivered",
    status: "Status:",
    resendOrder: "Resend order",
    fullOrderDialogTitle: "This is the complete summary of your order",
    orderPayType: "Order paid with:",
    deliveryDate: "Delivery Date",
  },
  CardManagement: {
    cardListTitle: "My credit cards",
    cardsNoFound: "Credit cards no found",
    addCardFormTitle: "Add credit card",
    editCardFormTitle: "Update card info",
    buttonAddLabel: "Save credit card",
  },
  Offer: {
    additionsTitle: "Additions",
    additionsActionAdd: "Add",
    variantTitle: "Variants",
    variantTitleNote: "Select one option",
  },
  OfferItem: {
    soldOut: "Sold out",
    maximumOfferOnStock:
      "You have added the maximum number of offers available.",
  },
  Footer: {
    support: "Support",
    frequentQuestions: "Frequent questions",
    testimonials: "Testimonials",
    help: "Help",
    legal: "Legal",
    privacy: "Privacy",
    termsOfUse: "Terms of use",
    privacyPolicy: "Privacy Policy",
    cookies: "Cookies",
    contact: "Contact",
    copyright: "Tamtos",
    termsAndConditions: "Terms and Conditions",
    joinTheTeam: "Join your business",
  },
  Banner: {
    text: "Due to Tropical Storm Laura, as of August 24 at 6PM the food delivery service will be affected. We apologize for any inconvenience.",
  },
  AvailabilityValidation: {
    alertTitle: "Attention!",
    alertSubtitle:
      "The following offers are not available and will be removed from the shopping cart:",
    offerMessage:
      "The following offers are not available and will be removed from the shopping cart:",
    offerNotAvailable:
      "The following products are not available and will be removed from the shopping cart:",
    offerInsufficientStock:
      "The requested quantity of the following products is not available, they will be removed from the shopping cart:",
    variantNotAvailable:
      "The following product specifications are not available:",
    additionsNotAvailable: "The following product add-ons are not available:",
    offerWithVariantsNotAvailable:
      "The following products will be removed from the shopping cart for containing specifications that are not available:",
    offerWithAdditionsNotAvailable:
      "The following products will be removed from the shopping cart for containing add-ons that are not available:",
    offerAddsMessage:
      "The following extras are not available. The foods that contain them will be removed from the shopping cart:",
    restaurantMessage:
      "The chosen restaurant is currently sold out, please choose another option. Sorry for the inconvenience.",
  },
  CustomerSatisfaction: {
    subtitle: "Your experience is very valuable to other users. Thanks a lot!",
    labelInputScore: "Your overall rating for this restaurant",
    labelInputScoreTag: "Click to rate",
    labelInputOpinion: "Your opinion helps us provide a better service",
    send: "Send",
    goBack: "Go Back",
    completeRatingMessage: "Your rating has been recorded",
    thanksMessage: "Thank you!",
    errorMessageTitle: "An error has occurred",
    errorMessage: "It is not possible to carry out the valuation of the order.",
  },
  Cart: {
    restaurantValidationMessage:
      "You have selected an offer from a different business than the existing offers in the shopping cart. This action will remove the previous selections. Do you wish to continue?",
    subtotal: "Subtotal",
    shipping: "Shipping",
    serviceTax: "Service Tax",
    total: "Total",
    completeRequest: "Complete request",
    myRequest: "My Request",
    serviceTaxInfo:
      "'Service Tax' is the comission for Tamtos, so this restaurant platforms can exists. It includes order processing and payments, support and maintenance",
  },
} as Translation;
