import React, { useEffect } from "react";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";
import { useDispatch } from "react-redux";
import { sendToCheckAvailability } from "../availabilityValidation/store/availability-validation.actions";

export function TermsAndConditions() {
  const dispatch = useDispatch();
  // const translations = useTranslations();

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(setLoadingMaskStatus(false));
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wrap container-fluid" style={{ textAlign: 'left' }}>
      <h1 style={{ textAlign: 'center' }}> Términos y Condiciones de Plataforma – Tamtos </h1>
      <p>
        Este sitio web es operado por RD Corporation Development Inc. t/a Tamtos. En todo el sitio, los términos «nosotros», «nos» y «nuestro» se refieren a Tamtos. Tamtos ofrece este sitio web, que incluye toda la información, herramientas y servicios disponibles en este sitio para usted, el usuario, condicionado a su aceptación de todos los términos, condiciones, políticas y avisos aquí establecidos.
      </p>
      <p>
        Al visitar nuestro sitio y / o comprar algo de nosotros, usted participa en nuestro «Servicio» y acepta estar sujeto a los siguientes términos y condiciones («Términos de servicio», «Términos»), incluidos los términos y condiciones y políticas adicionales referenciado aquí y / o disponible por hipervínculo. Estos Términos de servicio se aplican a todos los usuarios del sitio, incluidos, entre otros, los usuarios que son navegadores, proveedores, clientes, comerciantes y / o contribuyentes de contenido.
      </p>
      <p>
      </p>
      <p>
        Lea estos Términos de servicio detenidamente antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio, usted acepta estar sujeto a estos Términos de Servicio. Si no está de acuerdo con todos los términos y condiciones de este acuerdo, no podrá acceder al sitio web ni utilizar ningún servicio. Si estos Términos de servicio se consideran una oferta, la aceptación se limita expresamente a estos Términos de servicio.
      </p>
      <p>
        Cualquier nueva característica o herramienta que se agregue a la tienda actual también estará sujeta a los Términos de Servicio. Puede revisar la versión más reciente de los Términos de servicio en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de estos Términos de servicio mediante la publicación de actualizaciones y / o cambios en nuestro sitio web. Es su responsabilidad revisar esta página periódicamente para ver los cambios. Su uso continuo o acceso al sitio web después de la publicación de cualquier cambio constituye la aceptación de dichos cambios.
      </p>
      <p>
        Tamtos es una tienda en línea que posibilita la entrega de alimentos y diversos productos a sus familiares y amigos. Usted puede acceder a través de algún medio tecnológico como pc, tableta o móvil.
      </p>
      <p>
        Si algún producto incluido en alguna orden no estuviera en existencia en ese momento, nos comunicaremos con usted para establecer acuerdos con el objetivo de incrementar la cantidad de algún producto o incluir otros. También pudiera ajustarse la solicitud y se devolvería el monto correspondiente al producto faltante.
      </p>
      <p>
        Tamtos se compromete a entregar productos frescos y en buen estado, en todo momento
      </p>
      <p>
        Si usted está de acuerdo con estos términos y condiciones, puede utilizar nuestros servicios.
      </p>
      <p>
        Este sitio se reserva el derecho de renovar, modificar o enmendar estos términos y condiciones que usted podrá conocer si se mantiene informado.
      </p>

      <h2> SECCIÓN 1 – TÉRMINOS DE LA TIENDA ONLINE </h2>
      <p>Al aceptar estos Términos de servicio, usted declara que tiene al menos la mayoría de edad en su estado o provincia de residencia, o que tiene la mayoría de edad en su estado o provincia de residencia y nos ha dado su consentimiento para permita que cualquiera de sus dependientes menores use este sitio.
        No puede usar nuestros productos para ningún propósito ilegal o no autorizado ni puede, en el uso del Servicio, violar ninguna ley en su jurisdicción (incluidas, entre otras, las leyes de derechos de autor).
        No debe transmitir gusanos, virus o códigos de naturaleza destructiva.
        Una violación o violación de cualquiera de los Términos resultará en la terminación inmediata de sus Servicios
      </p>

      <h2> SECCIÓN 2 – CONDICIONES GENERALES </h2>
      <p>
        Nos reservamos el derecho de rechazar el servicio a cualquier persona por cualquier motivo en cualquier momento.
        Usted comprende que su contenido (sin incluir la información de la tarjeta de crédito) puede transferirse sin cifrar e implicar (a) transmisiones a través de varias redes; y (b) cambios para cumplir y adaptarse a los requisitos técnicos de conexión de redes o dispositivos. La información de la tarjeta de crédito siempre se cifra durante la transferencia a través de redes.
        Usted acepta no reproducir, duplicar, copiar, vender, revender o explotar ninguna parte del Servicio, el uso del Servicio o el acceso al Servicio o cualquier contacto en el sitio web a través del cual se presta el servicio, sin nuestro permiso expreso por escrito. .
        Los títulos utilizados en este acuerdo se incluyen solo por conveniencia y no limitarán ni afectarán estos Términos.
      </p>

      <h2>SECCIÓN 3 – PRECISIÓN, Y ACTUALIDAD DE LA INFORMACIÓN</h2>
      <p>No somos responsables si la información disponible en este sitio no es precisa, completa o actual. El material en este sitio se proporciona solo para información general y no debe utilizarse ni utilizarse como la única base para tomar decisiones sin consultar fuentes de información primarias, más precisas, más completas o más oportunas. Cualquier dependencia del material en este sitio es bajo su propio riesgo.
        Este sitio puede contener cierta información histórica. La información histórica, necesariamente, no es actual y se proporciona solo para su referencia. Nos reservamos el derecho de modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligación de actualizar ninguna información en nuestro sitio. Usted acepta que es su responsabilidad monitorear los cambios en nuestro sitio.
      </p>

      <h2>SECCIÓN 4 – MODIFICACIONES AL SERVICIO Y PRECIOS</h2>
      <p>Los precios de nuestros productos están sujetos a cambios sin previo aviso.
        Nos reservamos el derecho en cualquier momento de modificar o descontinuar el Servicio (o cualquier parte o contenido del mismo) sin previo aviso en cualquier momento.
        No seremos responsables ante usted ni ante ningún tercero por cualquier modificación, cambio de precio, suspensión o interrupción del Servicio.
      </p>

      <h2>SECCIÓN 5 – PRODUCTOS O SERVICIOS (si corresponde)</h2>
      <p>Ciertos productos o servicios pueden estar disponibles exclusivamente en línea a través del sitio web. Estos productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio solo de acuerdo con nuestra Política de devoluciones.
        Hemos hecho todo lo posible para mostrar con la mayor precisión posible los colores e imágenes de nuestros productos que aparecen en la tienda. No podemos garantizar que la pantalla del monitor de su computadora de ningún color sea precisa.
        Nos reservamos el derecho, pero no estamos obligados, de limitar las ventas de nuestros productos o servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho caso por caso. Nos reservamos el derecho de limitar las cantidades de cualquier producto o servicio que ofrecemos. Todas las descripciones de productos o precios de los productos están sujetas a cambios en cualquier momento sin previo aviso, a nuestro exclusivo criterio. Nos reservamos el derecho de descontinuar cualquier producto en cualquier momento. Cualquier oferta para cualquier producto o servicio realizado en este sitio es nula donde esté prohibida.
        No garantizamos que la calidad de los productos, servicios, información u otro material comprado u obtenido por usted cumpla con sus expectativas, o que se corrija cualquier error en el Servicio.
      </p>

      <h2>SECCIÓN 6 – PRECISIÓN DE LA INFORMACIÓN DE FACTURACIÓN Y CUENTA</h2>
      <p>Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros. Podemos, a nuestro exclusivo criterio, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido. Estas restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, la misma tarjeta de crédito y / o pedidos que utilizan la misma dirección de facturación y / o envío. En el caso de que hagamos un cambio o cancelemos un pedido, podemos intentar notificárselo contactando el correo electrónico y / o la dirección de facturación / número de teléfono proporcionado en el momento en que se realizó el pedido. Nos reservamos el derecho de limitar o prohibir pedidos que, a nuestro exclusivo criterio, parecen ser realizados por distribuidores, revendedores o distribuidores.
        Usted acepta proporcionar información de compra y cuenta actual, completa y precisa para todas las compras realizadas en nuestra tienda. Usted acepta actualizar rápidamente su cuenta y otra información, incluida su dirección de correo electrónico y números de tarjeta de crédito y fechas de vencimiento, para que podamos completar sus transacciones y contactarlo según sea necesario.
        Para obtener más detalles, consulte nuestra Política de devoluciones.
      </p>

      <h2>SECCIÓN 7 – HERRAMIENTAS OPCIONALES</h2>
      <p>Podemos proporcionarle acceso a herramientas de terceros sobre las cuales no supervisamos ni tenemos control ni aportes.
        Usted reconoce y acepta que proporcionamos acceso a dichas herramientas «tal cual» y «según esté disponible» sin garantías, representaciones o condiciones de ningún tipo y sin ningún tipo de respaldo. No tendremos ninguna responsabilidad derivada o relacionada con su uso de herramientas de terceros opcionales.
        Cualquier uso que usted haga de las herramientas opcionales ofrecidas a través del sitio es bajo su propio riesgo y discreción, y debe asegurarse de estar familiarizado y aprobar los términos en los que las herramientas son proporcionadas por los proveedores externos relevantes.
        También podemos, en el futuro, ofrecer nuevos servicios y / o características a través del sitio web (incluido el lanzamiento de nuevas herramientas y recursos). Tales nuevas características y / o servicios también estarán sujetos a estos Términos de Servicio.
      </p>

      <h2>SECCIÓN 8 – ENLACES DE TERCEROS</h2>
      <p>
        Ciertos contenidos, productos y servicios disponibles a través de nuestro Servicio pueden incluir materiales de terceros.
        Los enlaces de terceros en este sitio pueden dirigirlo a sitios web de terceros que no están afiliados a nosotros. No somos responsables de examinar o evaluar el contenido o la precisión y no garantizamos ni tendremos ninguna responsabilidad por los materiales o sitios web de terceros, ni por ningún otro material, producto o servicio de terceros.
        No somos responsables de ningún daño o perjuicio relacionado con la compra o uso de bienes, servicios, recursos, contenido o cualquier otra transacción realizada en relación con sitios web de terceros. Revise detenidamente las políticas y prácticas de terceros y asegúrese de comprenderlas antes de realizar cualquier transacción. Las quejas, reclamos, inquietudes o preguntas sobre productos de terceros deben dirigirse a terceros.
      </p>

      <h2>SECCIÓN 9: COMENTARIOS DEL USUARIO, COMENTARIOS Y OTRAS COMUNICACIONES</h2>
      <p>
        Si, a solicitud nuestra, usted envía ciertas presentaciones específicas(por ejemplo, participaciones en concursos) o sin una solicitud de nuestra parte, envía ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea en línea, por correo electrónico, por correo postal o de otra manera(colectivamente, «comentarios»), usted acepta que podemos, en cualquier momento, sin restricciones, editar, copiar, publicar, distribuir, traducir y de otra manera utilizar en cualquier medio cualquier comentario que nos envíe.No estamos y no tendremos ninguna obligación(1) de mantener ningún comentario confidencial; (2) pagar una compensación por cualquier comentario; o(3) para responder a cualquier comentario.
        Podemos, pero no tenemos la obligación de, monitorear, editar o eliminar contenido que, a nuestro exclusivo criterio, determinemos que es ilegal, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno u objetable o viola la propiedad intelectual de cualquiera de las partes o estos Términos de servicio.
        Usted acepta que sus comentarios no violarán ningún derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otro derecho personal o de propiedad.Además, acepta que sus comentarios no contendrán material difamatorio o ilegal, abusivo u obsceno, ni contendrán virus informáticos u otro malware que pueda afectar el funcionamiento del Servicio o cualquier sitio web relacionado.No puede utilizar una dirección de correo electrónico falsa, pretender ser otra persona que no sea usted mismo, o engañarnos a nosotros o a terceros en cuanto al origen de cualquier comentario.Usted es el único responsable de cualquier comentario que haga y de su precisión.No asumimos ninguna responsabilidad y no asumimos ninguna responsabilidad por los comentarios publicados por usted o cualquier tercero.
      </p >

      <h2>SECCIÓN 10 – INFORMACIÓN PERSONAL</h2>
      <p>
        Su envío de información personal a través de la tienda se rige por nuestra Política de privacidad.Para ver nuestra Política de privacidad.
      </p >

      <h2>SECCIÓN 11 – ERRORES, INEXACTITUDES Y OMISIONES</h2>
      <p>Ocasionalmente puede haber información en nuestro sitio o en el Servicio que contiene errores tipográficos, imprecisiones u omisiones que pueden estar relacionadas con descripciones de productos, precios, promociones, ofertas, cargos de envío de productos, tiempos de tránsito y disponibilidad.Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión, y de cambiar o actualizar información o cancelar pedidos si alguna información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin previo aviso(incluso después de haber enviado su pedido).
        No asumimos la obligación de actualizar, modificar o aclarar información en el Servicio o en cualquier sitio web relacionado, incluida, entre otras, información de precios, excepto según lo exija la ley.Ninguna fecha de actualización o actualización especificada aplicada en el Servicio o en cualquier sitio web relacionado, se debe tomar para indicar que toda la información en el Servicio o en cualquier sitio web relacionado se ha modificado o actualizado.
      </p >

      <h2>SECCIÓN 12 – USOS PROHIBIDOS</h2>
      <p>
        Además de otras prohibiciones establecidas en los Términos de servicio, tiene prohibido utilizar el sitio o su contenido: (a) para cualquier propósito ilegal; (b) para solicitar a otros que realicen o participen en actos ilegales; (c) violar cualquier regulación, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de terceros; (e) acosar, abusar, insultar, dañar, difamar, calumniar, menospreciar, intimidar o discriminar por motivos de género, orientación sexual, religión, origen étnico, raza, edad, origen nacional o discapacidad; (f) presentar información falsa o engañosa; (g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que se utilizará o podrá utilizarse de cualquier manera que afecte la funcionalidad u operación del Servicio o de cualquier sitio web relacionado, otros sitios web o Internet; (h) para recopilar o rastrear la información personal de otros; (i) a spam, phishing, pharm, pretexto, araña, rastreo o raspado; (j) para cualquier propósito obsceno o inmoral; o(k) interferir o eludir las características de seguridad del Servicio o cualquier sitio web relacionado, otros sitios web o Internet.Nos reservamos el derecho de terminar su uso del Servicio o cualquier sitio web relacionado por violar cualquiera de los usos prohibidos.
      </p >

      <h2>SECCIÓN 13 – DESCARGO DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD</h2>
      <p>
        No garantizamos, representamos ni garantizamos que su uso de nuestro servicio será ininterrumpido, oportuno, seguro o sin errores.
        No garantizamos que los resultados que se puedan obtener del uso del servicio sean precisos o confiables.
        Usted acepta que de vez en cuando podemos eliminar el servicio por períodos indefinidos o cancelar el servicio en cualquier momento, sin notificárselo.
        Usted acepta expresamente que el uso o la imposibilidad de usar el servicio es bajo su propio riesgo.El servicio y todos los productos y servicios entregados a usted a través del servicio se proporcionan(excepto según lo expresamente indicado por nosotros) «tal cual» y «según esté disponible» para su uso, sin ninguna representación, garantía o condición de ningún tipo, ya sea expresa o implícito, incluidas todas las garantías implícitas o condiciones de comerciabilidad, calidad comercializable, idoneidad para un propósito particular, durabilidad, título y no infracción.
        En ningún caso, Tamtos, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, pasantes, proveedores, proveedores de servicios o licenciantes serán responsables de ninguna lesión, pérdida, reclamo o cualquier daño directo, indirecto, incidental, punitivo, especial o daños consecuentes de cualquier tipo, incluidos, entre otros, pérdida de ganancias, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo o cualquier daño similar, ya sea por contrato, agravio(incluida negligencia), responsabilidad estricta o de otro tipo, derivados de su uso de cualquiera de los servicios o productos adquiridos utilizando el servicio, o para cualquier otro reclamo relacionado de alguna manera con su uso del servicio o cualquier producto, incluidos, entre otros, cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurrido como resultado del uso del servicio o cualquier contenido(o producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se le informa de su posibilidad.Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuentes o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitará en la medida máxima permitida por la ley.
      </p >

      <h2>SECCIÓN 14 – INDEMNIZACIÓN</h2>
      <p>
        Usted acepta indemnizar, defender y eximir de responsabilidad a Tamtos y a nuestras empresas matrices, subsidiarias, afiliadas, socios, funcionarios, directores, agentes, contratistas, otorgantes de licencias, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, indemnes de cualquier reclamo o demanda, incluso razonable Honorarios de abogados, realizados por cualquier tercero debido a su incumplimiento de estos Términos de servicio o los documentos que incorporan por referencia, o su violación de cualquier ley o los derechos de un tercero.
      </p>

      <h2>SECCIÓN 15 – SEPARABILIDAD</h2>
      <p>
        En el caso de que se determine que alguna disposición de estos Términos de servicio es ilegal, nula o inaplicable, dicha disposición será aplicable en la mayor medida permitida por la ley aplicable, y la parte no ejecutable se considerará separada de estos Términos de Servicio, dicha determinación no afectará la validez y aplicabilidad de cualquier otra disposición restante.
      </p>

      <h2>SECCIÓN 16 – TERMINACIÓN</h2>
      <p>Las obligaciones y responsabilidades de las partes contraídas antes de la fecha de terminación sobrevivirán a la terminación de este acuerdo a todos los efectos.
        Estos Términos de servicio son efectivos a menos que usted o nosotros los rescindamos. Puede rescindir estos Términos de servicio en cualquier momento notificándonos que ya no desea usar nuestros Servicios o cuando deja de usar nuestro sitio.
        Si a nuestro exclusivo criterio usted falla, o sospechamos que ha fallado, en cumplir con cualquier término o disposición de estos Términos de Servicio, también podemos rescindir este acuerdo en cualquier momento sin previo aviso y usted seguirá siendo responsable de todos los montos adeudados hasta e incluyendo la fecha de terminación; y / o en consecuencia puede negarle el acceso a nuestros Servicios (o cualquier parte de los mismos).
      </p>

      <h2>SECCIÓN 17 – ACUERDO COMPLETO</h2>
      <p>
        El hecho de que no ejercitemos o hagamos cumplir cualquier derecho o disposición de estos Términos de servicio no constituirá una renuncia a dicho derecho o disposición.
        Estos Términos de servicio y cualquier política o regla operativa publicada por nosotros en este sitio o con respecto al Servicio constituye el acuerdo completo y el entendimiento entre usted y nosotros y rigen su uso del Servicio, reemplazando cualquier acuerdo, comunicación y propuesta previa o contemporánea. , ya sea oral o escrito, entre usted y nosotros (incluidas, entre otras, las versiones anteriores de los Términos de servicio).
        Cualquier ambigüedad en la interpretación de estos Términos de servicio no se interpretará en contra de la parte redactora.
      </p>

      <h2>SECCIÓN 18 – LEY APLICABLE</h2>
      <p>
        Estos Términos de servicio y cualquier otro acuerdo por el cual le proporcionemos Servicios se regirán e interpretarán de conformidad con las leyes de Panamá.
      </p>

      <h2>SECCIÓN 19 – CAMBIOS A LOS TÉRMINOS DE SERVICIO</h2>
      <p>
        Puede revisar la versión más reciente de los Términos de servicio en cualquier momento en esta página.
        Nos reservamos el derecho, a nuestra sola discreción, de actualizar, cambiar o reemplazar cualquier parte de estos Términos de servicio mediante la publicación de actualizaciones y cambios en nuestro sitio web.Es su responsabilidad revisar periódicamente nuestro sitio web para ver los cambios.Su uso continuo o acceso a nuestro sitio web o al Servicio después de la publicación de cualquier cambio en estos Términos de servicio constituye la aceptación de dichos cambios.
      </p >

      <h2>SECCIÓN 20 – INFORMACIÓN DE CONTACTO</h2>
      <p>
        Las preguntas sobre los Términos de Servicio deben enviarse a info@tamtos.com
      </p>

      <h2>SECCION 21 - PROBLEMAS CON EL PAGO</h2>
      <p>¿Por qué la página no está aceptando mi pago/tarjeta de crédito?</p>
      <p>Puede haber varias razones por las cuales tus transacciones no se estén procesando. Normalmente, el problema más habitual es que tu banco no nos está permitiendo procesar tu pago/tarjeta. Siempre puedes contactar con nuestro equipo de Atención al Cliente a través de nuestra dirección de correo electrónico info@tamtos.com disponible los 7 días de la semana y estaremos encantados de atenderte y revisar tus transacciones. Alternativamente puedes contactar con tu banco para revisar que todo esté correcto por su parte.</p>
      <p>¿Qué es una retención temporal?</p>
      <p>Cuando compras en Tamtos, es posible que apliquemos una retención de autorización temporal en tu método de pago por el precio total de tu compra. Esto no es un cargo real, suele aparecer como un cargo pendiente y aunque el dinero no haya salido de tu cuenta, tu saldo disponible se habrá reducido temporalmente. Si la transacción es exitosa, esta retención temporal se convertirá en un cargo real, pero si la transacción falla, el dinero volverá a estar disponible en tu cuenta en un plazo de 2 a 7 días hábiles.</p>
      <p>¿Por qué me ha llegado una notificación de mi banco antes de recibir mi compra?</p>
      <p>Algunos bancos envían un mensaje de texto para notificar que se ha realizado una retención temporal en la cuenta del usuario. Esta retención se realiza al pagar por tu compra para asegurarse de que el método de pago funciona correctamente y de que también tiene los fondos suficientes para cubrir el importe total de la compra. Aunque a veces se parecen a cargos reales, no te preocupes, la retención se hará efectiva y el dinero será retirado de tu cuenta solo si tu orden es exitosa. Si tu orden falla, tu banco cancelará la retención en unos días y no se te cobrará.</p>
      <p>Mi pago fue rechazado</p>
      <p>Tanto los bancos pueden rechazar tu transacción debido a diferentes razones. A continuación, puedes encontrar las más comunes:</p>
      <ul>
        <li>- Los datos de la tarjeta introducidos durante el pago pueden ser incorrectos.</li>
        <li>- No hay suficientes fondos disponibles en tu cuenta.</li>
        <li>- Es posible que se haya alcanzado el límite máximo de crédito.</li>
        <li>- Tu banco está solicitando autenticar tu pago, y esto no se ha completado correctamente.</li>
      </ul>
      <p>¿Qué puedo hacer?</p>
      <p>Puedes volver a intentarlo seleccionando un nuevo método de pago o puedes ponerte en contacto con tu banco para obtener más detalles sobre por qué han declinado tu pago.</p>
      <p>¿Me vais a cobrar?</p>
      <p>Como no se pudo procesar tu transacción, no te cobraremos. Es posible que veas una autorización en tu extracto bancario, pero el dinero no saldrá de tu cuenta. Este proceso puede demorar se entre 2 y 5 días hábiles.</p>
      <p>¿Dónde se almacenan los datos de mi tarjeta?</p>
      <p>Por razones de seguridad nosotros no procesamos los pagos directamente. En su lugar, utilizamos el más avanzado proveedor para pagos a través de la Red. Éste se encarga de almacenar tus datos cuando así lo indiques, siendo ésta es la manera más segura para garantizar que tus datos están protegidos.</p>

      <h2>SECCION 22 - PREGUNTAS FRECUENTES SOBRE PAGOS ELECTRÓNICOS</h2>
      <p>En este documento, usted encontrará una sección de preguntas y respuestas que le ayudará a sus usuarios a aclarar sus dudas sobre los pagos electrónicos procesados a través de Placetopay. Recuerde que debe incluirlas en su sitio web.</p>
      
      <h3>1.  ¿Qué es Placetopay?</h3>
      <p>Placetopay es la plataforma de pagos electrónicos que usa Tamtos para procesar en línea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.</p>
      
      <h3>2.   ¿Cómo puedo pagar?</h3>
      <p>En la tienda virtual de Tamtos usted podrá realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo a las opciones de pago escogidas por el comercio, podrá pagar a través de tarjetas de crédito Visa y MasterCard.</p>
 
      <h3>3.  ¿Es seguro ingresar mis datos bancarios en este sitio web?</h3>
      <p>Para proteger tus datos Tamtos delega en Placetopay la captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera te podrás sentir seguro a la hora de ingresar la información de su tarjeta.</p>
      
      <p>Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde. Estas características son visibles de inmediato y dan garantía y confianza para completar la transacción en Placetopay.</p>
 
      <p>Placetopay también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.</p>
 
      <h3>4.  ¿Puedo realizar el pago cualquier día y a cualquier hora?</h3>
      <p>Sí, en Tamtos podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.</p>
 
      <h3>5.  ¿Puedo cambiar la forma de pago?</h3>
      <p>Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago.</p>
 
      <h3>6.  ¿Pagar electrónicamente tiene algún valor para mí como comprador?</h3>
      <p>No, los pagos electrónicos realizados a través de Placetopay no generan costos adicionales para el comprador.</p>
 
      <h3>7.  ¿Qué debo hacer si mi transacción no concluyó?</h3>
      <p>En primera instancia, revisar si llegó un email de confirmación de la transacción a la cuenta de correo electrónico inscrita en el momento de realizar el pago, en caso de no haberlo recibido, deberás contactar a info@tamtos.com para confirmar el estado de la transacción.</p>
 
      <h3>8.  ¿Qué debo hacer si no recibí el comprobante de pago?</h3>
      <p>Por cada transacción aprobada a través de Placetopay, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar.</p>
 
      <p>Si no lo recibes, podrás contactar al Servicio de Atención al Cliente de Tamtos a través de correo electrónico info@tamtos.com, para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar.</p>
    </div >
  );
}