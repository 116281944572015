import { createSelector } from "reselect";
import * as cart_actions from "./cart.actions";
import * as _ from "lodash";

const INITIAL_STATE = {
  bd_cart: null,
  restaurant: null,
  businessCategory: null,
  subtotal: 0,
  total: 0,
  shipping: 0,
  taxes: {
    amount: 0,
  },
  discounts: 0,
  bonus: [],
  talon_total_discounts: null,
  talon_shipping_cost_discounts: null,
  talon_service_fee_discounts: null,
  coupon: null,
  clean_coupon: false,
  invalid_coupon: false,
  items: [],
  temp_offer: null,
};

const calcShippingDays = (items) => {
  let deliveryDays = 1;
  let canteenOffers = _.filter(items, function (offer) {
    return offer.classification && offer.classification.id === 10;
  });
  if (canteenOffers && canteenOffers.length > 0) {
    _.forEach(canteenOffers, function (obj) {
      if (obj.deliveryDays > deliveryDays) {
        deliveryDays = obj.deliveryDays;
      }
    });
  }
  return deliveryDays;
};

const calcSubtotal = (items) =>
  items && items.length > 0
    ? items.map((item) => item.total).reduce((prev, next) => prev + next)
    : 0;

const calcTotal = (subtotalValue, shippingValue, taxesValue, discountsValue, talon_total_discounts) => {
  let result = subtotalValue + shippingValue + taxesValue;

  if (talon_total_discounts && talon_total_discounts.length > 0) {
    _.forEach(talon_total_discounts, function (discount) {
      if (result > discount.value) {
        result = result - discount.value;
      } else {
        result = 0;
      }
    });
  }
  if (result > 0 && discountsValue > 0) {
    if (result > discountsValue) {
      result = result - discountsValue;
    } else {
      result = 0;
    }
  }
  return result.toFixed(2);
};

const calcDiscountValue = (subtotalValue, shippingValue, taxesValue, discountsValue, talon_total_discounts) => {
  let total = subtotalValue + shippingValue + taxesValue;
  if (talon_total_discounts && talon_total_discounts.length > 0) {
    _.forEach(talon_total_discounts, function (discount) {
      if (total > discount.value) {
        total = total - discount.value;
      } else {
        total = 0;
      }
    });
  }
  if (total > discountsValue) {
    return discountsValue;
  } else {
    return total;
  }
};

const getItemsTotal = (item, count) => {
  const additions =
    item.additionsSelected && item.additionsSelected.length > 0
      ? item.additionsSelected
        .map((item) => item.price)
        .reduce((prev, current) => prev + current)
      : 0;
  const variants =
    item.variantSelected && item.variantSelected.length > 0
      ? item.variantSelected
        .map((item) => item.price)
        .reduce((prev, current) => prev + current)
      : 0;
  const rawTotal = (item.price + additions + variants) * count;
  return Math.round((rawTotal + Number.EPSILON) * 100) / 100;
};

export default function cart(state = INITIAL_STATE, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case cart_actions.AVAILABILITY_ERROR:{
      return {
        ...state,
        bd_cart: action?.response?.data?.result,
      };
    }
    case cart_actions.CART_LOADED_SUCCESS:{
      return {
        ...state,
        bd_cart: action?.response?.data?.result,
      };
    }
    case cart_actions.CART_LOADED_FAILURE:{
      return {
        ...state,
        bd_cart: null,
        restaurant: null,
      };
    }
    case cart_actions.SET_BUSINESS_IN_CART:{
      return {
        ...state,
        restaurant: action?.response,
      };
    }
    case cart_actions.ADD_OFFER_SUCCESS:{
      return {
        ...state,
        bd_cart: action?.response?.data?.result,
      };
    }
    case cart_actions.ADD_OFFER_FAILURE:{
      return {
        ...state,
      }
    }
    case cart_actions.UPDATE_OFFER_SUCCESS:{
      return {
        ...state,
        bd_cart: action?.response?.data?.result,
      }
    }
    case cart_actions.UPDATE_OFFER_FAILURE:{
      return {
        ...state,
      }
    }
    case cart_actions.DELETE_OFFER_SUCCESS:{
      return {
        ...state,
        bd_cart: action?.response?.data?.result,
      }
    }
    case cart_actions.DELETE_OFFER_FAILURE:{
      return {
        ...state,
      }
    }
    case cart_actions.CLEAN_CART: {
      return { ...INITIAL_STATE };
    }
    case cart_actions.CLEAN_EXTRA_PAYMENTS: {
      let { items } = state;
      const shipping = 0,
        taxes = { amount: 0 },
        discounts = 0,
        coupon = null,
        bonus = null;
      const subtotal = calcSubtotal(items);
      const total = calcTotal(
        subtotal,
        shipping,
        taxes.amount,
        discounts,
        0
      );
      return {
        ...state,
        shipping,
        taxes,
        discounts,
        coupon,
        bonus,
        subtotal,
        total,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.CLEAN_DISCOUNT_AMOUNT: {
      let { discounts, coupon } = INITIAL_STATE;
      let { subtotal, taxes, shipping, talon_total_discounts } = state;
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        discounts,
        coupon,
        total,
        invalid_coupon: false,
        clean_coupon: true,
      };
    }
    case cart_actions.CLEAN_DISCOUNT_BONUS: {
      let { bonus, talon_total_discounts } = INITIAL_STATE;
      let { subtotal, taxes, shipping, coupon } = state;
      let discountsValue = coupon ? coupon.amount : 0;
      let discounts = calcDiscountValue(subtotal, shipping, taxes ? taxes.amount : 0, discountsValue, talon_total_discounts);
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        discounts,
        bonus,
        total,
        invalid_coupon: false,
        clean_coupon: false,
      };
    }
    case cart_actions.SET_DELIVERY_COST: {
      let { subtotal, taxes, coupon, talon_total_discounts, items } = state;
      let deliveryDays = calcShippingDays(items);
      let shipping = action.shipping ? action.shipping * deliveryDays : 0;
      let discountsValue = coupon ? coupon.amount : 0;
      let discounts = calcDiscountValue(subtotal, shipping, taxes ? taxes.amount : 0, discountsValue, talon_total_discounts);
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        discounts,
        shipping,
        total,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.SET_SERVICE_FEE: {
      let { subtotal, coupon, shipping, talon_total_discounts } = state;
      let taxes = action.fee && action.fee.data ? action.fee.data : null;
      let discountsValue = coupon ? coupon.amount : 0;
      let discounts = calcDiscountValue(subtotal, shipping, taxes ? taxes.amount : 0, discountsValue, talon_total_discounts);
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        discounts,
        taxes,
        total,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.BONUS_LOADED_SUCCESS: {
      let { subtotal, taxes, shipping, coupon } = state;
      let talonSessionDto = action.bonus && action.bonus.data ? action.bonus.data.result : null;
      let bonus = talonSessionDto && talonSessionDto.sessionId ? talonSessionDto : null;
      let talon_total_discounts = _.filter(bonus.effects, { 'talonFunctionId': 1 });
      let discountsValue = coupon ? coupon.amount : 0;
      let discounts = calcDiscountValue(subtotal, shipping, taxes ? taxes.amount : 0, discountsValue, talon_total_discounts);
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        bonus,
        talon_total_discounts,
        shipping,
        total,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.COUPON_LOADED_SUCCESS: {
      let { subtotal, shipping, taxes, talon_total_discounts } = state;
      let coupon =
        action.discount && action.discount.data ? action.discount.data : null;
      let discountsValue = coupon ? coupon.amount : 0;
      let discounts = calcDiscountValue(subtotal, shipping, taxes ? taxes.amount : 0, discountsValue, talon_total_discounts);
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        discounts,
        total,
        coupon,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.COUPON_LOADED_FAILURE: {
      let { discounts, coupon } = INITIAL_STATE;
      let { subtotal, taxes, shipping, talon_total_discounts } = state;
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...state,
        discounts,
        total,
        coupon,
        clean_coupon: false,
        invalid_coupon: true,
      };
    }
    case cart_actions.CART_RESET_AND_ADD_ITEM: {
      const { shipping, taxes, discounts, coupon, talon_total_discounts } = INITIAL_STATE;
      const businessCategory = action.businessCategory;
      const items = [
        { ...payload, count: 1, total: getItemsTotal(payload, 1) },
      ];
      const subtotal = calcSubtotal(items);
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);
      return {
        ...INITIAL_STATE,
        restaurant: payload.restaurant,
        items,
        subtotal,
        total,
        coupon,
        discounts,
        businessCategory,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.CART_UPDATE_ITEM_STOCK: {
      let { items } = state;
      _.forEach(items, function (o) {
        if (o.id === payload.id) {
          if (o.offerInfosById && o.offerInfosById.manageStock) {
            o.offerInfosById.stock = payload.stockNumber;
          }
        }
      });
      return {
        ...state,
        items,
      };
    }
    case cart_actions.CART_UPDATE_RESTAURANT: {
      return {
        ...state,
        restaurant: payload,
      };
    }
    case cart_actions.SET_CART_IN_BD_SUCCESS: {
      const x = action?.response?.data?.result;
      return {
        ...state,
        bd_cart: x,
      };
    }
    case cart_actions.CART_ADD_ITEM: {
      let { items, shipping, taxes } = state;
      const { discounts, coupon, talon_total_discounts } = INITIAL_STATE;
      const businessCategory = action.businessCategory;
      if (shipping === 0) {
        shipping = INITIAL_STATE.shipping;
      }
      if (taxes === 0) {
        taxes = INITIAL_STATE.taxes;
      }
      const index = _.findIndex(items, function (o) {
        if (o.id === payload.id) {
          return (
            _.isEqual(o.additionsSelected, payload.additionsSelected) &&
            _.isEqual(o.variantSelected, payload.variantSelected)
          );
        }
      });
      const item = items[index] || payload;
      const count = items[index]
        ? items[index].count + payload.count
        : payload.count;

      const newItem = { ...item, count, total: getItemsTotal(item, count) };
      if (index === -1) {
        items.push(newItem);
      } else {
        items[index] = newItem;
      }
      const subtotal = calcSubtotal(items);
      let totalAmount = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);

      items.sort((a, b) => a.id - b.id);
      return {
        ...state,
        items,
        shipping,
        taxes,
        coupon,
        discounts,
        businessCategory,
        restaurant: payload.restaurant,
        subtotal,
        total: totalAmount,
        temp_offer: null,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    case cart_actions.CART_REMOVE_ITEM: {
      let { items, shipping, taxes, restaurant } = state;
      const { discounts, coupon, talon_total_discounts } = INITIAL_STATE;
      const index = _.findIndex(items, function (o) {
        if (o.id === payload.id) {
          return (
            _.isEqual(o.additionsSelected, payload.additionsSelected) &&
            _.isEqual(o.variantSelected, payload.variantSelected)
          );
        }
      });
      const item = items[index];
      const count = item ? item.count - 1 : 1;
      if (count === 0) {
        // if 0, remove the item
        items.splice(index, 1);
      } else {
        // otherwise just replace it with {..new value..}
        items.splice(index, 1, {
          ...payload,
          count,
          total: getItemsTotal(item, count),
        });
      }
      const subtotal = calcSubtotal(items);
      if (subtotal === 0) {
        shipping = 0;
        taxes = {
          amount: 0,
        };
        restaurant = null;
      }
      let total = calcTotal(subtotal, shipping, taxes ? taxes.amount : 0, discounts, talon_total_discounts);

      return {
        ...state,
        restaurant,
        items,
        subtotal,
        shipping,
        taxes,
        total,
        coupon,
        discounts,
        clean_coupon: false,
        invalid_coupon: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const selectCartState = (state) => state.cart;

export const selectCartItems = createSelector(
  selectCartState,
  (state) => state.items
);

export const selectTempOffer = createSelector(
  selectCartState,
  (state) => state.temp_offer
);

export const selectCartSubtotal = createSelector(
  selectCartState,
  (state) => state.subtotal
);

export const selectCartTotal = createSelector(
  selectCartState,
  (state) => state.total
);

export const selectCoupon = createSelector(
  selectCartState,
  (state) => state.coupon
);

export const selectBonus = createSelector(
  selectCartState,
  (state) => state.bonus
);

export const selectTalonTotalDiscounts = createSelector(
  selectCartState,
  (state) => state.talon_total_discounts
);

export const selectInvalidCoupon = createSelector(
  selectCartState,
  (state) => state.invalid_coupon
);

export const selectCleanCoupon = createSelector(
  selectCartState,
  (state) => state.clean_coupon
);

export const selectCartShipping = createSelector(
  selectCartState,
  (state) => state.shipping
);

export const selectCartTaxes = createSelector(
  selectCartState,
  (state) => state.taxes
);

export const selectCartDiscounts = createSelector(
  selectCartState,
  (state) => state.discounts
);

export const selectCartRestaurant = createSelector(
  selectCartState,
  (state) => state.restaurant
);

export const selectCategoryInCart = createSelector(
  selectCartState,
  (state) => state.businessCategory
);

export const selectBDCart = createSelector(
  selectCartState,
  (state) => state.bd_cart
);