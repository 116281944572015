import { simpleGet } from "core/services/ApiRequest";

const baseUrl = "banners";

export const LOADED_BANNER_MESSAGES = "LOADED_BANNER_MESSAGES";

function loadedBannerMessages(messages) {
  return {
    type: LOADED_BANNER_MESSAGES,
    messages,
  };
}

export function loadBannerMessages() {
  return (dispatch) => {
    simpleGet(`${baseUrl}/current`)
      .then((resp) => resp.data)
      .then((messages) => dispatch(loadedBannerMessages(messages)));
  };
}
