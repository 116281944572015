import {Translation} from "./languages/types";
import en from "./languages/en";
import es from "./languages/es";
import {store} from "../App";

interface Translations {
  [key: string]: Translation
}

const languages: Translations = {
  es,
  en
};

function useTranslation() {
  const language = store.getState().lang.code;
    return languages[language];
}

export default useTranslation;