import { post } from "core/services";

export const LOADING = "SEARCH_BOX_LOADING";
export const LOADED = "SEARCH_BOX_LOADED";
export const CLEAR_STATES = "SEARCH_BOX_CLEAR_STATES";
export const SEARCH_TERM = "SEARCH_BOX_LOADED_FILTERS";
export const SEARCH_SUCCESS = "SEARCH_BOX_SUCCESS";
export const SEARCH_FAIL = "SEARCH_BOX_FAIL";
export const LOAD_FROM_CACHE = "LOAD_FROM_CACHE";

export const loadFromCache = () => ({ type: LOAD_FROM_CACHE });
export const loading = () => ({ type: LOADING });
export const loaded = () => ({ type: LOADED });
export const setTerm = (term) => ({ type: SEARCH_TERM, term });
export const clearSearch = () => ({ type: CLEAR_STATES });
export const searchSuccess = (response) => ({ type: SEARCH_SUCCESS, response });
export const searchFail = (response) => ({ type: SEARCH_FAIL, response });

export function searchList(filters, passiveLoading = false) {
  return (dispatch) => {
    if (!passiveLoading) {
      dispatch(loading());
    }
    post("business/searchList", filters)
      .then((response) => {
        dispatch(searchSuccess(response));
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.message || "ERROR!";
        dispatch(searchFail(errMsg));
      })
      .finally(() => {
        dispatch(loaded());
      });
  };
}
