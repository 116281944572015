export const METHODS = {
  SIMPLE_GET: "SIMPLE_GET",
  SECURE_GET: "SECURE_GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  UPLOAD: "UPLOAD",
};

export const TYPES_HEADERS = {
  SIMPLE: "SIMPLE",
  SECURE: "SECURE",
};

export const REGULAR_EXPRESSIONS = {
  US_PHONE_NUMBER:
    /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/,
  EMAIL:
    /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  SSN: /^\d{3}-\d{2}-\d{4}$/,
};

export const getOrCreateDeviceID = () => {
  const cbrid_key = "__cll_brid";

  let cbrid = localStorage.getItem(cbrid_key);
  if (!cbrid) {
    cbrid = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 32; i++)
      cbrid += possible.charAt(Math.floor(Math.random() * possible.length));

    localStorage.setItem(cbrid_key, cbrid);
  }

  return cbrid;
};

export const isMobile = () => window.innerWidth < 768;

export const removeDuplicates = (list = []) => {
  const uniqueDiscounts = new Set([
    ...list.map((discount) => JSON.stringify(discount)),
  ]);
  return [...uniqueDiscounts].map((discountStr) => JSON.parse(discountStr));
};
