import React, { useEffect, useState } from 'react';
import './css/ResendOrderDialogStyles.scss';
import Dialog from "@material-ui/core/Dialog/Dialog";
import useTranslations from "../../../../translations/useTranslations";
import { DialogActions, Fade } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export const ResendOrderDialog = (props) => {
    const data = props.data;
    const offers = data.offersList;
    const translation = useTranslations();
    const [fullScreenSize, setFullScreenSize] = useState(false);

    useEffect(() => {
        handleFormAddAddressFullScreen();
        window.addEventListener('resize', handleFormAddAddressFullScreen);
        // eslint-disable-next-line
    }, []);

    const handleFormAddAddressFullScreen = () => {
        const width = window.innerWidth;
        const fullScreen = width < 768;
        setFullScreenSize(fullScreen);
    };

    // const editOrder = () => {
    //     fillShopCart();
    //     // props.history.push(`/restaurants/${data.restaurantSlug}`);
    // };

    // const fillShopCart = () => {
    //     _.forEach(offers, function (item) {
    //         let restaurant= {
    //             id: data.restaurantId,
    //             name: data.restaurantName,
    //             slug: data.restaurantSlug,
    //             restaurantAddress: data.restaurantAddress,
    //             restaurantTelephone: data.restaurantTelephone,
    //         }
    //         let cartItem = item.offer;
    //         cartItem.restaurant = restaurant;
    //         cartItem.count = cartItem.count > 0 ? cartItem.count : 1;
    //         cartItem.additionsSelected = cartItem.offerAdds || [];
    //         cartItem.variantSelected = cartItem.offerVariant || [];
    //         dispatch(addItemToCart(cartItem));
    //     });
    //     // dispatch(sendToCheckAvailability());
    //     closeDialog();
    // }

    // const payOrder = () => {
    //     fillShopCart();
    //     // props.history.push(`/restaurants/${data.restaurantSlug}`);
    // };

    const closeDialog = () => {
        props.hide();
    };


    return (
        <Dialog fullScreen={fullScreenSize} fullWidth={true} maxWidth="xs" TransitionComponent={Transition}
            scroll="body" open={props.show} onClose={closeDialog} className="purchase-history-dialog">
            <DialogTitle className="phd-header">
                <div className="phd-logo">
                    <img src={"./images/rd.png"} alt="Cuballama Restaurantes" />
                    <button className="phd-close" onClick={closeDialog}>
                        <Clear component="svg" />
                        <Clear component="svg" />
                    </button>
                </div>
                <div className="phd-title">
                    {translation.PurchaseHistory.resendOrder}
                    <div className="phd-divider-green" />
                    <div className="phd-divider-green" />
                </div>
            </DialogTitle>
            <div className="phd-order-type-icon">
                <img src={"./images/icons/orderType/delivery.png"} alt="Delivery" />
                <img src={"./images/icons/orderType/delivery.png"} alt="Delivery" />
            </div>
            <DialogContent className="phd-body">
                <div className="phd-rest-info">
                    <h3>{data.restaurantName}</h3>
                    <p> {data.deliveryDate} </p>
                </div>
                <h2 className="phd-total-price">
                    {translation.PurchaseHistory.total}
                    <span>{data.totalCost ? "$" + data.totalCost.toFixed(2) : (0).toFixed(2)}</span>
                </h2>
                <div className="phd-offers">
                    <ul>
                        {offers && offers.length > 0 ? offers.map((item, k) => {
                            return (
                                <li key={k}>
                                    <span className="phd-offer-quantity">{item.count}</span>
                                    <div className="phd-offer-detail">
                                        <p className="main">{item.offer.name}</p>
                                        {item.offer.offerVariant && item.offer.offerVariant.length > 0 ?
                                            <p className="secondary">
                                                ({item.offerVariant.map((variant) => `${translation.Code === "ES" ? variant.nameEs : variant.nameEn}`).join(", ")})
                                            </p> : null}
                                        {item.offer.offerAdds && item.offer.offerAdds.length > 0 ?
                                            <p className="secondary">
                                                ({item.offer.offerAdds.map(({ name }) => `${name}`).join(", ")})
                                                ({item.offer.offerAdds.map(({ name }) => `${name}`).join(", ")})
                                            </p> : null}
                                    </div>
                                    <p className="phd-offer-price">
                                        {item.offer.price ? "$" + item.offer.price.toFixed(2) : (0).toFixed(2)}
                                    </p>
                                </li>
                            )
                        }) : null}
                    </ul>
                </div>
                <div className="phd-line" />
                <div className="phd-line" />
            </DialogContent>
            <DialogActions className="phd-footer">
                <button className="button--primary" onClick={() => closeDialog()}>
                    {translation.Payment.modifyRequest}
                </button>
                <button className="button--primary" onClick={() => closeDialog()}>
                    {translation.Cart.completeRequest}
                </button>
                <button className="button--primary" onClick={() => closeDialog()}>
                    {translation.Actions.cancel}
                </button>
            </DialogActions>
        </Dialog>
    )
};