import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setLoadingMaskStatus } from "../../../../component/Mask/store/mask.actions";
import AtomIcon from '../../../../component/Icon/AtomIcon';
import useTranslations from "../../../../translations/useTranslations";
import { setFilters } from "../../../../store/filter/filter.actions";
import { selectFilters } from "../../../../store/filter/filter.reducers";

const BusinessItem = (props) => {
  const data = props.data || {};
  const BC = props.category || null;
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const translation = useTranslations();
  const estimatedDeliveryTime = data.deliveryTimeRestaurantDto || null;

  const viewBusinessDetail = (data) => {
    if (data?.slug && BC?.slug) {
      dispatch(setLoadingMaskStatus(true));
      dispatch(setFilters({
        ...filters,
        categories: [],
        businessCategory: BC
      }));
      props.history.push({ pathname: data.slug });
    }
  };

  return (
    <div className={props.customClass} onClick={() => viewBusinessDetail(data)}>
      <div className="card">
        <div className="card-image-container">
          {data.imgLg ? <img src={data.imgLg} alt={data.name} className="" />
            : <img src={"./images/picture/no-image.png"} alt={data.name} className="" />}
        </div>
        <div className="card-details-container">
          <div className="label-title">
            <h3>{data.name}</h3>
            {data.averageScore && <div className="rating-star">
              <span>{Math.round(data.averageScore * 10) / 10}</span>
              <AtomIcon icon="star--blue" />
            </div>}
          </div>
          <div className="label-content">
            {data?.deliveryCost !== null 
            ? <div>
                <span className="key">{`${translation.Payment.minDeliveryCost}`}</span>
                <span className="value">
                  {`${data?.deliveryCost === 0 ? translation.Payment.freeShipping : "$" + data.deliveryCost.toFixed(2)}`}
                </span>
              </div> : null}
            {estimatedDeliveryTime && data?.deliveryCost !== null && <div className="bar"><span>{"|"}</span></div>}
            {estimatedDeliveryTime && <div>
              <span className="key">{`${translation.Payment.estimatedDeliveryTime} `}</span>
              <span className="value">
                {estimatedDeliveryTime.deliveryTime && estimatedDeliveryTime.deliveryTime + " "}
                {estimatedDeliveryTime.deliveryTime && translation.Code === "ES"
                  ? estimatedDeliveryTime.deliveryTimeUnitEs + " "
                  : estimatedDeliveryTime.deliveryTimeUnitEn + " "}
              </span>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
};

export default BusinessItem;