import React, {useState} from "react";
import "./css/RegisterStyles.scss";
import {useDispatch, useSelector} from "react-redux";
import {doValidatePhone, setForm} from "./store/register.actions";
import {selectValidateError, selectForm} from "./store/register.reducers";
import CustomInputTextMask from "../../component/CustomInputTextMask/CustomInputTextMask";
import {Paper, Typography, Grid} from "@material-ui/core";

export default function ValidatePhone({translation}) {
  const validateError = useSelector(selectValidateError);
  const {phone} = useSelector(selectForm);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  const handleOnChange = (phone) => {
    const cleanPhone = phone.replace(/\D/g, "");
    setValidated(cleanPhone && cleanPhone.length >= 10);
    dispatch(setForm({phone: cleanPhone}));
  };

  const handleValidatePhone = () => {
    let data = {
      appName: "DELIVERY ",
      channel: "ANDROID",
      deviceId: "0008cccc-eeeeeeee-vvvvvvvv-gggggggg",
      typeOfPinShipping: "",
      username: phone,
      verify: false,
    };
    dispatch(doValidatePhone(data));
  };

  return (
    <Paper className="form">
      <Typography className="title">
        {translation.Register.createCuballamaAccount}
      </Typography>
      <h2>
        <div className="divider"/>
      </h2>
      <div className="fields">
        <Grid container item md={9} sm={8} xs={12} spacing={3} component="div">
          <Grid item xs={12} component="div">
            <CustomInputTextMask
              required
              id="phone"
              fieldLabel={translation.Register.phoneField}
              value={phone}
              onChange={event => handleOnChange(event.target.value)}
            />
            {validateError && validateError.code ? (
              <span className="label-error">{validateError.description}</span>
            ) : null}
          </Grid>
          <Grid container item xs={12} spacing={1} className="action" component="div">
            <button
              onClick={handleValidatePhone}
              disabled={!validated}
              className="button--primary button--max"
            >
              {translation.Register.validateAction}
            </button>
          </Grid>
          <Grid container item xs={12} spacing={1} className="register-link" component="div">
            <Typography>{translation.Register.alreadyRegistered}</Typography>
            <a href={"./login"} style={{color: "#428d44"}}>
              {translation.Login.loginAction}
            </a>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}