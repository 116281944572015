import { Tab, Tabs, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import React from "react";
import useTranslation from "translations/useTranslations";

const tabHeight = '32px'; // default to 48px
const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    minWidth: 'unset',
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
    minWidth: 'unset',
    padding: '12px 0px',
    margin: '0px 12px 0 0',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
}));

export default function SearchTabs({ loading, categories, tabValue, setTabValue }) {
  const translation = useTranslation();
  const classes = useStyles();

  return !loading
    ? <Tabs
      value={tabValue}
      onChange={(_, val) => setTabValue(val)}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      component={"div"}
      classes={{ root: classes.tabsRoot }}
    >
      {categories.map((cat, idx) => (
        <Tab
          href={""}
          key={idx}
          label={translation.Code === "ES" ? cat.nameEs : cat.nameEn}
          id={cat.id}
          component={"div"}
          classes={{ root: classes.tabRoot }}
        />
      ))}
    </Tabs>
    : <div style={{ display: 'flex' }}>
      <Skeleton variant="rect" width={100} height={32} animation="wave" style={{ marginRight: "5px" }} />
      <Skeleton variant="rect" width={100} height={32} animation="wave" style={{ marginRight: "5px" }} />
      <Skeleton variant="rect" width={100} height={32} animation="wave" style={{ marginRight: "5px" }} />
    </div>
    ;
}