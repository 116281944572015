import {createSelector} from "reselect";
import {SET_LANGUAGE} from "./language-picker.actions";

const INITIAL_STATE = {
  code: 'es',
};

export default function lang(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_LANGUAGE: {
      return {
        code: action.codeLang
      };
    }
    default: {
      return state;
    }
  }
}

export const selectLoaderMaskState = state => state.lang;

export const getLanguage = createSelector(
  selectLoaderMaskState,
  state => state.code
);