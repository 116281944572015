import { post, simpleGet, simplePost } from "../../../core/services";

const baseUrl = "deliveryOrder";

export const SET_BD_ORDER = "SET_BD_ORDER";
export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";
export const VALID_CHECKOUT_INFO = "VALID_CHECKOUT_INFO";
export const CLEAR_CHECKOUT_STATE = "CLEAR_CHECKOUT_STATE";
export const INVALID_CHECKOUT_INFO = "INVALID_CHECKOUT_INFO";
export const DELIVERY_DATE_LOADED_SUCCESS = "DELIVERY_DATE_LOADED_SUCCESS";
export const DELIVERY_DATE_LOADED_FAILURE = "DELIVERY_DATE_LOADED_FAILURE";
export const DELIVERY_TIME_LOADED_SUCCESS = "DELIVERY_TIME_LOADED_SUCCESS";
export const DELIVERY_TIME_LOADED_FAILURE = "DELIVERY_TIME_LOADED_FAILURE";
export const BROWSER_ORDER_STORED_SUCCESS = "BROWSER_ORDER_STORED_SUCCESS";
export const SET_BROWSER_ORDER_PARAMS_SUCCESS =
  "SET_BROWSER_ORDER_PARAMS_SUCCESS";
export const DELIVERY_DATE_LIST_LOADED_SUCCESS =
  "DELIVERY_DATE_LIST_LOADED_SUCCESS";
export const DELIVERY_DATE_LIST_LOADED_FAILURE =
  "DELIVERY_DATE_LIST_LOADED_FAILURE";
export const CLEAR_BROWSER_ORDER_DATE_SUCCESS =
  "CLEAR_BROWSER_ORDER_DATE_SUCCESS";
export const CLEAR_BROWSER_ORDER_COUPON_SUCCESS =
  "CLEAR_BROWSER_ORDER_COUPON_SUCCESS";
export const CLEAR_BROWSER_ORDER_ADDRESS_SUCCESS =
  "CLEAR_BROWSER_ORDER_ADDRESS_SUCCESS";

function deliveryDateLoadedSuccess(response) {
  return {
    type: DELIVERY_DATE_LOADED_SUCCESS,
    response,
  };
}

function deliveryDateLoadedFailure(response) {
  return {
    type: DELIVERY_DATE_LOADED_FAILURE,
    response,
  };
}

function deliveryDateListLoadedSuccess(response) {
  return {
    type: DELIVERY_DATE_LIST_LOADED_SUCCESS,
    response,
  };
}

function deliveryDateListLoadedFailure(response) {
  return {
    type: DELIVERY_DATE_LIST_LOADED_FAILURE,
    response,
  };
}

function deliveryTimeLoadedSuccess(response) {
  return {
    type: DELIVERY_TIME_LOADED_SUCCESS,
    response,
  };
}

function deliveryTimeLoadedFailure(response) {
  return {
    type: DELIVERY_TIME_LOADED_FAILURE,
    response,
  };
}

function checkoutInfoValid(response) {
  return {
    type: VALID_CHECKOUT_INFO,
    response,
  };
}

function checkoutInfoInvalid(response) {
  return {
    type: INVALID_CHECKOUT_INFO,
    response,
  };
}

export function clearCheckoutState() {
  return {
    type: CLEAR_CHECKOUT_STATE,
  };
}

export function storeBrowserOrder(response) {
  return {
    type: BROWSER_ORDER_STORED_SUCCESS,
    response,
  };
}

export function setBrowserOrderParams(params, response) {
  return {
    type: SET_BROWSER_ORDER_PARAMS_SUCCESS,
    params,
    response,
  };
}

export function clearBrowserOrderAddress() {
  return {
    type: CLEAR_BROWSER_ORDER_ADDRESS_SUCCESS,
  };
}

export function clearBrowserOrderCoupon() {
  return {
    type: CLEAR_BROWSER_ORDER_COUPON_SUCCESS,
  };
}

export function clearBrowserOrderDate() {
  return {
    type: CLEAR_BROWSER_ORDER_DATE_SUCCESS,
  };
}

export function setBDOrder(response) {
  return {
    type: SET_BD_ORDER,
    response,
  };
}

function sendAvailabilityError(response) {
  return {
    type: AVAILABILITY_ERROR,
    response,
  };
}

export function loadServerCurrentDate() {
  return (dispatch) => {
    simpleGet(`${baseUrl}/getActualDate`)
      .then((response) => {
        dispatch(deliveryDateLoadedSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(deliveryDateLoadedFailure(errMsg));
      });
  };
}

export function loadDeliveryDateList(RESTAURANT_ID) {
  return (dispatch) => {
    simpleGet(`${baseUrl}/getDeliveryDateTimeTest?id=${RESTAURANT_ID}`)
      .then((response) => {
        dispatch(deliveryDateListLoadedSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(deliveryDateListLoadedFailure(errMsg));
      });
  };
}

export function loadDeliveryTimeList(DATE, RESTAURANT_ID) {
  return (dispatch) => {
    simpleGet(
      `${baseUrl}/getDeliveryHoursRangeTest?date=${DATE}&id=${RESTAURANT_ID}`
    )
      .then((response) => {
        dispatch(deliveryTimeLoadedSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(deliveryTimeLoadedFailure(errMsg));
      });
  };
}

export function validateCheckoutInfo(DATA) {
  return (dispatch) => {
    simplePost(`checkout/`, DATA)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(checkoutInfoValid(response));
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        dispatch(checkoutInfoInvalid(response));
      });
  };
}

export function validateAddressInfo(DATA) {
  return (dispatch) => {
    post(`business/checkAddress`, DATA)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(checkoutInfoValid(response));
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        dispatch(checkoutInfoInvalid(response));
      });
  };
}
