import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { simplePost } from "core/services";
import { browserHistory } from "../../../utils/History";
import useTranslations from "translations";

export const PAY_ORDER_SUCCESS = "PAY_ORDER_SUCCESS";
export const PAY_ORDER_FAILURE = "PAY_ORDER_FAILURE";
export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";
export const CLEAR_PAY = "CLEAR_PAY";

export function payOrderSucess(response) {
  return {
    type: PAY_ORDER_SUCCESS,
    response,
  };
}

export function payOrderFailure(response) {
  return {
    type: PAY_ORDER_FAILURE,
    response,
  };
}

export function clearPaymentStore() {
  return {
    type: CLEAR_PAY,
  };
}

export function payOrder(DATA) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`checkout/payment`, DATA)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(payOrderSucess(response));
          browserHistory.push({
            pathname: "/prv/pay_success",
            state: {
              amount: DATA?.totalCost,
            },
          });
        } else {
          browserHistory.push({
            pathname: "/prv/pay_error",
            state: {
              amount: DATA?.totalCost,
            },
          });
        }
        dispatch(setLoadingMaskStatus(false));
      })
      .catch((response) => {
        dispatch(payOrderFailure(response));
        browserHistory.push({
          pathname: "/prv/pay_error",
          state: {
            amount: DATA?.total,
          },
        });
        dispatch(setLoadingMaskStatus(false));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
