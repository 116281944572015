import {get} from "core/services/ApiRequest";
import {BACKEND} from "config/BackendApis";

export const LOADED_TRENDING = 'LOADED_TRENDING';
export const CLEAR_STATES = 'CLEAR_STATES';

function trendingLoaded(trending) {
    return {
        type: LOADED_TRENDING,
        trending
    }
}

export function clearTrendingStates() {
    return {
        type: CLEAR_STATES
    }
}

export function loadTrending() {
    return dispatch => {
        get(BACKEND.TRENDING).then(trending => {
            dispatch(trendingLoaded(trending))
        });
    }
}