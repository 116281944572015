import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./css/PaymentProcessErrorStyle.scss";
import useTranslations from "../../translations/useTranslations";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";

export default function PaymentProcessError(props) {
  const translation = useTranslations();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setLoadingMaskStatus(false));
    // eslint-disable-next-line
  }, []);

  const goBack = () => {
    props.history.push({ pathname: "/prv/pay" });
  }

  // today as formatted string
  const today = new Date().toLocaleDateString(
    'en-GB'
  );

  return (
    <div className="payment-error">
      <div className="account-container">
        <div className="basic-card small relative mb-20">
          <div className="d-flex justfy-content-center flex-column">
            <div className="expanded-block">
              <img className="card-icon" src={"images/icons/system/circle-exclamation-red.svg"} alt="" />
              <div className="FlexColumn">
                <h4>{translation.Payment.failureTitle}</h4>
                <div style={{ textAlign: 'left', display: 'grid', gridTemplateColumns: '1fr', gap: '8px' }}>
                  <p>{translation.Payment.failureGenericMessage}</p>
                  <p>{translation.Payment.failureDate}: {today}</p>
                  <p>{translation.Payment.failureAmount}: ${props.location.state?.amount || 0}</p>
                  <p>{translation.Payment.failureSubtitle}</p>
                </div>
              </div>
            </div>
            <div className="d-flex justfy-content-center fixed-block mt-50">
              <button className="button--primary" onClick={() => goBack()}>
                {translation.Actions.accept}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
