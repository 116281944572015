export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export function showAlert(
  message = "Alert!",
  textAction1,
  textAction2,
  action1,
  action2,
  extras = {}
) {
  return {
    type: SHOW_ALERT,
    message,
    textAction1,
    textAction2,
    action1,
    action2,
    extras,
  };
}

export function hideAlert() {
  return { type: HIDE_ALERT };
}
