import { createSelector } from "reselect";
import { tConvert } from "utils/AuxiliarFunctions";
import * as checkout_actions from "./checkout.actions";

const INITIAL_STATE = {
  bd_order: null,
  deliveryDate: null,
  browser_order: null,
  deliveryDateOptions: null,
  deliveryTimeOptions: null,
}

export default function checkout(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case checkout_actions.SET_BD_ORDER: {
      return {
        ...state,
        bd_order: action?.response,
      };
    }
    case checkout_actions.VALID_CHECKOUT_INFO: {
      return {
        ...state,
        bd_order: action?.response?.data,
      };
    }
    case checkout_actions.INVALID_CHECKOUT_INFO: {
      let response = action?.response;
      return {
        ...state,
        bd_order: response
      };
    }
    case checkout_actions.BROWSER_ORDER_STORED_SUCCESS: {
      return {
        ...state,
        browser_order: action.response
      }
    }
    case checkout_actions.SET_BROWSER_ORDER_PARAMS_SUCCESS: {
      const browser_order = state;
      const attr = action.params;
      const aux = { ...browser_order }
      aux[`${attr}`] = action.response;

      return {
        ...state,
        browser_order: aux,
      }
    }
    case checkout_actions.CLEAR_BROWSER_ORDER_DATE_SUCCESS: {
      const browser_order = state.browser_order;
      const aux = {
        ...browser_order,
        deliveryDay: null,
        deliveryHour: null,
      }
      return {
        ...state,
        browser_order: aux,
      }
    }
    case checkout_actions.CLEAR_BROWSER_ORDER_COUPON_SUCCESS: {
      const browser_order = state.browser_order;
      const aux = {
        ...browser_order,
        coupon: null
      }
      return {
        ...state,
        browser_order: aux,
      }
    }
    case checkout_actions.CLEAR_BROWSER_ORDER_ADDRESS_SUCCESS: {
      const browser_order = state.browser_order;
      const aux = {
        ...browser_order,
        address: null
      }
      return {
        ...state,
        browser_order: aux,
      }
    }
    case checkout_actions.DELIVERY_DATE_LOADED_SUCCESS: {
      return {
        ...state,
        deliveryDate: action.response.data,
        deliveryTimeOptions: null,
      };
    }
    case checkout_actions.DELIVERY_DATE_LOADED_FAILURE: {
      return {
        ...state,
        deliveryDate: null,
        deliveryTimeOptions: null,
      };
    }
    case checkout_actions.DELIVERY_DATE_LIST_LOADED_SUCCESS: {
      return {
        ...state,
        deliveryDateOptions: action.response.data,
      };
    }
    case checkout_actions.DELIVERY_DATE_LIST_LOADED_FAILURE: {
      return {
        ...state,
        deliveryDateOptions: null,
      };
    }
    case checkout_actions.DELIVERY_TIME_LOADED_SUCCESS: {
      let options = action?.response?.data.map((time) => ({
        ...time,
        label: tConvert(time.hour),
      }));
      return {
        ...state,
        deliveryTimeOptions: options,
      };
    }
    case checkout_actions.DELIVERY_TIME_LOADED_FAILURE: {
      return {
        ...state,
        deliveryTimeOptions: null,
      };
    }
    case checkout_actions.CLEAR_CHECKOUT_STATE: {
      return {
        ...INITIAL_STATE
      };
    }
    default: {
      return state;
    }
  }
};

export const selectCheckoutState = (state) => state.checkout;

export const selectOrderFromBD = createSelector(
  selectCheckoutState,
  (state) => state.bd_order
);

export const selectBrowserOrder = createSelector(
  selectCheckoutState,
  (state) => state.browser_order
);


export const selectDeliveryDate = createSelector(
  selectCheckoutState,
  (state) => state.deliveryDate
);

export const selectDeliveryDateList = createSelector(
  selectCheckoutState,
  (state) => state.deliveryDateOptions
);

export const selectDeliveryTimeOptions = createSelector(
  selectCheckoutState,
  (state) => state.deliveryTimeOptions
);
