import {get} from "../../../../../core/services";
import {notifyError} from "../../../../notification/store/notification.actions";
import {setLoadingMaskStatus} from "../../../../../component/Mask/store/mask.actions";

const baseUrl = "";

export const ALL_RESTAURANT_VIEW = "ALL_RESTAURANT_VIEW";
export const MOST_POPULAR_LOADED_SUCCESS = 'MOST_POPULAR_LOADED_SUCCESS';
export const MOST_POPULAR_LOADED_FAILURE = 'MOST_POPULAR_LOADED_FAILURE';

export function setRestaurantView(restaurantView) {
    return {
        type: ALL_RESTAURANT_VIEW,
        restaurantView
    };
}

function mostPopularLoadedSuccess(most_popular) {
    return {
        type: MOST_POPULAR_LOADED_SUCCESS,
        most_popular
    }
}

function mostPopularLoadedFailure(most_popular) {
    return {
        type: MOST_POPULAR_LOADED_FAILURE,
        most_popular
    }
}

export function loadMostPopular(PREVIEW) {
    return dispatch => {
        get(`${baseUrl}getMostPopular${PREVIEW ? '?preview=' + PREVIEW : ''}`)
            .then(most_popular => {
                dispatch(mostPopularLoadedSuccess(most_popular))
            })
            .catch((response) => {
                const errMsg = response && response.data ? response.data.message : "ERROR!";
                dispatch(mostPopularLoadedFailure(errMsg));
                dispatch(notifyError(errMsg));
            })
            .finally(() => {
                dispatch(setLoadingMaskStatus(false));
            });
    }
}
