import { LOADED_SUGGESTIONS } from "./actions";
import { createSelector } from "reselect";

const INITIAL_STATE = {
  suggestions: [],
};

export default function topbar(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADED_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
      };
    default:
      return state;
  }
}

export const selectTopbarState = (state) => state.topbar;

export const selectSuggestions = createSelector(
  selectTopbarState,
  (state) =>
    state?.suggestions.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    ) || []
);
