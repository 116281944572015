import React, {useEffect} from "react";
import "./css/RegisterStyles.scss";
import {useSelector} from "react-redux";
import {selectValidated} from "./store/register.reducers";
import ValidatePhone from "./ValidatePhone";
import VerifyCode from "./VerifyCode";
import {Grid} from "@material-ui/core";
import useTranslations from "../../translations/useTranslations";

export default function Register() {
  const translation = useTranslations();
  const validated = useSelector(selectValidated);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [validated]);

  return (
    <div className="register">
      <Grid container spacing={3} className="container" component={"div"}>
        {!validated ? (
          <ValidatePhone translation={translation}/>
        ) : (
          <VerifyCode translation={translation}/>
        )}
      </Grid>
    </div>
  );
}