import * as authActions from "./login.actions";
import { createSelector } from "reselect";
import { customEncode } from "../../../utils/AuxiliarFunctions";

const INITIAL_STATE = {
  access_token: undefined,
  refresh_token: undefined,
  expires_in: undefined,
  stamp: undefined,
  user: undefined,
  cllWebAppToken: undefined,
  verificationData: undefined,
  verify_email: false,
  check_verify_email: false,
  show_email_form: false,
  verify_device_id: false,
  errors: {},
  login_success: false,
  login_failure: false,
  logout_success: false,
  logout_failure: false,
  token_success: false,
  token_failure: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authActions.LOGIN_SUCCESS:
      let authData = action.response ? action.response : {};
      let access_token = authData.access_token;
      let refresh_token = authData.refresh_token;
      let expires_in = authData.expires_in;
      let stamp = new Date().getTime() + Number(authData.expires_in) * 1000;
      let user = customEncode(authData.user);
      let cllWebAppToken = {
        access_token: authData.access_token ? authData.access_token : null,
        token_type: authData.token_type ? authData.token_type : null,
        refresh_token: authData.refresh_token ? authData.refresh_token : null,
        expires_in: authData.expires_in ? authData.expires_in : null,
        scope: authData.scope ? authData.scope : null,
        solicit_email: authData.solicit_email ? authData.solicit_email : null,
        deeplink: authData.deeplink ? authData.deeplink : null,
      };
      return {
        access_token,
        refresh_token,
        expires_in,
        stamp,
        user,
        cllWebAppToken,
        login_success: true,
        login_failure: false,
        show_email_form: false,
        verify_device_id: false,
        logout_success: false,
        logout_failure: false,
        token_success: false,
        token_failure: false,
        errors: {},
      };
    case authActions.CLL_LOCALSTORAGE_LOGIN:
      let cllAuthData = action.response ? action.response : {};
      let cllAccess_token = cllAuthData.access_token;
      let cllRefresh_token = cllAuthData.refresh_token;
      let cllExpires_in = cllAuthData.expires_in;
      let cllStamp = cllAuthData.stamp;
      let cllUser = cllAuthData.user ? customEncode(cllAuthData.user) : null;
      return {
        ...state,
        access_token: cllAccess_token,
        refresh_token: cllRefresh_token,
        expires_in: cllExpires_in,
        stamp: cllStamp,
        user: cllUser,
        login_success: true,
        login_failure: false,
        show_email_form: false,
        verify_device_id: false,
        logout_success: false,
        logout_failure: false,
        token_success: false,
        token_failure: false,
        errors: {},
      };
    case authActions.LOGIN_FAILURE:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        login_failure: true,
        show_email_form: false,
        verify_device_id: false,
        logout_success: false,
        logout_failure: false,
        token_success: false,
        token_failure: false,
        errors: action.response,
      };
    case authActions.LOGIN_DEVICEID:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        login_failure: false,
        logout_success: false,
        logout_failure: false,
        show_email_form: false,
        verify_device_id: true,
        token_success: false,
        token_failure: false,
        errors: action.response,
      };
    case authActions.SHOW_EMAIL_FORM:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        login_failure: false,
        logout_success: false,
        logout_failure: false,
        verify_device_id: false,
        show_email_form: true,
        token_success: false,
        token_failure: false,
        errors: {},
      };
    case authActions.VERIFY_EMAIL:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        login_failure: false,
        logout_success: false,
        logout_failure: false,
        verify_device_id: false,
        show_email_form: false,
        token_success: false,
        token_failure: false,
        verify_email: true,
        verificationData: action?.response?.data,
        errors: {},
      };
    case authActions.CHECK_VERIFY_EMAIL:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        login_failure: false,
        logout_success: false,
        logout_failure: false,
        verify_device_id: false,
        show_email_form: false,
        token_success: false,
        token_failure: false,
        verify_email: false,
        check_verify_email: true,
        verificationData: action?.response?.data,
        errors: {},
      };
    case authActions.TOKEN_REFRESHED:
      let newAuthData = action.response ? action.response.data : {};
      let newAccess_token = newAuthData.access_token;
      let newRefresh_token = newAuthData.refresh_token;
      let newExpires_in = newAuthData.expires_in;
      let newUser = customEncode(newAuthData.user);
      let newCllWebAppToken = {
        access_token: newAuthData.access_token
          ? newAuthData.access_token
          : null,
        token_type: newAuthData.token_type ? newAuthData.token_type : null,
        refresh_token: newAuthData.refresh_token
          ? newAuthData.refresh_token
          : null,
        expires_in: newAuthData.expires_in ? newAuthData.expires_in : null,
        scope: newAuthData.scope ? newAuthData.scope : null,
        solicit_email: newAuthData.solicit_email
          ? newAuthData.solicit_email
          : null,
        deeplink: newAuthData.deeplink ? newAuthData.deeplink : null,
      };
      return {
        access_token: newAccess_token,
        refresh_token: newRefresh_token,
        expires_in: newExpires_in,
        user: newUser,
        cllWebAppToken: newCllWebAppToken,
        login_success: false,
        login_failure: false,
        show_email_form: false,
        verify_device_id: false,
        logout_success: false,
        logout_failure: false,
        token_success: true,
        token_failure: false,
        errors: {},
      };
    case authActions.TOKEN_FAILURE:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        show_email_form: false,
        verify_device_id: false,
        login_failure: false,
        logout_success: false,
        logout_failure: false,
        token_success: false,
        token_failure: true,
        errors: action.response,
      };
    case authActions.LOGOUT_SUCCESS:
      return {
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        user: undefined,
        login_success: false,
        login_failure: false,
        show_email_form: false,
        verify_device_id: false,
        logout_success: true,
        logout_failure: false,
        token_success: false,
        token_failure: false,
        errors: action.response,
      };
    case authActions.LOGOUT_FAILURE:
      return {
        ...state,
        login_success: false,
        login_failure: false,
        logout_success: false,
        logout_failure: true,
        token_success: false,
        show_email_form: false,
        verify_device_id: false,
        token_failure: false,
        errors: action.response,
      };
    case authActions.CLEAR_AUTH_STATE:
      return {
        ...state,
        errors: {},
        login_success: false,
        login_failure: false,
        logout_success: false,
        logout_failure: false,
        show_email_form: false,
        verify_device_id: false,
        token_success: false,
        token_failure: false,
      };
    default:
      return state;
  }
}

export const selectorLoginState = (state) => state.auth;

export const loginUserSelector = createSelector(
  selectorLoginState,
  (state) => state
);

export const selectToken = createSelector(
  selectorLoginState,
  (state) => state.access_token
);
