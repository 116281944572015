import React from 'react';
import Select, { components } from 'react-select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from "@material-ui/core";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { ArrowDropDown, ArrowDropUp, Clear, Room, Search, PageviewOutlined } from "@material-ui/icons";
import * as _ from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";
import useTranslations from "../../translations/useTranslations";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        '&::placeholder': {
            color: "gray",
            fontStyle: 'italic !important',
        },
        display: 'flex',
        padding: 0,
        height: "39px",
        backgroundColor: "white !important",
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignContent: 'center',
        overflow: 'hidden',
        padding: "0 .8em",
    },
    indicatorSeparatorGreen: {
        backgroundColor: '#2c2c2c  !important',
        width: ".15em !important",
        marginTop: "0 !important",
        marginBottom: "0 !important",
        borderRight: "0 !important",
    },
    indicatorSeparatorGray: {
        backgroundColor: '#c2d1d9  !important',
        width: ".05em !important",
        marginTop: "0 !important",
        marginBottom: "0 !important",
        borderRight: "0 !important",
    },
    indicatorSeparatorError: {
        backgroundColor: '#ff0000  !important',
        width: ".05em !important",
        marginTop: "0 !important",
        marginBottom: "0 !important",
        borderRight: "0 !important",
    },
    dropdownIndicator: {
        padding: ".3em 1em",
    },
    clearIndicator: {
        padding: "0 .3em",
    },
    noOptionsContainer: {
        display: 'block',
        textAlign: 'center',
        alignItems: 'center',
    },
    noOptionsIcon: {
        marginTop: '1em',
        color: '#2c2c2c',
        width: '3em',
        height: '3em',

    },
    noOptionsMessage: {
        margin: '0 .5em 1em .5em',
        fontSize: '1em'
    },
    singleValue: {
        fontSize: "1em",
    },
    placeholder: {
        position: 'absolute',
        fontSize: "1em"
    },
    paper: {
        position: 'absolute',
        zIndex: 10,
        marginTop: theme.spacing(.5),
        left: 0,
        right: 0
    },
    group: {
        color: '#2a2a2a',
        background: '#ffffff',
        margin: 0,
        padding: "0",
    },
    groupHeading: {
        color: '#2a2a2a',
        background: '#f8f8f8',
        margin: 0,
        padding: "0",
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        fontSize: "1.5em",
        height: "40px",
    },
    helperText: {
        fontSize: ".8em",
    },
    errorText: {
        fontStyle: "italic",
        fontSize: ".8em",
        color: "red",
    },
}));

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#2c2c2c',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#2c2c2c',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: 0,
            },
            '&.Mui-focused fieldset': {
                borderColor: '#2c2c2c',
            },
        },
    },
})(TextField);

const groupStyles = {
    color: "#2a2a2a",
    fontSize: "1em",
    fontWeight: 550,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const NoOptionsMessage = props => {
    return (
        <div className={props.selectProps.classes.noOptionsContainer}>
            <PageviewOutlined component={"svg"} className={props.selectProps.classes.noOptionsIcon} />
            <Typography {...props.innerProps} className={props.selectProps.classes.noOptionsMessage}>
                {props.children}
            </Typography>
        </div>
    );
};

const inputComponent = ({ inputRef, ...props }) => (
    <div ref={inputRef} {...props} style={{ fontSize: '1em' }} />
);

const Control = props => {
    return <CssTextField
        fullWidth
        variant="outlined"
        InputProps={{
            inputComponent,
            inputProps: {
                className: props.selectProps.classes.input,
                inputRef: props.innerRef,
                children: props.children,
                ...props.innerProps
            }
        }}
        {...props.selectProps.textFieldProps}
    />;
};

const ValueLabel = ({ label, search, isDisabled }) => {
    const matches = match(label, search);
    const parts = parse(label, matches);
    if (isDisabled) {
        return <span style={{ fontSize: "16px", color: '#a1aeb7' }}>{label}</span>;
    } else {
        return (
            <div style={{ display: 'flex' }}>
                {parts.map((part, index) => {
                    if (_.startsWith(part.text, " ")) {
                        return (
                            <span key={index}
                                style={{
                                    fontSize: "16px",
                                    fontWeight: part.highlight ? 700 : 400,
                                    color: part.highlight ? '#2a2a2a' : '#373737',
                                }}>
                                &nbsp;{part.text}
                            </span>
                        )
                    } else {
                        return (
                            <span key={index}
                                style={{
                                    fontSize: "16px",
                                    fontWeight: part.highlight ? 700 : 400,
                                    color: part.highlight ? '#2a2a2a' : '#373737',
                                }}>
                                {part.text}
                            </span>
                        )
                    }
                })}
            </div>
        )
    }
};

const Option = props => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                color: props.isDisabled ? '#c2d1d9' : '#373737',
                fontWeight: props.isSelected ? 600 : 200
            }}
            {...props.innerProps}
        >
            <ValueLabel
                label={props.children}
                search={props.selectProps.inputValue}
                isDisabled={props.isDisabled}
            />
        </MenuItem>
    );
};

const Placeholder = props => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
    >
        {props.children ? props.children : null}
    </Typography>
);

const SingleValue = props => (
    <Typography
        className={props.selectProps.classes.singleValue}
        {...props.innerProps}
    >
        {props.data.parentLabel ? props.data.parentLabel + " - " : null}
        {props.children}
    </Typography>
);

const ValueContainer = props => {
    let color = props.isFocused ? '#2c2c2c' : '#c2d1d9';
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props} className={props.selectProps.classes.valueContainer}>
                {props.selectProps.inputLocationIcon && props.children && <Room style={{ color }} component={"svg"} />}
                {props.children}
            </components.ValueContainer>
        )
    );
};

const Menu = props => (
    <Paper
        square
        className={props.selectProps.classes.paper}
        {...props.innerProps}
    >
        {props.children}
    </Paper>
);

const Group = props => (
    <div className={props.selectProps.classes.group}>
        <components.Group {...props} />
    </div>
);
const GroupHeading = props => (
    <div className={props.selectProps.classes.groupHeading}>
        <components.GroupHeading {...props} />
    </div>
);

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
    </div>
);

const IndicatorSeparator = props => {
    // const customClasses = props.selectProps.classes;
    if (props.selectProps.separator) {
        // let style = props.selectProps.error ? customClasses.indicatorSeparatorError : props.isFocused ? customClasses.indicatorSeparatorGreen : customClasses.indicatorSeparatorGray
        return (
            components.IndicatorSeparator && (
                <components.IndicatorSeparator
                    {...props}
                    className={props.isFocused
                        ? props.selectProps.classes.indicatorSeparatorGreen
                        : props.selectProps.classes.indicatorSeparatorGray} />
            )
        );
    } else {
        return null;
    }
};

const ClearIndicator = props => {
    let color = props.isFocused ? '#2c2c2c' : '#c2d1d9';
    return (
        components.ClearIndicator && (
            <components.ClearIndicator {...props} className={props.selectProps.classes.clearIndicator}>
                <Clear style={{ color, width: 18 }} component={"svg"} />
            </components.ClearIndicator>
        )
    )
};

const DropdownIndicator = props => {
    let color = props.selectProps.error ? '#ff0000' : props.isFocused ? '#2c2c2c' : '#c2d1d9';
    if (props.selectProps.dropDownIconSearch) {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props} className={props.selectProps.classes.dropdownIndicator}>
                    <Search style={{ color }} component={"svg"} />
                </components.DropdownIndicator>
            )
        );
    } else if (props.selectProps.dropDownIcon) {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props} className={props.selectProps.classes.dropdownIndicator}>
                    {props.selectProps.dropDownIcon}
                </components.DropdownIndicator>
            )
        );
    } else {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props} className={props.selectProps.classes.dropdownIndicator}>
                    {props.selectProps.menuIsOpen
                        ? <ArrowDropUp style={{ color }} component={"svg"} />
                        : <ArrowDropDown style={{ color }} component={"svg"} />}
                </components.DropdownIndicator>
            )
        );
    }
};

export default function CustomSelect(props) {
    const classes = useStyles();
    const translation = useTranslations();
    const noMatchesFoundFor = props.noMatchesFoundFor || translation.General.noMatchesFoundFor;
    const tryAnotherSearchCriteria = props.tryAnotherSearchCriteria || translation.General.tryAnotherSearchCriteria;

    const getNoOptionsMessage = (props) => {
        return (
            <div>
                <span>
                    <strong>{`${noMatchesFoundFor} "${props.inputValue}"`}</strong>
                    <br />
                    {tryAnotherSearchCriteria}
                </span>
            </div>
        );
    };

    function onChange(v) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    function onBlur(v) {
        if (props.onBlur) {
            props.onBlur(v);
        }
    }

    return (
        <div className={classes.root}>
            <Select
                id={props.id}
                value={props.value}
                menuIsOpen={props.menuIsOpen}
                isClearable={props.isClearable}
                isSearchable={props.isSearchable}
                autoFocus={props.autoFocus}
                onChange={v => onChange(v)}
                onBlur={v => onBlur(v)}
                noOptionsMessage={getNoOptionsMessage}
                formatGroupLabel={formatGroupLabel}
                textFieldProps={{
                    label: props.fieldLabel,
                    onClick: props.onClick,
                    onTouchEnd: props.onClick,
                    error: props.error,
                    required: props.required,
                    InputLabelProps: {
                        shrink: true,
                    }
                }}
                {...{ ...props, classes }}
            />
            {props.errorMessage
                ? <FormHelperText id="component-helper-text" className={classes.errorText}>
                    {props.errorMessage}
                </FormHelperText>
                : props.helperText
                    ?
                    <FormHelperText id="component-helper-text" className={props.helperTextStyle || classes.helperText}>
                        {props.helperText}
                    </FormHelperText>
                    : null}
        </div>
    );
}

CustomSelect.defaultProps = {
    autoFocus: false,
    isClearable: true,
    isSearchable: true,
    separator: false,
    dropDownIconSearch: false,
    inputLocationIcon: false,
    fieldName: "",
    fieldLabel: "",
    placeholder: null,
    helperText: null,
    helperTextStyle: null,
    error: false,
    errorMessage: null,
    dropDownIcon: null,
    components: {
        Control,
        Menu,
        Group,
        GroupHeading,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        IndicatorSeparator,
        ClearIndicator,
        DropdownIndicator
    },
    options: []
};
