import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { simpleGet, simplePost } from "core/services";
import { notifyError } from "modules/notification/store/notification.actions";

export const FETCH_CLIENT_TOKEN = "FETCH_CLIENT_TOKEN";
export const FETCHED_CLIENT_TOKEN = "FETCHED_CLIENT_TOKEN";
export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCHED_PAYMENT_METHODS = "FETCHED_PAYMENT_METHODS";
export const DONE_BRAINTREE_PAYMENT = "DONE_BRAINTREE_PAYMENT";
export const SET_ERROR_ADD_PAYMENT_METHOD = "SET_ERROR_ADD_PAYMENT_METHOD";
export const CLEAR_ERROR_ADD_PAYMENT_METHOD = "CLEAR_ERROR_ADD_PAYMENT_METHOD";

const baseUrl = "payment/braintree";

function doneFetchClientToken(clientToken) {
  return {
    type: FETCHED_CLIENT_TOKEN,
    clientToken,
  };
}

function doneFetchPaymentMethods(paymentMethods) {
  return {
    type: FETCHED_PAYMENT_METHODS,
    paymentMethods,
  };
}

function doneBraintreePayment(data) {
  return {
    type: DONE_BRAINTREE_PAYMENT,
    data,
  };
}

function setErrorAddPaymentMethod(err) {
  return {
    type: SET_ERROR_ADD_PAYMENT_METHOD,
    err,
  };
}

export function clearErrorAddPaymentMethod() {
  return { type: CLEAR_ERROR_ADD_PAYMENT_METHOD };
}

export function doFetchClientToken() {
  return async (dispatch) => {
    try {
      const { data } = await simpleGet(`${baseUrl}/client-token`);
      dispatch(doneFetchClientToken(data));
    } catch (error) {
      let errObj =
        error.response && error.response.data
          ? error.response.data
          : error.response;
      if (errObj) {
        const errMsg = errObj.message
          ? `${errObj.error}: ${errObj.message}`
          : `${errObj.status}: ${errObj.statusText}`;
        dispatch(notifyError(errMsg));
      }
    }
  };
}

export function fetchPaymentMethods(btClient, cb = null) {
  return async (dispatch) => {
    try {
      const { paymentMethods } = await btClient.request({
        endpoint: "payment_methods",
        method: "get",
        data: {
          defaultFirst: 1,
        },
      });
      dispatch(doneFetchPaymentMethods(paymentMethods));
      if (cb) {
        cb(paymentMethods);
      }
    } catch (error) {
      let errObj =
        error.response && error.response.data
          ? error.response.data
          : error.response;
      if (errObj) {
        const errMsg = errObj.message
          ? `${errObj.error}: ${errObj.message}`
          : `${errObj.status}: ${errObj.statusText}`;
        dispatch(notifyError(errMsg));
      }
    }
  };
}

export function doDeletePaymentMethod(btClient, nonce) {
  const DELETE_PAYMENT_METHOD_MUTATION = `mutation DeletePaymentMethodFromSingleUseToken($input: DeletePaymentMethodFromSingleUseTokenInput!) { 
    deletePaymentMethodFromSingleUseToken(input: $input) { clientMutationId } }`;
  return async (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    try {
      await btClient.request({
        api: "graphQLApi",
        data: {
          query: DELETE_PAYMENT_METHOD_MUTATION,
          variables: {
            input: {
              singleUseTokenId: nonce,
            },
          },
          operationName: "DeletePaymentMethodFromSingleUseToken",
        },
      });
      dispatch(setLoadingMaskStatus(false));
      dispatch(fetchPaymentMethods(btClient));
    } catch (error) {
      console.log(error);
      let errObj =
        error.response && error.response.data
          ? error.response.data
          : error.response;
      if (errObj) {
        const errMsg = errObj.message
          ? `${errObj.error}: ${errObj.message}`
          : `${errObj.status}: ${errObj.statusText}`;
        dispatch(notifyError(errMsg));
      }
      dispatch(setLoadingMaskStatus(false));
    }
  };
}

export function addCardToVault(btClient, nonce, successCb) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/add-card-to-vault?nonce=${nonce}`)
      .then(({ data }) => {
        dispatch(setLoadingMaskStatus(false));
        dispatch(clearErrorAddPaymentMethod());
        dispatch(
          fetchPaymentMethods(btClient, (paymentMethods) =>
            successCb(data, paymentMethods)
          )
        );
      })
      .catch((error) => {
        let err =
          error.response && error.response.data
            ? error.response.data
            : error.response;

        dispatch(
          setErrorAddPaymentMethod(
            err || "Ha ocurrido un error al tratar de añadir su tarjeta"
          )
        );
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function doBraintreePayment(amount, nonce, paymentRequest, history) {
  return (dispatch) => {
    simplePost(
      `${baseUrl}/checkout?amount=${amount}&payment_method_nonce=${nonce}`,
      paymentRequest
    )
      .then((response) => {
        dispatch(doneBraintreePayment(response));
        history.push({ pathname: "/prv/pay_success" });
        dispatch(setLoadingMaskStatus(false));
      })
      .catch((error) => {
        console.log(error);
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          history.push({
            pathname: "/prv/pay_error",
            state: {
              error:
                errObj && errObj.message
                  ? errObj.message
                  : "TRANSACTION_NOT_ALLOWED",
            },
          });
          dispatch(setLoadingMaskStatus(false));
        }
      });
  };
}
