import React, { useEffect, useState } from "react";
import useTranslations from "translations";
import Skeleton from "@material-ui/lab/Skeleton";
import { LightTooltip } from "component/LightTooltip";
import { Info } from "@material-ui/icons";
import { removeDuplicates } from "utils/Utils";


const Voucher = ({ edit, browserOrder }) => {
  const translation = useTranslations();
  const [deliveryOrderType, setDeliveryOrderType] = useState(browserOrder?.deliveryOrderType);
  const [deliveryDays, setDeliveryDays] = useState(browserOrder?.deliveryDays);
  const [shipping, setShipping] = useState(browserOrder?.shipping);
  const [subtotal, setSubtotal] = useState(browserOrder?.setSubtotal);
  const [taxes, setTaxes] = useState(browserOrder?.taxes);
  const [discountByTalon, setDiscountByTalon] = useState(browserOrder?.discountByTalon);
  const [discountByCoupon, setDiscountByCoupon] = useState(browserOrder?.discountByCoupon);
  const [total, setTotal] = useState(browserOrder?.total);

  const getShipping = () => {
    if (shipping === 0) {
      return translation.Payment.freeShipping;
    }

    if (!shipping) {
      return translation.Payment.pending;
    }

    return shipping.toFixed(2);
  }

  useEffect(() => {
    setDeliveryOrderType(browserOrder.deliveryOrderType);
    setDeliveryDays(browserOrder.deliveryDays);
    setShipping(browserOrder.shipping);
    setSubtotal(browserOrder.subtotal);
    setTaxes(browserOrder.taxes);
    setDiscountByTalon(removeDuplicates(browserOrder.discountByTalon));
    setDiscountByCoupon(browserOrder.discountByCoupon);
    setTotal(browserOrder.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserOrder]);

  return (
    <div className="cart-resume">
      <div className="cart-resume__container">
        {/* Selected Offers */}
        <div className="cart-resume__list">
          {browserOrder?.items?.map((item, index) => (
            <div className="cart-resume__item" key={index}>
              <div className="cart-resume__item__info">
                <span className="cart-resume__item__info__count">{item.offerCount}</span>
                <div>
                  <span className="title">{item.name}</span>
                  <br />
                  {item.variantSelected?.length > 0 ?
                    <span className="variant">
                      {item.variantSelected.map((variant) => `${translation.Code === "ES" ? variant.nameEs : variant.nameEn}`).join(", ")}
                    </span> : null}
                  {item.additionsSelected?.length > 0 ?
                    <span className="details">
                      {` - ` + item.additionsSelected.map(({ name }) => `${name}`).join(", ")}
                    </span> : null}
                </div>
              </div>
              <div className="cart-resume__item__price">
                <span className="price">{'$' + item?.price?.toFixed(2)}</span>
              </div>
            </div>
          ))}
          <button onClick={edit} className="button--secondary" style={{ marginTop: "16px" }}>
            <span style={{ fontSize: "19px", fontWeight: 600, lineHeight: "23px", margin: "-4px 8px 0 0" }}>+</span> Añadir platos
          </button>
        </div>
        <div className="spacer" />

        {/* Cost Overview */}
        {subtotal !== undefined && <div className="subtotal">
          <span>{translation.Payment.subtotal}</span>
          <span>{subtotal > 0 ? `$${subtotal.toFixed(2)}` : "-"}</span>
        </div>}

        {deliveryOrderType === "DELIVERY"
          ? shipping !== undefined
            ? <div className="subtotal">
              <span>{translation.Payment.shipping}</span>
              <span>
                {getShipping()}
                {shipping > 0 && deliveryDays > 1 ? ` ($${(shipping / deliveryDays).toFixed(2)} ${translation.Payment.daily})` : null}
              </span>
            </div>
            : <div className="cost" style={{ margin: "4px 0 0" }}>
              <Skeleton variant="rect" width={150} height={18} animation="wave" />
              <Skeleton variant="rect" width={50} height={18} animation="wave" />
            </div>
          : null
        }

        {deliveryOrderType
          ? taxes !== undefined
            ? <div className="subtotal">
              <span style={{ display: 'flex' }}>
                {translation.Payment.taxes}
                <LightTooltip title={translation.Cart.serviceTaxInfo} placement="bottom">
                  <Info style={{ fontSize: 20, margin: '1px 0 0 5px' }} />
                </LightTooltip>
              </span>
              <span>${taxes ? taxes.toFixed(2) : (0).toFixed(2)}</span>
            </div>
            : <div className="cost" style={{ margin: "4px 0 0" }}>
              <Skeleton variant="rect" width={150} height={18} animation="wave" />
              <Skeleton variant="rect" width={50} height={18} animation="wave" />
            </div>
          : null
        }

        {discountByTalon ? discountByTalon?.map((effect, k) => <div className="cost" key={k}>
          <span>{effect.name ? `${effect.name}` : `${translation.Payment.bonus}`}</span>
          <span style={{ color: '#00d200' }}>
            {effect.value > 0 ? `- $${effect.value.toFixed(2)}` : `$${(0).toFixed(2)}`}
          </span>
        </div>) : null}

        {discountByCoupon ? <div className="cost">
          <span>{translation.Payment.discount}</span>
          <span style={{ color: '#00d200' }}>
            {discountByCoupon > 0 ? `-$${discountByCoupon?.toFixed(2)}` : "-"}
          </span>
        </div> : null}

        {total !== undefined
          ? <div className="total">
            <span>{translation.Payment.total}</span>
            <span>{total > 0 ? `$${total?.toFixed(2)}` : ` $${(0).toFixed(2)}`}</span>
          </div> : null}
      </div>
    </div>
  )
};
export default Voucher;