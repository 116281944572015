import React from "react";
import "./css/CartDialogStyle.scss";
import Cart from "./Cart";
import { selectBDCart } from "./store/cart.reducers";
import "./css/CartDialogStyle.scss";
import { useSelector } from "react-redux";

export default function CartDialog({ open, handleClose, translation }) {
  const cartBD = useSelector(selectBDCart);

  return (
    open && <Cart translation={translation} handleComplete={handleClose} onClose={handleClose}
      title={<h1 className="cart_dialog__header__title">
        {translation.Cart.myRequest} {cartBD?.restaurantName ? ` - ${cartBD?.restaurantName}` : null}
      </h1>} />
  );
}