/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./AddressesModal.Style.scss";
import useTranslation from "translations";
import PdfIcon from "component/Icon/PdfIcon";
import SeachableList from "component/SearchableList/SearchableList";
import { loadDeliveryAddress, setDeliveryAddressOnTopBarSelected } from "component/FormAddAddress/store/form-add-address.actions";
import { deliveryAddresses } from "component/FormAddAddress/store/form-add-address.reducers";
import { isAuth } from "App";
import UberModal from "component/UberModal/UberModal";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

export default function AddressesModal(props) {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const addresses = useSelector(deliveryAddresses);
  const [address, setAddress] = useState(null);
  const { handleAdd, handleContinue, showDialogForm, setShowDialogForm, required = false } = props;

  useEffect(() => {
    if (isAuth()) {
      dispatch(loadDeliveryAddress());
    }
  }, []);

  useEffect(() => {
    if (address?.id && addresses?.length) {
      const founded = addresses.find(({ id }) => address.id === id);
      founded && localStorage.setItem('address', JSON.stringify(address));
      dispatch(setDeliveryAddressOnTopBarSelected(address));
    }
  }, [address]);

  const handleSelectAddress = (address) => {
    setAddress(address);
    handleContinue(address);
  }

  return showDialogForm && <UberModal onClose={() => setShowDialogForm(false)}
    title={<h4>{translation.Payment.setDeliveryAddress}</h4>}
    subtitle={<h1>{translation.Payment.setDeliveryAddress}</h1>}
    required={required}
    // image={{ src: "./images/picture/addresses.jpg" }}
    actions={
      handleAdd && <button
        disabled={!isAuth()}
        className="button--primary button--max button--plus"
        onClick={() => handleAdd()} style={{ margin: "0 auto 15px" }}>
        <PdfIcon width={24} icon="system/add--white" alt="" ext="svg" />
        {translation.Payment.addDeliveryAddress}
      </button>
    }
    content={
      <div style={{ padding: '24px 24px 32px' }}>
        {props.options || addresses ?
          <SeachableList list={props.options?.length ? props.options : addresses} field1="name" field2={null}
            handleClick={handleSelectAddress} handleAdd={handleAdd} />
          : <div>
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '25px' }} />
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '8px' }} />
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '8px' }} />
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '8px' }} />
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '8px' }} />
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '8px' }} />
            <Skeleton variant="rect" width="100%" height={52} animation="wave" style={{ marginBottom: '8px' }} />
          </div>}
      </div>
    }
  />
}
