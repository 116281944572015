/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { components } from "react-select";
import { Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ArrowDropDown, ArrowDropUp, LocationOn } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import "./css/AddressStyle.scss";
import useTranslation from "translations";
import CustomSelect from "component/CustomSelect/CustomSelect";
import PdfIcon from "component/Icon/PdfIcon";
import { loadDeliveryAddress, setDeliveryAddressOnTopBarSelected } from "component/FormAddAddress/store/form-add-address.actions";
import { deliveryAddresses } from "component/FormAddAddress/store/form-add-address.reducers";
import { selectMunicipalities, selectProvinces } from "modules/home/store/home.reducers";
import { loadMunicipalities, loadProvinces } from "modules/home/store/home.actions";
import { selectDefaultPageCounter, selectDefaultFilters } from "store/filter/filter.reducers";
import { loadRestaurantsCarouselsList } from "store/filter/filter.actions";
import { selectTopBarAddress, } from "../../component/FormAddAddress/store/form-add-address.reducers";
import { loadBusinessGroupByProductType, showAllBusinessForProductType } from "../../store/filter/filter.actions";
import { store } from "../../App";
import { selectShowAllBusinessByProductType } from "../../store/filter/filter.reducers";
import { selectBrowserOrder } from "modules/Checkout/store/checkout.reducers";
import AddressesModal from "component/AddressesModal/AddressesModal";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { isAuth } from "App";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    height: "32px",
    background: '#f6f6f6 !important',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: "0 .8em",
  },
  indicatorSeparatorGreen: {
    backgroundColor: 'red  !important',
    width: ".15em !important",
    marginTop: "0 !important",
    marginBottom: "0 !important",
    borderRight: "0 !important",
  },
  indicatorSeparatorGray: {
    backgroundColor: '#c2d1d9  !important',
    width: ".05em !important",
    marginTop: "0 !important",
    marginBottom: "0 !important",
    borderRight: "0 !important",
  },
  dropdownIndicator: {
    padding: ".3em 1em",
  },
  clearIndicator: {
    padding: "0 .3em",
  },
  noOptionsContainer: {
    display: 'block',
    textAlign: 'center',
    alignItems: 'center',
  },
  noOptionsIcon: {
    marginTop: '1em',
    color: '#1e4acc',
    width: '2em',
    height: '2em',

  },
  noOptionsMessage: {
    margin: '0 .5em 1em .5em',
    fontSize: '16px'
  },
  singleValue: {
    fontSize: "16px",
  },
  placeholder: {
    position: 'absolute',
    fontSize: "16px"
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(.5),
    left: 0,
    right: 0
  },
  group: {
    color: '#2a2a2a',
    background: '#f6f6f6',
    margin: 0,
    padding: "0",
  },
  groupHeading: {
    color: '#2a2a2a',
    background: '#f6f6f6',
    margin: 0,
    padding: "0",
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    fontSize: "16px",
    height: "32px",
  },
}));
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#c2d1d9',
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
      backgroundColor: '#f6f6f6',
      '& fieldset': {
        borderColor: '#c2d1d9',
        borderRadius: 0,
      },
      '&:hover fieldset': {
        borderColor: '#c2d1d9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c2d1d9',
      },
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#c2d1d9',
    },
  },
})(TextField);

export default function Address(props) {
  const translation = useTranslation();
  const addresses = useSelector(deliveryAddresses);
  const provinces = useSelector(selectProvinces);
  const browserOrder = useSelector(selectBrowserOrder);
  const municipalities = useSelector(selectMunicipalities);
  const defaultFilters = useSelector(selectDefaultFilters);
  const defaultPageCounter = useSelector(selectDefaultPageCounter);
  const topBarAddressSelected = useSelector(selectTopBarAddress);
  const showAllBusinessByProductType = useSelector(selectShowAllBusinessByProductType);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [address, setAddress] = useState(null);
  const [grow, setGrow] = useState(props.grown || false);
  const [options, setOptions] = useState([]);
  const [showDialogForm, setShowDialogForm] = useState(false);
  const [preview] = useState(props.location && props.location.state && props.location.state.preview ? props.location.state.preview : 4);
  const [selectComponent, setSelectComponent] = useState(null);
  const { forceClose, minimalView, handleOpened } = props;
  const [previewBusiness] = useState(8);
  const businessCategorySelected = store.getState().filters.filters.businessCategory;
  const inputRef = useRef(null);
  const [places, setPlaces] = useState(undefined);


  // Effects

  useEffect(() => {
    if (isAuth()) {
      dispatch(loadDeliveryAddress());
    } else if (address?.userRef) {
      localStorage.removeItem('address');
    }
    if (!localStorage.getItem('provinces')) {
      dispatch(loadProvinces());
    }
    if (!localStorage.getItem('municipalities')) {
      dispatch(loadMunicipalities(-1));
    }
  }, []);

  useEffect(() => {
    const currentAddress = JSON.parse(localStorage.getItem("address"));
    if (!isAuth && currentAddress?.userRef) {
      updateAddresses();
    }
    if (isAuth() && !addresses?.length) {
      dispatch(loadDeliveryAddress());
    }
  }, [isAuth()]);

  useEffect(() => {
    if (!localStorage.getItem('address')) {
      updateAddresses();
    }
  }, [localStorage.getItem('address')]);

  useEffect(() => {
    if (options?.length > 0) {
      const localAddress = JSON.parse(localStorage.getItem('address'));
      if (localAddress === null && isAListRoute()) {
        setShowDialogForm(true);
      } else {
        setAddress(localAddress);
      }
      dispatch(setLoadingMaskStatus(false));
    }
  }, [options]);

  useEffect(() => {
    if (topBarAddressSelected) {
      localStorage.setItem('address', JSON.stringify(topBarAddressSelected));
      setAddress(topBarAddressSelected);
    }
  }, [topBarAddressSelected]);

  useEffect(() => { forceClose && setGrow(false); }, [forceClose, minimalView]);

  useEffect(() => { handleOpened && handleOpened(grow); }, [grow]);

  useEffect(() => { updateAddresses(); }, [municipalities, addresses]);

  // Methods

  const isAListRoute = () => ["/"].includes(props.history.location.pathname);
  const isHavana = (municipality) => municipality.provinceid === 13;
  const handleSelectOnBlurEvent = () => setGrow(false);
  const getSelectedAddress = () => address?.name || translation.Address.selectAddress;

  const updateAddresses = () => {
    let newOptions = [];
    if (addresses?.length > 0) {
      newOptions = newOptions.concat(addresses);
    }
    let list = [];
    if (provinces?.length && municipalities.length) {
      list = extractPlaces(provinces, municipalities);
      setPlaces(list);
    }
    newOptions = newOptions.concat(list);

    setSelectComponent(<CustomSelect
      components={{ Option, Control, Menu, IndicatorSeparator, DropdownIndicator }}
      options={newOptions}
      autoFocus={true}
      onBlur={handleSelectOnBlurEvent}
      onChange={handleSelectAddress}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
      isClearable={true}
      isSearchable={true}
      menuIsOpen={true}
    />)

    setOptions(newOptions);
  }

  const extractPlaces = (provinces, municipalities) => {
    const provincesList = provinces
      .map(({ name, id }) => ({
        id,
        municipalityid: null,
        provinceid: id,
        name: isHavana({ id }) ? 'Habana' : name,
        details: '',
      })).filter(item => item);

    const havanaIndex = provincesList.findIndex(isHavana);
    const havana = { ...provincesList[havanaIndex] };
    provincesList.splice(havanaIndex, 1);
    provincesList.unshift(havana);

    const municips = municipalities
      .sort((a, _) => a.provinceid === 13 ? -1 : 1)
      .map(({ id, name, provinceid }) => {
        const province = provinces.find(({ id }) => id === provinceid);
        return {
          id,
          provinceid: provinceid,
          municipalityid: id,
          localityid: null,
          name,
          details: province?.name || ''
        }
      }).filter(item => item);
    return [].concat(provincesList).concat(municips);
  }

  const handleSelectAddress = (address) => {
    setShowDialogForm(false);
    setGrow(false);
    if (address) {
      dispatch(setDeliveryAddressOnTopBarSelected(address));
      search(address);
    }
  }

  const search = (place) => {
    if (place) {
      const { userRef, municipalityid, provinceid, localityid } = place;
      const auxFilter = {
        ...defaultFilters,
        municipalityid,
        provinceid,
        localityid,
        businessCategory: businessCategorySelected,
        categories: [] // categoriesSelected,
      }

      const isAtHome = props.history.location.pathname === '/';
      if (!isAtHome && !userRef) {
        props.history.push('/');
      }
      if (isAtHome) {
        dispatch(showAllBusinessForProductType(auxFilter.productTypes?.length));
        if (showAllBusinessByProductType) {
          dispatch(loadRestaurantsCarouselsList(preview, auxFilter, 1, defaultPageCounter));
        } else {
          dispatch(loadBusinessGroupByProductType(previewBusiness, auxFilter, 1, defaultPageCounter));
        }
      }
    }
  }

  const handleOpenAddresses = () => {
    setGrow(!grow);
    setTimeout(() => {
      const select = document.getElementById('react-select-2-input');
      if (select) {
        select.focus();
        select.click();
      }
    }, 300);
  }

  // UI stuffs for select

  const inputComponent = ({ ...props }) => {
    const inputProps = { ...props };
    delete inputProps.inputRef;
    return <div className="button-address">
      <PdfIcon width="20" height="20" icon="system/location_solid_black" alt="loc" ext="svg" />
      <div {...inputProps} className="input-container" />
    </div>

  };

  const Option = props => {
    const { data, innerRef, innerProps } = props;
    return data.id ?
      <Grid container className="address-select_items" component="div">
        <Grid container item md={1} sm={1} xs={2} component="div" className="address-select_items_actions">
          <PdfIcon width="20" height="20" icon="system/location_solid_black" alt="loc" ext="svg" />
        </Grid>
        <Grid container item ref={innerRef} {...innerProps} md={11} sm={11} xs={10} component="div"
          className="address-select_items_content">
          <Grid container item md={12} sm={12} xs={12} component="div" className="info-name">
            <span>{data.name}</span>
          </Grid>
          <Grid container item md={12} sm={12} xs={12} component="div" className="info-address">
            <span>{data.details}</span>
          </Grid>
        </Grid>
      </Grid> : null;
  };

  const IndicatorSeparator = () => null;

  const DropdownIndicator = props => {
    let color = props.isFocused ? '#2c2c2c' : '#c2d1d9';
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? <ArrowDropUp style={{ color }} component="svg" /> :
            <ArrowDropDown style={{ color }} component="svg" />}
        </components.DropdownIndicator>
      )
    );
  };

  const Control = props => {
    return (
      <CssTextField
        fullWidth
        autoFocus
        ref={inputRef}
        variant="filled"
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  };

  const Menu = props => {
    return <Paper className={classes.paper}{...props.innerProps}>{props.children}</Paper>
  };

  return (
    <li className={`${!grow ? 'bs' : 'd8'} bt bu af`} style={{ padding: 0 }}>
      <div className={`${!grow ? 'bs' : 'd8'} bt bu`}>
        {!grow ?
          <button className="button-address btn-custom" style={{ textTransform: 'none' }} onClick={() => handleOpenAddresses()}>
            <LocationOn style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
            {address?.name ? <span style={{ marginRight: '5px' }}>
              {browserOrder?.deliveryOrderType === "DELIVERY" ? translation.Address.deliverIn : translation.Address.deliverAt}
            </span> : null}
            {!minimalView ? <span>{getSelectedAddress()}</span> : null}
            <ArrowDropDown />
          </button> :
          null
        }
        <div style={!grow ? { 'display': 'none' } : { 'display': 'block' }}>
          {selectComponent}
        </div>

        <AddressesModal showDialogForm={showDialogForm} setShowDialogForm={() => setShowDialogForm(false)} options={options}
          handleContinue={handleSelectAddress} required={true} />
      </div>
    </li>

  );
};

