import {setLoadingMaskStatus} from "../../../component/Mask/store/mask.actions";
import {notifyError} from "../../notification/store/notification.actions";
import {get, post,} from "../../../core/services";

const baseUrl = "score";

export const CLEAR_CS_STATE = "CLEAR_CS_STATE";
export const CHECK_CS_REGISTER_SUCCESS = "CHECK_CS_REGISTER_SUCCESS";
export const CHECK_CS_REGISTER_FAILURE = "CHECK_CS_REGISTER_FAILURE";
export const CREATE_CS_REGISTER_SUCCESS = "CREATE_CS_REGISTER_SUCCESS";
export const CREATE_CS_REGISTER_FAILURE = "CREATE_CS_REGISTER_FAILURE";

function checkCsRegisterSuccess(response) {
    return {
        type: CHECK_CS_REGISTER_SUCCESS,
        response,
    };
}

function checkCsRegisterFailure(response) {
    return {
        type: CHECK_CS_REGISTER_FAILURE,
        response,
    };
}

function createCsRegisterSuccess(response) {
    return {
        type: CREATE_CS_REGISTER_SUCCESS,
        response,
    };
}

function createCsRegisterFailure(response) {
    return {
        type: CREATE_CS_REGISTER_FAILURE,
        response,
    };
}

export function clearCsState() {
    return {
        type: CLEAR_CS_STATE,
    };
}

export function checkCsRegister(ORDER_ID) {
    return (dispatch) => {
        get(`${baseUrl}/getRestaurantData?orderId=${ORDER_ID}`)
            .then((response) => {
                dispatch(checkCsRegisterSuccess(response));
            })
            .catch((response) => {
                const errMsg = response && response.data ? response.data.message : "ERROR!";
                dispatch(checkCsRegisterFailure(errMsg));
                dispatch(notifyError(errMsg));
            })
            .finally(() => {
                dispatch(setLoadingMaskStatus(false));
            });
    };
}

export function createCsRegister(DATA) {
    return dispatch => {
        dispatch(setLoadingMaskStatus(true));
        post(`${baseUrl}/scoreForRestaurant`, DATA)
            .then(response => {
                dispatch(createCsRegisterSuccess(response));
            })
            .catch(error => {
                let errObj = error.response && error.response.data ? error.response.data : {};
                if (errObj) {
                    const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
                    dispatch(createCsRegisterFailure(errMsg));
                    dispatch(notifyError(errMsg));
                }
            })
            .finally(() => {
                dispatch(setLoadingMaskStatus(false));
            });
    };
}