
import { useDispatch } from "react-redux";
import { validateAddressInfo } from "modules/Checkout/store/checkout.actions";

const useAddress = () => {
  const dispatch = useDispatch();

  const dispatchValidateAddress = (data) => {
    const {
      id, localityId, municipalityId, provinceId, restaurantId, userRef
    } = data;
    dispatch(validateAddressInfo({
      id, localityId, municipalityId, provinceId, restaurantId, userRef
    }));
  }

  return {
    dispatchValidateAddress,
  };
};

export default useAddress;