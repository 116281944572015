import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { makeStyles, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        flexGrow: 1,
        background: "transparent !important",
        marginBottom: "1em !important",
    },
    input: {
        '&::placeholder': {
            color: "gray",
            fontStyle: 'italic !important',
        },
        display: "flex",
        height: "39px !important",
        background: "transparent !important",
    },
    helperText: {
        fontSize: ".8em",
    },
    errorText: {
        fontStyle: "italic",
        fontSize: ".8em",
        color: "red",
    },
    lengthMessage: {
        fontStyle: "italic",
        fontSize: ".8em",
        color: "orange",
    }
}));

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#2c2c2c"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#2c2c2c"
        },
        "& .MuiOutlinedInput-root": {
            background: "transparent !important",
            "& fieldset": {
                borderRadius: "5px"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#2c2c2c"
            }
        }
    }
})(TextField);

function TextMaskCustom(props) {
    const { mask, guide, inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            placeholderChar={'\u2000'}
            mask={mask}
            showMask={false}
            guide={guide}
        />
    );
}

// TextMaskCustom.propTypes = {
//     inputRef: PropTypes.func.isRequired,
// };

export default function CustomInputTextMask(props) {
    const classes = useStyles();

    function onChange(v) {
        if (props.onChange) {
            props.onChange(v);
        }
    }

    function onBlur(v) {
        if (props.onBlur) {
            props.onBlur(v);
        }
    }

    return (
        <div className={classes.root}>
            <CssTextField
                fullWidth
                id={props.id}
                type={props.inputType}
                value={props.value}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder}
                onChange={v => onChange(v)}
                onBlur={v => onBlur(v)}
                className={classes.textField}
                variant="outlined"
                label={props.fieldLabel}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    inputComponent: TextMaskCustom,
                    inputProps: {
                        className: classes.input,
                        inputRef: props?.innerRef,
                        children: props.children,
                        mask: props.hideMask ? props.mask2 : props.mask,
                        guide: props.guide,
                        ...props.innerProps
                    },
                    endAdornment: props.endAdornment
                }}
            />
            {props.errorMessage
                ? <FormHelperText id="component-helper-text" className={classes.errorText}>
                    {props.errorMessage}
                </FormHelperText>
                : props.helperText
                    ? <FormHelperText id="component-helper-text" className={props.helperTextStyle || classes.helperText}>
                        {props.helperText}
                    </FormHelperText>
                    : null}
        </div>
    );
}

CustomInputTextMask.defaultProps = {
    fieldLabel: "",
    placeholder: null,
    inputType: "text",
    helperText: null,
    helperTextStyle: null,
    autoFocus: false,
    error: false,
    errorMessage: null,
    guide: true,
    hideMask: false,
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    mask2: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};