/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./SearchModal.Style.scss";
import useTranslation from "translations";
import UberModal from "component/UberModal/UberModal";
import { browserHistory } from "../../utils/History";
import { selectProfile } from "modules/profile/store/profile.reducers";
import { selectIsLoading, selectList, selectTerm, searchBoxKey } from "store/searchBox/reducers";
import { loadFromCache, searchList } from "store/searchBox/actions";
import SearchTabs from "./components/SearchTabs";
import BusinessSwipper from "./components/BusinessSwipper";
import SearchInput from "./components/SearchInput";
import BusinessList from "./components/BusinessList";
import Picture from "component/Picture/Picture";

const categories = [
  { nameEs: "Todos", nameEn: "All" },
  { nameEs: "Restaurantes", nameEn: "Restaurants" },
  { nameEs: "Supermercado", nameEn: "Supermarket" },
  { nameEs: "Farmacia", nameEn: "Drug Store" }
].map(cat => ({ ...cat, names: [cat.nameEs, cat.nameEn] }));

const SEARCH_STATE_LOADING = 'loading';
const SEARCH_STATE_FOUNDED = 'founded';
const SEARCH_STATE_NOT_FOUNDED = 'notFounded';
const SEARCH_STATE_INITIAL = 'initial';

export default function SearchModal(props) {
  const { showDialogForm, setShowDialogForm, required = false } = props;
  const dispatch = useDispatch();
  const translation = useTranslation();

  // store
  const businesses = useSelector(selectList);
  const storeTerm = useSelector(selectTerm);
  const user = useSelector(selectProfile);
  const loading = useSelector(selectIsLoading);

  // states 
  const [list, setList] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [term, setTerm] = useState("");
  const [businessPlaceholders, setBusinessPlaceholders] = useState(10);
  const [searchState, setSearchState] = useState(SEARCH_STATE_INITIAL);

  useEffect(() => {
    dispatch(loadFromCache());

    // return {
    //   cleanup
    // }
  }, []);

  const storeResult = (term, list) => {
    if (term?.length && list?.length) {
      localStorage.setItem(searchBoxKey, JSON.stringify({
        term,
        list
      }));
    } else if (term?.length && !list?.length) {
      localStorage.removeItem(searchBoxKey);
    }
  }

  useEffect(() => {
    const existingCategories = [...new Set([
      ...businesses.flatMap(business => business.categories).flatMap(cat => cat.name)
    ])];
    const filteredCategories = categories.filter(
      cat => existingCategories.includes(cat.nameEs) || existingCategories.includes(cat.nameEn)
    );
    setBusinessCategories([categories[0], ...filteredCategories]);
  }, [businesses]);

  useEffect(() => { setTerm(storeTerm) }, [storeTerm])

  useEffect(() => {
    const selectedCategory = businessCategories[tabValue];
    if (selectedCategory) {
      const list = selectedCategory.nameEn === 'All' ? businesses : businesses.filter(
        business => {
          const catNames = business.categories.flatMap(cat => cat.name);
          return catNames.includes(selectedCategory.nameEs) || catNames.includes(selectedCategory.nameEn);
        }
      );
      setList(list);
      storeResult(term, list);
    } else {
      setList(businesses);
      storeResult(term, businesses);
    }
  }, [businesses, tabValue]);

  const searchByTerm = (term = "") => {
    setTerm(term);
    const address = JSON.parse(localStorage.getItem('address') || '{}');
    dispatch(searchList({
      pointer: 1,
      counter: 10,
      offersPreview: 10,
      accountId: user?.accountId,
      provinceid: address?.provinceId || address?.provinceid,
      municipalityid: address?.municipalityId || address?.municipalityid,
      localityid: null,
      deliveryOrderType: "DELIVERY",
      search: term,
      categories: [],
    }, false));
  };

  const goToBusiness = (event, business, offer = undefined) => {
    event.stopPropagation();
    setShowDialogForm(false);
    const route = {
      pathname: business.slug,
      state: offer ? { offer: offer.offerSlug } : {},
    };
    if (window.location.pathname !== '/') {
      browserHistory.replace(route);
      browserHistory.go(0)
    } else {
      browserHistory.push(route);
    }
  }

  const handleCloseModal = () => {
    setShowDialogForm(false);
    setList([]);
    setBusinessCategories([]);
    setBusinessCategories([]);
    setTabValue(0);
    setTerm("");
    setBusinessPlaceholders(10);
    setSearchState(SEARCH_STATE_INITIAL);
  }

  useEffect(() => {
    if (loading) {
      setSearchState(SEARCH_STATE_LOADING);
    } else if (term?.length && list.length) {
      setSearchState(SEARCH_STATE_FOUNDED);
    } else if (term?.length && !list.length) {
      setSearchState(SEARCH_STATE_NOT_FOUNDED);
    } else if (!term?.length && !list.length) {
      setSearchState(SEARCH_STATE_INITIAL);
    } else {
      setSearchState(SEARCH_STATE_INITIAL);
    }
  }, [term, list, loading]);

  const EMPTY_SEARCH = <div>
    <h1 className="general-placeholder">{translation.Search.notFoundPlaceholder}</h1>
    <Picture src="magnifier.png" width="100" height="100" />
  </div>;

  const INITIAL_SEARCH = <div></div>;

  return showDialogForm && <UberModal parentClass='searchUberModal' onClose={handleCloseModal}
    height={UberModal.sizes.HUGE}
    title={<h4>{translation.Search.title}</h4>}
    subtitle={<h1>{translation.Search.subtitle}</h1>}
    required={required}
    content={
      <div style={{ padding: '0px 32px 32px' }}>
        <div className="autosuggest-container">
          <SearchInput term={term} searchByTerm={searchByTerm} />
        </div>
        {[SEARCH_STATE_NOT_FOUNDED].includes(searchState) ? EMPTY_SEARCH :
          [SEARCH_STATE_INITIAL].includes(searchState) ? INITIAL_SEARCH :
            <div>
              <div className="container" style={{ overflow: 'hidden' }}>
                <div className="tab-container">
                  <SearchTabs loading={loading} categories={businessCategories} tabValue={tabValue} setTabValue={setTabValue} />
                </div>
                <BusinessSwipper loading={loading} list={list} goToBusiness={goToBusiness} businessPlaceholders={businessPlaceholders} />
              </div>
              <div className="separator" />
              <div className="container" style={{ overflow: 'hidden' }}>
                <BusinessList loading={loading} list={list} goToBusiness={goToBusiness} businessPlaceholders={businessPlaceholders} />
              </div>
            </div>}
      </div>
    } />
}
