import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, createStore } from "redux";
import { createFilter } from "redux-persist-transform-filter";
import { persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage";
import authMiddleware from "./Middleware/AuthMiddleware";
import { browserHistory } from "../utils/History";
import rootReducer from "../rootReducer";
import verificationMiddleware from "./Middleware/VerificationMiddleware";

export default function configureStore(preloadState) {
  const authPersistedFilter = createFilter("auth", [
    "access_token",
    "refresh_token",
    "expires_in",
    "stamp",
    "user",
  ]);

  const deliveryPersistedFilter = createFilter("delivery", [
    "deliveryInfo",
  ]);

  const checkoutPersistedFilter = createFilter("checkout", [
    "browser_order",
  ]);

  const filteringPersistedFilter = createFilter("filters", [
    "total",
    "filters",
    "pageCounter",
    "message",
    "current",
  ]);

  const persistConfig = {
    key: "delivery",
    storage,
    whitelist: ["cart", "filters", "auth", "lang", "delivery", "checkout"],
    transforms: [
      authPersistedFilter,
      deliveryPersistedFilter,
      checkoutPersistedFilter,
      filteringPersistedFilter,
    ],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middleware = [
    authMiddleware,
    verificationMiddleware,
    thunkMiddleware,
    routerMiddleware(browserHistory),
  ];
  const middlewareEnhancer = applyMiddleware(...middleware);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  return createStore(persistedReducer, preloadState, composedEnhancers);
}
