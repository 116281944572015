export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDZJfvlgEH8C_Sb_PSmZzysDmk2PgpV0F8",
  authDomain: "cuballama-restaurantes.firebaseapp.com",
  projectId: "cuballama-restaurantes",
  storageBucket: "cuballama-restaurantes.appspot.com",
  messagingSenderId: "730742614983",
  appId: "1:730742614983:web:9b928fc82cf5d9949e87e2",
  measurementId: "G-7V3XYJCPYW"
};

export const FACEBOOK_PIXEL_CONFIG = {
  key: "3371688316266442",
};

export const FACEBOOK_PIXEL_OPTIONS = {
  autoConfig: true,
  debug: false,
};