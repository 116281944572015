import React from "react";
import "./DeliveryModeSwitch.scss";

import SwitchSelector from "react-switch-selector";
import { DELIVERY } from "constants/delivery";

const DeliveryModeSwitch = ({ defaultValue = DELIVERY, onChange, restaurantDetails }) => {
  const options = restaurantDetails?.deliveryType
    .sort((a, _) => a.delivery === DELIVERY ? -1 : 1)
    .map(type => {
      const label = type.delivery === DELIVERY ? 'Tiempo de Entrega' : 'Recogida';
      let subLabel = type.active ? type.time : 'No Disponible';
      if (type.active && type.delivery === DELIVERY && restaurantDetails.deliveryCost) {
        let cost = parseFloat(restaurantDetails.deliveryCost, 10);
        cost = cost === 0 ? 'Entrega Gratis' : `$${cost.toFixed(2).toString()}`;
        subLabel = `${subLabel} - ${cost}`;
      }
      return {
        label: (
          <p className={type.active ? '' : 'disabled'}>
            {label} {<span style={{ display: "block" }}>{subLabel}</span>}
          </p>
        ),
        value: type.delivery,
        selectedBackgroundColor: "#ffffff",
      };
    });

  const initialSelectedIndex = options?.findIndex(({ value }) => value === defaultValue);

  return (
    <div className="switch-selector-container" style={{
      margin: "10px 1em",
      width: "calc(100% - 2em)"
    }}>
      <SwitchSelector
        onChange={onChange}
        options={options}
        initialSelectedIndex={initialSelectedIndex}
        backgroundColor={"#f2f2f2"}
        fontColor={"#000000"}
      />
    </div>
  );
}

export default DeliveryModeSwitch;
