import React, {useEffect, useState} from "react";
import './css/CustomerSatisfactionStyles.scss';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import useTranslation from "../../translations";
import {Star, StarBorder} from "@material-ui/icons";
import CustomInput from "../../component/CustomInput/CustomInput";
import _ from "lodash";
import {customDecodeNoCompressed, tConvertWithoutSeconds, validateFieldByArray} from "../../utils/AuxiliarFunctions";
import {selectCsState} from "./store/customer-satisfaction.reducers";
import {checkCsRegister, clearCsState, createCsRegister} from "./store/customer-satisfaction.actions";
import {getLoaderMaskStatus} from "../../component/Mask/store/mask.reducers";

const CustomerSatisfaction = (props) => {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const loaderMaskStatus = useSelector(getLoaderMaskStatus);
  const csState = useSelector(selectCsState);
  const [review, setReview] = useState({
    score: 0,
    opinion: '',
    orderId: '',
    restaurantId: '',
  });
  const [orderID, setOrderID] = useState(null);
  const [restaurant, setRestaurant] = useState({});
  const [priceRange, setPriceRange] = useState(null);
  const [cuisineTypesList, setCuisineTypesList] = useState(null);
  const [deliveryTimeRestaurant, setDeliveryTimeRestaurant] = useState(null);
  const [csRegisterCheckedResponse, setCsRegisterCheckedResponse] = useState('');
  const [existsScore, setExistsScore] = useState(false);
  const [cuisineTypes, setCuisineTypes] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState('');
  const [deliveryTimeUnit, setDeliveryTimeUnit] = useState('');
  const [sizeContainerScore, setSizeContainerScore] = useState('');
  const [sizeContainerTag, setSizeContainerTag] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    handleContainerSize();
    window.addEventListener('resize', handleContainerSize);
    const param = props.match && props.match.params ? props.match.params.order : null;
    setOrderID(param);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleContainerSize();
    //eslint-disable-next-line
  }, [review.score]);

  useEffect(() => {
    if (orderID) {
      dispatch(checkCsRegister(customDecodeNoCompressed(orderID)));
    }
    //eslint-disable-next-line
  }, [orderID]);

  useEffect(() => {
    if (csState && csState.cs_register_checked_success) {
      setCsRegisterCheckedResponse("success");
      setExistsScore(csState.cs_existsScore);
      setRestaurant(csState.cs_restaurant);
      dispatch(clearCsState());
    }
    if (csState && csState.cs_register_created_success) {
      setExistsScore(csState.cs_existsScore);
      setRestaurant(csState.cs_restaurant);
      dispatch(clearCsState());
    }
    if (csState && csState.cs_register_checked_failure) {
      setCsRegisterCheckedResponse("failure");
      dispatch(clearCsState());
    }
    if (csState && csState.cs_register_created_failure) {
      dispatch(clearCsState());
    }
    //eslint-disable-next-line
  }, [csState]);

  useEffect(() => {
    if (restaurant && restaurant.slug) {
      const priceRange = restaurant.averagePriceNomByAveragePriceNomid;
      const cuisineTypesList = restaurant.restaurantCuisineTypeNomsById;
      const deliveryTimeRestaurant = restaurant.deliveryTimeRestaurantDto;
      setPriceRange(priceRange);
      setCuisineTypesList(cuisineTypesList);
      setDeliveryTimeRestaurant(deliveryTimeRestaurant);
    }
    //eslint-disable-next-line
  }, [restaurant]);

  useEffect(() => {
    if (!cuisineTypes) {
      if (cuisineTypesList && cuisineTypesList.length > 0) {
        let tempTypes = '';
        _.forEach(cuisineTypesList, function (types) {
          if (types.cuisineTypeNomByCuisineTypeNomid) {
            if (tempTypes === '') {
              tempTypes = types.cuisineTypeNomByCuisineTypeNomid.name;
            } else {
              tempTypes += ', ' + types.cuisineTypeNomByCuisineTypeNomid.name;
            }
          }
        });
        setCuisineTypes(tempTypes);
      }
    }
    // eslint-disable-next-line
  }, [cuisineTypesList]);

  useEffect(() => {
    if (deliveryTimeRestaurant) {
      if (!deliveryTime) {
        let auxDeliveryTime = deliveryTimeRestaurant.deliveryTime;
        setDeliveryTime(auxDeliveryTime);
      }
      if (!deliveryTimeUnit) {
        let auxDeliveryTimeUnit = translation.Code === "ES" ? deliveryTimeRestaurant.deliveryTimeUnitEs : deliveryTimeRestaurant.deliveryTimeUnitEn;
        setDeliveryTimeUnit(auxDeliveryTimeUnit);
      }
    }
    // eslint-disable-next-line
  }, [deliveryTimeRestaurant]);

  useEffect(() => {
    validateForm();
    //eslint-disable-next-line
  }, [review]);

  const handleContainerSize = () => {
    const width = window.innerWidth;
    if (review.score) {
      setSizeContainerScore(12);
      setSizeContainerTag(12);
    } else {
      if (width <= 280) {
        setSizeContainerScore(5);
        setSizeContainerTag(7);
      } else if (width < 320) {
        setSizeContainerScore(6);
        setSizeContainerTag(6);
      } else if (width < 576) {
        setSizeContainerScore(6);
        setSizeContainerTag(6);
      } else if (width <= 768) {
        setSizeContainerScore(4);
        setSizeContainerTag(8);
      } else {
        setSizeContainerScore(4);
        setSizeContainerTag(8);
      }
    }
  };

  const rate = value => setReview({...review, score: value});
  const handleChangeInputForm = e => setReview({...review, opinion: e.target.value});

  const send = () => {
    let data = review;
    data['orderId'] = customDecodeNoCompressed(orderID);
    data['restaurantId'] = restaurant.id;
    setReview(data);
    dispatch(createCsRegister(data));
  };

  const goBack = () => {
    props.history.push("/");
  };

  const validateForm = () => {
    let errors = {};
    let score = validateFieldByArray(review.score, ["required"], translation.Validations["required"]);
    if (score) {
      errors.score = score;
    }
    setValid(_.isEmpty(errors));
  };

  return (
    <Grid container item lg={12} md={12} xs={12} className="customer-satisfaction">
      {loaderMaskStatus
        ? null
        : csRegisterCheckedResponse && csRegisterCheckedResponse === "success"
          ? existsScore
            ? <div className="cs-register-message">
              <div className="cs-container">
                <div className="basic-card small relative mb-20">
                  <div className="d-flex justfy-content-center flex-column">
                    <div className="expanded-block">
                      <img className="card-icon" src={"images/icons/system/circle-check-green.svg"} alt=""/>
                      <div className="FlexColumn">
                        <h4>{translation.CustomerSatisfaction.completeRatingMessage}</h4>
                        <p>{translation.CustomerSatisfaction.thanksMessage}</p>
                      </div>
                    </div>
                    <div className="d-flex justfy-content-center fixed-block mt-50">
                      <button className="button--primary" onClick={() => goBack()}>
                        {translation.CustomerSatisfaction.goBack}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <Grid container item lg={12} md={12} xs={12} className="cs-form">
              <Grid container item lg={12} md={12} xs={12} className="cs-restaurant">
                <Grid container item lg={3} md={3} sm={3} xs={12} className="cs-image-box">
                  <div style={{
                    background: `#f2f5f7 url("${restaurant && restaurant.imgSm ? restaurant.imgSm : "./images/picture/no-image.png"}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                  }} className="cs-image-box-background"/>
                </Grid>
                <Grid container item lg={9} md={9} sm={9} xs={12} className="cs-restaurant-info">
                  <h4 className="cs-title">{restaurant.name}</h4>
                  <div className="cs-label" style={{display: "grid"}}>
                                <span>
                                  {`${restaurant.open ? tConvertWithoutSeconds(restaurant.open) : ''} - ${restaurant.close ? tConvertWithoutSeconds(restaurant.close) : ''}`}
                                </span>
                    <span className="shipping">
                                  {priceRange ? `$${priceRange.lowerPrice} - $${priceRange.higherPrice} | ${deliveryTime} ${deliveryTimeUnit}` : null}
                                </span>
                    <span>{cuisineTypes || null}</span>
                  </div>
                </Grid>
              </Grid>
              <Grid container item lg={12} md={12} xs={12} className="cs-form-subtitle">
                <span>{translation.CustomerSatisfaction.subtitle}</span>
              </Grid>
              <Grid container item lg={12} md={12} xs={12} className="cs-review">
                <Grid container item lg={12} md={12} xs={12} className="cs-score-label">
                  <span>{translation.CustomerSatisfaction.labelInputScore}</span>
                </Grid>
                <Grid container item lg={12} md={12} xs={12} className="cs-score">
                  <Grid container item className="rate" lg={sizeContainerScore}
                        md={sizeContainerScore} sm={sizeContainerScore} xs={sizeContainerScore}>
                    <div style={{textAlign: 'center'}}>
                      {review.score >= 1 ?
                        <Star component={"svg"} className="icons" onClick={() => rate(1)}/>
                        : <StarBorder component={"svg"} className="icons"
                                      onClick={() => rate(1)}/>}
                      {review.score >= 2 ?
                        <Star component={"svg"} className="icons" onClick={() => rate(2)}/>
                        : <StarBorder component={"svg"} className="icons"
                                      onClick={() => rate(2)}/>}
                      {review.score >= 3 ?
                        <Star component={"svg"} className="icons" onClick={() => rate(3)}/>
                        : <StarBorder component={"svg"} className="icons"
                                      onClick={() => rate(3)}/>}
                      {review.score >= 4 ?
                        <Star component={"svg"} className="icons" onClick={() => rate(4)}/>
                        : <StarBorder component={"svg"} className="icons"
                                      onClick={() => rate(4)}/>}
                      {review.score >= 5 ?
                        <Star component={"svg"} className="icons" onClick={() => rate(5)}/>
                        : <StarBorder component={"svg"} className="icons"
                                      onClick={() => rate(5)}/>}
                    </div>
                  </Grid>
                  {review.score ? null :
                    <Grid container item className="cs-tag" lg={sizeContainerTag}
                          md={sizeContainerTag} sm={sizeContainerTag} xs={sizeContainerTag}>
                      <div className="arrow-left"/>
                      <div className="arrow-content">
                        <span>{translation.CustomerSatisfaction.labelInputScoreTag}</span>
                      </div>
                    </Grid>
                  }
                </Grid>
                <Grid container item lg={12} md={12} xs={12} className="cs-input-detail">
                  <CustomInput
                    multiline
                    rows="4"
                    rowsMax="6"
                    id="opinion"
                    fieldLabel={`${translation.CustomerSatisfaction.labelInputOpinion}`}
                    value={review.opinion}
                    onChange={e => handleChangeInputForm(e)}
                  />
                </Grid>
                <Grid container item lg={12} md={12} xs={12} className="cs-input-detail">
                  <button onClick={() => send()} disabled={!valid} className="button--primary">
                    {translation.CustomerSatisfaction.send}
                  </button>
                </Grid>
              </Grid>
            </Grid>
          : csRegisterCheckedResponse && csRegisterCheckedResponse === "failure"
            ? <div className="cs-register-message">
              <div className="cs-container">
                <div className="basic-card small relative mb-20">
                  <div className="d-flex justfy-content-center flex-column">
                    <div className="expanded-block">
                      <img className="card-icon" src={"images/icons/system/circle-exclamation-red.svg"} alt=""/>
                      <div className="FlexColumn">
                        <h4>{translation.CustomerSatisfaction.errorMessageTitle}</h4>
                        {translation.CustomerSatisfaction.errorMessage}
                      </div>
                    </div>
                    <div className="d-flex justfy-content-center fixed-block mt-50">
                      <button className="button--primary" onClick={() => goBack()}>
                        {translation.CustomerSatisfaction.goBack}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : null
      }
    </Grid>
  )
}

export default CustomerSatisfaction;