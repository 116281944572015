import * as _ from "lodash";
import { simplePost } from "../../../../core/services";
import { notifyError } from "../../../notification/store/notification.actions";

export const FETCHED_TUBOLSILLO_CREDIT = "FETCHED_TUBOLSILLO_CREDIT";
export const DONE_TUBOLSILLO_PAYMENT = "DONE_TUBOLSILLO_PAYMENT";
export const SET_ERROR_TUBOLSILLO_PAYMENT = "SET_ERROR_TUBOLSILLO_PAYMENT";

const baseUrl = "payment/tubolsillo";

function doneFetchTuBolsilloCredit(data) {
  return {
    type: FETCHED_TUBOLSILLO_CREDIT,
    data,
  };
}

function doneTuBolsilloPayment(data) {
  return {
    type: DONE_TUBOLSILLO_PAYMENT,
    data,
  };
}

function setErrorTuBolsilloPayment(error) {
  return {
    type: SET_ERROR_TUBOLSILLO_PAYMENT,
    data: error ? error.data : "error pagando con Tu Bolsillo",
  };
}

export function doFetchTuBolsilloCredit() {
  return (dispatch) => {
    simplePost(`${baseUrl}/credit`)
      .then((response) => {
        dispatch(
          doneFetchTuBolsilloCredit(
            response.data && _.isNumber(response.data) && response.data > 0
              ? response.data
              : 0
          )
        );
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj.message
            ? `${errObj.error}: ${errObj.message}`
            : `${errObj.status}: ${errObj.statusText}`;
          dispatch(notifyError(errMsg));
          dispatch(doneFetchTuBolsilloCredit(0));
          window.location = window.location + "_error";
        }
      });
  };
}

export function doTuBolsilloPayment(amount, paymentRequest, history) {
  return (dispatch) => {
    simplePost(`${baseUrl}/make/payment?amount=${amount}`, paymentRequest)
      .then(({ data }) => {
        dispatch(doneTuBolsilloPayment(data));
        history.push({ pathname: "/prv/pay_success" });
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(notifyError(errMsg));
          dispatch(setErrorTuBolsilloPayment(error.response));
          window.location = window.location + "_error";
          history.push({ pathname: "/prv/pay_error" });
        }
      });
  };
}
