import { publicGet } from "core/services/ApiRequest";

const baseUrl = "filter";

export const LOAD_SUGGESTIONS = "LOAD_SUGGESTIONS";
export const LOADED_SUGGESTIONS = "LOADED_SUGGESTIONS";

function loadedSuggestions(suggestions) {
  return {
    type: LOADED_SUGGESTIONS,
    suggestions,
  };
}

export function loadBannerMessages(query, count = 10) {
  return (dispatch) => {
    publicGet(`${baseUrl}/search?query=${query}&count=${count}`)
      .then((resp) => resp.data)
      .then((suggestions) => dispatch(loadedSuggestions(suggestions)));
  };
}
