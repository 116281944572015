import { combineReducers } from "redux";
import payment from "modules/payment/store/payment.reducers";
import pay from "modules/Pay/store/pay.reducers";
import checkout from "modules/Checkout/store/checkout.reducers";
import profile from "modules/profile/store/profile.reducers";
import alert from "modules/alert/store/alert.reducers";
import banner from "modules/home/store/banner.reducers";
import topbar from "modules/Topbar/store/reducers";
import register from "modules/register/store/register.reducers";
import notification from "modules/notification/store/notification.reducers";
import delivery from "modules/payment/store/delivery.reducers";
import address from "modules/home/store/home.reducers";
import cart from "modules/cart/store/cart.reducers";
import cuisine_type from "modules/home/component/CuisineType/store/cuisine-type.reducers";
import trending from "modules/home/component/Trending/store/trending.reducers";
import restaurant_detail from "modules/BusinessDetail/store/restaurant-detail.reducers";
import auth from "modules/login/store/login.reducers";
import loader_mask from "component/Mask/store/mask.reducers";
import delivery_address from "component/FormAddAddress/store/form-add-address.reducers";
import lang from "component/LanguagePicker/store/language-picker.reducers";
import filters from "store/filter/filter.reducers";
import nom from "store/nom/nom.reducers";
import recover from "./modules/recoverPassword/store/recover-password.reducers";
import restaurant_carousel from "./modules/home/component/RestaurantCarousel/store/restaurant-carousel.reducers";
import offer_carousel from "./modules/home/component/OfferCarousel/store/offer-carousel.reducers";
import availability_validation from "./modules/availabilityValidation/store/availability-validation.reducers";
import promotions_carousel from "./component/PromotionsCarousel/store/promotions-carousel.reducers";
import customer_satisfaction from "./modules/customer_satisfaction/store/customer-satisfaction.reducers";
import purchase from "./modules/purchase_history/store/purchase.reducers";
import business_categories from "./modules/home/component/BusinessCategories/store/business-categories.reducers";
import user_cards from "./modules/CardManagement/store/card-management.reducers";
import search from "./store/search/search.reducers";
import searchBox from "./store/searchBox/reducers";

export default combineReducers({
  address,
  delivery_address,
  filters,
  banner,
  business_categories,
  topbar,
  alert,
  loader_mask,
  auth,
  lang,
  nom,
  delivery,
  payment,
  notification,
  restaurant_detail,
  cart,
  cuisine_type,
  trending,
  profile,
  purchase,
  register,
  recover,
  restaurant_carousel,
  offer_carousel,
  availability_validation,
  promotions_carousel,
  customer_satisfaction,
  checkout,
  pay,
  user_cards,
  search,
  searchBox,
});
