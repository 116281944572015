import {get} from "../../../core/services";
import {notifyError} from "../../notification/store/notification.actions";

const BASE_URL = "restaurant";

export const SEND_TO_CHECK_AVAILABILITY = 'SEND_TO_CHECK_AVAILABILITY';
export const SUCCESSFUL_AVAILABILITY_CHECK = 'SUCCESSFUL_AVAILABILITY_CHECK';
export const FAILED_AVAILABILITY_CHECK = 'FAILED_AVAILABILITY_CHECK';
export const CLEAR_AVAILABILITY_STATES = 'CLEAR_AVAILABILITY_STATES';

export function sendToCheckAvailability() {
  return {
    type: SEND_TO_CHECK_AVAILABILITY,
  }
}

export function successfulAvailabilityCheck(response) {
  return {
    type: SUCCESSFUL_AVAILABILITY_CHECK,
    response
  }
}

export function failedAvailabilityCheck(response) {
  return {
    type: FAILED_AVAILABILITY_CHECK,
    response
  }
}

export function clearAvailabilityStates() {
  return {
    type: CLEAR_AVAILABILITY_STATES
  }
}

export function checkRestaurantAvailable(RESTAURANT_ID) {
  return dispatch => {
    get(BASE_URL + "/checkRestaurantAvailable/" + RESTAURANT_ID)
      .then(response => {
        dispatch(successfulAvailabilityCheck(response))
      })
      .catch(error => {
        let errObj = error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(failedAvailabilityCheck(errObj.result));
          dispatch(notifyError(errMsg));
        }
      })
      .finally(() => {
        // dispatch(setLoadingMaskStatus(false));
      });
  }
}