import {createSelector} from "reselect";
import { SET_NOTIFICATION, CLEAR_NOTIFICATION } from "./notification.actions";

const INITIAL_STATE = {
  notification: null,
  notificationType: "info"
};

export default function profile(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.notification,
        notificationType: action.notificationType || "info"
      };
    }
    case CLEAR_NOTIFICATION: {
      return {
        ...INITIAL_STATE
      };
    }
    default: {
      return state;
    }
  }
}

export const selectNotificationState = state => state.notification;

export const selectNotification = createSelector(
  selectNotificationState,
  state => state.notification
);

export const selectNotificationType = createSelector(
  selectNotificationState,
  state => state.notificationType
);
