/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useTranslation from "translations";
import FormAddAddress from "component/FormAddAddress/FormAddAddress";
import UberModal from "component/UberModal/UberModal";

export default function AddAddressModal({ edit, selectedAddress, restaurant, onSavedAddress, showDialogForm, setShowDialogForm, onClose = setShowDialogForm(false) }) {
  const translation = useTranslation();

  return showDialogForm && <UberModal onClose={() => onClose() /** setShowDialogForm(false) */}
    title={<h4>{translation.Payment.addDeliveryAddress}</h4>}
    subtitle={<h1>{translation.Payment.addDeliveryAddress}</h1>}
    // image={{ src: "./images/picture/addresses.jpg" }}
    content={
      <div style={{ padding: '24px 24px 32px' }}>
        <FormAddAddress translation={translation} edit={edit} checkAsUsual={!edit} onSavedAddress={onSavedAddress}
          selectedRestaurant={restaurant} selectedAddress={selectedAddress} />
      </div>
    }
  />
}

