import {createSelector} from "reselect";
import {DONE_FETCH_PURCHASES, DONE_FETCH_MORE_PURCHASES, FAILURE_FETCH_PURCHASES} from "./purchase.actions";

const INITIAL_STATE = {
  purchases: [],
  purchase_loaded: false,
  pageCounter: 10,
  message: "",
  current: {},
  total: 0,
};

export default function purchase(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case DONE_FETCH_PURCHASES: {
      return {
        ...state,
        purchases: action.response.data || [],
      };
    }
    case DONE_FETCH_MORE_PURCHASES: {
      return {
        ...state,
        purchases: state.purchases.concat([...(action.response.data || [])]),
      };
    }
    case FAILURE_FETCH_PURCHASES: {
      return {
        ...state,
        purchases: [],
      };
    }
    default: {
      return state;
    }
  }
}

export const selectPurchaseState = state => state.purchase;

export const selectPurchasesState = createSelector(
  selectPurchaseState,
  state => state
);

export const selectPurchaseDefaultPageCounter = createSelector(
  selectPurchaseState,
  (state) => state.pageCounter || 10
);

export const selectPurchases = createSelector(
  selectPurchaseState,
  state => state.purchases
);