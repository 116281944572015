import { createSelector } from "reselect";
import {
  FETCHED_CLL2_PAYMENT_METHODS,
  DONE_CLL2_PAYMENT,
  SET_CLL2_ERROR_ADD_PAYMENT_METHOD,
  CLEAR_CLL2_ERROR_ADD_PAYMENT_METHOD,
  CLEAR_CLL2_PAYMENTS,
  CLEAR_CLL2_PAYMENTS_METHODS,
  FETCHED_CLL2_CLIENT_TOKEN,
} from "./actions";

const INITIAL_STATE = {
  clientToken: null,
  paymentMethods: null,
  paymentResult: null,
  paymentError: null,
  errorAddPaymentMethod: null,
};

export default function cll2(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCHED_CLL2_CLIENT_TOKEN: {
      return {
        ...state,
        clientToken: action.clientToken,
      };
    }
    case FETCHED_CLL2_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods: action.paymentMethods,
      };
    }
    case DONE_CLL2_PAYMENT: {
      return {
        ...state,
        paymentResult: action.data.data,
        errorAddPaymentMethod: null,
      };
    }
    case SET_CLL2_ERROR_ADD_PAYMENT_METHOD: {
      return {
        ...state,
        errorAddPaymentMethod: action.err,
      };
    }
    case CLEAR_CLL2_ERROR_ADD_PAYMENT_METHOD: {
      return {
        ...state,
        errorAddPaymentMethod: null,
      };
    }
    case CLEAR_CLL2_PAYMENTS_METHODS: {
      return {
        ...state,
        paymentMethods: null,
      };
    }
    case CLEAR_CLL2_PAYMENTS: {
      return {
        ...state,
        paymentResult: INITIAL_STATE.paymentResult,
        paymentError: INITIAL_STATE.paymentError,
      };
    }
    default: {
      return state;
    }
  }
}

const selectPaymentState = (state) => state.payment.cll2;

export const selectCll2ClientToken = createSelector(
  selectPaymentState,
  (state) => state.clientToken
);

export const selectCll2PaymentMethods = createSelector(
  selectPaymentState,
  (state) => state.paymentMethods
);

export const selectCll2Result = createSelector(
  selectPaymentState,
  (state) => state.paymentResult
);

export const selectCll2Error = createSelector(
  selectPaymentState,
  (state) => state.paymentError
);

export const selectErrorAddPaymentMethod = createSelector(
  selectPaymentState,
  (state) => state.errorAddPaymentMethod
);
