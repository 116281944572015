import { simpleDel, simpleGet, simplePost } from "../../../core/services";
import { setLoadingMaskStatus } from "../../Mask/store/mask.actions";

const baseUrl = "address";

export const LOAD_DELIVERY_ADDRESSES = "LOAD_DELIVERY_ADDRESSES";
export const FAIL_LOAD_DELIVERY_ADDRESSES = "FAIL_LOAD_DELIVERY_ADDRESSES";
export const ADD_DELIVERY_ADDRESS = "ADD_DELIVERY_ADDRESS";
export const FAIL_ADD_DELIVERY_ADDRESS = "FAIL_ADD_DELIVERY_ADDRESS";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const FAIL_UPDATE_DELIVERY_ADDRESS = "FAIL_UPDATE_DELIVERY_ADDRESS";
export const SET_DELIVERY_ADDRESS_TO_EDIT = "SET_DELIVERY_ADDRESS_TO_EDIT";
export const DELETE_DELIVERY_ADDRESS = "DELETE_DELIVERY_ADDRESS";
export const FAIL_DELETE_DELIVERY_ADDRESS = "FAIL_DELETE_DELIVERY_ADDRESS";
export const CLEAR_DELIVERY_ADDRESS_STATE = "CLEAR_DELIVERY_ADDRESS_STATE";
export const SET_DELIVERY_ADDRESS_ON_TOP_BAR_SELECTED =
  "SET_DELIVERY_ADDRESS_ON_TOP_BAR_SELECTED";

function doneLoadDeliveryAddress(addresses) {
  return {
    type: LOAD_DELIVERY_ADDRESSES,
    addresses,
  };
}

function failLoadDeliveryAddress(addresses) {
  return {
    type: FAIL_LOAD_DELIVERY_ADDRESSES,
    addresses,
  };
}

// function doneAddDeliveryAddress(address) {
//   return {
//     type: ADD_DELIVERY_ADDRESS,
//     address,
//   };
// }

function failAddDeliveryAddress(address) {
  return {
    type: FAIL_ADD_DELIVERY_ADDRESS,
    address,
  };
}

// function doneUpdateDeliveryAddress(addresses) {
//   return {
//     type: UPDATE_DELIVERY_ADDRESS,
//     addresses,
//   };
// }

function failUpdateDeliveryAddress(addresses) {
  return {
    type: FAIL_UPDATE_DELIVERY_ADDRESS,
    addresses,
  };
}

export function setDeliveryAddressToEdit(address) {
  return {
    type: SET_DELIVERY_ADDRESS_TO_EDIT,
    address,
  };
}

function doneDeleteDeliveryAddress(address) {
  return {
    type: DELETE_DELIVERY_ADDRESS,
    address,
  };
}

function failDeleteDeliveryAddress(address) {
  return {
    type: FAIL_DELETE_DELIVERY_ADDRESS,
    address,
  };
}

export function clearDeliveryAddressState() {
  return {
    type: CLEAR_DELIVERY_ADDRESS_STATE,
  };
}

export function setDeliveryAddressOnTopBarSelected(response) {
  return {
    type: SET_DELIVERY_ADDRESS_ON_TOP_BAR_SELECTED,
    response,
  };
}

export function loadDeliveryAddress() {
  return (dispatch) => {
    simpleGet(`${baseUrl}/getUserAddresses`)
      .then((addresses) => {
        dispatch(doneLoadDeliveryAddress(addresses));
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(failLoadDeliveryAddress(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function addDeliveryAddress(ADDRESS) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/createUpdateAddresses`, ADDRESS)
      .then((address) => {
        dispatch(loadDeliveryAddress());
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(failAddDeliveryAddress(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function updateDeliveryAddress(ADDRESS) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/createUpdateAddresses`, ADDRESS)
      .then((address) => {
        dispatch(loadDeliveryAddress());
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(failUpdateDeliveryAddress(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function deleteDeliveryAddress(ADDRESS_ID) {
  return (dispatch) => {
    simpleDel(`${baseUrl}/deleteAddresses?id=${ADDRESS_ID}`)
      .then(() => {
        dispatch(doneDeleteDeliveryAddress(ADDRESS_ID));
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(failDeleteDeliveryAddress(errMsg));
        }
      });
  };
}
