
export * from "./cll2/actions";
export * from "./braintree/braintree.actions";
export * from "./cash/cash.actions";
export * from "./tubolsillo/tubolsillo.actions";

export const CLEAR_PAYMENTS = "CLEAR_PAYMENTS";

export function clearPaymentStore() {
  return {
    type: CLEAR_PAYMENTS,
  };
}