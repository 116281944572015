/*================================================================================================*/

/*

This routine checks the credit card number. The following checks are made:

1. A number has been provided
2. The number is a right length for the card
3. The number has an appropriate prefix for the card
4. The number has a valid modulus 10 number check digit if required

If the validation fails an error is reported.

The structure of credit card formats was gleaned from a variety of sources on the web, although the 
best is probably on Wikepedia ("Credit card number"):

  http://en.wikipedia.org/wiki/Credit_card_number

Parameters:
  cardnumber           number on the card

Author:     John Gardner
Date:       1st November 2003
Updated:    26th Feb. 2005      Additional cards added by request
Updated:    27th Nov. 2006      Additional cards added from Wikipedia
Updated:    18th Jan. 2008      Additional cards added from Wikipedia
Updated:    26th Nov. 2008      Maestro cards extended
Updated:    19th Jun. 2009      Laser cards extended from Wikipedia
Updated:    11th Sep. 2010      Typos removed from Diners and Solo definitions (thanks to Noe Leon)
Updated:    10th April 2012     New matches for Maestro, Diners Enroute and Switch
Updated:    17th October 2012   Diners Club prefix 38 not encoded

*/

/*
   If a credit card number is invalid, an error reason is loaded into the global ccErrorNo variable. 
   This can be be used to index into the global error  string array to report the reason to the user 
   if required:
   
   e.g. if (!checkCreditCard (number, name) alert (ccErrors(ccErrorNo);
*/

// Define the cards we support. You may add addtional card types as follows.

//  Name:         As in the selection box of the form - must be same as user's
//  Length:       List of possible valid lengths of the card number for the card
//  prefixes:     List of possible prefixes for the card
//  checkdigit:   Boolean to say whether there is a check digit
const cards = [
  { name: "Visa", length: "13,16", prefixes: "4", checkdigit: true },
  {
    name: "MasterCard",
    length: "16",
    prefixes: "51,52,53,54,55",
    checkdigit: true,
  },
  {
    name: "DinersClub",
    length: "14,16",
    prefixes: "36,38,54,55",
    checkdigit: true,
  },
  {
    name: "CarteBlanche",
    length: "14",
    prefixes: "300,301,302,303,304,305",
    checkdigit: true,
  },
  { name: "AmEx", length: "15", prefixes: "34,37", checkdigit: true },
  {
    name: "Discover",
    length: "16",
    prefixes: "6011,622,64,65",
    checkdigit: true,
  },
  { name: "JCB", length: "16", prefixes: "35", checkdigit: true },
  { name: "enRoute", length: "15", prefixes: "2014,2149", checkdigit: true },
  {
    name: "Solo",
    length: "16,18,19",
    prefixes: "6334,6767",
    checkdigit: true,
  },
  {
    name: "Switch",
    length: "16,18,19",
    prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
    checkdigit: true,
  },
  {
    name: "Maestro",
    length: "12,13,14,15,16,18,19",
    prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
    checkdigit: true,
  },
  {
    name: "VisaElectron",
    length: "16",
    prefixes: "4026,417500,4508,4844,4913,4917",
    checkdigit: true,
  },
  {
    name: "LaserCard",
    length: "16,17,18,19",
    prefixes: "6304,6706,6771,6709",
    checkdigit: true,
  },
];

export const checkCreditCard = (cardnumber) => {
  if (!cardnumber || cardnumber.length === 0) {
    return "No card number provided";
  }
  // Now remove any spaces from the credit card number
  cardnumber = cardnumber.replace(/\s/g, "");

  // Check that the number is numeric
  if (!/^[0-9]{13,19}$/.exec(cardnumber)) {
    return "Credit card number is in invalid format";
  }

  // Check it's not a spam number
  if (cardnumber === "5490997771092064") {
    return "Warning! This credit card number is associated with a scam attempt";
  }

  // Establish card type
  let matchingCards = cards.filter(({ prefixes }) => {
    const result = prefixes.split(",").filter((prefix) => {
      return cardnumber.startsWith(prefix);
    });
    return result.length > 0;
  });

  if (!matchingCards || matchingCards.length === 0) {
    return "Credit card number is invalid";
  }

  let matchedCard = matchingCards[0]; // Take only the first
  if (matchedCard.checkdigit) {
    // Now check the modulus 10 check digit - if required
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2
    let calc; // Process each digit one by one starting at the right
    for (let i = cardnumber.length - 1; i >= 0; i--) {
      calc = Number(cardnumber.charAt(i)) * j; // Extract the next digit and multiply by 1 or 2 on alternative digits.
      if (calc > 9) {
        // If the result is in two digits add 1 to the checksum total
        checksum += 1;
        calc -= 10;
      }
      checksum += calc; // Add the units element to the checksum total
      j = j === 1 ? 2 : 1; // Toggle value of j
    }

    if (checksum % 10 !== 0) {
      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      return "Credit card number is invalid"; // If not, report an error.
    }
  }

  // check length to see if any matches
  const lenghts = matchedCard.length.split(",");
  const lengthResult = lenghts.filter(
    (length) => cardnumber.length === parseInt(length, 10)
  );
  if (!lengthResult || lengthResult.length === 0) {
    // See if all is OK by seeing if the length was valid. We only check the length if all else was hunky dory.
    return "Credit card number has an inappropriate number of digits";
  }

  // The credit card is in the required format.
  return true;
};
