import {secureGet, securePost} from "../../../core/services";
import {setLoadingMaskStatus} from "../../../component/Mask/store/mask.actions";

export const SET_PROFILE = "SET_PROFILE";
export const DONE_FETCH_PROFILE = "DONE_FETCH_PROFILE";
export const DONE_UPDATE_PROFILE = "DONE_UPDATE_PROFILE";

const baseUrl = "account";

function doneFetchProfile(profile) {
  return {
    type: DONE_FETCH_PROFILE,
    profile
  };
}

function doneUpdateProfile(profile) {
  return {
    type: DONE_UPDATE_PROFILE,
    profile
  };
}

export function doFetchProfile() {
  return dispatch => {
    secureGet(`${baseUrl}/`)
      .then(profile => {
        dispatch(doneFetchProfile(profile));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile
  };
}

export function doUpdateProfile(profile) {
  return dispatch => {
    securePost(`${baseUrl}/`, profile).then(data => {
      dispatch(doneUpdateProfile(data))
    });
  };
}
