/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormControl, IconButton, InputAdornment, OutlinedInput, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import useTranslation from "translations";

const useStyles = makeStyles(theme => ({
  searchRoot: {
    borderRadius: "50px",
    background: "#f6f6f6",
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    '& .MuiOutlinedInput-input': {
      height: '48px !important',
      background: 'transparent',
    }
  }
}));

export default function SearchInput({ term, searchByTerm }) {
  const translation = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(term);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(value);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value !== term) {
        handleSearch(value);
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [value])

  const handleSearch = (term) => {
    setValue(term);
    searchByTerm(term);
  }

  return <FormControl sx={{ m: 1 }} variant="outlined" size="medium">
    <OutlinedInput
      value={value}
      autoFocus={true}
      placeholder={translation.Search.inputPlaceholder}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      classes={{
        root: classes.searchRoot
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton style={{ width: '38px' }} onClick={() => handleSearch(value)}>
            <Search />
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>;
}