import { createSelector } from "reselect";

import {
  SET_FORM,
  DONE_VALIDATE_PHONE,
  ERROR_VALIDATE_PHONE,
  DONE_VERIFY_CODE,
  ERROR_VERIFY_CODE,
  BACK_TO_VALIDATE_PHONE
} from "./register.actions";

const INITIAL_STATE = {
  phone: "",
  email: "",
  pin: "",
  password: "",

  validating: false,
  validated: false,
  validateError: null,

  verifying: false,
  verifyError: null
};

export const selectRegisterState = state => state.register;

export const selectForm = createSelector(
  selectRegisterState,
  state => {
    const { phone, email, pin, password } = state;
    return { phone, email, pin, password };
  }
);

export const selectVerifyError = createSelector(
  selectRegisterState,
  state => state.verifyError
);
export const selectValidateError = createSelector(
  selectRegisterState,
  state => state.validateError
);
export const selectValidated = createSelector(
  selectRegisterState,
  state => state.validated
);

export default function register(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_FORM: {
      return {
        ...state,
        ...action.form,
      }
    }
    case DONE_VALIDATE_PHONE: {
      return {
        ...state,
        data: action.data,
        validated: true,
        error: null
      };
    }
    case BACK_TO_VALIDATE_PHONE: {
      return {
        ...INITIAL_STATE,
        validated: false
      };
    }
    case ERROR_VALIDATE_PHONE: {
      const { error } = action;
      const validateError = error.data || {
        code: "unknow",
        description: error.message
      };
      return {
        ...INITIAL_STATE,
        validateError,
        validated: false,
        data: null
      };
    }
    case DONE_VERIFY_CODE: {
      return {
        ...state,
        data: action.data
      };
    }
    case ERROR_VERIFY_CODE: {
      const { error } = action;
      const verifyError = error.data || {
        code: "unknow",
        description: error.message
      };
      return {
        ...state,
        verifyError,
        data: null
      };
    }
    default: {
      return state;
    }
  }
}
