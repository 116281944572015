/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./css/CartStyle.scss";
import SpinnerEnhanced from "../../component/SpinnerEnhanced/SpinnerEnhanced";
import { addOfferToCart, deleteOfferToCart, updateOfferToCart } from "./store/cart.actions";
import { selectBDCart, } from "./store/cart.reducers";
import { browserHistory } from "../../utils/History";
import { selectProfile } from "modules/profile/store/profile.reducers";
import { selectRestaurantDetailObject } from "modules/BusinessDetail/store/restaurant-detail.reducers";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { setBDOrder, storeBrowserOrder } from "modules/Checkout/store/checkout.actions";
import UberModal from "component/UberModal/UberModal";

export default function Cart({ translation, title, onClose, handleOnClickCardItem = null }) {
  const dispatch = useDispatch();
  const history = browserHistory;
  const user = useSelector(selectProfile);
  const cartBD = useSelector(selectBDCart);
  const restaurantBySlug = useSelector(selectRestaurantDetailObject);
  const [cartItems, setCartItems] = useState([]);
  const cardList = useRef();

  useEffect(() => { cartBD?.items && setCartItems(cartBD.items); }, [cartBD?.items]);

  const addOrUpdate = (offer) => {
    const offerInCart = cartBD?.items.find(({ id }) => id === offer.id);
    if (offerInCart?.id) {
      handleUpdate({ ...offerInCart, offerCount: offer?.offerCount + 1 });
    } else {
      handleAdd(offer);
    }
  };

  const updateOrDelete = (offer) => {
    if (offer?.offerCount > 1) {
      handleUpdate({ ...offer, offerCount: offer?.offerCount - 1 });
    } else {
      handleDelete(offer);
    }
  };

  const handleAdd = (offer) => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(addOfferToCart({
      accountId: user?.accountId,
      restaurantId: cartBD?.restaurantId,
      deliveryOrderType: cartBD?.deliveryOrderType,
      items: [{
        offerCount: offer?.offerCount,
        offerId: offer?.id,
        addsIds: offer?.adds?.map(a => a.id),
        variantsIds: offer?.variants?.map(v => v.id),
      }]
    }, restaurantBySlug));
  };

  const handleUpdate = (offer) => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(updateOfferToCart({
      accountId: user?.accountId,
      restaurantId: cartBD?.restaurantId,
      deliveryOrderType: cartBD?.deliveryOrderType,
      items: [{
        offerId: offer?.id,
        id: offer?.id,
        offerCount: offer?.offerCount,
        addsIds: offer?.adds?.map(a => a.id),
        variantsIds: offer?.variants?.map(v => v.id),
      }]
    }));
  };

  const handleDelete = (offer) => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(deleteOfferToCart({
      accountId: user?.accountId,
      restaurantId: cartBD?.restaurantId,
      items: [{ id: offer?.id }]
    }));
  };

  const editPurchase = () => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(setBDOrder(null));
    dispatch(storeBrowserOrder(null));
    onClose();
    history.push({ pathname: `/${cartBD?.restaurantSlug}` });
  };

  const completeOrder = () => {
    if (cartItems?.length) {
      onClose();
      history.push({ pathname: '/prv/checkout' });
    }
  };

  const content = <div className="cart">
    <div ref={cardList} className="cart__list scrollable_list">
      {cartItems && cartItems.length > 0 && cartItems.map((item, index) => (
        <div className="cart__item" key={index}
          style={{ cursor: handleOnClickCardItem ? 'pointer' : 'inherit' }}
          onClick={() => handleOnClickCardItem && handleOnClickCardItem(item)}>
          <div className="cart__item__info" direction="row">
            <div className="cart-item-actions" >
              <SpinnerEnhanced
                value={item.offerCount}
                handleAdd={(event) => {
                  addOrUpdate(item);
                  event.stopPropagation();
                }}
                handleRemove={(event) => {
                  updateOrDelete(item);
                  event.stopPropagation();
                }}
              />
            </div>
            <div className="cart-item-column" direction="row">
              <span className="title">{item.name}</span>
              {item?.variants?.length ? <span className="variant">
                ({item?.variants.map((variant) => `${translation.Code === "ES" ? variant.nameEs : variant.nameEn}`).join(", ")})
              </span> : null}
              {item?.adds?.length ? <span className="details">
                ({item?.adds?.map(({ name }) => `${name}`).join(", ")})
              </span> : null}
            </div>
            <div className="cart__item__price" justify="flex-end" direction="row">
              <span className="price">${item.price ? item.price.toFixed(2) : (0).toFixed(2)}</span>
            </div>
          </div>
        </div>))}

      {history.location.pathname === '/' &&
        <button onClick={editPurchase} className="button--secondary button--secondary--small" style={{ 'marginTop': '1em' }}>
          + Añadir platos
        </button>}
    </div>
  </div >

  return (
    <UberModal
      title={title}
      // image={{ src: "./images/picture/cart.webp" }}
      subtitle={title}
      onClose={onClose}
      content={content}
      height={UberModal.sizes.MEDIUM}
      smallModal={true}
      actions={
        <button className="button--primary button--max button--plus button-like"
          style={{ height: "54px", fontSize: "1.4em", margin: "0 auto 15px" }}
          onClick={() => completeOrder()}>
          {`${translation.Cart.completeRequest} • ${parseFloat(cartBD?.subtotal).toFixed(2)}`}
        </button>
      }
    />
  );
}