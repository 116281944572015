import React, { useState } from "react";
import useTranslations from "translations";
import { ArrowForwardIos, Contacts, LocationOn } from "@material-ui/icons";

const SelectAddress = ({ isDelivery, address, receiver, receiverPhone, openLocation }) => {
  const translation = useTranslations();
  const [showLocation, setShowLocation] = useState(null);

  return <div onClick={openLocation} className="new__button">
    {isDelivery
      ? <LocationOn style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
      : <Contacts style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
    }
    <div className="new__button__address">
      <p>{isDelivery ? (address?.name || translation.Payment.deliveryAddress) : (receiver || translation.Payment.contactData)}</p>
      <p><span>{isDelivery ? (address?.details || null) : (receiverPhone || null)}</span></p>
    </div>
    <ArrowForwardIos style={{ alignSelf: 'center', color: '#2c2c2c', fontSize: "large", fontWeight: "bold", transform: showLocation ? "rotate(90deg)" : "" }} component="svg" />
  </div>;
}

export default SelectAddress;