import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./css/PurchaseHistoryStyles.scss";
import useTranslations from "../../translations/useTranslations";
import {sendToCheckAvailability} from "../availabilityValidation/store/availability-validation.actions";
import {PurchaseHistoryCard} from "./component/PurchaseHistoryCard/PurchaseHistoryCard";
import {selectPurchaseDefaultPageCounter, selectPurchases} from "./store/purchase.reducers";
import {doFetchPurchases} from "./store/purchase.actions";
import InfiniteScroll from "react-infinite-scroll-component";
import {getLoaderMaskStatus} from "../../component/Mask/store/mask.reducers";

export default function PurchaseHistory(props) {
  const dispatch = useDispatch();
  const translation = useTranslations();
  const loaderMaskStatus = useSelector(getLoaderMaskStatus);
  const purchasesList = useSelector(selectPurchases);
  const purchaseDefaultPageCounter = useSelector(selectPurchaseDefaultPageCounter);
  const [pointer, setPointer] = useState(1);
  const [purchases, setPurchases] = useState([]);
  const purchaseListRef = useRef(null);

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(doFetchPurchases(1, purchaseDefaultPageCounter));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (purchasesList && purchasesList.length > 0) {
      setPurchases([...purchasesList]);
    }
    //eslint-disable-next-line
  }, [purchasesList]);

  const loadMoreData = () => {
    const page = pointer + 1;
    setPointer(page);
    dispatch(doFetchPurchases(page, purchaseDefaultPageCounter, true));
  };

  return (
    <div className="purchase container small-container">
      <section className="page-section">
        <div className="wrap container-fluid">
          <div className="history-header">
            <h2 className="headline left">
              {translation.PurchaseHistory.title}
              <div className="divider-green"/>
            </h2>
            <p>{translation.PurchaseHistory.description}</p>
          </div>

          <div ref={purchaseListRef}>
            {loaderMaskStatus ? null : purchases && purchases.length > 0 ?
              <div id="phBody" className="history-body">
                <InfiniteScroll
                  dataLength={purchases.length}
                  next={loadMoreData}
                  hasMore={true}
                  loader={null}
                  scrollableTarget="root"
                >
                  {purchases && purchases.length > 0 ? purchases.map((data, k) => {
                    return <PurchaseHistoryCard key={k} {...props} data={data}/>
                  }) : null}
                </InfiniteScroll>
              </div> :
              <div className="not-found-container not-found-wrapper">
                <h1>{translation.General.noResultList}</h1>
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}
