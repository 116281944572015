import { post } from "core/services";
import { DELIVERY } from "constants/delivery";

const useRestaurant = () => {
  const fixAddress = (address) => {
    if (address?.provinceid) {
      address['provinceId'] = address.provinceid;
      delete address.provinceid;
    }
    if (address?.municipalityid) {
      address['municipalityId'] = address.municipalityid;
      delete address.municipalityid;
    }
    if (address?.localityid) {
      address['localityId'] = address.localityid;
      delete address.localityid;
    }
    return address;
  }
  const fetchDetails = async (address, restaurantId) => {
    return post('business/businessDetails', {
      id: null,
      category: 0,
      offerId: null,
      restaurantSlug: null,
      restaurantId,
      accountId: address?.userRef,
      address: fixAddress(address),
      deliveryOrderType: DELIVERY
    }, true).then((res) => res?.data || res);
  };

  return { fixAddress, fetchDetails }
}

export default useRestaurant;