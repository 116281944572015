export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

function setNotification(notification, notificationType = null) {
  return {
    type: SET_NOTIFICATION,
    notification,
    notificationType
  };
}

export function clearNotification() {
  return { type: CLEAR_NOTIFICATION };
}

export function notify(notification) {
  return dispatch => {
    dispatch(setNotification(notification));
    setTimeout(()=> {
      dispatch(clearNotification());
    }, 500);
  };
}

export function notifyError(notification) {
  return dispatch => {
    dispatch(setNotification(notification, "error"));
    setTimeout(()=> {
      dispatch(clearNotification());
    }, 500);
  };
}

export function notifySuccess(notification) {
  return dispatch => {
    dispatch(setNotification(notification, "success"));
    setTimeout(()=> {
      dispatch(clearNotification());
    }, 500);
  };
}

export function notifyInfo(notification) {
  return dispatch => {
    dispatch(setNotification(notification, "info"));
    setTimeout(()=> {
      dispatch(clearNotification());
    }, 500);
  };
}

export function notifyWarning(notification) {
  return dispatch => {
    dispatch(setNotification(notification, "warning"));
    setTimeout(()=> {
      dispatch(clearNotification());
    }, 500);
  };
}