import { createSelector } from "reselect";
import { DONE_CASH_PAYMENT, SET_ERROR_CASH_PAYMENT } from "./cash.actions";
import { CLEAR_PAYMENTS } from "../payment.actions";

const INITIAL_STATE = {
  paymentResult: null,
  paymentError: null,
};

export default function cash(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case DONE_CASH_PAYMENT: {
      return {
        ...state,
        paymentResult: action.data.data,
      };
    }
    case SET_ERROR_CASH_PAYMENT: {
      return {
        ...state,
        paymentError: action.data.message,
      };
    }
    case CLEAR_PAYMENTS: {
      return {
        ...state,
        paymentResult: INITIAL_STATE.paymentResult,
        paymentError: INITIAL_STATE.paymentError,
      };
    }
    default: {
      return state;
    }
  }
}

const selectPaymentState = (state) => state.payment.cash;

export const selectCashResult = createSelector(
  selectPaymentState,
  (state) => state.paymentResult
);

export const selectCashError = createSelector(
  selectPaymentState,
  (state) => state.paymentError
);
