import {get} from "../../../core/services";
import {notifyError} from "../../../modules/notification/store/notification.actions";

const baseUrl = "cllPromo";

export const GENERAL_PROMOTIONS_LOADED_SUCCESS = "GENERAL_PROMOTIONS_LOADED_SUCCESS";
export const GENERAL_PROMOTIONS_LOADED_FAILURE = "GENERAL_PROMOTIONS_LOADED_FAILURE";

function generalPromotionsLoadedSuccess(response) {
  return {
    type: GENERAL_PROMOTIONS_LOADED_SUCCESS,
    response
  };
}

function generalPromotionsLoadedFailure(response) {
  return {
    type: GENERAL_PROMOTIONS_LOADED_FAILURE,
    response
  };
}

export function loadGeneralPromotions(PROVINCE_ID) {
  let URL = PROVINCE_ID ? '?provinceId=' + PROVINCE_ID : '';
  return dispatch => {
    get(`${baseUrl}/geCllPromotions${URL}`)
      .then(response => {
        dispatch(generalPromotionsLoadedSuccess(response));
      })
      .catch(error => {
        let errObj = error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(generalPromotionsLoadedFailure(errMsg));
          dispatch(notifyError(errMsg));
        }
      })
  }
}