/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PhoneInput from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es';
import en from 'react-phone-number-input/locale/en';
import ReCAPTCHA from "react-google-recaptcha";

import "./css/LoginStyle.scss";
import 'react-phone-number-input/style.css';

import { validateFieldByArray } from "utils/AuxiliarFunctions";
import useTranslations from "translations/useTranslations";
import { DEFAULT_CONFIG } from "config/Config";
import { getOrCreateDeviceID } from "utils/Utils";
import { loadCountryCode } from "core/util/network";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { clearAuthState, requestPin } from "./store/login.actions";
import { loginUserSelector } from "./store/login.reducers";
import { store } from "../../App";

export const Login = ({ history }) => {
  const dispatch = useDispatch();
  const language = store.getState().lang.code;
  const translation = useTranslations();
  const loginResponse = useSelector(loginUserSelector);
  const [validateForm, setValidateForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [country, setCountry] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loginData, setLoginData] = useState({
    username: undefined,
    password: undefined,
    code_delivery_method: '',
    grant_type: "password",
    client_id: DEFAULT_CONFIG.client_id,
    device_id: getOrCreateDeviceID()
  });
  const recaptchaRef = React.createRef();

  useEffect(() => {
    localStorage.removeItem('Dt3mp0');
    localStorage.removeItem('Dv4lP1');
    localStorage.removeItem('D3m41l');
    localStorage.removeItem('D3m41l3rr0r');
    dispatch(setLoadingMaskStatus(false));
    (async () => {
      const { country } = await loadCountryCode();
      setCountry(country)
    })();
  }, []);

  useEffect(() => {
    if (loginResponse.login_success && loginResponse.access_token?.lengthgit) {
      const url = localStorage.getItem("backTo");
      history.push(url ? url : "../");
      localStorage.removeItem("backTo");
      dispatch(clearAuthState());
    }
    if (loginResponse.login_failure) {
      setErrors({ ...errors, username: loginResponse.errors });
    }
    if (loginResponse.verify_device_id) {
      history.push("/pin");
    }
  }, [loginResponse]);

  useEffect(() => { validateForm && validateFields(); }, [loginData, captchaToken]);

  const handleChangeInputForm = (field, value) => setLoginData({ ...loginData, [field]: value });
  const handleOnBlur = (field, value) => setLoginData({ ...loginData, [field]: value });

  const onChangeCaptcha = (value) => {
    setCaptchaToken(value);
    validateFields();
  };

  const validateFields = () => {
    const { username } = loginData;
    const errorUsername = validateFieldByArray(username, ["required", "phone_length"], 5);
    const errorCaptcha = validateFieldByArray(captchaToken, ["required"], 0, translation.Validations["required"]);
    setErrors({ ...errors, username: errorUsername, captcha: errorCaptcha });

    return !errorUsername && !errorCaptcha;
  };

  const tryLogin = () => {
    setValidateForm(true);
    if (validateFields()) {
      dispatch(requestPin({
        deviceId: loginData.device_id,
        formatedPhoneNumber: loginData.username.trim(),
        phoneNumber: loginData.username.trim(),
        pinDeliveryMethod: "",
        client_id: DEFAULT_CONFIG.client_id,
        channel: 'WEB',
        captchaToken: captchaToken
      }))
    }
  }

  return (
    <div className="login">
      <Grid container className="container" component="div">
        <div className="form">
          <Typography className="title" component="h1">
            {translation.Login.welcome}
            <span />
          </Typography>
          <Typography className="subtitle" component="p">
            {translation.Login.indentifyWithCuballama}
          </Typography>
          <div className="fields">
            <Grid container item md={10} sm={10} xs={12} spacing={4} component="div">
              <Grid item xs={12} component="div">
                <PhoneInput
                  pt="5"
                  id="username"
                  labels={language === 'es' ? es : en}
                  defaultCountry={country}
                  international={true}
                  placeholder={`${translation.Login.phonePlaceHolder}`}
                  value={loginData.username}
                  onChange={val => handleChangeInputForm("username", val)}
                  onBlur={e => handleOnBlur("username", e.target.value)}
                />
                {validateForm && errors.username && <p className="fields-error">{errors.username}</p>}
              </Grid>
              <Grid item className="captcha" xs={12} component="div">
                <ReCAPTCHA
                  className="captcha"
                  ref={recaptchaRef}
                  sitekey={DEFAULT_CONFIG.captcha_site_key}
                  onChange={onChangeCaptcha}
                />
                {validateForm && errors.captcha && <p className="fields-error">{errors.captcha}</p>}
              </Grid>

              <Grid container item xs={12} className="register-link" component="div">
                <Typography component="p">{translation.Login.registerInvitation}</Typography>
              </Grid>
              <Grid container item xs={12} className="action" component="div">
                <button onClick={tryLogin} className="button--primary button--max">
                  {translation.Login.loginAction}
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
};