import React from "react";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import PdfIcon from "component/Icon/PdfIcon";
import AtomIcon from "component/Icon/AtomIcon";
import OfferSwipper from "./OfferSwipper";

export default function BusinessList({ loading, list, goToBusiness, businessPlaceholders }) {

  return !loading
    ? list?.map((business, k) => (
      <div className="business-detail-item" key={k}>
        <div className="business-detail-item__header" onClick={(event) => goToBusiness(event, business)}>
          <div className="business-detail-item__title-div">
            <div className="business-detail-item__avatar">
              <img src={business.imgSm} alt={business.name} />
            </div>
            <div className="business-detail-item__title">
              <div className="business-detail-item__name">
                {business.name}
                <PdfIcon icon="system/chevron_right--black" ext="svg" width="25px" />
              </div>
              <div className="business-detail-item__more">
                {business.deliveryTimeRestaurantDto ? <div className="business-detail-item__more__item">
                  <PdfIcon icon="system/clock--grey" ext="svg" width="12px" />
                  <small className="business-detail-item__more__item__text">
                    {business.deliveryTimeRestaurantDto.deliveryTime} {business.deliveryTimeRestaurantDto.deliveryTimeUnitEs}
                  </small>
                </div> : null}
                {business.deliveryCost ? <div className="business-detail-item__more__item">
                  <PdfIcon icon="system/scooter-2" ext="svg" width="18px" />
                  <small className="business-detail-item__more__item__text">{business.deliveryCost}</small>
                </div> : null}
                {business.averageScore ? <div className="business-detail-item__more__item">
                  <AtomIcon icon="star--blue" />
                  <small className="business-detail-item__more__item__text">{business.averageScore}</small>
                </div> : null}
              </div>
            </div>
          </div>

        </div>
        <OfferSwipper loading={loading} business={business} goToBusiness={goToBusiness} businessPlaceholders={businessPlaceholders} />
        <div className="separator" />
      </div>))
    : <div className="skeleton-row__business">
      {[...Array(businessPlaceholders)].map((_, k) => <div key={k} className="bc-skeleton__business" style={{ marginBottom: '1em' }}>
        <div className="bc-skeleton__business__header">
          <div className="bc-skeleton__avatar">
            <Skeleton variant="circle" width={65} height={65} animation="wave" />
          </div>
          <div className="bc-skeleton__title">
            <Skeleton variant="rect" width={65} height={20} animation="wave" style={{ marginBottom: '.8em' }} />
            <Skeleton variant="rect" width={65} height={20} animation="wave" />
          </div>
        </div>
        <div className="bc-skeleton__business__offers">
          {[...Array(businessPlaceholders)].map((_, k) =>
            <div className="bc-skeleton__business__offer" key={k}>
              <Skeleton variant="rect" width={120} height={120} animation="wave" style={{ marginBottom: '.5em' }} />
              <Skeleton variant="rect" width={120} height={20} animation="wave" />
            </div>)}
        </div>
      </div>)}
    </div>;
}