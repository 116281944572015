import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectNotification, selectNotificationType } from "./store/notification.reducers";

import { ToastsStore } from "react-toasts";
import Toast from "./Toast";

const ToastManager = () => {
  const toast = useSelector(selectNotification);
  const toastType = useSelector(selectNotificationType);
  const timer = 10000;
  useEffect(() => {
    if (toast && toastType) {
      switch(toastType) {
        case "success": {
          ToastsStore.success(<Toast text={toast} />, timer);
          break;
        }
        case "error": {
          ToastsStore.error(<Toast text={toast} />, timer);
          break;
        }
        case "info": {
          ToastsStore.info(<Toast text={toast} />, timer);
          break;
        }
        case "warning": {
          ToastsStore.warnign(<Toast text={toast} />, timer);
          break;
        }
        default: {
          ToastsStore.info(<Toast text={toast} />, timer);
        }
      }
    }
  },[toast, toastType]); 
  
  return (<div></div>);
};

export default ToastManager;
