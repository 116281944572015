import React from "react";
import "./css/Loading.scss";

export default function LoadingMask({ msg }) {
    return (
        <div id="preloader" className={msg && msg.length > 0 ? 'preloader-center' : ''}>
            {msg && msg.length > 0 ?
                <div className="loading-message">
                    <p>{msg}</p>
                    <div id="loader" />
                </div>
                : <div id="loader" />}
        </div>
    );
}