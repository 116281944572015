import React, { useEffect, useState } from "react";
import SpinnerEnhanced from "../../../../component/SpinnerEnhanced/SpinnerEnhanced";
import { useSelector, useDispatch } from "react-redux";
import "./css/OfferItemStyles.scss";
import { selectBDCart, selectCartTotal } from "../../../cart/store/cart.reducers";
import useTranslation from "../../../../translations";
import { notifyInfo } from "../../../notification/store/notification.actions";

const OfferItemCard = ({ offer, handleOnClickOffer, handleOnAddToCart, handleOnRemoveFromCart }) => {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const cartBD = useSelector(selectBDCart);
  const cartTotal = useSelector(selectCartTotal);
  const [cartItems, setCartItems] = useState([]);
  const [availableStock, setAvailableStock] = useState(false);
  const [stock, setStock] = useState('');

  useEffect(() => {
    if (cartBD?.items) {
      setCartItems(cartBD.items);
    }
    // eslint-disable-next-line
  }, [cartBD?.items]);

  useEffect(() => {
    setStock(offer.stock || '');
    let isAvailable = ((offer.manageStock && offer.stock > 0) || !offer.manageStock);
    setAvailableStock(isAvailable);
    // eslint-disable-next-line
  }, [offer]);

  const validateBeforeAddToCart = () => {
    if ((offer?.manageStock && countOfferInCart() < stock) || !offer?.manageStock) {
      if (offer?.adds?.length > 0 || offer?.variants?.length > 0) {
        handleOnClickOffer(offer, countOfferInCart())
      } else {
        handleOnAddToCart(offer);
      }
    } else {
      dispatch(notifyInfo(translation.OfferItem.maximumOfferOnStock));
    }
  };

  const handleRemove = () => {
    const cartItem = cartItems.find(item => item.offerId === offer.id);
    handleOnRemoveFromCart(cartItem)
  };

  const countOfferInCart = () => {
    const items = cartItems.reverse().filter(item => item.offerId === offer.id);
    if (items && items.length > 0) {
      return items.map(item => item.offerCount).reduce((prev, next) => prev + next);
    }
    return 0;
  };

  return (
    <div className={`offer-item shadow`} onClick={() => handleOnClickOffer(offer, countOfferInCart())}>
      <div className="offer-image-box">
        {offer.imgSm
          ? <img src={offer.imgSm} alt={offer.name} className="thumbnails-image" />
          : <img src={"./images/picture/no-image.png"} alt={offer.name} className="no-image" />
        }
      </div>
      <div className={`offer-content`}>
        <div className={`offer-detail`}>
          <div style={{ width: '200px' }}>
            <span className="offer-name">
              {offer.name}
            </span>
          </div>
          <span className="offer-price">
            {`$${offer.price ? offer.price.toFixed(2) : (0).toFixed(2)}`}
          </span>
        </div>
        {cartTotal > -1 && (!availableStock) ? null :
          <div className="offer-actions">
            <SpinnerEnhanced
              value={countOfferInCart()}
              handleAdd={(event) => {
                validateBeforeAddToCart();
                event.stopPropagation();
              }}
              handleRemove={(event) => {
                handleRemove(offer);
                event.stopPropagation();
              }}
            />
          </div>}
      </div>
    </div>
  )
};

export default OfferItemCard;
