import React from 'react';
import LoadingOverlay from 'react-loading-overlay'
import { useSelector } from "react-redux";

import LoadingMask from "../Loading/LoadingMask";
import { getLoaderMaskMessage, getLoaderMaskStatus } from "./store/mask.reducers";

export default function Mask({ children }) {
    const active = useSelector(getLoaderMaskStatus);
    const msg = useSelector(getLoaderMaskMessage);

    return (
        <LoadingOverlay
            active={active}
            spinner={<LoadingMask msg={msg} />}
        >
            {children}
        </LoadingOverlay>
    )
}