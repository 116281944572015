import React, { useEffect } from "react";
import "./css/UserProfileStyles.scss";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import Picture from "../../component/Picture/Picture";
import { selectProfile } from "./store/profile.reducers";
import { doFetchProfile } from "./store/profile.actions";
import useTranslations from "../../translations/useTranslations";
import { CallOutlined, EmailOutlined } from "@material-ui/icons";
import { setLoadingMaskStatus } from "../../component/Mask/store/mask.actions";
import { logoutUser } from "../login/store/login.actions";
import { sendToCheckAvailability } from "../availabilityValidation/store/availability-validation.actions";
import Skeleton from "@material-ui/lab/Skeleton";

const colorPrimary = "#1e4acc";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: "1px"
  },
  textField: {
    flexBasis: 200,
    width: "100%",
    border: "none",
    margin: "none"
  },
  uploadInput: {
    display: "none"
  },
  uploadButton: {
    backgroundColor: colorPrimary,
    padding: "8px",
    marginBottom: "-10px",

    "&:hover": {
      backgroundColor: "gray",
      borderColor: "gray",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "gray",
      borderColor: "gray"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
    }
  },
  input: {
    fontSize: ".8em",
    backgroundColor: "#fff !important"
  },
  helper: {
    fontSize: ".8em",
    lineHeight: ".8",
    fontStyle: "italic"
  },
  button: {
    text: {
      fontSize: ".8em"
    },
    color: colorPrimary
  }
}));

export default function UserProfile() {
  const classes = useStyles();
  const translation = useTranslations();

  const dispatch = useDispatch();
  let profile = useSelector(selectProfile);

  const fullName = () => {
    let info = '';
    if (profile.firstName) {
      info += profile.firstName + ' ';
    }
    if (profile.lastName) {
      info += profile.lastName + ' ';
    }
    return info;
  }

  useEffect(() => {
    dispatch(sendToCheckAvailability());
    dispatch(doFetchProfile());
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(logoutUser());
  };

  return (
    <div className="container small-container">
      <div className="profile">
        <div className="profile__header">
          <h2 className="main-title">
            {translation.Profile.personalData}
            <span className="underline"></span>
          </h2>
          <button className="button--outline" onClick={logout}>
            <span>{translation.Profile.logout}</span>
          </button>
        </div>

        <div className="profile-data">
          <div square expanded={true} className="expansion-bottom-special">
            <div id="panel1d-header">
              <div className="profile-data__header">
                <div className="profile-data__avatar">
                  <div className="profile-data__avatar-wrapper">
                    <Picture src={profile.photo || "avatar.png"} height="100" width="auto" />
                  </div>
                </div>
                <div className="profile-data__name">
                  <span className="profile-data__name__label">
                    {profile ? fullName() || translation.Profile.fullname
                      : <Skeleton variant="rect" width={232} height={30} animation="wave" />}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*<ExpansionPanel square expanded={true}>
            <ExpansionPanelSummary id="panel1d-header">
              <div className="profile-data__header">
                <div className="profile-data__name">
                  <span className="profile-data__name__label">
                    {profile ? fullName() || translation.Profile.fullname
                      : <Skeleton variant="rect" width={232} height={30} animation="wave" />}
                  </span>
                </div>
              </div>
            </ExpansionPanelSummary>
          </ExpansionPanel>*/}

          <div square expanded={true}>
            <div id="panel1d-header">
              <div className="profile-data__header">
                <div className="field-group-2">
                  <div className="label">{translation.Profile.email}</div>
                  {/*<PdfIcon 
                    icon="atom/atoms-icons-system-mail" 
                    ext="svg" 
                    width={ '20px' }
                    fill={ 'colorPrimary' } 
                  />*/}
                  <EmailOutlined
                    className="front-icon"
                    style={{ width: '1em', fill: colorPrimary }}
                    component={"svg"}
                  />
                  {profile && profile.email ? <TextField
                    className={classes.textField}
                    value={profile.email}
                    disabled={true}
                  /> : <Skeleton variant="rect" width={200} height={30} animation="wave" />}
                </div>
              </div>
            </div>
          </div>

          <div square expanded={true}>
            <div id="panel1d-header">
              <div className="profile-data__header">
                <div className="field-group-2">
                  <div className="label">{translation.Profile.mainPhone}</div>
                  <CallOutlined
                    className="front-icon"
                    style={{ width: '1em', color: colorPrimary }}
                    component={"svg"}
                  />
                  {profile && profile.phoneNumber ? <TextField
                    className={classes.textField}
                    value={profile.phoneNumber}
                    disabled={true}
                  /> : <Skeleton variant="rect" width={200} height={30} animation="wave" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}