interface Language {
    value: string;
    flag: string;
    name: string;
    defaultName: string;
}

export default [
    {value: 'es', flag: 'cu', name: 'Español', defaultName: 'Español'},
    {value: 'en', flag: 'us', name: 'English', defaultName: 'Inglés'},
] as Language[];