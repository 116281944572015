import {
  CLEAR_ADDRESS_STATES,
  LOADED_AND_STREETS,
  LOADED_BETWEEN_STREETS,
  LOADED_MAIN_STREETS,
  LOADED_MUNICIPALITIES,
  LOADED_PROVINCES,
  LOADED_RESTAURANT_NAMES,
} from "./home.actions";
import { createSelector } from "reselect";
import _ from "lodash";

const localStorageProvinces = localStorage.getItem("provinces");
const localStorageMunicipalities = localStorage.getItem("municipalities");
const INITIAL_STATE = {
  provinces: localStorageProvinces ? JSON.parse(localStorageProvinces) : [],
  provinces_loaded: !!localStorageProvinces,
  municipalities: localStorageMunicipalities
    ? JSON.parse(localStorageMunicipalities)
    : [],
  municipalities_loaded: !!localStorageMunicipalities,
  main_streets: [],
  main_streets_loaded: false,
  between_streets: [],
  between_streets_loaded: false,
  and_streets: [],
  and_streets_loaded: false,
  names: [],
  restaurant_names_loaded: false,
  message: "",
};

export default function address(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADED_PROVINCES:
      let provinces = action.provinces || [];
      _.forEach(provinces, function (item) {
        if (item.id === 13) {
          item["isDisabled"] = false;
          item["value"] = item.id;
          item["label"] = item.name;
        } else {
          // item["isDisabled"] = true;
          item["value"] = item.id;
          item["label"] = item.name;
        }
      });
      return {
        ...state,
        provinces: provinces,
        provinces_loaded: true,
      };
    case LOADED_MUNICIPALITIES:
      return {
        ...state,
        municipalities: action.municipalities || [],
        municipalities_loaded: true,
      };
    case LOADED_MAIN_STREETS:
      return {
        ...state,
        main_streets: action.main_streets || [],
        main_streets_loaded: true,
      };
    case LOADED_BETWEEN_STREETS:
      return {
        ...state,
        between_streets: action.main_streets || [],
        between_streets_loaded: true,
      };
    case LOADED_AND_STREETS:
      return {
        ...state,
        and_streets: action.main_streets || [],
        and_streets_loaded: true,
      };
    case LOADED_RESTAURANT_NAMES:
      return {
        ...state,
        names: action.names || [],
        restaurant_names_loaded: true,
      };
    case CLEAR_ADDRESS_STATES:
      return {
        ...state,
        provinces_loaded: false,
        municipalities_loaded: false,
        main_streets_loaded: false,
        between_streets_loaded: false,
        and_streets_loaded: false,
        restaurant_names_loaded: false,
        message: "",
      };
    default:
      return state;
  }
}

export const selectAddressState = (state) => state.address;

export const selectStateAddress = createSelector(
  selectAddressState,
  (state) => state
);

export const selectProvinces = createSelector(
  selectAddressState,
  (state) => state.provinces
);

export const selectMunicipalities = createSelector(
  selectAddressState,
  (state) => state.municipalities
);
