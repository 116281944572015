import React, { useRef } from 'react';
import './css/ListGroupByProducTypeStyles.scss';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import Swiper from "react-id-swiper";
import useTranslations from "../../../../translations/useTranslations";
import Grid from "@material-ui/core/Grid";
import BusinessItem from "../BusinessItem/BusinessItem";

const ListGroupByProductType = (props) => {
  const translation = useTranslations();
  const productTypeGroup = props.data;
  const business = productTypeGroup.businesses || [];
  const swiperRef = useRef(null);
  const params = {
    //spaceBetween: 32,
    //slidesPerView: 'auto',
    slidesPerView: 5,
    breakpoints: {
      0: {
        slidesPerView: 'auto',
      },
      320: {
        spaceBetween: 18,
        slidesPerView: 1.25,
        slidesPerGroup: 1,
      },
      390: {
        spaceBetween: 18,
        slidesPerView: 1.25,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 32,
        slidesPerView: 1.65,
        slidesPerGroup: 1,
      },
      620: {
        spaceBetween: 32,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      768: {
        spaceBetween: 32,
        slidesPerView: 2.5,
        slidesPerGroup: 2,
      },
      1024: {
        spaceBetween: 32,
        slidesPerView: 3.35,
        slidesPerGroup: 3,
      },
      1230: {
        spaceBetween: 32,
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1350: {
        spaceBetween: 32,
        slidesPerView: 4.35,
        slidesPerGroup: 4,
      }
    },
  };

  const goNext = () => swiperRef.current?.swiper?.slideNext();
  const goPrev = () => swiperRef.current?.swiper?.slidePrev();
  const showAllBusiness = (productType) => props.showAll(productType.id);

  return (
    <Grid className="business-group-by-product-type" container item md={12} sm={12} xs={12} component="div">
      <div className={business.length ? "wrap container-fluid" : "hide"}>
        <div className="carousel-title-bar">
          <div className="restaurant-name">
            <h2 onClick={() => showAllBusiness(productTypeGroup)}>
              {translation.Code === "ES" ? productTypeGroup.nameEs : productTypeGroup.nameEn}
            </h2>
            <div className="more-business" onClick={() => showAllBusiness(productTypeGroup)}>
              <span>{translation.General.seeMore}</span>
            </div>
          </div>
          {props.mobileSize || business.length < 5 ? null :
            <div className="carousel-swiper-buttons">
              <button className="swiper-button-prev" onClick={goPrev}>
                <KeyboardArrowLeft component={"svg"} />
              </button>
              <button className="swiper-button-next" onClick={goNext}>
                <KeyboardArrowRight component={"svg"} />
              </button>
            </div>}
        </div>
        <Swiper {...params} ref={swiperRef}>
          {business.length && business.map((data, index) => <div key={index}>
            <BusinessItem {...props} data={data} category={productTypeGroup.categoryDto} />
          </div>)}
        </Swiper>
      </div>
    </Grid>
  )
};

export default ListGroupByProductType;