/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useTranslations from "translations";
import "../Checkout/css/CheckoutStyles.scss";
import { selectBrowserOrder } from "modules/Checkout/store/checkout.reducers";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";
import { Add, ArrowBack, Edit } from "@material-ui/icons";
import PayMethodRow from "modules/payment/components/StepSelectPaymentMethod/PayMethodRow";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { selectBDCart } from "modules/cart/store/cart.reducers";
import { selectErrorAddPaymentMethod, selectTuBolsilloCredit } from "modules/payment/store/payment.reducers";
import { clearErrorAddPaymentMethod, doFetchTuBolsilloCredit } from "modules/payment/store/payment.actions";
import { showAlert } from "modules/alert/store/alert.actions";
import { CASH, TU_BOLSILLO } from "modules/payment/paymentType";
import { payOrder } from "./store/pay.actions";
import { selectPayError } from "./store/pay.reducers";
import { addCard, clearManageCreditCard, deleteCard, loadCards } from "modules/CardManagement/store/card-management.actions";
import { addCardSelector, cardsListSelector, deletedCardSelector, newCardSelector } from "modules/CardManagement/store/card-management.reducers";
import { selectProfile } from "modules/profile/store/profile.reducers";
import Alert from "modules/alert/Alert";
import PaymentErrorAlert from "./components/PaymentErrorAlert";
import AddCardModal from "./components/AddCardModal";
import { TASA_PESO } from "../../constants";
import useRestaurant from "hooks/useRestaurant";
import { browserHistory } from "utils/History";


export default function Pay(props) {
  const dispatch = useDispatch();
  const translation = useTranslations();

  const { fetchDetails } = useRestaurant();

  const user = useSelector(selectProfile);
  const cartBD = useSelector(selectBDCart);
  const payError = useSelector(selectPayError);
  const cardAdded = useSelector(addCardSelector);
  const newUserCard = useSelector(newCardSelector);
  const cardDeleted = useSelector(deletedCardSelector);
  const userCardsList = useSelector(cardsListSelector);
  const browserOrder = useSelector(selectBrowserOrder);
  const tubolsilloCredit = useSelector(selectTuBolsilloCredit);
  const errorAddPaymentMethod = useSelector(selectErrorAddPaymentMethod);

  const [creditSelected, setCreditSelected] = useState(null);
  const [method, setMethod] = useState(null);
  const [editMethods, setEditMethods] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showNewMethodDialog, setShowNewMethodDialog] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  const [fetchingTuBolsilloCredit, setFetchingTuBolsilloCredit] = useState(false);
  const [creditCardsList, setCreditCardsList] = useState([]);
  const [isLoadingNewPaymentMethod, setIsLoadingNewPaymentMethod] = useState([]);

  const notEnoughCreditInTuBolsillo = tubolsilloCredit <= 0;
  const extraNeededBesideCredit = (browserOrder?.total > tubolsilloCredit ? browserOrder?.total - tubolsilloCredit : 0).toFixed(2);

  useEffect(() => {
    if (!browserOrder?.accountId || !browserOrder?.deliveryDay || !browserOrder?.deliveryHour ||
      !browserOrder?.restaurantId || !browserOrder?.total || !cartBD?.items || cartBD?.items?.length === 0 ||
      (browserOrder?.deliveryOrderType === 'DELIVERY' && !browserOrder?.address.id) ||
      (browserOrder?.deliveryOrderType === 'PICKUP' && (!browserOrder?.receiver || !browserOrder?.receiverPhone))) {
      props.history.push({ pathname: "/prv/checkout" });
    } else {
      dispatch(loadCards());
      setFetchingTuBolsilloCredit(true);
      dispatch(doFetchTuBolsilloCredit());

      loadBusinessDetails();
    }
  }, []);

  useEffect(() => {
    if (tubolsilloCredit !== false) {
      setFetchingTuBolsilloCredit(false);
    }
  }, [tubolsilloCredit]);

  useEffect(() => {
    if (userCardsList) {
      setCreditCardsList(userCardsList);
      dispatch(setLoadingMaskStatus(false));
    }
  }, [userCardsList]);

  useEffect(() => {
    if (cardAdded && newUserCard?.id) {
      setMethod(newUserCard?.id);
    } else {
      dispatch(clearManageCreditCard());
    }
    dispatch(setLoadingMaskStatus(false));
    setIsLoadingNewPaymentMethod(false);
  }, [cardAdded]);

  useEffect(() => { cardDeleted && dispatch(loadCards()); }, [cardDeleted]);

  useEffect(() => {
    if (payError) {
      if ([8001, 8002, 8003, 8004, 8006, 8007, 8008, 8010].includes(payError?.code)) {
        setErrorAlertMessage(payError?.description);
        setIsErrorAlert(true);
        dispatch(setLoadingMaskStatus(false));
        setIsLoadingNewPaymentMethod(false);
        browserHistory.push({ pathname: "/prv/pay_error", state: {
          amount: browserOrder?.total,
        }, });
      }
    }
  }, [payError]);

  useEffect(() => {
    const isMethodSelected = method && !creditSelected;
    const isCreditSelectedAndIsEnoughToPay = creditSelected && extraNeededBesideCredit <= 0;
    const isCreditSelectedAndMethodSelected = creditSelected && extraNeededBesideCredit > 0 && method;
    setIsFormValid(isMethodSelected || isCreditSelectedAndIsEnoughToPay || isCreditSelectedAndMethodSelected);
  }, [method, creditSelected]);

  const handleBack = () => props.history.push({ pathname: '/prv/checkout' });

  const loadBusinessDetails = async () => {
    const address = JSON.parse(localStorage.getItem('address'));
    if (address && cartBD?.restaurantId) {
      const details = await fetchDetails(address, cartBD.restaurantId);
      setRestaurantDetails(details);
    }
  }

  const selectPayTuBolsillo = () => {
    const selected = !creditSelected;
    setCreditSelected(selected);
    if (selected) {
      setMethod(null);
    }
  };

  const selectPayMethod = (method) => {
    setMethod(method);
    if (extraNeededBesideCredit <= 0) {
      setCreditSelected(false);
    }
  }

  const handleOpenAddPaymentMethod = () => {
    dispatch(clearErrorAddPaymentMethod());
    setShowNewMethodDialog(true);
  }

  const handleDeleteMethod = (id) => {
    dispatch(showAlert(translation.Payment.alertDeletePaymentMethod, '', '', '', () => {
      dispatch(deleteCard(id));
      setEditMethods(false)
    }));
  };

  const addCreditCard = (card) => {
    setShowNewMethodDialog(false);
    setIsLoadingNewPaymentMethod(true);
    dispatch(addCard({
      accountId: user.accountId,
      card: {
        cvv: card.cvv,
        postalCode: card.zip,
        number: card.number.replaceAll(" ", ""),
        expirationMonth: card.expirationDate.split("/")[0],
        expirationYear: `20${card.expirationDate.split("/")[1]}`,
      },
    }));
  };

  const goToPay = () => {
    let paymentMethodId = method; // TU_BOLSILLO=>0 / null if CASH / method else
    let paymentFlowCode = 'CLL2'; // CLL2 if TU_BOLSILLO or METHOD / CASH else
    let amountCredit = null;

    const payWithNoCredit = method && !creditSelected;
    const payOnlyWithCredit = creditSelected && extraNeededBesideCredit <= 0;
    const payWithCredit = creditSelected && extraNeededBesideCredit > 0 && method;

    if (payWithNoCredit && method === CASH) {
      paymentMethodId = null;
      paymentFlowCode = CASH;
    }

    if (payOnlyWithCredit) {
      paymentMethodId = 0;
    }

    if (payWithCredit && extraNeededBesideCredit > 0) {
      amountCredit = browserOrder?.total - extraNeededBesideCredit;
    }

    if (paymentFlowCode !== null) {
      // dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
      dispatch(payOrder(buildPaymentPayload(paymentFlowCode, paymentMethodId, amountCredit)));
      dispatch(clearManageCreditCard());
    }
  }

  const buildPaymentPayload = (paymentFlowCode, paymentMethodId, amountCredit = null) => ({
    address: buildAddressPayload(),
    taxes: browserOrder?.taxes,
    amountCredit,
    receiver: browserOrder?.receiver,
    subtotal: browserOrder?.subtotal,
    shipping: browserOrder?.shipping,
    accountId: browserOrder?.accountId,
    restaurantId: browserOrder?.restaurantId,
    receiverPhone: browserOrder?.receiverPhone,
    deliveryOrderType: browserOrder?.deliveryOrderType,
    discountByTalon: browserOrder?.discountByTalon,
    totalCost: browserOrder?.total,
    discount: browserOrder?.discountByCoupon,
    deliveryHour: browserOrder?.deliveryHour?.hour,
    deliveryDay: browserOrder?.deliveryDay?.date.split('-').reverse().join('-'),
    paymentFlowCode,
    payment: {
      orderId: 0,
      required: false,
      paymentMethodId,
      accountId: browserOrder.accountId,
      amountCredit // credit
    },
    paymentTransactionid: null,
  });

  const buildAddressPayload = () => ({
    mobile: null,
    landLine: null,
    provinceName: null,
    addressSearch: null,
    municipalityName: null,
    name: browserOrder?.address?.name,
    details: browserOrder?.address?.details,
    accountId: browserOrder?.address?.userRef,
    provinceId: browserOrder?.address?.provinceid,
    localityId: browserOrder?.address?.localityid,
    restaurantId: browserOrder?.address?.restaurantid,
    phone: browserOrder?.address?.telephoneById?.number,
    municipalityId: browserOrder?.address?.municipalityid,
    additionalInfo: browserOrder?.address?.additionalInfo,
  });

  const closeErrorAlert = () => { console.log('closed error alert') };

  const getTotalToPay = () => {
    if (method === CASH) {
      return ` ${parseFloat(browserOrder?.total * TASA_PESO).toFixed(2)} CUP`
    }

    if (creditSelected && extraNeededBesideCredit > 0) {
      return ` $${parseFloat(extraNeededBesideCredit).toFixed(2)}`;
    } else {
      return browserOrder?.total > 0
        ? ` $${parseFloat(browserOrder?.total).toFixed(2)}`
        : ` $${(0).toFixed(2)}`
    }
  }

  return (
    <div className="container" style={{ backgroundColor: "white" }}>
      <div className="grid">
        <div className="steps new__steps">
          <div className="step new__step">
            <div className="new__step__header-wrapper">
              <PaymentErrorAlert show={isErrorAlert} onClose={closeErrorAlert} message={errorAlertMessage} />
              <AddCardModal show={showNewMethodDialog} onClose={() => setShowNewMethodDialog(false)}
                handleSubmit={addCreditCard} handleError={errorAddPaymentMethod} />
              <Alert />
              <div className="step-delivery__actions mb-20">
                <button className="button--primary button--primary-inverted" onClick={handleBack} >
                  <ArrowBack style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                  <span>{translation.Payment.goBack}</span>
                </button>
              </div>
              <Typography variant="h5" component="h2" className="subtitle mb-20">
                {translation.Payment.titleSelectPaymentMethod}
              </Typography>
              <div className="step-delivery__actions mb-20" style={{ justifyContent: 'flex-end' }}>
                {creditCardsList
                  ? <button className="button--primary button--primary-inverted"
                    onClick={handleOpenAddPaymentMethod} style={{ marginRight: '10px' }}>
                    <Add style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                    <span style={{ textTransform: 'initial' }}>
                      {translation.Payment.addPaymentMethod}
                    </span>
                  </button>
                  : <Skeleton
                    variant="rect" width={110} height={42} animation="wave"
                    className="creditcard-skeleton" style={{ borderRadius: '21px' }}
                  />}
                {creditCardsList?.length > 0
                  ? <button className="button--primary button--primary-inverted"
                    onClick={() => setEditMethods(!editMethods)}>
                    <Edit style={{ fill: '#1e4acc', width: '20px', height: '20px' }} component="svg" />
                    <span style={{ textTransform: 'initial' }}>
                      {translation.Payment[editMethods ? 'cancelEdit' : 'edit']}
                    </span>
                  </button>
                  : null}
              </div>

              <div className="step-delivery__sections">
                {creditCardsList ? <div className="payment-methods">
                  {!fetchingTuBolsilloCredit ?
                    <PayMethodRow
                      id={TU_BOLSILLO}
                      icon="tubolsillo"
                      ifCheckFunction={() => creditSelected}
                      text={`${translation.Payment.availableCredit} ($${parseFloat(tubolsilloCredit).toFixed(2)})`}
                      handleClick={!notEnoughCreditInTuBolsillo ? selectPayTuBolsillo : null}
                      disabled={notEnoughCreditInTuBolsillo || editMethods}
                      styleClass={editMethods ? { cursor: 'default' } : {}}
                      edit={false}
                      useSwitch={extraNeededBesideCredit > 0}
                    /> : <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />}

                  {creditSelected && extraNeededBesideCredit > 0 && <Typography variant="subtitle1" component="div" style={{
                    fontWeight: 400,
                    marginTop: '1.5em',
                    textAlign: 'left'
                  }}>
                    {translation.Payment.payRestWith1} <strong>${extraNeededBesideCredit}</strong> {translation.Payment.payRestWith2}:
                  </Typography>}

                  {creditCardsList?.map(({ id, lastFour, cardType, nameOnCard, paymentMethodType }) => {
                    const icon = paymentMethodType === 'CARD' ? (cardType?.replace(/\s/g, "") || "visa") : `paypal`;
                    const text = paymentMethodType === 'CARD' ? `${translation.Payment.creditCardEndWith} ${lastFour}` : nameOnCard;
                    return <PayMethodRow
                      id={id}
                      key={id}
                      icon={icon}
                      ifCheckFunction={(id) => editMethods || method === id}
                      text={text}
                      handleClick={() => selectPayMethod(id)}
                      handleDelete={() => handleDeleteMethod(id)}
                      styleClass={editMethods ? { cursor: 'default' } : {}}
                      edit={editMethods}
                    />
                  })}

                  {isLoadingNewPaymentMethod && <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />}

                  {restaurantDetails.allowCashPayment && <PayMethodRow
                    id={CASH}
                    icon="cash"
                    ifCheckFunction={(id) => !editMethods && method === id}
                    text={`${translation.Payment.cashPaymentOptionLabel} ${browserOrder?.total ? `(${parseFloat(browserOrder?.total * TASA_PESO).toFixed(2)} CUP)` : null}`}
                    handleClick={() => selectPayMethod(CASH)}
                    disabled={editMethods}
                    styleClass={editMethods ? { cursor: 'default' } : {}}
                    edit={editMethods}
                  />}
                </div>
                  : <div className="payment-methods">
                    <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
                    <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
                    <Skeleton variant="rect" height={54} animation="wave" className="creditcard-skeleton" style={{ marginBottom: '.7em' }} />
                  </div>
                }
              </div>
              <div className="step-delivery__button-next">
                <button onClick={goToPay} disabled={!isFormValid} className="button--primary button--large">
                  <span>{translation.Payment.pay}</span>
                  <span className="bullet__white">{` • `}</span>
                  <span className="cart-total">
                    {getTotalToPay()}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}