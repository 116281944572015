import { createSelector } from "reselect";
import * as filter_actions from "./filter.actions";

const defaultCounter = 10;

const INITIAL_FILTER_STATE = {
  deliveryOrderType: "DELIVERY",
  categories: null,
  businessCategory: null,
  filterTags: [],
  productTypes: [],
  provinceid: 13,
  menu: [],
  classificationLabel: "",
  search: [],
};

const INITIAL_STATE = {
  restaurantsCarouselsList: [],
  restaurantsByProductTypeList: [],
  total: 0,
  totalByProductType: 0,
  loading: true,
  moreResults: true,
  showAllBusiness: false,
  filters: {
    ...INITIAL_FILTER_STATE,
  },
  pageCounter: defaultCounter,
  message: "",
  current: {},
};

export default function filters(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case filter_actions.LOADED_FILTERS:
      return {
        ...state,
        moreResults: true,
        filters: {
          ...INITIAL_FILTER_STATE,
          ...action.filters,
        },
      };
    case filter_actions.SHOW_ALL_BUSINESS:
      return {
        ...state,
        showAllBusiness: action.showAll,
      };
    case filter_actions.CLEAR_FILTERS_STATES:
      return {
        ...state,
        moreResults: true,
        filters: { ...INITIAL_FILTER_STATE },
      };
    case filter_actions.CLEAR_PRODUCT_TYPE:
      return {
        ...state,
        filters: { ...filters, productTypes: [] },
      };
    case filter_actions.CLEAR_RESTAURANTS_CAROUSELS_LIST:
      return {
        ...state,
        moreResults: true,
        restaurantsCarouselsList: [],
      };
    case filter_actions.LOADING:
      return {
        ...state,
        loading: true,
      };
    case filter_actions.LOADED:
      return {
        ...state,
        loading: false,
      };
    case filter_actions.RESTAURANTS_CAROUSELS_LIST_FILTERED_SUCCESS:
      return {
        ...state,
        total: action.response.data.total || 0,
        restaurantsCarouselsList: action.response.data.restaurants || [],
        loading: false,
      };
    case filter_actions.RESTAURANTS_CAROUSELS_LIST_FETCH_MORE_SUCCESS:
      const newFetchedRestaurants = action.response.data.restaurants || [];
      const restaurantsIds = state.restaurantsCarouselsList.map(({ id }) => id);
      const sanitazedList = newFetchedRestaurants.filter(
        (fetched) => !restaurantsIds.includes(fetched.id)
      );
      return {
        ...state,
        moreResults: sanitazedList.length > 0,
        loading: false,
        restaurantsCarouselsList: state.restaurantsCarouselsList.concat([
          ...sanitazedList,
        ]),
      };
    case filter_actions.RESTAURANTS_CAROUSELS_LIST_FILTERED_FAILURE:
      return {
        ...state,
        moreResults: true,
        loading: false,
        restaurantsCarouselsList: [],
      };
    case filter_actions.RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_SUCCESS:
      return {
        ...state,
        totalByProductType: action.response.data.total || 0,
        restaurantsByProductTypeList: action.response.data.restaurants || [],
        loading: false,
      };
    case filter_actions.RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_FAILURE:
      return {
        ...state,
        moreResults: true,
        loading: false,
        restaurantsByProductTypeList: [],
      };
    case filter_actions.RESTAURANTS_BY_PRODUCT_TYPE_LIST_FETCH_MORE_SUCCESS:
      const othersNewFetchedRestaurants =
        action.response.data.restaurants || [];
      const othersRestaurantsIds = state.restaurantsByProductTypeList.map(
        ({ id }) => id
      );
      const othersSanitizedList = othersNewFetchedRestaurants.filter(
        (fetched) => !othersRestaurantsIds.includes(fetched.id)
      );
      return {
        ...state,
        moreResults: othersSanitizedList.length > 0,
        totalByProductType:state.totalByProductType - (othersNewFetchedRestaurants.length - othersSanitizedList.length),
        loading: false,
        restaurantsByProductTypeList: state.restaurantsByProductTypeList.concat(
          [...othersSanitizedList]
        ),
      };
    default:
      return state;
  }
}

const selectFilterState = (state) => state.filters;

export const selectRestaurants = createSelector(
  selectFilterState,
  (state) => state.restaurantsCarouselsList || []
);

export const selectShowAllBusinessByProductType = createSelector(
  selectFilterState,
  (state) => state.showAllBusiness
);

export const selectBusinessListByProductType = createSelector(
  selectFilterState,
  (state) => state.restaurantsByProductTypeList || []
);

export const selectBusinessTotalByProductType = createSelector(
  selectFilterState,
  (state) => state.totalByProductType
);

export const selectRestaurantsTotal = createSelector(
  selectFilterState,
  (state) => state.total
);

export const selectIsLoading = createSelector(
  selectFilterState,
  (state) => state.loading
);

export const selectDefaultPageCounter = createSelector(
  selectFilterState,
  ({ pageCounter }) => 10 //state.pageCounter || 10
);

export const selectMoreResults = createSelector(
  selectFilterState,
  ({ moreResults }) => moreResults
);

export const selectFilters = createSelector(
  selectFilterState,
  (state) => state.filters
);

export const selectDefaultFilters = createSelector(() => INITIAL_FILTER_STATE);

export const getEntityById = (id) => (state) =>
  state.restaurant.filter((r) => r.id === id);

export const selectRestaurant = (id) =>
  createSelector(selectFilterState, getEntityById(id));

export const selectCurrent = () =>
  createSelector(selectFilterState, (state) => state.current);
