import React, { useState } from "react";
import useTranslations from "translations";
import { ArrowForwardIos, WatchLater } from "@material-ui/icons";
import CustomSelect from "component/CustomSelect/CustomSelect";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

const CheckoutDateTime = ({ deliveryDay, deliveryHour, availableDates, availableTimes, errorDay, errorHour, selectDate, selectTimeline }) => {
  const translation = useTranslations();
  const [showDateAndTime, setShowDateAndTime] = useState(null);
  const openDateAndTime = () => setShowDateAndTime(!showDateAndTime);
  const handleSelectTimeline = (time) => {
    setShowDateAndTime(false);
    selectTimeline(time);
  };

  return <>
    <div onClick={openDateAndTime} className="new__button" style={{ marginTop: '1.8em' }}>
      <WatchLater style={{ color: '#2c2c2c', fontSize: 20, margin: '1px 7px 0 0' }} component="svg" />
      <p>
        {availableDates && deliveryDay ? translation.Code === "ES"
          ? deliveryDay?.labelEs : deliveryDay?.labelEn
          : translation.Payment.labelDateAndTimeOf}
        {availableTimes && deliveryHour ? ", " + deliveryHour?.label : null}
      </p>
      <ArrowForwardIos style={{ color: '#2c2c2c', fontSize: "large", fontWeight: "bold", transform: showDateAndTime ? "rotate(90deg)" : "" }} component="svg" />
    </div>
    {showDateAndTime && <div className="step-delivery__sections">
      <div className="field-group">
        <div className="field-group__half">
          <div className="field-group">
            {availableDates ? <CustomSelect
              required
              id="deliveryDateText"
              isClearable={false}
              errorMessage={errorDay}
              fieldLabel={translation.Payment.date}
              value={deliveryDay}
              options={availableDates}
              onChange={date => selectDate(date)}
              getOptionValue={option => option.id}
              getOptionLabel={option => translation.Code === "ES" ? option.labelEs : option.labelEn}
            /> : <Skeleton variant="rect" width="100%" height={39} animation="wave" />}
          </div>
          <div className="field-group">
            {availableTimes ? <CustomSelect
              required
              id="deliveryHour"
              isClearable={false}
              errorMessage={errorHour}
              fieldLabel={translation.Payment.time}
              value={deliveryHour}
              options={availableTimes}
              onChange={time => handleSelectTimeline(time)}
              getOptionValue={option => option.hour}
              getOptionLabel={option => option.label}
            /> : <Skeleton variant="rect" width="100%" height={39} animation="wave" />}
          </div>
        </div>
      </div>
    </div>}
  </>;
}

export default CheckoutDateTime;