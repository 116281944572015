import React from 'react';
import { Route } from 'react-router-dom';
import { store } from "../App";
import { browserHistory } from "../utils/History";

// const me = async () => {
//   try {
//     const account = await simpleGet('account/');
//     if (!account || typeof (account) !== 'object' || account.data === undefined || account.data === "") {
//       return false;
//     }
//     const { accountId, role } = account.data;
//     return accountId !== undefined && role?.id !== undefined;
//   } catch (e) {
//     return false;
//   }
// }

const PrivateRoute = (props) => {
  const authData = store.getState().auth;
  let finalComponent = null;

  if (props.location.pathname !== "/login") {
    localStorage.setItem('backTo', ".." + props.location.pathname);
  }

  if (!authData?.access_token) {
    browserHistory.push({ pathname: "/login" });
  } else {
    finalComponent = props.component;
  }

  return <Route {...props} component={finalComponent} />;
};

export default PrivateRoute;