import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import useTranslations from "translations";

const CheckoutErrorAlert = ({ show, errorAlertMessage }) => {
  const translation = useTranslations();

  return <Dialog open={show}>
    <DialogTitle className="verification_dialog_header">
      {translation.Payment.attention}
    </DialogTitle>
    <DialogContent className="verification_dialog_body">
      <h3>{errorAlertMessage}</h3>
    </DialogContent>
    <DialogActions className="verification_dialog_actions">
      <button className="button--primary">
        {translation.Actions.accept}
      </button>
    </DialogActions>
  </Dialog>
}

export default CheckoutErrorAlert;