import { post } from "core/services";
import { browserHistory } from "utils/History";
import { notifyError } from "modules/notification/store/notification.actions";

const baseUrl = "restaurant";

export const SHOW_ALL_BUSINESS = "SHOW_ALL_BUSINESS";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const RESTAURANTS_FILTERED = "RESTAURANTS_FILTERED";
export const CLEAR_RESTAURANTS_STATES = "CLEAR_RESTAURANTS_STATES";
export const LOADED_FILTERS = "LOADED_FILTERS";
export const CLEAR_PRODUCT_TYPE = "CLEAR_PRODUCT_TYPE";
export const CLEAR_FILTERS_STATES = "CLEAR_FILTERS_STATES";
export const CLEAR_RESTAURANTS_CAROUSELS_LIST =
  "CLEAR_RESTAURANTS_CAROUSELS_LIST";
export const RESTAURANTS_CAROUSELS_LIST_FILTERED_SUCCESS =
  "RESTAURANTS_CAROUSELS_LIST_FILTERED_SUCCESS";
export const RESTAURANTS_CAROUSELS_LIST_FETCH_MORE_SUCCESS =
  "RESTAURANTS_CAROUSELS_LIST_FETCH_MORE_SUCCESS";
export const RESTAURANTS_CAROUSELS_LIST_FILTERED_FAILURE =
  "RESTAURANTS_CAROUSELS_LIST_FILTERED_FAILURE";
export const RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_SUCCESS =
  "RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_SUCCESS";
export const RESTAURANTS_BY_PRODUCT_TYPE_LIST_FETCH_MORE_SUCCESS =
  "RESTAURANTS_BY_PRODUCT_TYPE_LIST_FETCH_MORE_SUCCESS";
export const RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_FAILURE =
  "RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_FAILURE";

export function setFilters(filters) {
  return {
    type: LOADED_FILTERS,
    filters,
  };
}

export function showAllBusinessForProductType(showAll) {
  return {
    type: SHOW_ALL_BUSINESS,
    showAll,
  };
}

export function clearProductTypes() {
  return {
    type: CLEAR_PRODUCT_TYPE,
  };
}

export const loading = () => ({ type: LOADING });

export const loaded = () => ({ type: LOADED });

export function clearFiltersStates() {
  return {
    type: CLEAR_FILTERS_STATES,
  };
}

export function clearRestaurantCarouselsListStates() {
  return {
    type: CLEAR_RESTAURANTS_CAROUSELS_LIST,
  };
}

function restaurantsCarouselsListFilteredSuccess(response) {
  return {
    type: RESTAURANTS_CAROUSELS_LIST_FILTERED_SUCCESS,
    response,
  };
}

function restaurantsCarouselsListFetchMoreSuccess(response) {
  return {
    type: RESTAURANTS_CAROUSELS_LIST_FETCH_MORE_SUCCESS,
    response,
  };
}

function restaurantsCarouselsListFilteredFailure(response) {
  return {
    type: RESTAURANTS_CAROUSELS_LIST_FILTERED_FAILURE,
    response,
  };
}

function restaurantsByProductTypeListFilteredSuccess(response) {
  return {
    type: RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_SUCCESS,
    response,
  };
}

function restaurantsByProductTypeListFetchMoreSuccess(response) {
  return {
    type: RESTAURANTS_BY_PRODUCT_TYPE_LIST_FETCH_MORE_SUCCESS,
    response,
  };
}

function restaurantsByProductTypeListFilteredFailure(response) {
  return {
    type: RESTAURANTS_BY_PRODUCT_TYPE_LIST_FILTERED_FAILURE,
    response,
  };
}

export function loadRestaurantsCarouselsList(
  preview,
  filters,
  pointer,
  counter,
  passiveLoading = false,
  redirect = false
) {
  return (dispatch) => {
    dispatch(setFilters(filters));
    if (!passiveLoading) {
      dispatch(loading());
    }
    post(
      `${baseUrl}/getRestaurantOffersList?preview=${preview}&pointer=${pointer}&counter=${counter}`,
      filters
    )
      .then((response) => {
        if (!passiveLoading) {
          dispatch(restaurantsCarouselsListFilteredSuccess(response));
        } else {
          dispatch(restaurantsCarouselsListFetchMoreSuccess(response));
        }
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(restaurantsCarouselsListFilteredFailure(errMsg));
          dispatch(notifyError(errMsg));
        }
        if (!passiveLoading) {
          browserHistory.push({ pathname: "/" });
        }
      })
      .finally(() => {
        dispatch(loaded());
      });
  };
}

const goToCategorySlug = ({ slug }, redirect = false) => {
  browserHistory.push(
    `${slug === "/mercado" ? (redirect ? "/?redirect=true" : "/") : slug}${
      redirect ? "?redirect=true" : ""
    }`
  );
};

export function loadBusinessGroupByProductType(
  preview,
  filters,
  pointer,
  counter,
  passiveLoading = false
) {
  return (dispatch) => {
    if (filters.categories) {
      filters.categories = [];
    }
    dispatch(setFilters(filters));
    if (!passiveLoading) {
      dispatch(loading());
    }
    post(
      `${baseUrl}/getRestaurantByProductType?preview=${preview}&pointer=${pointer}&counter=${counter}`,
      filters
    )
      .then((response) => {
        if (!passiveLoading) {
          dispatch(restaurantsByProductTypeListFilteredSuccess(response));
          if (!["/", "/mercado", "/mercado/"].includes(window.location.pathname)) {
            goToCategorySlug({ slug: "mercado" }, true);
          }
        } else {
          dispatch(restaurantsByProductTypeListFetchMoreSuccess(response));
        }
      })
      .catch((error) => {
        let errObj = error?.response?.data || null;
        if (errObj) {
          const errMsg = errObj?.message || "ERROR!";
          dispatch(restaurantsByProductTypeListFilteredFailure(errMsg));
          dispatch(notifyError(errMsg));
        }
        if (!passiveLoading) {
          browserHistory.push({ pathname: "/" });
        }
      })
      .finally(() => {
        dispatch(loaded());
      });
  };
}
