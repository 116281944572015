import {simplePost} from "../../../../core/services";
import {notifyError} from "../../../notification/store/notification.actions";

export const DONE_CASH_PAYMENT = "DONE_CASH_PAYMENT";
export const SET_ERROR_CASH_PAYMENT = "SET_ERROR_CASH_PAYMENT";

const baseUrl = "payment/cash";

function doneCashPayment(data) {
  return {
    type: DONE_CASH_PAYMENT,
    data,
  };
}

// function setErrorCashPayment(data) {
//   return {
//     type: SET_ERROR_CASH_PAYMENT,
//     data
//   };
// }

export function doCashPayment(amount, paymentRequest, history) {
  return (dispatch) => {
    simplePost(`${baseUrl}/make/payment?amount=${amount}`, paymentRequest)
      .then((response) => {
        dispatch(doneCashPayment(response));
        history.push({pathname: "/prv/pay_success"});
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(notifyError(errMsg));
          history.push({pathname: "/prv/pay_error"});
        }
      });
  };
}
