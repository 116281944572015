export const BACKEND = {
  ADDRESS_MUNICIPALITIES_LIST: "address/getMunicipalities?provinceId=",
  ADDRESS_PROVINCES_LIST: "address/getProvinces",
  ADDRESS_MAIN_STREET_LIST: "address/getStreet?name=",
  ADDRESS_RELATED_LIST: "address/getRelatedStreets?id=",
  ALL_CUISINE_TYPE: "filter/getCuisineType",
  CUISINE_TYPE: "filter/getCuisineType?preview=",
  CREATE_UPDATE_PURCHASE: "purchase/createUpdatePurchase",
  DELIVERY_COST: "cart/getDeliveryCost?restaurantId=",
  PROMOTIONS: "filter/getPromotions",
  RESTAURANT_ADDRESS: "address/getRestaurantAddress",
  RESTAURANT_NAMES: "filter/getRestaurantNames?type=",
  RESTAURANT_DETAIL: "menu/getRestaurantMenu?id=",
  RESTAURANTS_LIST: "restaurant/getRestaurantList",
  RESTAURANT_MENU: "menu/getRestaurantMenu?id=",
  RESTAURANTS_MOST_POPULAR: "getMostPopular?preview=",
  SPECIFICATIONS: "filter/getSpecifications",
  TRENDING: "getTrending",
  USER_CREATE_DELIVERY_ADDRESS: "address/createUpdateAddresses",
  USER_DELIVERY_ADDRESS_LIST: "address/getUserAddresses",
  USER_DELETE_DELIVERY_ADDRESS: "address/deleteAddresses?id=",
  USER_PROFILE: "user/getUserProfile?id=",
  USER_UPDATE_DELIVERY_ADDRESS: "address/createUpdateAddresses",
  VALIDATE_ADDRESS_FOR_DELIVERY: "address/validateAddressForDelivery?restaurantId=",
};
