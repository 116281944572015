import { createSelector } from "reselect";
import * as actions_pay from "./pay.actions";

const INITIAL_STATE = {
  result: null,
  payError: null,
};

export default function pay(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions_pay.PAY_ORDER_SUCCESS: {
      return {
        ...state,
        result: action.response.data
      };
    }
    case actions_pay.PAY_ORDER_FAILURE: {
      return {
        ...state,
        payError: action.response.data
      };
    }
    default: {
      return {
        ...INITIAL_STATE,
      };
    }
  }
}

export const selectPayState = (state) => state.pay;

export const selectPayResult = createSelector(
  selectPayState,
  (state) => state.result
);

export const selectPayError = createSelector(
  selectPayState,
  (state) => state.payError
);