import React from "react";
import './css/BusinessCardListStyles.scss';
import OfferItemCard from "../OfferItem/OfferItemCard";

const BusinessCardList = ({ category, restaurant, handleOnClickOffer, handleOnAddToCart, handleOnRemoveFromCart }) => {

  return (
    <div className="business-card-list">
      {category.offers.map((offer, key) => {
        return (
          <div key={key}>
            <OfferItemCard
              offer={offer}
              restaurant={restaurant}
              handleOnAddToCart={handleOnAddToCart}
              handleOnRemoveFromCart={handleOnRemoveFromCart}
              handleOnClickOffer={handleOnClickOffer}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BusinessCardList;
