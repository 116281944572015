import { get } from "core/services";
import { notifyError } from "modules/notification/store/notification.actions";
import { post } from "../../../../../core/services";

export const BUSINESS_CATEGORIES_LOADED_SUCCESS =
  "BUSINESS_CATEGORIES_LOADED_SUCCESS";
export const BUSINESS_CATEGORIES_LOADED_FAILURE =
  "BUSINESS_CATEGORIES_LOADED_FAILURE";

function loadedBusinessCategoriesSuccess(response) {
  return {
    type: BUSINESS_CATEGORIES_LOADED_SUCCESS,
    response,
  };
}

function loadedBusinessCategoriesFailure(response) {
  return {
    type: BUSINESS_CATEGORIES_LOADED_FAILURE,
    response,
  };
}

export function loadBusinessCategories() {
  return (dispatch) => {
    get("businessCategory")
      .then((response) => dispatch(loadedBusinessCategoriesSuccess(response)))
      .catch((response) => {
        const errMsg = response?.data?.message || "ERROR!";
        dispatch(loadedBusinessCategoriesFailure(errMsg));
        dispatch(notifyError(errMsg));
      });
  };
}

export function loadBusinessCategoriesWithFilters(FILTERS) {
  return (dispatch) => {
    post("categoriesWhitBusiness", FILTERS)
      .then((response) => dispatch(loadedBusinessCategoriesSuccess(response)))
      .catch((response) => {
        const errMsg = response?.data?.message || "ERROR!";
        dispatch(loadedBusinessCategoriesFailure(errMsg));
        dispatch(notifyError(errMsg));
      });
  };
}
