import {setLoadingMaskStatus} from "../../../component/Mask/store/mask.actions";
import {post} from "../../../core/services";
import {notifyInfo} from "../../notification/store/notification.actions";

const baseUrl = "account";

export const SET_FORM_DATA = "SET_FORM_DATA";
export const SEND_PIN_SUCCESS = "SEND_PIN_SUCCESS";
export const SEND_PIN_FAILURE = "SEND_PIN_FAILURE";
export const BACK_TO_EDIT_PHONE = "BACK_TO_EDIT_PHONE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export function setRecoverPasswordForm(data) {
  return {
    type: SET_FORM_DATA,
    data
  }
}

export function editPhone() {
  return {
    type: BACK_TO_EDIT_PHONE
  }
}

function sendPinSuccess(response) {
  return {
    type: SEND_PIN_SUCCESS,
    response
  }
}

function sendPinFailure(response) {
  return {
    type: SEND_PIN_FAILURE,
    response
  }
}

function changePasswordSuccess(response) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    response
  }
}

function changePasswordFailure(response) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    response
  }
}

export function sendRecoverPasswordPin(DATA) {
  return dispatch => {
    dispatch(setLoadingMaskStatus(true));
    post(`${baseUrl}/recoverPsw`, DATA)
      .then(response => {
        dispatch(sendPinSuccess(response));
      })
      .catch(error => {
        let errObj = error.response && error.response.data ? error.response.data : {};
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(sendPinFailure(errMsg));
          dispatch(notifyInfo(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

export function changePassword(DATA) {
  return dispatch => {
    dispatch(setLoadingMaskStatus(true));
    post(`${baseUrl}/verifyPsw`, DATA)
      .then(response => {
        dispatch(changePasswordSuccess(response));
      })
      .catch(error => {
        let errObj = error.response && error.response.data ? error.response.data : {};
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(changePasswordFailure(errMsg));
          dispatch(notifyInfo(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}