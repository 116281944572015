/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { withStyles, Menu, MenuItem, ListItemText, ListItemIcon, Drawer, List, ListItem, } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import "./css/TopbarStyle.scss";
import CartDialog from "../cart/CartDialog";
import Alert from "../alert/Alert";
import AtomIcon from "component/Icon/AtomIcon";
import Picture from "component/Picture/Picture";
import PdfIcon from "component/Icon/PdfIcon";
import LanguagePicker from "component/LanguagePicker/LanguagePicker";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import Address from "./Address";
import Search from "./Search";
import { selectCartTotal, selectBDCart } from "../cart/store/cart.reducers";
import { cllLoginSuccess, logoutUser } from "../login/store/login.actions";
import { loginUserSelector } from "../login/store/login.reducers";
import { loadUserCart } from "../cart/store/cart.actions";
import { isAuth, store } from "../../App";
import useTranslations from "../../translations/useTranslations";
import { showAllBusinessForProductType } from "../../store/filter/filter.actions";
import * as _ from 'lodash';
import { selectProfile } from "modules/profile/store/profile.reducers";
import { doFetchProfile } from "modules/profile/store/profile.actions";
import { loadBusinessList } from "store/search/search.actions";

const useStyles = makeStyles({
  list: {
    width: 250
  }
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    "border-radius": 0
  },
  list: {
    padding: 0
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      fontSize: "16px",
    },
    "&:focus": {
      backgroundColor: "#424b5a",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#fff"
      }
    }
  }
}))(MenuItem);

function Topbar({ history }) {
  const classes = useStyles();
  const authData = store.getState().auth;
  const dispatch = useDispatch();
  const translations = useTranslations();
  const user = useSelector(selectProfile);
  const cartBD = useSelector(selectBDCart);
  const cartTotal = useSelector(selectCartTotal);
  const logoutResponse = useSelector(loginUserSelector);
  const [cartModal, setCartModal] = useState(false);
  const [showCart, setShowCart] = useState(true);
  const [anchorEl3, setAnchorEl3] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAuthDrawer, setOpenAuthDrawer] = useState(false);
  const [fullScreenSize, setFullScreenSize] = useState(false);
  const [topbarFixed] = useState(true);
  const authRest = store.getState().auth;
  const stampRest = authRest.stamp;
  const stampCll = localStorage.getItem("cllWebApp-stamp");
  const token = localStorage.getItem("cllWebApp-token");
  const tokenCll = token && token !== "undefined" ? JSON.parse(token) : "";

  const [showImpersonationBlock, setShowImpersonationBlock] = useState(null);

  useEffect(() => {
    processImpersonation();

    handleFullScreenSize();
    window.addEventListener('resize', handleFullScreenSize);
    if (stampCll) {
      if (stampRest && stampCll !== stampRest) {
        setAuthStoreData();
      } else {
        setAuthStoreData();
      }
    }

    if (isAuth()) {
      dispatch(doFetchProfile());
    }
  }, []);

  const processImpersonation = () => {
    const impersonationId = JSON.parse(localStorage.getItem('cllWebApp-agent'))?.replaceAll('"', '');
    setShowImpersonationBlock(impersonationId);
    const currentAddress = JSON.parse(localStorage.getItem('address'));
    if (currentAddress) {
      const owner = currentAddress.userRef || currentAddress.accountId
      if (owner && owner !== impersonationId) {
        localStorage.removeItem('address');
        localStorage.removeItem('checkout');
      }
    }
  };

  useEffect(() => {
    user?.accountId && dispatch(loadUserCart({ accountId: user.accountId }));
  }, [user]);

  useEffect(() => {
    if (['/legal', '/register', '/login', '/pin', '/email', '/recover', '/customer/satisfaction'].includes(history.location.pathname) || _.includes(history.location.pathname, '/prv/')) {
      setShowCart(false);
    } else if (cartBD?.accountId) {
      setShowCart(cartBD?.items?.length > 0);
    } else {
      setShowCart(false);
    }
  }, [history.location.pathname, cartBD]);

  // useEffect(() => {
  //   if (addresses?.length && !localAddress?.userRef) {
  //     setShowAddressForm(true);
  //   }
  //   // eslint-disable-next-line
  // }, [addresses]);

  useEffect(() => {
    if (logoutResponse.logout_success) {
      history.push("../");
      localStorage.removeItem("backTo");
      dispatch(setLoadingMaskStatus(false));
    }
    // NOTA: Este bloque hace que las paginas se redireccionen aun cuando puedes acceder a ellas sin estar logueado
    // No se debe atar un logout response con la redireccion de las paginas, eso es responsabilidad de cada pagina individual
    // if (logoutResponse.token_failure) {
    //   login();
    //   console.log('Logout responnse token failure');
    //   dispatch(setLoadingMaskStatus(false));
    //   dispatch(clearAuthState());
    // }
  }, [logoutResponse]);

  const handleFullScreenSize = () => setFullScreenSize(window.innerWidth >= 1200);
  const setAuthStoreData = () => store.dispatch(cllLoginSuccess({
    ...tokenCll,
    stamp: stampCll,
  }));
  const showCartModal = () => setCartModal(!cartModal);
  const goHome = () => {
    dispatch(showAllBusinessForProductType(false));
    history.push({ pathname: `/` });
  }
  const login = () => {
    history.push("../login");
    handleCloseMenu3();
  };
  const logout = () => {
    dispatch(setLoadingMaskStatus(true));
    dispatch(logoutUser());
    handleCloseProfile();
    handleCloseAuthDrawer();
  };
  const handleOpenDrawer = event => setOpenDrawer(event.currentTarget);
  const handleCloseDrawer = () => setOpenDrawer(false);
  const handleOpenAuthDrawer = event => setOpenAuthDrawer(event.currentTarget);
  const handleCloseAuthDrawer = () => setOpenAuthDrawer(false);
  const handleOpenProfile = event => setAnchorEl(event.currentTarget);
  const handleCloseProfile = () => setAnchorEl(false);
  const handleOpenMenu3 = event => setAnchorEl3(event.currentTarget);
  const handleCloseMenu3 = () => setAnchorEl3(false);

  return (
    <Fragment>
      <div className={`topbar-placeholder ${topbarFixed ? 'topbar-placeholder--fixed' : ''}`} style={{ height: showImpersonationBlock ? '112px' : '80px' }}>
        {showImpersonationBlock && <div className="impersonation-block">
          {translations.ConnectedTo}: {showImpersonationBlock}
        </div>}
        <div className={`topbar ${topbarFixed ? 'topbar--fixed' : ''}`}>
          <div className="topbar-container">
            <header className="topbar">
              {fullScreenSize ? <div className="container">
                {/*------------ LOGO ------------ */}
                <div onClick={goHome} className="topbar__logo-block">
                  <Picture classes="margin-auto"
                    src={fullScreenSize ? "rd.png" : "rd.png"}
                    width="auto"
                    height={fullScreenSize ? '50' : '44'} />
                </div>

                {/*---------- NAV LINKS --------- */}

                <nav className="topbar__navigation desktop--on">
                  <ul style={{ width: '100%' }}>
                    {fullScreenSize ? <Address history={history} authData={authData} /> : null}
                  </ul>
                </nav>

                <nav className="topbar__navigation">
                  <ul style={{ justifyContent: 'flex-end' }}>
                    {fullScreenSize ? <Search /> : null}

                    {/*------------ AUTH ------------ */}
                    {authData?.access_token ? (
                      <li className="topbar__flat-link">
                        <button onClick={handleOpenProfile} className="desktop--on">
                          <PdfIcon width={24} icon={`system/user_line_black`} ext="svg" />
                          <span>{translations.TopBar.btnProfile}</span>
                        </button>
                        {/* This button is visible only for mobiles */}
                        <button
                          onClick={handleOpenAuthDrawer}
                          className="desktop--disabled"
                        >
                          <PdfIcon width={24} icon={`system/user_line_black`} ext="svg" />
                        </button>
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseProfile}
                        >
                          <StyledMenuItem dense component="a" href="./prv/profile">
                            <ListItemText
                              primary={translations.TopBar.linkPersonalData}
                            />
                          </StyledMenuItem>
                          <StyledMenuItem
                            dense
                            component="a"
                            href="./prv/user_address"
                          >
                            <ListItemText
                              primary={translations.TopBar.linkPreferredAddresses}
                            />
                          </StyledMenuItem>
                          <StyledMenuItem
                            dense
                            component="a"
                            href="./prv/card_management"
                          >
                            <ListItemText
                              primary={translations.TopBar.linkCardManagement}
                            />
                          </StyledMenuItem>
                          <StyledMenuItem
                            dense
                            component="a"
                            href="./prv/purchase_history"
                          >
                            <ListItemText
                              primary={translations.TopBar.linkPurchaseHistory}
                            />
                          </StyledMenuItem>
                          <StyledMenuItem dense onClick={logout}>
                            <ListItemIcon>
                              <AtomIcon width="16" icon="exit--gunmetal" alt="plus" />
                            </ListItemIcon>
                            <ListItemText primary={translations.TopBar.btnLogout} />
                          </StyledMenuItem>
                        </StyledMenu>
                      </li>
                    ) : null}

                    {/*------------ CART ------------ */}
                    {authData?.access_token && showCart ?
                      <li className={`topbar__flat-link ${cartBD?.items?.length > 0 ? "cart-full" : null}`}>
                        {<CartDialog
                          open={cartModal}
                          handleClose={showCartModal}
                          translation={translations}
                        />}
                        {/* <Alert /> */}
                        <button onClick={() => showCartModal()} className="btn-rounded--primary">
                          <PdfIcon width="20" height="20" icon={`system/shop_cart--white${cartTotal > 0 ? "" : ""}`} ext="svg" />
                          <span>{translations.TopBar.cart}</span>
                          <span className="bullet__white">•</span>
                          <span className="cart-total">${parseFloat(cartBD?.subtotal).toFixed(2)}</span>
                        </button>
                      </li> : null}

                    {/*---------- MENU FOR WIDE SCREENS ---------- */}
                    <li className="topbar__flat-link">
                      <button onClick={handleOpenMenu3} className="desktop--on">
                        <PdfIcon width={24} icon={`system/menu_black`} ext="svg" />
                      </button>
                      <StyledMenu
                        id="menu-3"
                        anchorEl={anchorEl3}
                        keepMounted
                        open={Boolean(anchorEl3)}
                        onClose={handleCloseMenu3}
                      >
                        {authData?.access_token ? null : (
                          <StyledMenuItem component="a" onClick={login}>
                            <ListItemText primary={translations.TopBar.btnLogin} />
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem dense component="a" href="./">
                          <ListItemText primary={translations.TopBar.linkHome} />
                        </StyledMenuItem>
                        {/* <StyledMenuItem
                          dense
                          component="a"
                          href="https://www.cuballama.com/informacion/contacto"
                        >
                          <ListItemText primary={translations.TopBar.linkHelp} />
                        </StyledMenuItem> */}
                        <StyledMenuItem dense component="a" href="mailto:alacarta@tamtos.com">
                          <ListItemText primary={translations.Footer.joinTheTeam} />
                        </StyledMenuItem>
                        {/* <StyledMenuItem
                          dense
                          component="a"
                          href="https://www.cuballama.com"
                        >
                          <ListItemText primary={translations.TopBar.linkCll} />
                        </StyledMenuItem> */}
                        <LanguagePicker handleClose={handleCloseMenu3} />
                      </StyledMenu>
                    </li>
                  </ul>
                </nav>
              </div> :
                <div className="container nav-container">
                  <nav className="topbar__navigation">
                    <ul style={{ width: '100%' }}>
                      {/* <ul style={{ justifyContent: 'flex-start' }}> */}
                      <li className="topbar__flat-link">
                        <button onClick={handleOpenDrawer}>
                          <PdfIcon icon="system/menu" width="24" height="24" ext="svg" />
                        </button>
                      </li>
                      {authData && authData.access_token ? (
                        <li className="topbar__flat-link">
                          <div style={{ width: '31.37px' }} />
                        </li>) : null}
                      {/*------------ LOGO ------------ */}
                      <li className="topbar__flat-link logo-li">
                        <div onClick={goHome} className="topbar__logo-block">
                          <Picture classes="margin-auto" width="auto" height={fullScreenSize ? '50' : '44'}
                            src={fullScreenSize ? "rd.png" : "rd.png"} />
                        </div>
                      </li>
                    </ul>
                  </nav>

                  <nav className="topbar__navigation" style={{ flex: 0 }}>
                    <ul style={{ justifyContent: 'flex-end' }}>
                      {fullScreenSize ? <Search /> : null}

                      {/*------------ AUTH ------------ */}
                      {authData && authData.access_token ? (
                        <li className="topbar__flat-link">
                          <button onClick={handleOpenProfile} className="desktop--on">
                            <PdfIcon width={24} icon={`system/user_line_black`} ext="svg" />
                            <span>{translations.TopBar.btnProfile}</span>
                          </button>
                          {/* This button is visible only for mobiles */}
                          <button
                            onClick={handleOpenAuthDrawer}
                            className="desktop--disabled"
                          >
                            <PdfIcon width={24} icon={`system/user_line_black`} ext="svg" />
                          </button>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseProfile}
                          >
                            <StyledMenuItem dense component="a" href="./prv/profile">
                              <ListItemText
                                primary={translations.TopBar.linkPersonalData}
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              dense
                              component="a"
                              href="./prv/user_address"
                            >
                              <ListItemText
                                primary={translations.TopBar.linkPreferredAddresses}
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              dense
                              component="a"
                              href="./prv/card_management"
                            >
                              <ListItemText
                                primary={translations.TopBar.linkCardManagement}
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              dense
                              component="a"
                              href="./prv/purchase_history"
                            >
                              <ListItemText
                                primary={translations.TopBar.linkPurchaseHistory}
                              />
                            </StyledMenuItem>
                            <StyledMenuItem dense onClick={logout}>
                              <ListItemIcon>
                                <AtomIcon width="16" icon="exit--gunmetal" alt="plus" />
                              </ListItemIcon>
                              <ListItemText primary={translations.TopBar.btnLogout} />
                            </StyledMenuItem>
                          </StyledMenu>
                        </li>
                      ) : null}

                      {/*------------ CART ------------ */}
                      {showCart ?
                        <li className={`topbar__flat-link ${cartTotal > 0 ? "cart-full" : null}`}>
                          {<CartDialog open={cartModal} handleClose={showCartModal} translation={translations} />}
                          {/* {cartTotal > 0 ? (
                              checkoutPath ?
                                <CheckoutCartDialog
                                  open={cartModal}
                                  handleClose={showCartModal}
                                  parent={"TOPBAR"}
                                /> :
                                <CartDialog
                                  open={cartModal}
                                  handleClose={showCartModal}
                                  translation={translations}
                                />
                            ) : null} */}
                          <Alert />
                          {/* 
                              <button onClick={() => showCartModal()} className="btn-rounded-black">
                                <PdfIcon width="20" height="20" icon={`system/shop_cart--white${cartTotal > 0 ? "" : ""}`} ext="svg" />
                                <span className="bullet__white">•{translations.TopBar.cartMobile}</span>
                                {cartTotal > 0 ? (
                                  <span className="cart-total">
                                    ${parseFloat(cartTotal).toFixed(2)}
                                  </span>
                                ) : null}
                              </button>
                            */}
                        </li> : null}
                    </ul>
                  </nav>
                </div>}
            </header>
          </div>
        </div>
      </div>
      <Drawer anchor={fullScreenSize ? 'right' : 'left'} open={openDrawer} onClose={handleCloseDrawer}>
        <div className={classes.list} role="presentation" onClick={handleCloseDrawer} onKeyDown={handleCloseDrawer}>
          <div className="drawable-logo">
            <Picture src="rd.png" width="auto" height="50" />
            <div className="close-btn" onClick={handleCloseDrawer}>
              <PdfIcon width={24} icon={`system/cancel`} ext="svg" />
            </div>
          </div>
          {authData && authData.access_token ? null : (
            <div
              className="topbar__btn-link topbar__btn-link--active"
              onClick={login}
            >
              <span>{translations.TopBar.btnLogin}</span>
            </div>
          )}
          <List style={{ padding: "1em 0" }} component="div">
            <ListItem button component="a" href="./">
              <ListItemText primary={translations.TopBar.linkHome} />
            </ListItem>
            {/* <ListItem button component="a" href="https://www.cuballama.com/informacion/contacto">
              <ListItemText primary={translations.TopBar.linkHelp} />
            </ListItem>
            <ListItem button component="a" href="https://www.cuballama.com">
              <ListItemText primary={translations.TopBar.linkCll} />
            </ListItem> */}

            <ListItem button component="a" href="mailto:alacarta@tamtos.com">
              <ListItemText
                primary={translations.Footer.joinTheTeam}
              />
            </ListItem>

            <LanguagePicker handleClose={handleCloseDrawer} />

          </List>
        </div>
      </Drawer>
      <Drawer anchor="right" open={openAuthDrawer} onClose={handleCloseAuthDrawer}>
        <div
          className={classes.list}
          role="presentation"
          onClick={handleCloseAuthDrawer}
          onKeyDown={handleCloseAuthDrawer}
        >
          <div className="drawable-logo">
            <Picture src="rd.png" width="auto" height="50" />
            <div className="close-btn" onClick={handleCloseAuthDrawer}>
              <PdfIcon width={24} icon={`system/cancel`} ext="svg" />
            </div>
          </div>
          <List style={{ padding: "1em 0" }} component={"div"}>
            <ListItem button component="a" href="./prv/profile">
              <ListItemText
                primary={translations.TopBar.linkPersonalData}
              />
            </ListItem>
            <ListItem button component="a" href="./prv/user_address">
              <ListItemText
                primary={translations.TopBar.linkPreferredAddresses}
              />
            </ListItem>
            <ListItem button component="a" href="./prv/card_management">
              <ListItemText
                primary={translations.TopBar.linkCardManagement}
              />
            </ListItem>
            <ListItem button component="a" href="./prv/purchase_history">
              <ListItemText
                primary={translations.TopBar.linkPurchaseHistory}
              />
            </ListItem>
            <ListItem button component="a" href="mailto:alacarta@tamtos.com">
              <ListItemText
                primary={translations.Footer.joinTheTeam}
              />
            </ListItem>
            <ListItem button onClick={logout} component="div">
              <ListItemIcon>
                <AtomIcon width="16" icon="exit--gunmetal" alt="plus" />
              </ListItemIcon>
              <ListItemText primary={translations.TopBar.btnLogout} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </Fragment>
  );
}

export default Topbar;