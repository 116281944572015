const LOCAL = {
  client_id: "delivery_web",
  server: "http://localhost:9090/restaurantes",
  cuballama: "https://bill.cuballama.net",
  captcha_site_key: "6LdJ8BcaAAAAAJzJF8qZG1pVP6bunuSVCJgRTAli",
  segment_write_key: "36budJFqX2zAqKpgBnAFG8gKkikCeSQs",
  asid: 'AC1acfb8ae3039ba0ada326e0cb8d9c6fe',
  fsid: 'FO1cfd62b9fa22678b26ffcf4d628fe7c4',
};
const DEV = {
  client_id: "delivery_web",
  server: "https://api.dev1.morfeo.cubatelefono.com/restaurantes",
  cuballama: "https://bill.cuballama.net",
  captcha_site_key: "6LdJ8BcaAAAAAJzJF8qZG1pVP6bunuSVCJgRTAli",
  segment_write_key: "36budJFqX2zAqKpgBnAFG8gKkikCeSQs",
  asid: 'AC1acfb8ae3039ba0ada326e0cb8d9c6fe',
  fsid: 'FO1cfd62b9fa22678b26ffcf4d628fe7c4',
};
const TEST = {
  client_id: "delivery_web",
  server: "https://api.test1.morfeo.cubatelefono.com/restaurantes",
  cuballama: "https://bill.cuballama.net",
  captcha_site_key: "6LdJ8BcaAAAAAJzJF8qZG1pVP6bunuSVCJgRTAli",
  segment_write_key: "36budJFqX2zAqKpgBnAFG8gKkikCeSQs",
  asid: 'AC1acfb8ae3039ba0ada326e0cb8d9c6fe',
  fsid: 'FO1cfd62b9fa22678b26ffcf4d628fe7c4',
};
const PREPROD = {
  client_id: "delivery_web",
  server: "https://api.cuballama.com/restaurantes",
  cuballama: "https://bill.cuballama.net",
  captcha_site_key: "6LfDsRoaAAAAACBXlsdPRVZAaXd0kd6g8RJyzOzg",
  segment_write_key: "MsW0Cxvx3Dg4puuFQA8VDeUIspNO9yLE",
  asid: 'AC64146a2f5fbc929d71665bcdfae6f18b',
  fsid: 'FO78aba8351d4efec885405bbe8ebb48cf',
};
const PROD = {
  client_id: "tamtos_web",
  server: "https://api.tamtos.com/restaurantes",
  cuballama: "https://bill.cuballama.net",
  captcha_site_key: "6LfDsRoaAAAAACBXlsdPRVZAaXd0kd6g8RJyzOzg",
  segment_write_key: "MsW0Cxvx3Dg4puuFQA8VDeUIspNO9yLE",
  asid: 'AC64146a2f5fbc929d71665bcdfae6f18b',
  fsid: 'FO78aba8351d4efec885405bbe8ebb48cf',
};

export const DEFAULT_CONFIG =
  process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "local"
    ? LOCAL
    : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"
    ? DEV
    : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "test"
    ? TEST
    : process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "preprod"
    ? PREPROD
    : PROD;
