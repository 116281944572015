import {get} from "../../../../../core/services";
import {notifyError} from "../../../../notification/store/notification.actions";
import {setLoadingMaskStatus} from "../../../../../component/Mask/store/mask.actions";

const baseUrl = "";

export const ALL_OFFERS_VIEW = "ALL_OFFERS_VIEW";
export const RESTAURANT_OFFER_LOADED_SUCCESS = "RESTAURANT_OFFER_LOADED_SUCCESS";
export const RESTAURANT_OFFER_LOADED_FAILURE = "RESTAURANT_OFFER_LOADED_FAILURE";

export function setOffersView(view_all) {
    return {
        type: ALL_OFFERS_VIEW,
        view_all
    };
}

function restaurantOfferLoadedSuccess(offers) {
    return {
        type: RESTAURANT_OFFER_LOADED_SUCCESS,
        offers
    }
}

function restaurantOfferLoadedFailure(offers) {
    return {
        type: RESTAURANT_OFFER_LOADED_FAILURE,
        offers
    }
}

export function loadRestaurantOfferPromotion(PREVIEW) {
    return dispatch => {
        get(`${baseUrl}getOfferPromotions${PREVIEW ? '?preview=' + PREVIEW : ''}`)
            .then(most_popular => {
                dispatch(restaurantOfferLoadedSuccess(most_popular))
            })
            .catch((response) => {
                const errMsg = response && response.data ? response.data.message : "ERROR!";
                dispatch(restaurantOfferLoadedFailure(errMsg));
                dispatch(notifyError(errMsg));
            })
            .finally(() => {
                dispatch(setLoadingMaskStatus(false));
            });
    }
}
