import { createSelector } from "reselect";
import {
  FETCHED_TUBOLSILLO_CREDIT,
  DONE_TUBOLSILLO_PAYMENT,
  SET_ERROR_TUBOLSILLO_PAYMENT,
} from "./tubolsillo.actions";
import { CLEAR_PAYMENTS } from "../payment.actions";

const INITIAL_STATE = {
  tubolsilloCredit: 0,
  paymentResult: null,
  paymentError: null,
};

export default function payment(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case FETCHED_TUBOLSILLO_CREDIT: {
      return {
        ...state,
        tubolsilloCredit: action.data,
      };
    }
    case DONE_TUBOLSILLO_PAYMENT: {
      return {
        ...state,
        paymentResult: action.data,
      };
    }
    case SET_ERROR_TUBOLSILLO_PAYMENT: {
      return {
        ...state,
        paymentError: action.data.message,
      };
    }
    case CLEAR_PAYMENTS: {
      return {
        ...state,
        paymentResult: INITIAL_STATE.paymentResult,
        paymentError: INITIAL_STATE.paymentError,
      };
    }
    default: {
      return state;
    }
  }
}

const selectPaymentState = (state) => state.payment.tubolsillo;

export const selectTuBolsilloCredit = createSelector(
  selectPaymentState,
  (state) => state.tubolsilloCredit
);

export const selectTuBolsilloResult = createSelector(
  selectPaymentState,
  (state) => state.paymentResult
);

export const selectTuBolsilloError = createSelector(
  selectPaymentState,
  (state) => state.paymentError
);
