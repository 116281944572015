import React, {useEffect} from 'react';
import './css/RecoverPasswordStyle.scss';
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import {SendValidationPin} from "./components/SendValidationPin/SendValidationPin";
import {SetPassword} from "./components/SetPassword/SetPassword";
import {selectSendPinSuccess} from "./store/recover-password.reducers";

export const RecoverPassword = () => {
  const sendPinSuccess = useSelector(selectSendPinSuccess);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [sendPinSuccess]);


  return (
    <div className="recover-password">
      <Grid container spacing={3} className="container" component="div">
        {sendPinSuccess ? <SetPassword/> : <SendValidationPin/>}
      </Grid>
    </div>
  )
};