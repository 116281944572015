import { createSelector } from "reselect";
import _ from "lodash";
import * as action_address from "./form-add-address.actions";

const INITIAL_STATE = {
  addresses: null,
  new_delivery_address: null,
  delivery_address_to_edit: null,
  updated_delivery_address: null,
  load_delivery_addresses: false,
  done_add_delivery_address: false,
  done_update_delivery_address: false,
  top_bar_address_selected: null,
};

export default function delivery_address(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case action_address.LOAD_DELIVERY_ADDRESSES: {
      return {
        ...state,
        load_delivery_addresses: true,
        addresses: action.addresses?.data || [],
      };
    }
    case action_address.FAIL_LOAD_DELIVERY_ADDRESSES: {
      return {
        ...state,
        load_delivery_addresses: false,
        addresses: [],
      };
    }
    case action_address.ADD_DELIVERY_ADDRESS: {
      return {
        ...state,
        addresses: [...state.addresses, action.address.data],
        done_add_delivery_address: true,
        load_delivery_addresses: false,
        updated_delivery_address: null,
        // delivery_address_to_edit: null,
        new_delivery_address: action.address.data,
      };
    }
    case action_address.FAIL_ADD_DELIVERY_ADDRESS: {
      return {
        ...state,
        done_add_delivery_address: false,
        load_delivery_addresses: false,
        updated_delivery_address: null,
        // delivery_address_to_edit: null,
        new_delivery_address: null,
      };
    }
    case action_address.SET_DELIVERY_ADDRESS_TO_EDIT: {
      return {
        ...state,
        delivery_address_to_edit: action.address,
      };
    }
    case action_address.UPDATE_DELIVERY_ADDRESS: {
      let { addresses, delivery_address_to_edit } = state;
      let updated = action.addresses.data;
      _.remove(addresses, function (address) {
        return address.id === delivery_address_to_edit.id;
      });
      addresses.push(updated);
      addresses = _.orderBy(addresses, ["id"]);
      return {
        ...state,
        addresses,
        updated_delivery_address: updated,
        // delivery_address_to_edit: null,
        load_delivery_addresses: false,
        done_update_delivery_address: true,
      };
    }
    case action_address.FAIL_UPDATE_DELIVERY_ADDRESS: {
      let { addresses, delivery_address_to_edit } = state;
      return {
        ...state,
        addresses,
        updated_delivery_address: delivery_address_to_edit,
        // delivery_address_to_edit: null,
        load_delivery_addresses: false,
        done_update_delivery_address: false,
      };
    }
    case action_address.DELETE_DELIVERY_ADDRESS: {
      let addresses = state.addresses.filter(
        (address) => address.id !== action.address
      );
      return {
        ...state,
        load_delivery_addresses: false,
        addresses,
      };
    }
    case action_address.FAIL_DELETE_DELIVERY_ADDRESS: {
      return {
        ...state,
        load_delivery_addresses: false,
      };
    }
    case action_address.CLEAR_DELIVERY_ADDRESS_STATE: {
      return {
        ...state,
        done_add_delivery_address: false,
        done_update_delivery_address: false,
        load_delivery_addresses: false,
        updated_delivery_address: null,
        // delivery_address_to_edit: null,
        top_bar_address_selected: null,
        new_delivery_address: null,
      };
    }
    case action_address.SET_DELIVERY_ADDRESS_ON_TOP_BAR_SELECTED: {
      return {
        ...state,
        top_bar_address_selected: action.response,
      };
    }
    default: {
      return {
        ...state,
        load_delivery_addresses: false,
        updated_delivery_address: null,
        new_delivery_address: null,
      };
    }
  }
}

export const selectorDeliveryAddressState = (state) => state.delivery_address;

export const deliveryAddresses = createSelector(
  selectorDeliveryAddressState,
  (state) => state.addresses
);

export const selectTopBarAddress = createSelector(
  selectorDeliveryAddressState,
  (state) => state.top_bar_address_selected
);

export const deliveryAddressesState = createSelector(
  selectorDeliveryAddressState,
  (state) => state
);
