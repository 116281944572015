import {
  secureGet,
  securePost,
  simpleGet,
  simplePost,
} from "../../../core/services";
import { showAlert } from "../../alert/store/alert.actions";
import { setLoadingMaskStatus } from "../../../component/Mask/store/mask.actions";

export const CLEAN_CART = "CLEAN_CART";
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const SET_CART_IN_BD_SUCCESS = "SET_CART_IN_BD_SUCCESS";
export const SET_CART_IN_BD_FAILURE = "SET_CART_IN_BD_FAILURE";
export const CREATE_TEMP_OFFER = "CREATE_TEMP_OFFER";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_RESET_AND_ADD_ITEM = "CART_RESET_AND_ADD_ITEM";
export const CART_UPDATE_ITEM_STOCK = "CART_UPDATE_ITEM_STOCK";
export const CART_UPDATE_RESTAURANT = "CART_UPDATE_RESTAURANT";
export const SET_SERVICE_FEE = "SET_SERVICE_FEE";
export const SET_DELIVERY_COST = "SET_DELIVERY_COST";
export const COUPON_LOADED_SUCCESS = "COUPON_LOADED_SUCCESS";
export const COUPON_LOADED_FAILURE = "COUPON_LOADED_FAILURE";
export const BONUS_LOADED_SUCCESS = "BONUS_LOADED_SUCCESS";
export const BONUS_LOADED_FAILURE = "BONUS_LOADED_FAILURE";
export const CLEAN_EXTRA_PAYMENTS = "CLEAN_EXTRA_PAYMENTS";
export const CLEAN_DISCOUNT_AMOUNT = "CLEAN_DISCOUNT_AMOUNT";
export const CLEAN_DISCOUNT_BONUS = "CLEAN_DISCOUNT_BONUS";

export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS";
export const ADD_OFFER_FAILURE = "ADD_OFFER_FAILURE";
export const CART_LOADED_SUCCESS = "CART_LOADED_SUCCESS";
export const CART_LOADED_FAILURE = "CART_LOADED_FAILURE";
export const SET_BUSINESS_IN_CART = "SET_BUSINESS_IN_CART";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILURE = "UPDATE_OFFER_FAILURE";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILURE = "DELETE_OFFER_FAILURE";

export const AVAILABILITY_ERROR = "AVAILABILITY_ERROR";

const baseUrl = "cart";

function sendAvailabilityError(response) {
  return {
    type: AVAILABILITY_ERROR,
    response,
  };
}

function cartLoadedSuccess(response) {
  return {
    type: CART_LOADED_SUCCESS,
    response,
  };
}

function cartLoadedFailure(response) {
  return {
    type: CART_LOADED_FAILURE,
    response,
  };
}

export function loadUserCart(USER) {
  return (dispatch) => {
    simplePost(`${baseUrl}/getCart`, USER)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(cartLoadedSuccess(response));
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        const errMsg = response?.data ? response?.data?.message : response;
        dispatch(cartLoadedFailure(errMsg));
      });
  };
}

function addOfferSuccess(response) {
  return {
    type: ADD_OFFER_SUCCESS,
    response,
  };
}

function addOfferFailure(response) {
  return {
    type: ADD_OFFER_FAILURE,
    response,
  };
}

export function addOfferToCart(OFFER, BUSINESS = null) {
  return (dispatch) => {
    simplePost(`${baseUrl}/addItems`, OFFER)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(addOfferSuccess(response));
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        const errMsg = response?.data ? response?.data?.message : response;
        dispatch(addOfferFailure(errMsg));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

function updateOfferSuccess(response) {
  return {
    type: UPDATE_OFFER_SUCCESS,
    response,
  };
}

function updateOfferFailure(response) {
  return {
    type: UPDATE_OFFER_FAILURE,
    response,
  };
}

export function updateOfferToCart(DATA) {
  return (dispatch) => {
    simplePost(`${baseUrl}/updateItems`, DATA)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(updateOfferSuccess(response));
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        const errMsg = response?.data ? response?.data?.message : response;
        dispatch(updateOfferFailure(errMsg));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

function deleteOfferSuccess(response) {
  return {
    type: DELETE_OFFER_SUCCESS,
    response,
  };
}

function deleteOfferFailure(response) {
  return {
    type: DELETE_OFFER_FAILURE,
    response,
  };
}

export function deleteOfferToCart(DATA) {
  return (dispatch) => {
    simplePost(`${baseUrl}/deleteItems`, DATA)
      .then((response) => {
        if (response?.data?.code === 0) {
          dispatch(deleteOfferSuccess(response));
        } else {
          dispatch(sendAvailabilityError(response));
        }
      })
      .catch((response) => {
        const errMsg = response?.data ? response?.data?.message : response;
        dispatch(deleteOfferFailure(errMsg));
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}

/*************************************************************************************************/

function couponLoadedSuccess(discount) {
  return {
    type: COUPON_LOADED_SUCCESS,
    discount,
  };
}

function couponLoadedFailure(discount) {
  return {
    type: COUPON_LOADED_FAILURE,
    discount,
  };
}

function bonusLoadedSuccess(bonus) {
  return {
    type: BONUS_LOADED_SUCCESS,
    bonus,
  };
}

function bonusLoadedFailure(bonus) {
  return {
    type: BONUS_LOADED_FAILURE,
    bonus,
  };
}

export function cleanCart() {
  return {
    type: CLEAN_CART,
  };
}

export function updateOfferStock(payload) {
  return {
    type: CART_UPDATE_ITEM_STOCK,
    payload,
  };
}

export function updateRestaurantInCart(payload) {
  return {
    type: CART_UPDATE_RESTAURANT,
    payload,
  };
}

export function addItemToCart(payload, businessCategory) {
  return (dispatch, getState) => {
    const offersInCart = getState().cart.items;
    const restaurantInCart = getState().cart.restaurant;
    const categoryInCart = getState().cart.businessCategory;
    const restId =
      payload.restaurant && payload.restaurant.id ? payload.restaurant.id : "";
    const isNewRestaurant =
      (!restaurantInCart && offersInCart.length > 0) ||
      (restaurantInCart && restaurantInCart.id !== restId);
    const isNewBusinessCategory =
      categoryInCart &&
      businessCategory &&
      categoryInCart.id !== businessCategory.id;

    isNewBusinessCategory
      ? dispatch(
          showAlert(
            `Ha seleccionado una oferta de una categoría diferente a la que ya ha seleccionado. Si continúa se eliminarán las selecciones previas. ¿Seguro que desea continuar?`,
            "",
            "",
            "",
            () =>
              dispatch({
                type: CART_RESET_AND_ADD_ITEM,
                payload,
                businessCategory,
              })
          )
        )
      : isNewRestaurant
      ? dispatch(
          showAlert(
            `Ha seleccionado una oferta de un restaurante diferente al de las ofertas que ya ha seleccionado. Si continúa se eliminarán las selecciones previas. ¿Seguro que desea continuar?`,
            "",
            "",
            "",
            () =>
              dispatch({
                type: CART_RESET_AND_ADD_ITEM,
                payload,
                businessCategory,
              })
          )
        )
      : dispatch({ type: CART_ADD_ITEM, payload, businessCategory });
  };
}

export function setTempOffer(payload) {
  return {
    type: CREATE_TEMP_OFFER,
    payload,
  };
}

export function removeItemToCart(payload) {
  return {
    type: CART_REMOVE_ITEM,
    payload,
  };
}

export function cleanExtraPayments() {
  return {
    type: CLEAN_EXTRA_PAYMENTS,
  };
}

export function setDeliveryCostValue(shipping) {
  return {
    type: SET_DELIVERY_COST,
    shipping,
  };
}

export function serviceFee(fee) {
  return {
    type: SET_SERVICE_FEE,
    fee,
  };
}

export function cleanDiscountAmount() {
  return {
    type: CLEAN_DISCOUNT_AMOUNT,
  };
}

export function cleanDiscountBonus() {
  return {
    type: CLEAN_DISCOUNT_BONUS,
  };
}

function createUpdateCartInBDSuccess(response) {
  return {
    type: SET_CART_IN_BD_SUCCESS,
    response,
  };
}

function createUpdateCartInBDFailure(response) {
  return {
    type: SET_CART_IN_BD_FAILURE,
    response,
  };
}

export function createUpdateCartInBD(DATA) {
  return (dispatch) => {
    securePost(`resources/${baseUrl}/addItems`, DATA)
      .then((response) => {
        dispatch(createUpdateCartInBDSuccess(response));
      })
      .catch((response) => {
        const errMsg =
          response && response.data ? response.data.message : "ERROR!";
        dispatch(createUpdateCartInBDFailure(errMsg));
      });
  };
}

export function getServiceFee(AMOUNT) {
  return (dispatch) =>
    secureGet(`${baseUrl}/getServiceFee?amount=${AMOUNT}`).then((fee) =>
      dispatch(serviceFee(fee))
    );
}

export function loadCoupon(COUPON) {
  return (dispatch) => {
    simpleGet(`${baseUrl}/getCoupon?code=${COUPON}`)
      .then((response) => dispatch(couponLoadedSuccess(response)))
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(couponLoadedFailure(errMsg));
        }
      });
  };
}

export function loadBonusAvailable(CART) {
  return (dispatch) => {
    dispatch(setLoadingMaskStatus(true));
    simplePost(`${baseUrl}/applyTalon`, CART)
      .then((response) => {
        dispatch(bonusLoadedSuccess(response));
      })
      .catch((error) => {
        let errObj =
          error.response && error.response.data ? error.response.data : null;
        if (errObj) {
          const errMsg = errObj && errObj.message ? errObj.message : "ERROR!";
          dispatch(bonusLoadedFailure(errMsg));
        }
      })
      .finally(() => {
        dispatch(setLoadingMaskStatus(false));
      });
  };
}
