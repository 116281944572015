
import { useDispatch } from "react-redux";
import { validateCheckoutInfo } from "modules/Checkout/store/checkout.actions";

const useCheckout = () => {
  const dispatch = useDispatch();

  const buildAddress = (order) => {
    const { address } = order
    return {
      mobile: null,
      landLine: null,
      provinceName: null,
      addressSearch: null,
      municipalityName: null,
      name: address?.name,
      details: address?.details,
      phone: address?.telephoneById?.number,
      additionalInfo: address?.additionalInfo,
      accountId: address?.userRef,
      provinceId: address?.provinceid || address?.provinceId,
      municipalityId: address?.municipalityid || address?.municipalityId,
      localityId: address?.localityid || address?.localityId,
      restaurantId: order?.restaurantid || order?.restaurantId,
    };
  };

  const dispatchValidateOrder = (order) => {
    dispatch(validateCheckoutInfo({
      address: buildAddress(order),
      coupon: order?.coupon,
      accountId: order?.accountId,
      restaurantId: order?.restaurantId,
      deliveryHour: order?.deliveryHour?.hour,
      deliveryOrderType: order?.deliveryOrderType,
      deliveryDay: order?.deliveryDay?.date.split('-').reverse().join('-'),
    }));
  }

  return {
    buildAddress,
    dispatchValidateOrder,
  };
};

export default useCheckout;