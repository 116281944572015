/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./css/CheckoutStyles.scss";
import * as _ from "lodash";
// import es_ES from "date-fns/locale/es";
// import en_US from "date-fns/locale/en-US";
import Skeleton from "@material-ui/lab/Skeleton";
// import { registerLocale } from "react-datepicker";
import useTranslations from "translations";
import { ArrowBack } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import Voucher from "./components/Voucher";
import DeliveryModeSwitch from "modules/BusinessDetail/components/DeliveryModeSwitch/DeliveryModeSwitch";
import { deliveryAddresses, selectTopBarAddress } from "component/FormAddAddress/store/form-add-address.reducers";
import { validateFieldByArray } from "utils/AuxiliarFunctions";
import { selectBDCart, selectCartRestaurant } from "modules/cart/store/cart.reducers";
import { selectProfile } from "modules/profile/store/profile.reducers";
import { payOrder } from "modules/Pay/store/pay.actions";
import { setLoadingMaskStatus } from "component/Mask/store/mask.actions";
import { loadDeliveryDateList, loadDeliveryTimeList, loadServerCurrentDate, storeBrowserOrder, setBDOrder, clearCheckoutState } from "./store/checkout.actions";
import { setDeliveryAddressOnTopBarSelected } from "component/FormAddAddress/store/form-add-address.actions";
import { selectBrowserOrder, selectDeliveryDate, selectDeliveryDateList, selectDeliveryTimeOptions, selectOrderFromBD } from "./store/checkout.reducers";
import useRestaurant from "hooks/useRestaurant";
import AddressesModal from "component/AddressesModal/AddressesModal";
import AddAddressModal from "component/AddAddressModal/AddAddressModal";
import Alert from "modules/alert/Alert";
import CheckoutErrorAlert from "./components/CheckoutErrorAlert";
import useCheckout from "../../hooks/useCheckout";
import { hideAlert, showAlert } from "modules/alert/store/alert.actions";
import { DELIVERY } from "constants/delivery";
import AcceptTerms from "./components/AcceptTerms";
import DiscountCoupon from "./components/DiscountCoupon";
import CheckoutDateTime from "./components/CheckoutDateTime";
import SelectAddress from "./components/SelectAddress";

// registerLocale("en-US", en_US);
// registerLocale("es-ES", es_ES);

const monthNames = {
    es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const CheckoutOrder = (props) => {
    const dispatch = useDispatch();
    const translation = useTranslations();
    const user = useSelector(selectProfile);
    const cartBD = useSelector(selectBDCart);
    const bdOrder = useSelector(selectOrderFromBD);
    const addresses = useSelector(deliveryAddresses);
    const restaurant = useSelector(selectCartRestaurant);
    const browserOrder = useSelector(selectBrowserOrder);
    const dateFromServer = useSelector(selectDeliveryDate);
    const topBarAddressSelected = useSelector(selectTopBarAddress);
    const restaurantAvailableDates = useSelector(selectDeliveryDateList);
    const restaurantAvailableTimes = useSelector(selectDeliveryTimeOptions);
    const [validSteps, setValidSteps] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [isErrorAlert, setIsErrorAlert] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [showLocation, setShowLocation] = useState(null);
    const [errorsSteps, setErrorsSteps] = useState({});
    const [backendErrors, setBackendErrors] = useState(null);
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [havanaActualDate, setHavanaActualDate] = useState('');
    const [errorAlertMessage, setErrorAlertMessage] = useState('');
    const [showAddAddressForm, setShowAddAddressForm] = useState(false);
    const [firstCheckout, setFirstCheckout] = useState(false);
    const [addressToEdit, setAddressToEdit] = useState(null);
    const { fetchDetails } = useRestaurant();
    const [restaurantDetails, setRestaurantDetails] = useState(undefined);
    const localStorageAddress = JSON.parse(localStorage.getItem('address'));
    const { buildAddress, dispatchValidateOrder } = useCheckout();
    const topBarSearchOptionSelected = JSON.parse(localStorage.getItem('address'));


    useEffect(() => {
        dispatch(loadServerCurrentDate());
        dispatch(loadDeliveryDateList(cartBD?.restaurantId));

        dispatch(clearCheckoutState());
        dispatch(storeBrowserOrder({
            coupon: null,
            shipping: null,
            restaurantId: cartBD?.restaurantId,
            address: localStorageAddress,
            deliveryOrderType: !browserOrder?.deliveryOrderType ? DELIVERY : browserOrder?.deliveryOrderType
        }));

        loadDeliveryDetails();
    }, []);

    useEffect(() => {
        const userref = browserOrder?.address?.userRef || browserOrder?.address?.accountId;
        if (userref && browserOrder?.restaurantId && !firstCheckout) {
            backendValidation();
            setFirstCheckout(true);
        }
    }, restaurantDetails);

    // Validates order when changed
    useEffect(() => {
        if (!browserOrder?.deliveryOrderType) {
            dispatch(storeBrowserOrder({
                ...browserOrder,
                coupon: null,
                restaurantId: cartBD?.restaurantId,
                deliveryOrderType: "DELIVERY",
            }));
        }
        validateStepForm();
    }, [browserOrder]);

    // Validates order when changed
    useEffect(() => {
        if (browserOrder?.deliveryOrderType && cartBD?.items
            && browserOrder?.deliveryDay && browserOrder?.deliveryHour
            && browserOrder?.address?.userRef) {
            backendValidation();
        }
        validateStepForm();
    }, [browserOrder?.address, browserOrder?.receiver, browserOrder?.deliveryDay]);

    // Updates ORDER when ADDRESS is changed
    useEffect(() => {
        if (topBarAddressSelected && !browserOrder?.address) {
            dispatch(storeBrowserOrder({
                ...browserOrder,
                address: topBarAddressSelected,
                receiver: topBarAddressSelected?.contactName,
                receiverPhone: topBarAddressSelected?.telephoneById?.number
            }));
        }
    }, [topBarAddressSelected]);

    useEffect(() => { addresses?.length && !browserOrder?.address?.userRef && openLocation(); }, [browserOrder?.address]);
    useEffect(() => { user?.accountId && dispatch(storeBrowserOrder({ ...browserOrder, accountId: user.accountId })) }, [user]);
    useEffect(() => { preLoadAddressFromTopBarFilter() }, [addresses, browserOrder?.address]);
    useEffect(() => { dateFromServer && setHavanaActualDate(dateFromServer) }, [dateFromServer]);
    useEffect(() => { setEnableButton(validSteps && acceptTerms) }, [validSteps, acceptTerms]);

    // Updates ORDER when DAY or Time are changed
    useEffect(() => {
        restaurantAvailableDates?.length > 0 && dispatch(storeBrowserOrder({
            ...browserOrder,
            deliveryDay: browserOrder?.deliveryDay || restaurantAvailableDates[0],
            deliveryHour: browserOrder?.deliveryHour || null
        }))
    }, [restaurantAvailableDates]);

    // Updates ORDER when DAY or Time are changed
    useEffect(() => {
        restaurantAvailableTimes?.length > 0 && dispatch(storeBrowserOrder({
            ...browserOrder,
            deliveryHour: browserOrder?.deliveryHour || restaurantAvailableTimes[0]
        }))
    }, [restaurantAvailableTimes]);

    // Load DELIVERY DATE when DAY is changed
    useEffect(() => {
        if (browserOrder?.deliveryDay?.date) {
            const [d, m,] = browserOrder?.deliveryDay?.date.split('-');
            setSelectedDay(parseInt(d, 10));
            setSelectedMonth(monthNames[translation.Code.toLowerCase()][parseInt(m, 10) - 1]);
            dispatch(loadDeliveryTimeList(browserOrder?.deliveryDay?.date.split('-').reverse().join('-'), cartBD?.restaurantId));
        }
    }, [browserOrder?.deliveryDay]);

    // Load BD order (fetched from backend) into order
    useEffect(() => {
        if (bdOrder?.result && bdOrder?.code === 0) {
            setIsErrorAlert(null);
            setShowLocation(false);
            setBackendErrors(null);
            setErrorAlertMessage(null);
            dispatch(storeBrowserOrder({
                ...browserOrder,
                ...bdOrder?.result,
            }));
            props.history.push({ state: null });
        }

        dispatch(setLoadingMaskStatus(false));
    }, [bdOrder]);

    const backendValidation = () => {
        if (!backendErrors && !props?.location?.state?.backendErrors) {
            dispatch(setLoadingMaskStatus(true));
            if (!browserOrder?.address.accountId) { }
            dispatchValidateOrder({ ...browserOrder, restaurantId: cartBD.restaurantId });
        }
    }

    const loadDeliveryDetails = async () => {
        const address = JSON.parse(localStorage.getItem('address'));
        if (address && cartBD?.restaurantId) {
            const details = await fetchDetails(address, cartBD?.restaurantId);
            setRestaurantDetails(details)
        }
    }

    const handleChangeDeliveryOrderType = (type) => {
        setShowLocation(!browserOrder?.address);
        setErrorsSteps({});
        setBackendErrors(null);
        props.history.push({ state: null });
        dispatch(storeBrowserOrder({ ...browserOrder, deliveryOrderType: type }));
    }

    const handleSelectDeliveryAddress = selected => {
        dispatch(storeBrowserOrder({
            ...browserOrder,
            address: selected,
            receiver: selected?.contactName,
            receiverPhone: selected?.telephoneById?.number
        }));
        localStorage.setItem('address', JSON.stringify(selected));
        setErrorsSteps({});
        setShowLocation(false);
        setBackendErrors(null);
        props.history.push({ state: null });
        validateStepForm();
    };

    const handleSelectDeliveryDate = dateSelected => {
        setBackendErrors(null);
        props.history.push({ state: null });
        dispatch(storeBrowserOrder({ ...browserOrder, deliveryDay: dateSelected, deliveryHour: '' }));
    };

    const handleSelectDeliveryTimeline = (selected) => {
        setErrorsSteps({});
        setBackendErrors(null);
        props.history.push({ state: null });
        dispatch(storeBrowserOrder({ ...browserOrder, deliveryHour: selected }));
    };

    const preLoadAddressFromTopBarFilter = () => {
        if (topBarSearchOptionSelected?.id && addresses?.length > 0) {
            let address = browserOrder?.address;
            if (!address) {
                address = topBarSearchOptionSelected;
            }
            if (address?.id !== topBarSearchOptionSelected?.id) {
                dispatch(setDeliveryAddressOnTopBarSelected(address));
                dispatch(storeBrowserOrder({
                    ...browserOrder,
                    address,
                    receiver: address?.contactName,
                    receiverPhone: address?.telephoneById?.number
                }));
            } else {
                const existAddress = addresses.find(({ id }) => topBarSearchOptionSelected?.id === id);
                if (existAddress) {
                    handleSelectDeliveryAddress(existAddress);
                }
            }
        }
    };

    const openLocation = () => setShowLocation(true);

    const applyDiscount = (coupon) => {
        const order = { ...browserOrder, coupon };
        dispatch(storeBrowserOrder(order));
        dispatchValidateOrder(order);
    };

    const clearDiscount = () => {
        setBackendErrors(null);
        props.history.push({ state: null });
        dispatch(storeBrowserOrder({ ...browserOrder, coupon: null }));
    };

    const editPurchase = () => {
        dispatch(setLoadingMaskStatus(true));
        dispatch(setBDOrder(null));
        dispatch(storeBrowserOrder(null));
        props.history.push({ pathname: `/${cartBD?.restaurantSlug}` });
    };

    const continueToPay = () => {
        if (_.isEqual(havanaActualDate, browserOrder?.deliveryDay?.date.split('-').reverse().join('-'))) {
            handleStepDeliveryNext();
        } else {
            dispatch(
                showAlert(
                    `${translation.Payment.confirmDateDialogTitle}
            ${translation.Code === "ES" ? `${selectedDay} ${selectedMonth}` : `${selectedMonth} ${selectedDay}`}
            ${translation.Payment.confirmDateDialogMessage}
          `,
                    "Aceptar",
                    "Cancelar",
                    () => {
                        dispatch(hideAlert());
                        handleStepDeliveryNext();
                    },
                    () => {
                        dispatch(hideAlert());
                    },
                    { required: true }
                )
            );
        }
    }

    const handleStepDeliveryNext = () => {
        dispatch(setLoadingMaskStatus(true));
        if (browserOrder?.total > 0) {
            if (!browserOrder.accountId && browserOrder?.address?.userRef) {
                browserOrder.accountId = browserOrder?.address?.userRef;
            }
            props.history.push({ pathname: "/prv/pay" });
        } else {
            const { taxes, receiver, subtotal, shipping, accountId, restaurantId, receiverPhone, discountByTalon, deliveryOrderType } = browserOrder;
            dispatch(setLoadingMaskStatus(true, translation.Payment.paymentInProgressMessage));
            dispatch(payOrder({
                address: buildAddress(browserOrder),
                taxes, receiver, subtotal, shipping, accountId, restaurantId, receiverPhone, discountByTalon, deliveryOrderType,
                totalCost: browserOrder?.total,
                discount: browserOrder?.discountByCoupon,
                deliveryHour: browserOrder?.deliveryHour?.hour,
                deliveryDay: browserOrder?.deliveryDay?.date.split('-').reverse().join('-'),
                paymentFlowCode: browserOrder?.discountByCoupon ? 'DISCOUNT_COUPON' : 'CLL2',
                payment: {
                    orderId: 0,
                    required: false,
                    paymentMethodId: null,
                    accountId: browserOrder.accountId,
                },
                paymentTransactionid: null,
            }));
        }
    };

    const validateStepForm = () => {
        let errors = {};
        let address = validateFieldByArray(browserOrder?.address, ["required"], translation.Validations["required"]);
        if (address) {
            errors.fullAddress = address
        }
        let deliveryDay = validateFieldByArray(browserOrder?.deliveryDay, ["required"], translation.Validations["required"]);
        if (deliveryDay) {
            errors.deliveryDay = deliveryDay
        }
        let deliveryHour = validateFieldByArray(browserOrder?.deliveryHour, ["required"], translation.Validations["required"]);
        if (deliveryHour) {
            errors.deliveryHour = deliveryHour
        }
        if (backendErrors) {
            errors = { ...errors, ...backendErrors };
        }
        setErrorsSteps(errors);
        setValidSteps(_.isEmpty(errors));
    };

    const handleUpsertAddress = (address) => {
        setShowLocation(false);
        setAddressToEdit(address)
        setShowAddAddressForm(true);
    }

    const closeAddAddressForm = () => {
        setShowAddAddressForm(false);
        setShowLocation(true);
    }

    const handleOnSavedAddress = (address) => {
        setShowAddAddressForm(false);
        setAddressToEdit(address)
        handleSelectDeliveryAddress(address);
        setShowLocation(true);
    }

    return (
        <div className="checkout">
            {isErrorAlert && <CheckoutErrorAlert show={isErrorAlert} errorAlertMessage={errorAlertMessage} />}
            <Alert />
            {/* <MyPurchaseButton {...props} /> */}
            <div className="container" style={{ backgroundColor: "white" }}>
                <div className="grid">
                    <div className="steps new__steps">
                        <div className="step new__step">
                            <div className="new__step__header-wrapper">
                                <div className="step-delivery__actions mb-20">
                                    <button className="button--primary button--primary-inverted" onClick={editPurchase} >
                                        <ArrowBack style={{ fill: '#1e4acc', width: '24px', height: '24px' }} component="svg" />
                                        <span>{translation.Payment.goBack}</span>
                                    </button>
                                </div>

                                <Typography variant="h5" component="h2" className="subtitle mb-20">{cartBD?.restaurantName}</Typography>

                                {/** ORDER TYPE BUTTON*/}
                                <div className="selector">
                                    {restaurantDetails?.deliveryType && <DeliveryModeSwitch defaultValue={browserOrder.deliveryOrderType}
                                        restaurantDetails={restaurantDetails} onChange={handleChangeDeliveryOrderType} />}
                                </div>

                                {/** ADDRESS - CONTACT */}
                                <div className="step-delivery__container">
                                    <SelectAddress isDelivery={browserOrder?.deliveryOrderType === DELIVERY} address={browserOrder?.address}
                                        receiver={browserOrder?.receiver} receiverPhone={browserOrder?.receiverPhone} openLocation={openLocation} />

                                    <AddressesModal required showDialogForm={showLocation} setShowDialogForm={setShowLocation}
                                        handleContinue={handleSelectDeliveryAddress} handleAdd={handleUpsertAddress}
                                    />
                                    <AddAddressModal showDialogForm={showAddAddressForm} setShowDialogForm={setShowAddAddressForm} onClose={closeAddAddressForm}
                                        edit={!addressToEdit} selectedAddress={addressToEdit} restaurant={restaurant} onSavedAddress={handleOnSavedAddress} />
                                </div>

                                <div className="spacer" />

                                <CheckoutDateTime deliveryDay={browserOrder?.deliveryDay} deliveryHour={browserOrder?.deliveryHour}
                                    availableDates={restaurantAvailableDates} availableTimes={restaurantAvailableTimes}
                                    selectDate={handleSelectDeliveryDate} selectTimeline={handleSelectDeliveryTimeline}
                                    errorDay={errorsSteps["deliveryDateText"]} errorHour={errorsSteps["deliveryHour"]}
                                />

                                <div className="spacer" />

                                <DiscountCoupon coupon={browserOrder?.coupon} error={errorsSteps["couponMessage"]}
                                    clearDiscount={clearDiscount} applyDiscount={applyDiscount} />

                                <div className="spacer" />
                            </div>


                            {browserOrder && <Voucher browserOrder={browserOrder} edit={editPurchase} />}

                            <div className="new__step__footer">
                                <div className="new__step__footer__container">
                                    <AcceptTerms acceptTerms={acceptTerms} setAcceptTerms={setAcceptTerms} />

                                    <div className="step-delivery__button-next">
                                        {browserOrder?.total !== undefined ? <button onClick={continueToPay} disabled={!enableButton} className="button--primary button--large">
                                            <span>{translation.Payment.pay}</span>
                                            <span className="bullet__white">{` • `}</span>
                                            <span className="cart-total">
                                                {` $${parseFloat(browserOrder?.total).toFixed(2)}`}
                                            </span>
                                        </button> : <Skeleton variant="rect" width="300px" height={52} animation="wave" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default CheckoutOrder;
