import {CLEAR_STATES, LOADED_TRENDING} from "./trending.actions";

const INITIAL_STATE = {
    trending: [],
    trending_loaded: false,
    message: '',
};

export default function trending(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case LOADED_TRENDING:
            return {
                trending: action.trending || [],
                trending_loaded: true
            };
        case CLEAR_STATES:
            return {
                ...state,
                trending_loaded: false,
                message: ''
            };
        default:
            return state;
    }
}