import { get } from "core/services/ApiRequest";
import { BACKEND } from "../../../config/BackendApis";

const baseUrl = "filter";

export const LOADED_PROVINCES = "LOADED_PROVINCES";
export const LOADED_MUNICIPALITIES = "LOADED_MUNICIPALITIES";
export const LOADED_MAIN_STREETS = "LOADED_MAIN_STREETS";
export const LOADED_BETWEEN_STREETS = "LOADED_BETWEEN_STREETS";
export const LOADED_AND_STREETS = "LOADED_AND_STREETS";
export const LOADED_RESTAURANT_NAMES = "LOADED_RESTAURANT_NAMES";
export const CLEAR_ADDRESS_STATES = "CLEAR_ADDRESS_STATES";

function provincesLoaded(provinces) {
  return {
    type: LOADED_PROVINCES,
    provinces,
  };
}

function municipalitiesLoaded(municipalities) {
  return {
    type: LOADED_MUNICIPALITIES,
    municipalities,
  };
}

function mainStreetsLoaded(main_streets) {
  return {
    type: LOADED_MAIN_STREETS,
    main_streets,
  };
}

function betweenStreetsLoaded(between_streets) {
  return {
    type: LOADED_BETWEEN_STREETS,
    between_streets,
  };
}

function andStreetsLoaded(and_streets) {
  return {
    type: LOADED_AND_STREETS,
    and_streets,
  };
}

export function restaurantNamesLoaded(names) {
  return {
    type: LOADED_RESTAURANT_NAMES,
    names,
  };
}

export function clearAddressStates() {
  return {
    type: CLEAR_ADDRESS_STATES,
  };
}

export function loadProvinces() {
  return (dispatch) => {
    get(BACKEND.ADDRESS_PROVINCES_LIST).then((provinces) => {
      localStorage.setItem("provinces", JSON.stringify(provinces));
      dispatch(provincesLoaded(provinces));
    });
  };
}

export function loadMunicipalities(PROVINCE_ID) {
  return (dispatch) => {
    get(BACKEND.ADDRESS_MUNICIPALITIES_LIST + PROVINCE_ID).then(
      (municipalities) => {
        localStorage.setItem("municipalities", JSON.stringify(municipalities));
        dispatch(municipalitiesLoaded(municipalities));
      }
    );
  };
}

export function loadMainStreets(NAME) {
  return (dispatch) => {
    get(BACKEND.ADDRESS_MAIN_STREET_LIST + NAME).then((main_streets) => {
      dispatch(mainStreetsLoaded(main_streets));
    });
  };
}

export function loadBetweenStreets(MAIN) {
  return (dispatch) => {
    get(BACKEND.ADDRESS_RELATED_LIST + MAIN).then((main_streets) => {
      dispatch(betweenStreetsLoaded(main_streets));
    });
  };
}

export function loadAndStreets(MAIN, BETWEEN) {
  return (dispatch) => {
    get(BACKEND.ADDRESS_RELATED_LIST + MAIN + `&other=${BETWEEN}`).then(
      (and_streets) => {
        dispatch(andStreetsLoaded(and_streets));
      }
    );
  };
}

export function loadRestaurantNames(
  CATEGORY,
  TYPE,
  PROVINCE_ID,
  MUNICIPALITY_ID,
  LOCALITY_ID
) {
  // (CATEGORY ? "&categories=" + CATEGORY : "") +
  let PARAMS =
    (PROVINCE_ID ? "&provinceId=" + PROVINCE_ID : "") +
    (MUNICIPALITY_ID ? "&municipalityId=" + MUNICIPALITY_ID : "") +
    (LOCALITY_ID ? "&localityId=" + LOCALITY_ID : "");
  return (dispatch) => {
    get(`${baseUrl}/getRestaurantNames?type=${TYPE}${PARAMS}`).then((names) => {
      dispatch(restaurantNamesLoaded(names));
    });
  };
}
