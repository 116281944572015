/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './css/EmailFormStyles.scss';
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useTranslations from "../../../../translations/useTranslations";
import CustomInput from "../../../../component/CustomInput/CustomInput";
import { customDecode, customEncode, validateFieldByArray } from "../../../../utils/AuxiliarFunctions";
import { clearAuthState, verifyPin } from "../../store/login.actions";
import { loginUserSelector } from "../../store/login.reducers";

export const EmailForm = (props) => {
    const dispatch = useDispatch();
    const translation = useTranslations();
    const loginResponse = useSelector(loginUserSelector);
    const [email, setEmail] = useState(null);
    const [errors, setErrors] = useState({});
    const [validateForm, setValidateForm] = useState(false);

    useEffect(() => {
        const loginData = JSON.parse(localStorage.getItem('Dt3mp0'));
        if (!loginData || !loginData.username || !loginData.passwordless_pin) {
            localStorage.removeItem('Dt3mp0');
            props.history.push("/login");
            dispatch(clearAuthState());
        }
        localStorage.removeItem('Dv4lP1');
        localStorage.removeItem('D3m41l');
        localStorage.removeItem('D3m41l3rr0r');
    }, []);

    useEffect(() => { validateForm && validateFields(); }, [email]);

    useEffect(() => {
        if (loginResponse.login_success) {
            const url = localStorage.getItem("backTo");
            props.history.push(url ? url : "../");
            localStorage.removeItem("backTo");
            localStorage.removeItem('Dt3mp0');
            localStorage.removeItem('Dv4lP1');
            localStorage.removeItem('D3m41l');
            localStorage.removeItem('D3m41l3rr0r');
            dispatch(clearAuthState());
        }
        if (loginResponse.login_failure) {
            let errorMessage = loginResponse.errors;
            localStorage.setItem("D3m41l3rr0r", customEncode(errorMessage));
            setErrors({ ...errors, email: errorMessage });
        }
        if (loginResponse.show_email_form) {
            props.history.push("/email");
        }
    }, [loginResponse]);

    const handleChangeInputForm = (e) => setEmail(e.target.value);

    const handleSubmit = () => {
        setValidateForm(true);
        if (validateFields()) {
            const loginData = JSON.parse(localStorage.getItem('Dt3mp0'));
            if (loginData && loginData.username && loginData.passwordless_pin) {
                let login = { ...loginData, email: email };
                localStorage.setItem('D3m41l', customEncode(login.email));
                dispatch(verifyPin(login));
            }
        }
    };

    const validateFields = () => {
        let errors = {};
        let userEmail = validateFieldByArray(email, ["required"], translation.Validations["required"]);
        if (userEmail) {
            errors.email = userEmail;
        } else {
            userEmail = validateFieldByArray(email, ["email"], translation.Validations["email"]);
            if (userEmail) {
                errors.email = userEmail;
            }
            const saved = localStorage.getItem('D3m41l');
            const valueSaved = saved ? customDecode(saved) : null;
            if (email === valueSaved) {
                const errorMessage = localStorage.getItem('D3m41l3rr0r');
                errors.email = customDecode(errorMessage);
            }
        }
        setErrors(errors);
        return userEmail === null;
    };

    return (
        <div className="email-form">
            <Grid container className="container" component="div">
                <div className="form-content">
                    <Typography className="title" component="h1">
                        {translation.Login.emailHeader}
                        <span />
                    </Typography>
                    <Typography className="subtitle" component="p">
                        {translation.Login.emailSubHeader}
                    </Typography>
                    <div className="fields">
                        <Grid container item md={10} sm={10} xs={12} spacing={4} component={"div"}>
                            <Grid container item className="form_container__body__fields" md={12} sm={12} xs={12}
                                component="div">
                                <CustomInput
                                    required
                                    id="email"
                                    // error={!validResponse}
                                    // errorMessage={errors["email"]}
                                    fieldLabel={translation.Login.emailField}
                                    value={email}
                                    onChange={e => handleChangeInputForm(e)}
                                />
                                {validateForm && errors.email && <p className="fields-error">{errors.email}</p>}
                            </Grid>

                            <Grid container item xs={12} className="action" component={"div"}>
                                <button onClick={handleSubmit} className="button--primary button--max">
                                    {translation.Login.pinAction}
                                </button>
                            </Grid>
                            {/* <Grid container item xs={12} className="register-link" component="div">
                                <Typography onClick={backToLogin}
                                    component="p">{translation.Login.backToLogin}</Typography>
                            </Grid> */}
                        </Grid>
                    </div>
                </div>
            </Grid>
        </div>
    )
};