import React, { useEffect, useState } from "react";
import SearchBar from 'material-ui-search-bar';
import "./SearchableList.scss";
import { Grid } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const SearchableList = ({ list = [], field1 = 'name', field2 = 'details', fieldEditable = 'userRef', handleClick, handleAdd }) => {
  const [searchWord, setSearchWord] = useState("");
  const [filteredList, setFilteredList] = useState([...list]);

  useEffect(() => {
    const term = searchWord?.trim();
    if (term?.length) {
      const filtered = list.filter(item => (
        `${item[field1].toLowerCase()}${field2 && item[field2] ? item[field2].toLowerCase() : ""}`.includes(term.toLowerCase()))
      );
      setFilteredList(filtered);
    } else {
      setFilteredList([...list]);
    }
  }, [field1, field2, list, searchWord]);

  return <>
    <div className="searchbar">
      <SearchBar
        onChange={setSearchWord}
        onRequestSearch={setSearchWord}
        placeholder="Escriba parte del nombre del elemento a buscar ..."
        autoFocus
      />
      <div className="list" style={{ height: "calc(440px - (1.3em + 2em) - 49px)" }}>
        {!filteredList.length ? (
          <h3 className="text-center">No existen elementos que mostrar</h3>
        ) : (
          filteredList.map(item => <div key={item[field1]} className="list_items">
            <Grid container component="div" className="list_items__container">
              <Grid container item md={10} sm={10} xs={9} component="div"
                className="list_items_content" onClick={() => handleClick(item)}>
                <Grid container item md={12} sm={12} xs={12} component="div" className="field1">
                  <span>{item[field1]}</span>
                </Grid>
                <Grid container item md={12} sm={12} xs={12} component="div" className="field2">
                  <span>{item[field2]}</span>
                </Grid>
              </Grid>
              {item[fieldEditable] ? <Grid container item md={2} sm={2} xs={3} component="div"
                className="list_items_actions"
                onClick={() => handleAdd(item)}
              >
                <Edit style={{ color: '#2c2c2c' }} component="svg" />
              </Grid> : null}
            </Grid>
          </div>)
        )}
        <div className="list__bottom"></div>
      </div>
    </div>
  </>
}

export default SearchableList;