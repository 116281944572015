import {get} from "core/services/ApiRequest";
import {BACKEND} from "config/BackendApis";

export const LOADED_PROMOTIONS = 'LOADED_PROMOTIONS';
export const LOADED_SPECIFICATIONS = 'LOADED_SPECIFICATIONS';
export const CLEAR_NOM_STATES = 'CLEAR_NOM_STATES';

export function promotionsLoaded(promotions) {
    return {
        type: LOADED_PROMOTIONS,
        promotions
    }
}

export function specificationsLoaded(specifications) {
    return {
        type: LOADED_SPECIFICATIONS,
        specifications
    }
}

export function clearNomStates() {
    return {
        type: CLEAR_NOM_STATES
    }
}

export function loadPromotions() {
    return dispatch => {
        get(BACKEND.PROMOTIONS).then(promotions => {
            dispatch(promotionsLoaded(promotions))
        });
    }
}

export function loadSpecifications() {
    return dispatch => {
        get(BACKEND.SPECIFICATIONS).then(specifications => {
            dispatch(specificationsLoaded(specifications))
        });
    }
}