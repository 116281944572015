import {CLEAR_NOM_STATES, LOADED_PROMOTIONS, LOADED_SPECIFICATIONS} from "./nom.actions";

const INITIAL_STATE = {
    promotions: [],
    promotions_loaded: false,
    specifications: [],
    specifications_loaded: false,
    message: '',
};

export default function nom(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case LOADED_PROMOTIONS:
            return {
                promotions: action.promotions || [],
                promotions_loaded: true
            };
        case LOADED_SPECIFICATIONS:
            return {
                specifications: action.specifications || [],
                specifications_loaded: true
            };
        case CLEAR_NOM_STATES:
            return {
                ...state,
                promotions_loaded: false,
                specifications_loaded: false,
                message: ''
            };
        default:
            return state;
    }
}