import React, { useEffect, useState } from 'react';
import './css/PurchaseHistoryCardStyles.scss';
import Grid from "@material-ui/core/Grid";
import * as _ from 'lodash';
import { PurchaseHistoryDialog } from "../PurchaseHistoryDialog/PurchaseHistoryDialog";
import useTranslations from "../../../../translations/useTranslations";
import { ResendOrderDialog } from "../ResendOrderDialog/ResendOrderDialog";
import { formatDateWithMonthShort } from "../../../../utils/AuxiliarFunctions";
import { TASA_PESO } from "../../../../constants";

export const PurchaseHistoryCard = (props) => {
  const data = props.data;
  const offers = data.offersList ? data.offersList : [];
  const translation = useTranslations();
  const [showMore, setShowMore] = useState(false);
  const [confirmResendOrder, setConfirmResendOrder] = useState(false);
  const [totalOfferCount, setTotalOfferCount] = useState(0);
  const [previewOffers, setPreviewOffers] = useState([]);

  // useEffect(() => {
  //   if (data && data.deliveryDateReal) {
  //     formatDateWithMonthShort(data.deliveryDateReal);
  //   }
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (offers && offers.length > 0) {
      let aux = [];
      let count = 0;
      let quantity = 0;
      _.forEach(offers, function (item) {
        quantity += item.count;
        if (count < 1) {
          aux.push(item);
          count++;
        }
      });
      setPreviewOffers(aux);
      setTotalOfferCount(quantity);
    }
    //eslint-disable-next-line
  }, [data]);

  const showMoreDetail = () => {
    setShowMore(true);
  };

  const hideMoreDetail = () => {
    setShowMore(false);
  };

  const resendOrder = () => {
    setShowMore(false);
    setConfirmResendOrder(true);
  };

  const cancelResendOrder = () => {
    setConfirmResendOrder(false);
  };

  let MORE_DETAIL = null;
  if (showMore) {
    MORE_DETAIL = (
      <PurchaseHistoryDialog {...props} data={data} show={showMore} hide={hideMoreDetail} resend={resendOrder} />
    )
  }

  let RESEND_ORDER = null;
  if (confirmResendOrder) {
    RESEND_ORDER = (
      <ResendOrderDialog {...props} data={data} show={confirmResendOrder} hide={cancelResendOrder} />
    )
  }

  return (
    <div className="history-card">
      {MORE_DETAIL}
      {RESEND_ORDER}
      <Grid container item className="card" component={"div"}>
        <Grid container item className="history-card-image" component={"div"}>
          <img src={data.imgSm} alt={data.imgSm} />
        </Grid>
        <Grid container item className="card-middle" component={"div"}>
          <Grid className="top" container item component={"div"}>
            <Grid className="name" container item component={"div"}>
              <h3>{data.restaurantName}</h3>
            </Grid>
            <Grid className="resume-line" container item component={"div"}>
              <span className="label-text">
                {totalOfferCount}
                {totalOfferCount > 1 ? translation.PurchaseHistory.itemsBy : translation.PurchaseHistory.itemBy}
              </span>
              <span className="label-text">
                {data.paymentType && data.paymentType.id === 6 ?
                  data.totalCost ? (data.totalCost * TASA_PESO).toFixed(2) + " CUP" : (0).toFixed(2) + " CUP" :
                  data.totalCost ? "$" + data.totalCost.toFixed(2) : "$" + (0).toFixed(2)}
                <span className="dot">{'·'}</span>
              </span>
              {/*<span className="label-text">
                {data.deliveryDate}
                <span className="dot">{'·'}</span>
              </span>*/}
              <span className="more" onClick={showMoreDetail}>
                {translation.PurchaseHistory.more}
              </span>
              {/*<span className="label-text">*/}
              {/*    <span className="dot">{'·'}</span>*/}
              {/*</span>*/}
              {/*<span className="more" onClick={resendOrder}>*/}
              {/*    {translation.PurchaseHistory.resendOrder}*/}
              {/*</span>*/}
            </Grid>
            <Grid className="delivered" container item component={"div"}>
              <span className="card-label">{translation.PurchaseHistory.orderId}</span>
              <span className="card-value">{data.purchaseId}</span>
            </Grid>
            <Grid className="delivered" container item component={"div"}>
              <span className="card-label">{translation.PurchaseHistory.deliveredTo}</span>
              <span className="card-value">{data.contactName}</span>
            </Grid>
            <Grid className="delivered" container item component={"div"}>
              <span className="card-label">{translation.PurchaseHistory.status}</span>
              <span className="card-value">
                {data.status ? translation.Code === 'ES' ? data.status.nameEs : data.status.nameEn : null}
              </span>
            </Grid>
            <Grid className="delivered" container item component={"div"}>
              <span className="card-label">{translation.PurchaseHistory.deliveryDate}: </span>
              <span className="card-value">{formatDateWithMonthShort(data.deliveryDateReal)}</span>
            </Grid>
          </Grid>
          <Grid className="phc-offers" container item component={"div"}>
            <ul>
              {previewOffers && previewOffers.length > 0 ? previewOffers.map((item, j) => {
                return (
                  <li key={j}>
                    <span className="phc-item-quantity">{item.count}</span>
                    <div className="phc-offer-detail">
                      <p className="main">{item.offer.name}</p>
                      {/*{item.offer.offerVariant && item.offer.offerVariant.length > 0 ?*/}
                      {/*  <p className="secondary">*/}
                      {/*    ({item.offerVariant.map((variant) => `${translation.Code === "ES" ? variant.nameEs : variant.nameEn}`).join(", ")})*/}
                      {/*  </p> : null}*/}
                      {/*{item.offer.offerAdds && item.offer.offerAdds.length > 0 ?*/}
                      {/*  <p className="secondary">*/}
                      {/*    ({item.offer.offerAdds.map(({name}) => `${name}`).join(", ")})*/}
                      {/*  </p> : null}*/}
                    </div>
                  </li>
                )
              }) : null}
              {offers && offers.length > 2 ? <li className="dot">{' ... '}</li> : null}
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
};