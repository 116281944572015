import React, { useEffect, useRef, useState } from 'react';
import './css/BusinessCarouselListStyles.scss';
import { ArrowForward, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import Swiper from "react-id-swiper";
import OfferItem from "../OfferItem/OfferItem";
import useTranslations from "../../../../translations/useTranslations";
import Grid from "@material-ui/core/Grid";
import AtomIcon from 'component/Icon/AtomIcon';
import { store } from "../../../../App";
import { setFilters } from "../../../../store/filter/filter.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectFilters } from "../../../../store/filter/filter.reducers";
import { setLoadingMaskStatus } from "../../../../component/Mask/store/mask.actions";

const BusinessCarouselList = (props) => {
  const translation = useTranslations();
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const restaurant = props.restaurant;
  const estimatedDeliveryTime = props.restaurant.deliveryTimeRestaurantDto;
  const offers = props.offers;
  const businessCategorySelected = store.getState().filters.filters.businessCategory;
  const [tagsExtras, setTagsExtras] = useState([]);
  const params = {
    //spaceBetween: 32,
    //slidesPerView: 'auto',
    slidesPerView: 5,
    breakpoints: {
      0: {
        slidesPerView: 'auto',
      },
      320: {
        spaceBetween: 18,
        slidesPerView: 1.25,
        slidesPerGroup: 1,
      },
      390: {
        spaceBetween: 18,
        slidesPerView: 1.25,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 32,
        slidesPerView: 1.65,
        slidesPerGroup: 1,
      },
      620: {
        spaceBetween: 32,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      768: {
        spaceBetween: 32,
        slidesPerView: 2.5,
        slidesPerGroup: 2,
      },
      1024: {
        spaceBetween: 32,
        slidesPerView: 3.35,
        slidesPerGroup: 3,
      },
      1230: {
        spaceBetween: 32,
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      1350: {
        spaceBetween: 32,
        slidesPerView: 4.35,
        slidesPerGroup: 4,
      }
    },
  };
  const swiperRef = useRef(null);

  const goNext = () => swiperRef.current?.swiper?.slideNext();
  const goPrev = () => swiperRef.current?.swiper?.slidePrev();

  useEffect(() => {
    if (restaurant?.id) {
      const extras = restaurant.restaurantExtrasById || [];
      const tags = extras.map(item => item.tagExtrasByTagExtrasid);
      setTagsExtras(tags);
    }
  }, [restaurant]);

  const viewBusinessDetail = (data) => {
    const BC = businessCategorySelected?.slug ? businessCategorySelected :
      data.categoryDto?.length ? data.categoryDto[0] : null;
    if (data?.slug && BC?.slug) {
      dispatch(setLoadingMaskStatus(true));
      dispatch(setFilters({
        ...filters,
        categories: [],
        businessCategory: BC
      }));
      props.history.push({ pathname: data.slug });
    }
  };

  return (
    <Grid className="restaurant-carousels-list-item" container item md={12} sm={12} xs={12} component="div">
      <div className={offers?.length ? "wrap container-fluid" : "hide"}>
        <div className="carousel-title-bar">
          <div className="tag-container">
            {tagsExtras?.map((tags, index) => (
              <div key={index} className="restaurant-tag">
                <img src={tags.image} alt={tags.name} />
              </div>
            ))}
          </div>
          <div className="restaurant-name">
            <h2 onClick={() => viewBusinessDetail(restaurant)}>{restaurant.name}</h2>
            {restaurant.averageScore && <div className="rating">
              <AtomIcon icon="star--blue" />
              <span>{Math.round(restaurant.averageScore * 10) / 10}</span>
            </div>}
          </div>
          <div className="restaurant-detail">
            <div className="restaurant-info">
              {restaurant.deliveryCost && <span className="key">{`${translation.Payment.minDeliveryCost}`}</span>}
              {restaurant.deliveryCost && <span className="value">{`$${restaurant.deliveryCost.toFixed(2)}`}</span>}
              {restaurant.deliveryCost && <span className="bar">{"|"}</span>}
              <span className="key">{`${translation.Payment.estimatedDeliveryTime} `}</span>
              <span className="value">
                {estimatedDeliveryTime?.deliveryTime && estimatedDeliveryTime.deliveryTime + " "}
                {estimatedDeliveryTime?.deliveryTime && translation.Code === "ES"
                  ? estimatedDeliveryTime.deliveryTimeUnitEs + " "
                  : estimatedDeliveryTime.deliveryTimeUnitEn + " "}
              </span>
            </div>
            {props.mobileSize || (offers?.length < 4) ? null :
              <div className="carousel-swiper-buttons">
                <button className="swiper-button-prev" onClick={goPrev}>
                  <KeyboardArrowLeft component={"svg"} />
                </button>
                <button className="swiper-button-next" onClick={goNext}>
                  <KeyboardArrowRight component={"svg"} />
                </button>
              </div>}
          </div>
        </div>
        <Swiper {...params} ref={swiperRef}>
          {offers?.map((data, index) => (
            <div>
              <OfferItem key={index} {...props} data={data}
                restaurantSlug={restaurant.slug} />
            </div>
          ))}
          <div className="showAll">
            <div className="showAllLink" onClick={() => viewBusinessDetail(restaurant)}>
              {translation.General.viewAll}
              <ArrowForward component={"svg"} />
            </div>
          </div>
        </Swiper>
      </div>
    </Grid>
  )
};

export default BusinessCarouselList;