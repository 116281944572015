import * as cs_actions from "./customer-satisfaction.actions";

const INITIAL_STATE = {
    cs_score: null,
    cs_existsScore: false,
    cs_restaurant: null,
    cs_register_checked_success: false,
    cs_register_checked_failure: false,
    cs_register_created_success: false,
    cs_register_created_failure: false,
}

export default function customer_satisfaction(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case cs_actions.CHECK_CS_REGISTER_SUCCESS: {
            const data = action.response.result;
            return {
                ...state,
                cs_score: data.score || null,
                cs_existsScore: data.existsScore || false,
                cs_restaurant: data.restaurant || null,
                cs_register_checked_success: true,
            }
        }
        case cs_actions.CHECK_CS_REGISTER_FAILURE: {
            return {
                ...state,
                cs_score: null,
                cs_existsScore: false,
                cs_restaurant: null,
                cs_register_checked_failure: true,
            }
        }
        case cs_actions.CREATE_CS_REGISTER_SUCCESS: {
            const data = action.response.data;
            return {
                ...state,
                cs_score: data.score,
                cs_existsScore: true,
                cs_restaurant: data.restaurant,
                cs_register_created_success: true,
            }
        }
        case cs_actions.CREATE_CS_REGISTER_FAILURE: {
            return {
                ...state,
                cs_score: null,
                cs_existsScore: false,
                cs_restaurant: null,
                cs_register_created_failure: true,
            }
        }
        case cs_actions.CLEAR_CS_STATE: {
            return {
                ...state,
                cs_register_created_success: false,
                cs_register_created_failure: false,
                cs_register_checked_success: false,
                cs_register_checked_failure: false,
            }
        }
        default:
            return state;
    }
}

export const selectCsState = state => state.customer_satisfaction;