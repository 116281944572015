import React from "react";

export default function CardIcon({ icon, ext = 'svg', width = 16, alt, height = "auto" }) {
  return (
    <img
      className="card-icon"
      width={width}
      height={height}
      src={`./images/icons/cards/${icon}.${ext || 'svg'}`}
      alt={alt}
    />
  );
}
