import { createSelector } from "reselect";
import * as actions from "./actions";

export const searchBoxKey = "last_search";

const INITIAL_STATE = {
  term: "",
  list: [],
  count: 0,
  page: 0,
  pageTotal: 0,
  total: 0,
  loading: false,
};

export default function searchBox(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: true };
    case actions.LOADED:
      return { ...state, loading: false };
    case actions.SEARCH_SUCCESS:
      return {
        ...state,
        ...action.response.data,
        loading: false,
      };
    case actions.LOAD_FROM_CACHE: {
      const lastSearch = JSON.parse(localStorage.getItem(searchBoxKey) || "{}");
      if (lastSearch?.term?.lenght && lastSearch?.list?.lenght) {
        return {
          ...state,
          loading: false,
          term: lastSearch.term,
          list: lastSearch.list,
        };
      }
      return state;
    }
    case actions.CLEAR_STATES:
    case actions.SEARCH_FAIL:
      return INITIAL_STATE;
    default:
      return state;
  }
}

const selectState = (state) => state.searchBox;

export const getEntityById = (id) => (state) =>
  state.restaurant.filter((r) => r.id === id);

export const selectBusinessById = (id) =>
  createSelector(selectState, getEntityById(id));

export const selectTotal = createSelector(selectState, (state) => state.total);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.loading
);

export const selectTerm = createSelector(selectState, (state) => state.term);

export const selectList = createSelector(selectState, (state) => state.list);
